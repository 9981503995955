import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';


function ResetConfirmOTPForm() {

  const location = useLocation();

  const [disabled, setDisabled] = useState(false);
  const [verified, setVerified] = useState(false);
  const { t } = useTranslation();
  const email = location?.state?.email;
  const navigate = useNavigate();
  const timestamp = location?.state?.timestamp;

  if (!timestamp || !email) {
    navigate('/auth/password-reset');
  }

  const legitimize = async (values) => {

    var formData = { ...values };

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.processing'));

    return await postData('auth/password-reset/otp-validate/', formData, { sleep: 500 }).then(async (response) => {
      toast.update(brot, { render: t('public.auth.toast-otp-verified'), type: 'success', isLoading: false, autoClose: 1000 });
      return true;
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 2000 });
      return false;
    }).finally(() => {
      setDisabled(false);
    });
  };


  const reset = async (values) => {

    var formData = { ...values };

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.saving'));

    return await postData('auth/password-reset/otp-confirm/', formData, { sleep: 500 }).then(async (response) => {
      navigate('/auth/login', { state: { justChanged: true } })
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
      return false;
    }).finally(() => {
      setDisabled(false);
      toast.dismiss(brot);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: '',
      email: email,
      new_password: '',
      new_password_confirm: '',
      timestamp: timestamp,
    },
    onSubmit: async (values) => {

      if (!verified) {
        let result = await legitimize(values);
        setVerified(result);
        return;
      }

      reset(values);
    },
  });

  if (!verified || formik.errors?.fields?.code) {
    return (
      <>
        <p className="text-center lead text-muted">{t('public.auth.password-reset-lead-confirm-otp', { email })}</p>
        <br />
        <Form.Group className="mb-3">
          <Form.Label>{t('public.auth.field-otp')}</Form.Label>
          <Form.Control
            autoComplete="off"
            isInvalid={formik.errors?.fields?.code}
            name="code"
            onChange={formik.handleChange}
            value={formik.values.code}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors?.fields?.code}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="text-center my-3">
          <div className="d-grid gap-2">
            <Button type="button" onClick={() => { setVerified(false); formik.handleSubmit() }} variant="primary" disabled={disabled}>
              {t('public.auth.btn-legitimize')}
            </Button>
          </div>
        </div>
        <small>
          <Link to="/auth/login" className="text-decoration-none">{t('forms.actions.cancel')}</Link>
        </small>
      </>
    );
  }

  return (
    <>
      <p className="text-center lead text-muted">{t('public.auth.password-reset-lead-confirm-otp-new-pw')}</p>
      <br />
      <Form.Group className="mb-3">
        <Form.Label>{t('public.auth.field-new-password')}</Form.Label>
        <Form.Control
          autoComplete="off"
          isInvalid={formik.errors?.fields?.new_password}
          name="new_password"
          onChange={formik.handleChange}
          type="password"
          value={formik.values.new_password}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors?.fields?.new_password}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>{t('public.auth.field-new-password-confirm')}</Form.Label>
        <Form.Control
          autoComplete="off"
          isInvalid={formik.errors?.fields?.new_password_confirm}
          name="new_password_confirm"
          onChange={formik.handleChange}
          type="password"
          value={formik.values.new_password_confirm}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors?.fields?.new_password_confirm}
        </Form.Control.Feedback>
      </Form.Group>
      <div className="text-center my-3">
        <div className="d-grid gap-2">
          <Button type="button" onClick={formik.handleSubmit} variant="primary" disabled={disabled}>
            {t('public.auth.btn-change-password')}
          </Button>
        </div>
      </div>
      <small>
        <Link to="/auth/login" className="text-decoration-none">{t('forms.actions.cancel')}</Link>
      </small>
    </>
  );
}

export default ResetConfirmOTPForm;
