import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import { People as GroupIcon } from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import addImage from '../../assets/img/artwork/add.png';
import CollectionGroupCard from '../../components/collections/CardGroup';
import MemberAvatar from '../../components/groups/MemberAvatar';
import GroupModalAdd from '../../components/groups/ModalAdd';
import GroupInvitationModalAdd from '../../components/groups/ModalAddInvitation';
import GroupModalDelete from '../../components/groups/ModalDelete';
import GroupModalEdit from '../../components/groups/ModalEdit';
import GroupModalLeave from '../../components/groups/ModalLeave';
import GroupSidebarInfo from '../../components/groups/SidebarInfo';
import GroupSidebarPermission from '../../components/groups/SidebarPermission';
import GroupToolbar from '../../components/groups/Toolbar';
import { GET_GROUP_BY_ID } from '../../components/groups/queries';
import useLocalStorage from '../../hooks/useLocalStorage';
import { infoFromGroup } from '../../utils/renderHelper';


const Group = () => {

  const [group, setGroup] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { groupId } = useParams();
  const { t } = useTranslation();

  const [getGroup, { loading, error, refetch }] = useLazyQuery(GET_GROUP_BY_ID, {
    variables: { id: groupId },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setGroup(data.groupById);
    },
  });

  const createModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      refetch();
    },
  };

  const createInvitationHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      refetch();
    },
  };

  const leaveGroupModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    leftGroup: () => {
      handleDeletionResponse();
    },
  };

  const editModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      if (response === 'destroyed') {
        handleDeletionResponse();
      } else {
        refetch();
      }
    },
  };

  const deleteModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      handleDeletionResponse();
    },
  };

  const infoSidebarHandler = {
    opened: Object.assign({}, ...useLocalStorage('show-sidebar-info', false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
  };

  const permissionSidebarHandler = {
    opened: Object.assign({}, ...useLocalStorage('show-sidebar-permissions', false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
  };

  const handleDeletionResponse = () => {
    navigate('/private/groups', { state: { doRefetch: true } });
  };

  const renderBreadcrump = ({ showImage = true, className = 'mb-5', bsPrefix = 'breadcrumb' }) => {
    let imgInfo = infoFromGroup(group);

    return (
      <Breadcrumb className={className} bsPrefix={bsPrefix}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/private/groups" }}>
          {showImage && <GroupIcon className="bi img-fluid rounded me-1 align-text-top" width={20} height={20} style={{maxHeight: '20px', minHeight: '20px'}} />}
          {t('pages.groups.headline')}
        </Breadcrumb.Item>
        <Breadcrumb.Item active linkAs={Link} linkProps={{ to: `/private/groups/${group.pk}` }}>
          {showImage && imgInfo && (
            <img
              alt={group.name}
              title={group.name}
              className="img-fluid rounded-circle me-1 align-text-top"
              src={`${process.env.REACT_APP_MEDIA_URL}${imgInfo.img}`}
              width="20"
              height="20"
              style={{maxHeight: '20px', minHeight: '20px'}}
            />
          )}
          {group?.name}
        </Breadcrumb.Item>

      </Breadcrumb>
    );
  };

  useEffect(() => { getGroup(); }, [groupId, getGroup]);
  useEffect(() => { if (location.state?.doRefetch) refetch(); }, [location.state?.doRefetch, refetch]);

  if (error) return `${error}`;
  if (!group) return null;

  return (
    <>
      {createModalHandler.opened.value && <GroupModalAdd handler={createModalHandler} />}
      {createInvitationHandler.opened.value && <GroupInvitationModalAdd handler={createInvitationHandler} group={group} />}
      {leaveGroupModalHandler.opened.value && <GroupModalLeave handler={leaveGroupModalHandler} cachedGroup={group} />}
      {editModalHandler.opened.value && <GroupModalEdit handler={editModalHandler} original={group} />}
      {deleteModalHandler.opened.value && <GroupModalDelete handler={deleteModalHandler} group={group} />}
      <Helmet title={group.name} />
      <GroupToolbar
        group={group}
        infoSidebarHandler={infoSidebarHandler}
        loading={loading}
        permissionSidebarHandler={permissionSidebarHandler}
        refetch={refetch}
        showAddDialog={createModalHandler.opened.set}
        showDeleteDialog={deleteModalHandler.opened.set}
        showEditDialog={editModalHandler.opened.set}
        showInviteDialog={createInvitationHandler.opened.set}
        showLeaveGroupDialog={leaveGroupModalHandler.opened.set}
      />
      <div className="mainspace">
        <div className="splitter d-flex">
          <div className={`panel overflow-auto flex-fill ${(infoSidebarHandler.opened.value || permissionSidebarHandler.opened.value) ? 'd-none d-sm-block' : ''}`}>
            <div className="position-relative h-100 w-100">
              <div className="module">
                <div className="mainspace">
                  <div className="p-4 pb-0 pt-3">
                    <h2>{group?.name || '...'}</h2>
                    <hr className="mt-0 mb-3" />
                    {renderBreadcrump({ bsPrefix: 'breadcrumb flex-column flex-sm-row' })}
                    <h3 className="mb-4">{t('pages.groups.headline-members')}</h3>
                    <Row className="mb-5">
                      {group.members && group.members.length > 0 && group.members.map((member, index) => (
                        <Col key={index} xs={4} sm={2} lg={2} xl={1}>
                          <MemberAvatar member={member} height="auto" width="auto" className="rounded-circle img-fluid mb-4" />
                        </Col>
                      ))}
                      <Col xs={4} sm={2} lg={2} xl={1}>
                        <img
                          src={addImage}
                          alt={t('forms.alt-tags.avatar')}
                          className="rounded-circle img-fluid"
                          height="auto"
                          width="auto"
                          onClick={() => createInvitationHandler.opened.set(true)}
                        />
                      </Col>
                    </Row>
                    {group?.collectionCount > 0 && (
                      <>
                        {group.collections.filter(c => c.parent === null).length > 0 && (
                          <>
                            <h3 className="mb-4">{t('pages.groups.headline-main-collections')}</h3>
                            <Row className="mb-5">
                              {group.collections.filter(c => c.parent === null).map((subCollection, index) => (
                                <Col key={index} sm={12} md={12} lg={6} xl={4} xxl={3} className='d-flex'>
                                  <CollectionGroupCard collection={subCollection} group={group} />
                                </Col>
                              ))}
                            </Row>
                          </>
                        )}
                        {group.collections.filter(c => c.parent !== null).length > 0 && (
                          <>
                            <h3 className="mb-4">{t('pages.groups.headline-sub-collections')}</h3>
                            <Row className="mb-5">
                              {group.collections.filter(c => c.parent !== null).sort((a, b) => a.name < b.name ? -1 : 1).map((subCollection, index) => (
                                <Col key={index} sm={12} md={12} lg={6} xl={4} xxl={3} className='d-flex'>
                                  <CollectionGroupCard collection={subCollection} group={group} />
                                </Col>
                              ))}
                            </Row>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {infoSidebarHandler.opened.value && <GroupSidebarInfo handler={infoSidebarHandler} group={group} />}
          {permissionSidebarHandler.opened.value && <GroupSidebarPermission handler={permissionSidebarHandler} group={group} />}
        </div>
      </div>
    </>
  );
};

export default Group;
