import React from 'react';

import { Outlet } from 'react-router-dom';
import Main from '../components/basic/Main';
import Footer from '../components/public/Footer';
import Navbar from '../components/public/Navbar';


const Meta = ({ children }) => (
  <>
    <Navbar variant='blue' />
    <Main className="main-landing">
      <div style={{ marginTop: '80px', marginBottom: '150px' }}>
        {children}
        <Outlet />
      </div>
    </Main>
    <Footer spacer={true} variant='parcel' />
  </>
);

export default Meta;
