import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import ChatBox from '../../components/basic/ChatBox';
import ItemMessageBox from '../../components/items/MessageBox';
import { GET_ITEM_COMMENTS } from '../../components/items/queries';


const ItemSidebarComments = ({ handler, item }) => {

  const { opened } = handler;
  const { t } = useTranslation();
  const [itemComments, setItemComments] = useState([]);

  const [getItemComments, { loading, refetch }] = useLazyQuery(GET_ITEM_COMMENTS, {
    variables: { id: item?.pk },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setItemComments(data.itemById?.comments || []);
    },
  });

  const handleClose = () => {
    opened.set(false);
  };

  useEffect(() => {
    if (item) getItemComments();
  }, [item, getItemComments]);

  if (!item) return null;

  return (
    <>
      <div className="panel bg-body-tertiary border-start flex-fill mw-330 overflow-hidden">
        <div className="app">
          <div className="module modal">
            <div className="modal-header">
              <div className="modal-title h5">{t('sidebars.item-comments.title')}</div>
              <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
            </div>
            <div className="mainspace p-3">
              {itemComments && itemComments.length > 0 && <ChatBox messages={itemComments} loading={loading} />}
            </div>
            <div className="p-3 pb-0 border-top">
              <ItemMessageBox endpoint="warehouse/items/message/" instance={item} visible={true} refetch={refetch} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemSidebarComments;
