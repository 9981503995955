import React, { useState } from 'react';

import { faBox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Dropdown } from 'react-bootstrap';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Lightbox } from 'react-modal-image';
import { useNavigate } from 'react-router-dom';
import { LIST_VIEW_MODES } from '../basic/Classes';


const ElementCard = ({
  collection,
  deleteItemModalHandler,
  editItemModalHandler,
  element,
  listViewMode,
  moveElementModalHandler,
  removeElementModalHandler,
  selected,
  setYaLightboxIndex,
  toggleElementModalHandler,
  usePublicAddr = false,
  yaLightBoxIndex = -1,
}) => {

  const [lightbox, setLightbox] = useState();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const openElement = (elementId) => {
    if (usePublicAddr) {
      navigate(`/c/${collection.publicSlug || collection.publicPk}/${elementId}`);
    } else {
      navigate(`/private/collections/${collection.pk}/${elementId}`);
    }
  };

  const removeElement = () => {
    removeElementModalHandler.opened.set(element);
  }

  const hideElement = () => {
    toggleElementModalHandler.opened.set(element);
  }

  const showElement = () => {
    toggleElementModalHandler.opened.set(element);
  }

  const deleteItem = () => {
    deleteItemModalHandler.opened.set(element?.item);
  }

  const editItem = () => {
    editItemModalHandler.opened.set(element?.item);
  }

  const moveElement = () => {
    moveElementModalHandler.opened.set(element.pk);
  }

  const lightboxElement = () => {
    if (element.item.cardImageThumbnail || element.item.typeOf?.itemImageThumbnail) {
      openLightbox({
        large: `${process.env.REACT_APP_MEDIA_URL}${element.item.imageFull || element.item.cardImageThumbnail || element.item.typeOf?.itemImageThumbnail}`,
        alt: element.item.name,
      });
    }
  };

  const openLightbox = (params) => {
    if (params?.large) setLightbox(params);
  }

  const closeLightbox = (params) => {
    setLightbox();
  }

  const renderDropDown = () => {
    return (
      <Dropdown onClick={(e) => e.stopPropagation()} className="float-end">
        <Dropdown.Toggle as="a" variant="success" className="dropdown-no-toggle shadowed-details" id={`dropdown-basic-${element.pk}`}>
          <ThreeDotsVertical className="ms-2 dropdown-no-toggle" size={18} alt="Select actions" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          {editItemModalHandler && <Dropdown.Item onClick={editItem}>{t('pages.element.card-burger-edit')}</Dropdown.Item>}
          {removeElementModalHandler && <Dropdown.Item onClick={removeElement}>{t('pages.element.card-burger-remove')}</Dropdown.Item>}
          {toggleElementModalHandler && !element.isHidden && (
            <Dropdown.Item onClick={showElement}>
              {t('pages.element.card-burger-hide')}
            </Dropdown.Item>
          )}
          {toggleElementModalHandler && element.isHidden && (
            <Dropdown.Item onClick={hideElement}>
              {t('pages.element.card-burger-show')}
            </Dropdown.Item>
          )}
          {deleteItemModalHandler && <Dropdown.Item onClick={deleteItem}>{t('pages.element.card-burger-delete')}</Dropdown.Item>}
          <Dropdown.Item onClick={lightboxElement}>
            {t('pages.element.card-burger-preview')}
          </Dropdown.Item>
          {setYaLightboxIndex && (
            <Dropdown.Item onClick={() => setYaLightboxIndex(yaLightBoxIndex)}>
              {t('pages.element.card-burger-gallery')}
            </Dropdown.Item>
          )}
          <Dropdown.Divider />
          {moveElementModalHandler && (
            <>
              <Dropdown.Item onClick={() => moveElement(element.pk)}>
                {t('pages.element.card-burger-move')}
              </Dropdown.Item>
              <Dropdown.Divider />
            </>
          )}
          <Dropdown.Item onClick={() => openElement(element.pk)}>
            {t('pages.element.card-burger-open')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  if (!listViewMode || listViewMode === LIST_VIEW_MODES.Vertical)
    return (
      <>
        <Card className={`mb-4 card-warehouse item ${element.isHidden ? 'transparent' : ''}`} data-last-element={selected} role="button" onClick={() => openElement(element.pk)}>
          {element.item.cardImageThumbnail && <img className="card-img-top bg-body" loading="lazy" src={`${process.env.REACT_APP_MEDIA_URL}${element.item.cardImageThumbnail}`} alt={element.item.name} />}
          {!element.item.cardImageThumbnail && element.item.typeOf?.itemImageThumbnail && <img className="card-img-top bg-body" loading="lazy" src={`${process.env.REACT_APP_MEDIA_URL}${element.item.typeOf.itemImageThumbnail}`} alt={element.item.name} />}
          {!element.item.cardImageSquare && !element.item.typeOf?.cardImageSquare && <FontAwesomeIcon icon={faBox} size="lg" className="my-5 display-1 text-warning" />}
          <Card.ImgOverlay className="p-0 pe-2 pt-2">
            <div className="text-end">{renderDropDown()}</div>
          </Card.ImgOverlay>
          <Card.Body className={`p-2 border-top rounded-bottom ${selected ? ' text-bg-primary' : ''}`}>
            <Card.Text>{element.item.name}</Card.Text>
          </Card.Body>
        </Card>
        {lightbox && <Lightbox large={lightbox.large} alt={lightbox.alt} onClose={closeLightbox} showRotate={true} />}
      </>
    );

  if (listViewMode === LIST_VIEW_MODES.Horizontal)
    return (
      <>
        <Card className={`item card-warehouse h-100 ${element.isHidden ? 'transparent' : ''}`} data-last-element={selected} role="button" onClick={() => openElement(element.pk)}>
          {element.item.cardImageSquare && <img className="card-img-top ratio ratio-1 bg-body" loading="lazy" src={`${process.env.REACT_APP_MEDIA_URL}${element.item.cardImageSquare}`} alt={element.item.name} />}
          {!element.item.cardImageSquare && element.item.typeOf?.cardImageSquare && <img className="card-img-top ratio ratio-1 bg-body" loading="lazy" src={`${process.env.REACT_APP_MEDIA_URL}${element.item.typeOf.cardImageSquare}`} alt={element.item.name} />}
          {!element.item.cardImageSquare && !element.item.typeOf?.cardImageSquare && <FontAwesomeIcon icon={faBox} size="lg" className="my-5 display-1 text-warning" />}
          <Card.ImgOverlay className="p-0 pe-2 pt-2">
            <div className="text-end">{renderDropDown()}</div>
          </Card.ImgOverlay>
          <Card.Body className={`p-2 border-top rounded-bottom ${selected ? ' text-bg-primary' : ''}`}>
            <Card.Text>{element.item.name}</Card.Text>
          </Card.Body>
        </Card>
        {lightbox && <Lightbox large={lightbox.large} alt={lightbox.alt} onClose={closeLightbox} showRotate={true} />}
      </>
    );

  if (listViewMode === LIST_VIEW_MODES.Square)
    return (
      <>
        <Card className={`item rounded-4 card-warehouse h-100 ${selected ? 'text-bg-primary' : ''}${element.isHidden ? ' transparent' : ''}`} data-last-element={selected} role="button" onClick={() => openElement(element.pk)}>
          {element.item.cardImageSquare && <img className="card-img-top rounded-4 ratio ratio-1 bg-body" loading="lazy" src={`${process.env.REACT_APP_MEDIA_URL}${element.item.cardImageSquare}`} alt={element.item.name} />}
          {!element.item.cardImageSquare && element.item.typeOf?.cardImageSquare && <img className="card-img-top rounded-4 ratio ratio-1 bg-body" loading="lazy" src={`${process.env.REACT_APP_MEDIA_URL}${element.item.typeOf.cardImageSquare}`} alt={element.item.name} />}
          {!element.item.cardImageSquare && !element.item.typeOf?.cardImageSquare && <FontAwesomeIcon icon={faBox} size="lg" className="my-5 display-1 text-warning" />}
          <Card.ImgOverlay className="p-0 pe-2 pt-2 rounded-4">
            <div className="text-end rounded-4">{renderDropDown()}</div>
          </Card.ImgOverlay>
        </Card>
        {lightbox && <Lightbox large={lightbox.large} alt={lightbox.alt} onClose={closeLightbox} showRotate={true} />}
      </>
    );

    if (listViewMode === LIST_VIEW_MODES.Compress)
    return (
      <>
        <Card className={`item rounded-3 card-warehouse h-100 ${selected ? 'text-bg-primary' : ''}${element.isHidden ? ' transparent' : ''}`} data-last-element={selected} role="button" onClick={() => openElement(element.pk)}>
          {element.item.cardImageSquare && <img className="card-img-top rounded-3 ratio ratio-1 bg-body object-fit-cover" loading="lazy" src={`${process.env.REACT_APP_MEDIA_URL}${element.item.cardImageSquare}`} alt={element.item.name} height="64" />}
          {!element.item.cardImageSquare && element.item.typeOf?.cardImageSquare && <img className="card-img-top rounded-3 ratio ratio-1 bg-body object-fit-cover" loading="lazy" src={`${process.env.REACT_APP_MEDIA_URL}${element.item.typeOf.cardImageSquare}`} alt={element.item.name}  height="64" />}
          {!element.item.cardImageSquare && !element.item.typeOf?.cardImageSquare && <FontAwesomeIcon icon={faBox} size="lg" className="my-5 display-1 text-warning" />}
          <Card.ImgOverlay className="p-0 pe-2 pt-2 rounded-4">
            <div className="text-end rounded-4">{renderDropDown()}</div>
          </Card.ImgOverlay>
        </Card>
        {lightbox && <Lightbox large={lightbox.large} alt={lightbox.alt} onClose={closeLightbox} showRotate={true} />}
      </>
    );
};

export default ElementCard;
