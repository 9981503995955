import { useTranslation } from 'react-i18next';
import { currency, dateFromDate, dateFromTimestamp, dateTimeFromDate, decimal, formatBytes, propertyDisplay, shortDateFromDate, timeFromDate } from '../../utils/formatter';


const Formi = ({ currencyVal, timestampVal, dateVal, shortDateVal, timeVal, dateTimeVal, decimalVal, byteVal, propertyVal }) => {

  const { i18n } = useTranslation();

  if (currencyVal || currencyVal === 0) {
    return currency(currencyVal, i18n.resolvedLanguage);
  }

  if (timestampVal) {
    return dateFromTimestamp(timestampVal, i18n.resolvedLanguage);
  }

  if (dateVal) {
    return dateFromDate(dateVal, i18n.resolvedLanguage);
  }

  if (shortDateVal) {
    return shortDateFromDate(shortDateVal, i18n.resolvedLanguage);
  }

  if (timeVal) {
    return timeFromDate(timeVal, i18n.resolvedLanguage);
  }

  if (dateTimeVal) {
    return dateTimeFromDate(dateTimeVal, i18n.resolvedLanguage);
  }

  if (decimalVal) {
    return decimal(decimalVal, i18n.resolvedLanguage);
  }

  if (byteVal) {
    return formatBytes(byteVal, 2, i18n.resolvedLanguage);
  }

  if (propertyVal) {
    return propertyDisplay(propertyVal, i18n.resolvedLanguage);
  }

  return null;
};

export default Formi;
