import React from 'react';

import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { confirmable, createConfirmation } from 'react-confirm';
import { useTranslation } from 'react-i18next';


const dangerousMessage = (msg) => {
  return {
    __html: msg,
  };
};

const Confirmation = ({ okLabel, cancelLabel, title, lead, show, proceed, showCancel = true, enableEscape = true, danger = false, warning = false }) => {
  const { t } = useTranslation();

  return (
    <Modal animation={true} show={show} onHide={() => proceed(false)} backdrop={enableEscape ? true : 'static'} keyboard={enableEscape}>
      {title && (
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body dangerouslySetInnerHTML={dangerousMessage(lead)}></Modal.Body>
      <Modal.Footer>
        {showCancel && (
          <Button variant="secondary" onClick={() => proceed(false)}>
            {cancelLabel || t('modals.confirmation.cancel')}
          </Button>
        )}
        <Button className="button-l" variant={`${danger ? 'danger' : warning ? 'warning' : 'primary'}`} onClick={() => proceed(true)}>
          {okLabel || t('modals.confirmation.ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

Confirmation.propTypes = {
  cancelLabel: PropTypes.string,
  enableEscape: PropTypes.bool,
  lead: PropTypes.string,
  okLabel: PropTypes.string,
  proceed: PropTypes.func,
  show: PropTypes.bool,
  showCancel: PropTypes.bool,
  title: PropTypes.string,
};

export function confirm(options = {}) {
  return createConfirmation(confirmable(Confirmation))({
    ...options,
  });
}
