import React from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import teamwork from '../../../assets/img/illustrations/teamwork.png';
import ResetConfirmForm from '../../../components/auth/ResetConfirmForm';
import useAuth from '../../../hooks/useAuth';


const PWResetConfirm = () => {

  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();

  if (isAuthenticated) {
    return <Navigate to="/private" replace />
  }

  return (
    <>
      <Helmet title={t('public.auth.password-reset-headline-confirm')} />
      <Container>
        <Row style={{ margin: '100px 0 150px 0' }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 4, offset: 1 }} className="pb-5">
            <h1 className="text-center">{t('public.auth.password-reset-headline-confirm')}</h1>
            <p className="text-center lead text-muted">{t('public.auth.password-reset-lead-confirm')}</p>
            <br />
            <ResetConfirmForm />
          </Col>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} className="pb-5">
            <img src={teamwork} alt="Teamwork" className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </>
  );

}

export default PWResetConfirm;
