import React from 'react';

import 'moment/locale/de';
import 'moment/locale/zh-tw';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useRoutes } from 'react-router-dom';
import './App.sass';
import ToastContainer from './components/basic/ToastContainer';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';
import routes from './routes';


const App = () => {
  const content = useRoutes(routes);
  const { t } = useTranslation();

  const metaTags = [
    { name: 'description', content: 'Organize, Moreganize!' },
    { name: 'keywords', content: t('public.app.meta-keywords') }
  ];

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | Lagerraum" defaultTitle="Lagerraum.pro" meta={metaTags} />
      <ThemeProvider>
        <AuthProvider>{content}</AuthProvider>
        <ToastContainer theme="light" />
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
