import React, { useMemo, useRef } from 'react';

import { faBook, faCircleInfo, faDownload, faEye, faEyeSlash, faFile, faFileImage, faFileLines, faFingerprint, faFolder, faFolderPlus, faHouse, faPenToSquare, faPlay, faPlus, faRotate, faTrashCan, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Nav, NavDropdown, Navbar, Spinner } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { renderParentBreadcrumps } from '../../utils/renderHelper';
import ListModeNavToolbar from '../basic/ToolbarListModeNav';


const getHasHiddenElements = (elements) => {
  let hiddenElementCount = elements?.edges?.filter((e) => e.node.isHidden).length;
  return hiddenElementCount > 0;
};


const CollectionToolbar = ({
  collection,
  currentListMode,
  elements,
  infoSidebarHandler,
  loading,
  permissionSidebarHandler,
  refetch,
  setCurrentListMode,
  setShowHidden,
  setYaLightboxIndex,
  showAddBookElementDialog,
  showAddDialog,
  showAddElementDialog,
  showAddFoodElementDialog,
  showAddImageElementDialog,
  showAddISBNsDialog,
  showBreadcrumps = false,
  showDeleteDialog,
  showEditDialog,
  showExportDialog,
  showHidden,
  uploadImageCallback,
}) => {

  const { t } = useTranslation();
  const inputFile = useRef(null);

  const hasHiddenElements = useMemo(() => getHasHiddenElements(elements), [elements]);

  const onUploadImageClick = (e) => {
    inputFile.current.click();
  };

  const uploadQueueChanged = async (event) => {
    await uploadImageCallback(event.target.files);
    inputFile.current.value = '';
  };


  return (
    <Navbar className="bg-body-tertiary border-bottom" expand={true}>
      <Container fluid={true}>
        <Navbar.Toggle aria-controls="collection-navbar-nav" />
        <Navbar.Collapse id="collection-navbar-nav">
          {showBreadcrumps && (
            <Nav className="d-none d-sm-flex">
              <Breadcrumb>
                <Breadcrumb.Item className="d-none d-md-block" linkAs={Link} linkProps={{ to: "/private/collections" }}>{t('pages.collections.headline')}</Breadcrumb.Item>
                <Breadcrumb.Item className="d-block d-md-none" linkAs={Link} linkProps={{ to: "/private/collections" }}><FontAwesomeIcon icon={faHouse} /></Breadcrumb.Item>
                {collection && (
                  <>
                    {renderParentBreadcrumps(collection)}
                    <Breadcrumb.Item active>{collection.name}</Breadcrumb.Item>
                  </>
                )}
                {loading && <Breadcrumb.Item active><Spinner animation="border" size="sm"></Spinner></Breadcrumb.Item>}
              </Breadcrumb>
            </Nav>
          )}
          <Nav>
            <NavDropdown
              title=<FontAwesomeIcon size="lg" icon={faPlus} fixedWidth />
              className="navdropdown-no-toggle"
              id="nav-add-dropdown"
            >
              <NavDropdown.Item onClick={() => showAddElementDialog(true)}>
                <FontAwesomeIcon size="lg" icon={faFile} className="me-2" fixedWidth />
                {t('pages.collections.add-element')}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => showAddImageElementDialog(true)}>
                <FontAwesomeIcon size="lg" icon={faFileImage} className="me-2" fixedWidth />
                {t('pages.collections.add-image')}
              </NavDropdown.Item>
              {uploadImageCallback && (
                <NavDropdown.Item as="div" role="button" onClick={() => onUploadImageClick(true)}>
                  <FontAwesomeIcon size="lg" icon={faFileImage} className="me-2" fixedWidth />
                  {t('pages.collections.image-upload')}
                  <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} accept="image/*" onChange={uploadQueueChanged} />
                </NavDropdown.Item>
              )}
              <NavDropdown.Item onClick={() => showAddBookElementDialog(true)}>
                <FontAwesomeIcon size="lg" icon={faFileLines} className="me-2" fixedWidth />
                {t('pages.collections.add-book')}
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => showAddISBNsDialog(true)}>
                <FontAwesomeIcon size="lg" icon={faFileLines} className="me-2" fixedWidth />
                {t('pages.collections.add-books')}
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => showAddFoodElementDialog(true)}>
                <span className="fa-layers ms-1" style={{ marginRight: '13px' }}>
                  <FontAwesomeIcon size="lg" icon={faFile} />
                  <FontAwesomeIcon size="lg" icon={faUtensils} inverse transform="shrink-9 down-2 left-1" />
                </span>
                {t('pages.collections.add-food')}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => showAddDialog(true)}>
                <FontAwesomeIcon size="lg" icon={faFolderPlus} className="me-2" fixedWidth />
                {t('pages.collections.add-subcollection')}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className="d-none">
            <Nav.Link disabled={loading} onClick={() => showAddElementDialog(true)}>
              <span className="fa-layers">
                <FontAwesomeIcon size="lg" icon={faFile} />
                <FontAwesomeIcon size="lg" icon={faPlus} inverse transform="shrink-9 down-2 left-0.5" />
              </span>
            </Nav.Link>
            <Nav.Link disabled={loading} onClick={() => showAddImageElementDialog(true)}>
              <FontAwesomeIcon size="lg" icon={faFileImage} />
            </Nav.Link>
            <Nav.Link disabled={loading} onClick={() => showAddBookElementDialog(true)}>
              <FontAwesomeIcon size="lg" icon={faFileLines} />
            </Nav.Link>
            <Nav.Link disabled={loading} onClick={() => showAddFoodElementDialog(true)}>
              <span className="fa-layers">
                <FontAwesomeIcon size="lg" icon={faFile} />
                <FontAwesomeIcon size="lg" icon={faUtensils} inverse transform="shrink-9 down-2 left-1" />
              </span>
            </Nav.Link>
            <Nav.Link disabled={loading} onClick={() => showAddISBNsDialog(true)}>
              <span className="fa-layers">
                <FontAwesomeIcon size="lg" icon={faFile} />
                <FontAwesomeIcon size="lg" icon={faBook} inverse transform="shrink-9 down-2 left-1" />
              </span>
            </Nav.Link>
          </Nav>
          <Nav className="d-flex d-sm-none">
            <NavDropdown
              title=<FontAwesomeIcon size="lg" icon={faFolder} fixedWidth />
              className="navdropdown-no-toggle"
              id="nav-add-dropdown"
            >
              <NavDropdown.Item onClick={() => showEditDialog(true)}>
                <FontAwesomeIcon size="lg" icon={faPenToSquare} className="me-2" fixedWidth />
                {t('pages.collections.collection-edit')}
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => showAddDialog(true)}>
                <FontAwesomeIcon size="lg" icon={faFolderPlus} className="me-2" fixedWidth />
                {t('pages.collections.collection-add-subcollection')}
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => showDeleteDialog(true)}>
                <FontAwesomeIcon size="lg" icon={faTrashCan} className="me-2" fixedWidth />
                {t('pages.collections.collection-delete')}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => refetch(true)}>
                <FontAwesomeIcon size="lg" icon={faRotate} className="me-2" fixedWidth />
                {t('pages.collections.collection-reload')}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className="d-none d-sm-flex border-end pe-2">
            <Nav.Link disabled={loading} onClick={() => showEditDialog(true)}>
              <FontAwesomeIcon size="lg" icon={faPenToSquare} fixedWidth />
            </Nav.Link>
            <Nav.Link disabled={loading} onClick={() => showDeleteDialog(true)}>
              <FontAwesomeIcon size="lg" icon={faTrashCan} fixedWidth />
            </Nav.Link>
          </Nav>
          <Nav className="d-none d-sm-flex border-end px-2">
            {setYaLightboxIndex && (
              <Nav.Link disabled={loading || collection?.elements?.length <= 0} onClick={() => setYaLightboxIndex(0)}>
                <FontAwesomeIcon size="lg" icon={faPlay} fixedWidth />
              </Nav.Link>
            )}
            {showHidden && hasHiddenElements && (
              <Nav.Link disabled={loading} onClick={() => setShowHidden(false)}>
                <FontAwesomeIcon size="lg" icon={faEye} fixedWidth />
              </Nav.Link>
            )}
            {!showHidden && hasHiddenElements && (
              <Nav.Link disabled={loading} onClick={() => setShowHidden(true)}>
                <FontAwesomeIcon size="lg" icon={faEyeSlash} fixedWidth />
              </Nav.Link>
            )}
            <Nav.Link disabled={loading} onClick={() => showExportDialog(collection)}>
              <FontAwesomeIcon size="lg" icon={faDownload} fixedWidth />
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto ps-2">&nbsp;</Nav>
          <ListModeNavToolbar loading={loading} current={currentListMode} setCurrent={setCurrentListMode} className="" />
          <Nav className="ms-2 border-start ps-2">
            <Nav.Link disabled={loading} onClick={() => refetch()}>
              <FontAwesomeIcon size="lg" icon={faRotate} fixedWidth />
            </Nav.Link>
            <Nav.Link disabled={loading} active={permissionSidebarHandler.opened.value} onClick={() => permissionSidebarHandler.opened.set(!permissionSidebarHandler.opened.value)}>
              <FontAwesomeIcon size="lg" icon={faFingerprint} fixedWidth />
            </Nav.Link>
            <Nav.Link disabled={loading} active={infoSidebarHandler.opened.value} onClick={() => infoSidebarHandler.opened.set(!infoSidebarHandler.opened.value)}>
              <FontAwesomeIcon size="lg" icon={faCircleInfo} fixedWidth />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CollectionToolbar;
