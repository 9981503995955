import React, { useEffect, useState } from 'react';

import { useApolloClient, useLazyQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';
import { GET_GROUPS } from '../groups/queries';


const CollectionModalAddGroupPermission = ({ handler, collection }) => {

  const [disabled, setDisabled] = useState(false);
  const [groups, setGroups] = useState([]);
  const client = useApolloClient();
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const [getGroups] = useLazyQuery(GET_GROUPS, {
    variables: {
      parent_Isnull: true,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      // Groups current users administers:
      // setGroups(data.groups.edges.filter(edge => edge.node.myPermission?.role === 'A_1' || edge.node.myMembership?.role === 'A_1'));

      setGroups(data.groups.edges);
    },
  });

  const valueFromId = (opts, id) => opts.find((o) => o.node.pk === id);

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleSave = async (values) => {

    var formData = { ...values };
    formData.group = formData.group?.pk || undefined;
    formData.mode = formData.mode === 'write' ? 2 : formData.mode === 'manage' ? 3 : 1;

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.saving'));

    await postData(`warehouse/collections/${collection.pk}/add_group_permission/`, formData, { sleep: 500 }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.collection-add-group.created-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      await client.clearStore();
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      mode: 'read',
      collection: collection?.pk,
      group: '',
    },
    onSubmit: (values) => handleSave(values),
  });

  useEffect(() => { getGroups(); }, [getGroups]);

  return (
    <Modal show={opened.value} onHide={handleClose} backdrop={disabled ? 'static' : true}>
      <Modal.Header closeButton={!disabled}>
        <Modal.Title>{t('modals.collection-add-group.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <fieldset disabled={disabled}>
          {collection && formik.errors?.fields?.collection && (
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.collection-add-group.field-collection')}</Form.Label>
              <Form.Control
                name="collection"
                onChange={formik.handleChange}
                type="text"
                value={collection.name}
                isInvalid={formik.errors?.fields?.collection}
                disabled
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.collection}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          <Form.Group className="mb-3">
            <Form.Label>{t('modals.collection-add-group.field-group')}</Form.Label>
            <Select
              getOptionLabel={(e) => e.node.name}
              getOptionValue={(e) => e.node.pk}
              placeholder={t('modals.collection-edit.field-type-placeholder')}
              isClearable={true}
              name="group"
              onChange={(o) => formik.setFieldValue('group', o?.node || null)}
              options={groups || []}
              value={valueFromId(groups || [], formik.values.group)}
              className="react-select-container"
              classNamePrefix="react-select"
            />
            <Form.Control type="hidden" isInvalid={formik.errors?.fields?.group} disabled />
            <Form.Control.Feedback type="invalid">
              {formik.errors?.fields?.group}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="radio"
              id="mode-switch-read"
              checked={formik.values.mode === 'read'}
              onChange={(event) => formik.setFieldValue('mode', 'read')}
              label={t('modals.collection-add-group.field-mode-switch-read')}
            />
            <Form.Check
              type="radio"
              id="mode-switch-manage"
              checked={formik.values.mode === 'manage'}
              onChange={(event) => formik.setFieldValue('mode', 'manage')}
              label={t('modals.collection-add-group.field-mode-switch-manage')}
            />
            <Form.Check
              type="radio"
              id="mode-switch-write"
              checked={formik.values.mode === 'write'}
              onChange={(event) => formik.setFieldValue('mode', 'write')}
              label={t('modals.collection-add-group.field-mode-switch-write')}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors?.fields?.name}
            </Form.Control.Feedback>
          </Form.Group>
        </fieldset>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
        <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.add')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CollectionModalAddGroupPermission;
