import React from 'react';

import { useTranslation } from 'react-i18next';


const ISBNLookupResult = ({ result }) => {

  const { t } = useTranslation();

  return (
    <div className="d-flex mt-4">
      <div className="flex-shrink-0">
        {result.img_repr_thumbnail && <img src={`${process.env.REACT_APP_MEDIA_URL}${result.img_repr_thumbnail}`} className="rounded" alt={`ISBN-13 ${result['isbn_13']}`} style={{ maxWidth: '64px', width: '64px' }} />}
      </div>
      <div className={`flex-grow-1 ${result.img_repr_thumbnail ? 'ms-3' : ''}`}>
        <div className="fs-5">{result.title}</div>

        {result.description && <p>{result.description.substring(0, 100)}...</p>}

        <dl className="row mt-2">
          <dt className="col-4">{t('modals.item-create-book.lookup-authors')}</dt>
          <dd className="col-8 mb-0">{result['authors'].join(', ')}</dd>
          <dt className="col-4">{t('modals.item-create-book.lookup-year')}</dt>
          <dd className="col-8 mb-0">{result['year']}</dd>
          <dt className="col-4">{t('modals.item-create-book.lookup-language')}</dt>
          <dd className="col-8 mb-0">{result['language']}</dd>
          {result['Publisher'] && (
            <>
              <dt className="col-4">{t('modals.item-create-book.lookup-publisher')}</dt>
              <dd className="col-8 mb-0">{result['publisher']}</dd>
            </>
          )}
        </dl>

        {/*<div><pre>{JSON.stringify(result, null, 4)}</pre></div>*/}
      </div>
    </div>
  );
};

export default ISBNLookupResult;
