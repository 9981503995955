import React from 'react';

import { Card } from 'react-bootstrap';
import { People } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import defaultGroupImage from '../../assets/img/illustrations/default-group-image.png';


const GroupCard = ({ group }) => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  const openGroup = (groupId) => {
    navigate(`/private/groups/${groupId}`);
  };

  return (
    <>
      <Card className="mb-4 collection flex-fill" role="button" onClick={() => openGroup(group.pk)}>
        {group.cardImageThumbnail && <Card.Img variant='top' src={`${process.env.REACT_APP_MEDIA_URL}${group.cardImageThumbnail}`} />}
        {!group.cardImageThumbnail && <Card.Img variant='top' src={defaultGroupImage} />}
        <Card.ImgOverlay className="fw-500">
          <div className={`shadowed-details text-end${group.memberCount === 0 ? " d-none" : ""}`}>
            {group.memberCount}
            <People className="ms-2" size={16} alt={t('cards.group.child-count')} />
          </div>
        </Card.ImgOverlay>
        <Card.Body className="border-top d-none">
          <dl className="row mb-0">
            <dt className="col-sm-6">{t('cards.group.item-count')}</dt>
            <dd className="col-sm-6 mb-0">{group.itemCount}</dd>
            <dt className="col-sm-6">{t('cards.group.child-count')}</dt>
            <dd className="col-sm-6 mb-0">{group.childCount}</dd>
          </dl>
        </Card.Body>
        <Card.Footer>{group.name}</Card.Footer>
      </Card>
    </>
  );
};

export default GroupCard;
