import * as React from 'react';

import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';


function AuthGuard({ children }) {

  const { isAuthenticated, isInitialized } = useAuth();
  const location = useLocation();

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/login" state={{ next: location.pathname }} replace={true} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
