import React from 'react';

import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Formi from '../../components/basic/Formi';
import { currency, renderBoolean, renderVisibleCollectionUnitCountByEdges, renderVisibleCollectionUnitCount } from '../../utils/formatter';


const CollectionSidebarInfo = ({ handler, collection, elements }) => {

  const { opened } = handler;
  const { t, i18n } = useTranslation();

  const handleClose = () => {
    opened.set(false);
  };

  if (!collection) return null;

  return (
    <>
      <div className="panel bg-body-tertiary border-start flex-fill mw-330 overflow-hidden">
        <div className="app">
          <div className="module modal">
            <div className="modal-header">
              <div className="modal-title h5">{t('sidebars.collection-info.title')}</div>
              <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
            </div>
            <div className="mainspace p-3">
              {collection.cardImageThumbnail && <img alt={collection.name} className="img-fluid rounded border mb-3 bg-white" src={`${process.env.REACT_APP_MEDIA_URL}${collection.cardImageThumbnail}`} />}
              {!collection.cardImageThumbnail && collection.defaultType?.cardImageThumbnail && <img alt={collection.name} className="img-fluid rounded border mb-3 bg-white" src={`${process.env.REACT_APP_MEDIA_URL}${collection.defaultType.cardImageThumbnail}`} />}
              {collection.description && (
                <>
                  <b>{t('sidebars.collection-info.description')}</b>
                  <ReactMarkdown>{collection.description}</ReactMarkdown>
                </>
              )}
              <dl>
                <dt>{t('sidebars.collection-info.name')}</dt>
                <dd>{collection.name}</dd>
                {collection.defaultType && (
                  <>
                    <dt>{t('sidebars.collection-info.default-type')}</dt>
                    <dd>{collection.defaultType?.name}</dd>
                  </>
                )}
                {collection?.childCount > 0 && (
                  <>
                    <dt>{t('sidebars.collection-info.child-count')}</dt>
                    <dd>{collection.childCount}</dd>
                  </>
                )}
                <dt>{t('sidebars.collection-info.element-count')}</dt>
                <dd>{elements?.totalCount || collection?.elementCount}</dd>
                {elements?.totalCount && elements.totalCount !== renderVisibleCollectionUnitCountByEdges(elements) && (
                  <>
                    <dt>{t('sidebars.collection-info.unit-count')}</dt>
                    <dd>{renderVisibleCollectionUnitCountByEdges(elements)}</dd>
                  </>
                )}
                {!elements?.totalCount && collection?.elementCount && collection.elementCount !== renderVisibleCollectionUnitCount(collection) && (
                  <>
                    <dt>{t('sidebars.collection-info.unit-count')}</dt>
                    <dd>{renderVisibleCollectionUnitCount(collection)}</dd>
                  </>
                )}
                <dt>{t('sidebars.collection-info.enable-consumption-options')}</dt>
                <dd>{renderBoolean(collection.enableConsumptionOptions)}</dd>
                <dt>{t('sidebars.collection-info.enable-price-options')}</dt>
                <dd>{renderBoolean(collection.enablePriceOptions)}</dd>
                {collection.enablePriceOptions === true && (
                  <>
                    <dt>{t('sidebars.collection-info.overall-value')}</dt>
                    <dd>{currency(elements.totalValue, i18n.resolvedLanguage)}</dd>
                  </>
                )}
                <dt>{t('sidebars.collection-info.created-at')}</dt>
                <dd>
                  <Formi dateTimeVal={collection.createdAt} />
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionSidebarInfo;
