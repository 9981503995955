import React from 'react';

import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import Formi from '../../components/basic/Formi';


const GroupSidebarInfo = ({ handler, group }) => {

  const { opened } = handler;
  const { t } = useTranslation();

  if (!group) return null;

  return (
    <>
      <div className="panel bg-body-tertiary border-start flex-fill mw-330 overflow-hidden">
        <div className="app">
          <div className="module modal">
            <div className="modal-header">
              <div className="modal-title h5">{t('sidebars.group-info.title')}</div>
              <button type="button" className="btn-close" aria-label="Close" onClick={() => opened.set(false)}></button>
            </div>
            <div className="mainspace p-3">
              {group.cardImageThumbnail && <img alt={group.name} className="img-fluid rounded border mb-3 bg-white" src={`${process.env.REACT_APP_MEDIA_URL}${group.cardImageThumbnail}`} />}
              {group.description && (
                <>
                  <b>{t('sidebars.group-info.description')}</b>
                  <ReactMarkdown>{group.description}</ReactMarkdown>
                </>
              )}
              <dl>
                <dt>{t('sidebars.group-info.member-count')}</dt>
                <dd>{group.memberCount}</dd>
                <dt>{t('sidebars.group-info.created-at')}</dt>
                <dd>
                  <Formi dateTimeVal={group.createdAt} />
                </dd>
                {group.collectionCount > 0 && (
                  <>
                    <dt>{t('sidebars.group-info.collections')}</dt>
                    <ul>
                      {group.collections.map((collection, index) => (
                        <li key={index}>
                          <Link to={`/private/collections/${collection.pk}`}>{collection.name}</Link>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupSidebarInfo;
