import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Col, Container, Row } from 'react-bootstrap';
import { Collection as CollectionIcon } from 'react-bootstrap-icons';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Link, useLocation } from 'react-router-dom';
import CollectionCard from '../../../components/collections/Card';
import { GET_PUBLIC_COLLECTIONS } from '../../../components/collections/queries';


const Collections = () => {

  const [collections, setCollections] = useState([]);
  const location = useLocation();
  const { t } = useTranslation();

  const leadText = t('public.collections.meta-description');

  const metaTags = [
    { name: 'description', content: t('public.collections.meta-description') },
    { name: 'keywords', content: t('public.collections.meta-keywords') }
  ];

  const linkTags = [
    { rel: 'canonical', href: `${process.env.REACT_APP_SELF}${location.pathname}` },
  ];

  const [getCollections] = useLazyQuery(GET_PUBLIC_COLLECTIONS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setCollections(data.collections.edges);
    },
  });


  const renderBreadcrump = (showImage = true, className = 'mb-4') => {
    return (
      <Breadcrumb className={className}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/collections" }}>
          {showImage && <CollectionIcon className="bi img-fluid rounded me-1 align-text-top" width={20} height={20} style={{ maxHeight: '20px', minHeight: '20px' }} />}
          {t('public.collections.home-breadcrump')}
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  };

  useEffect(() => {
    getCollections();
  }, [getCollections]);

  return (
    <>
      <Helmet title={t('public.collections.title')} meta={metaTags} link={linkTags} />
      <Container>
        <div className="d-flex">
          <h2>{t('public.collections.title')}</h2>
        </div>
        <hr className="mt-0 mb-3" />
        {renderBreadcrump()}
        {leadText && <div className="mb-5">
          <ReactMarkdown>{leadText}</ReactMarkdown>
        </div>}
        <div className="mt-5">
          <h3>{t('public.collections.headline-selected-collections')}</h3>
          <Row>
            {collections.map((subCollection, index) => (
              <Col key={index} sm={12} md={12} lg={6} xl={4} xxl={3} className='d-flex'>
                <CollectionCard collection={subCollection.node} usePublicAddr={true} />
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </>
  );
}

export default Collections;
