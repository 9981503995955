import React, { useCallback, useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import Confetti from 'react-confetti';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import teamwork from '../../assets/img/illustrations/teamwork.png';
import SignIn from '../../components/auth/SignIn';
import useAuth from '../../hooks/useAuth';
import { getObj, postData } from '../../utils/axiosHelper';
import E404 from './E404';


const Invitation = () => {

  const [disabled, setDisabled] = useState(false);
  const [invitation, setInvitation] = useState();
  const { isAuthenticated } = useAuth();
  const { publicLinkId } = useParams();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const fetchInvitation = useCallback(async () => {
    const brot = toast.loading(t('widgets.toast.loading'));
    await getObj(`network/groups/invitations/${publicLinkId}/`).then(async (response) => {
      setInvitation(response.data);
      toast.dismiss(brot);
    }).catch((error) => {
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    });
  }, [publicLinkId, t]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      public_link_id: publicLinkId,
    },
    onSubmit: async (values) => {
      accept(values);
    },
  });

  const accept = async (values) => {

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.joining'));

    await postData(`network/groups/invitations/${publicLinkId}/accept/`, values, { sleep: 500 }).then(async (response) => {
      toast.update(brot, { render: t('public.groups.invitation.joined-successfully'), type: 'success', autoClose: 2000, isLoading: false });
      navigate(`/private/groups/${response.data.group}`);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.error(`${t('widgets.toast.error')} ${error.response.status}`, { autoClose: 2000 });
    }).finally(() => {
      setDisabled(false);
    });

  };

  useEffect(() => {
    if (publicLinkId) {
      fetchInvitation();
    }
  }, [publicLinkId, fetchInvitation]);

  if (!invitation) return <E404 msg={t('public.groups.invitation.404-lead')} />

  return (
    <>
      <Helmet title={t('public.groups.invitation.headline')} />
      <Container>
        <Row style={{ margin: '100px 0 150px 0' }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 4, offset: 1 }} className="pb-5">
            <h1 className="text-center">{t('public.groups.invitation.headline')}</h1>
            <p className="text-center lead text-muted mb-5">{t('public.groups.invitation.headline-lead-confirm', { initiator: invitation.initiator })}</p>
            <dl className="row mb-4">
              <dt className="col col-sm-6">{t('public.groups.invitation.group-name')}</dt>
              <dd className="col col-sm-6 mb-0">{invitation.group}</dd>
              <dt className="col col-sm-6">{t('public.groups.invitation.member_count')}</dt>
              <dd className="col col-sm-6 mb-0">{invitation.member_count || '?'}</dd>
              <dt className="col col-sm-6">{t('public.groups.invitation.created')}</dt>
              <dd className="col col-sm-6 mb-0">
                <Moment locale={i18n.resolvedLanguage} fromNow>{invitation?.group_created_at}</Moment>
              </dd>
            </dl>
            {!isAuthenticated && (
              <>
                <Alert variant="warning">{t('public.groups.invitation.login-required-msg', { group: invitation.group })}</Alert>
                <SignIn goto={location.pathname} />
              </>
            )}
            {isAuthenticated && (
              <>
                <div className="d-grid gap-2">
                  <Button type="submit" onClick={formik.handleSubmit} variant="primary" disabled={disabled}>
                    {t('public.groups.invitation.accept-and-open')}
                  </Button>
                </div>
              </>
            )}
          </Col>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} className="pb-5">
            <img src={teamwork} alt="Teamwork" className="img-fluid" />
          </Col>
        </Row>
      </Container>
      <Confetti
        recycle={false}
        wind={-0.007}
        gravity={0.5}
        numberOfPieces={150}
        run={isAuthenticated}
        colors={['#163A89', '#FEBC51', '#043BE8']}
      />
    </>
  );

}

export default Invitation;
