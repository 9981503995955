import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { faImage, faTableColumns } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Breadcrumb, Button, Col, Dropdown, Row, Table } from 'react-bootstrap';
import { Collection as CollectionIcon, ThreeDotsVertical } from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Masonry from 'react-masonry-css';
import { Lightbox } from 'react-modal-image';
import Moment from 'react-moment';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Lightbox as YALightbox } from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import pathfinder from '../../assets/img/illustrations/pathfinder.png';
import { DEFAULT_LIST_VIEW_MODE, LIST_VIEW_MODES } from '../../components/basic/Classes';
import EmptyPanel from '../../components/basic/EmptyPanel';
import EndOfSteamHandle from '../../components/basic/EndOfSteamHandle';
import Formi from '../../components/basic/Formi';
import ModalExport from '../../components/basic/ModalExport';
import StreamFooter from '../../components/basic/StreamFooter';
import CollectionCard from '../../components/collections/Card';
import CollectionImageBreadcrump from '../../components/collections/ImageBreadcrump';
import CollectionModalAdd from '../../components/collections/ModalAdd';
import CollectionModalDelete from '../../components/collections/ModalDelete';
import CollectionModalEdit from '../../components/collections/ModalEdit';
import CollectionModalEditTable from '../../components/collections/ModalEditTable';
import CollectionSidebarInfo from '../../components/collections/SidebarInfo';
import CollectionSidebarPermission from '../../components/collections/SidebarPermission';
import CollectionToolbar from '../../components/collections/Toolbar';
import { GET_COLLECTION_BY_ID_WITH_PAGINATION } from '../../components/collections/queries';
import ElementCard from '../../components/elements/Card';
import ElementModalAdd from '../../components/elements/ModalAdd';
import ElementModalAddBook from '../../components/elements/ModalAddBook';
import ElementModalAddFood from '../../components/elements/ModalAddFood';
import ElementModalAddImage from '../../components/elements/ModalAddImage';
import ElementModalDelete from '../../components/elements/ModalDelete';
import ElementModalMove from '../../components/elements/ModalMove';
import ElementModalToggle from '../../components/elements/ModalToggle';
import ItemModalDelete from '../../components/items/ModalDelete';
import ItemModalEdit from '../../components/items/ModalEdit';
import ImportFromISBNBulkModal from '../../components/settings/ModalImportISBNBulk';
import useLocalStorage from '../../hooks/useLocalStorage';
import { postData } from '../../utils/axiosHelper';
import { renderParentBreadcrumps } from '../../utils/renderHelper';


const Collection = () => {

  const [collection, setCollection] = useState();
  const [currentListMode, setCurrentListMode] = useLocalStorage('list-view-mode', DEFAULT_LIST_VIEW_MODE);
  const [disableHistoryScroll,] = useLocalStorage('disable-history-scroll', false);
  const [dragActive, setDragActive] = useState(false);
  const [elements, setElements] = useState({});
  const [firstFetchHandled, setFirstFetchHandled] = useState(false);
  const [lastElementIdGathered,] = useLocalStorage('last-element-id-gathered', false);
  const [lightbox, setLightbox] = useState();
  const [showHidden, setShowHidden] = useLocalStorage('show-hidden', false);
  const [tableConfig, setTableConfig] = useState({});
  const [yaLightboxIndex, setYaLightboxIndex] = useState(-1);
  const { collectionId } = useParams();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [getCollection, { loading, error, refetch, fetchMore }] = useLazyQuery(GET_COLLECTION_BY_ID_WITH_PAGINATION, {
    variables: {
      collectionId: collectionId,
      collectionPk: collectionId,
      orderBy: ['item__name'],
      first: 80,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setCollection(data.collectionById);
      setElements(data.elements);
      if (data.tableConfigsForCollection.length > 0) {
        setTableConfig(data.tableConfigsForCollection[0]);
      } else {
        setTableConfig({});
      }
      if (firstFetchHandled === false) {
        setFirstFetchHandled(true);
      }
    },
  });

  const createElementModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  const createBookElementModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  const createFoodElementModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  const createImageElementModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  const createModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  const editModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      if (response === 'destroyed') {
        handleDeletionResponse();
      } else {
        refetch();
      }
    },
  };

  const deleteModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      handleDeletionResponse();
    },
  };

  const importFromISBNBulkModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  const editItemModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  const editCollectionTableModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    backdrop: true,
    ok: (response) => {
      setTableConfig(response);
    },
    destroyed: (response) => {
      setTableConfig({});
      refetch();
    },
  };

  const deleteItemModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  const exportModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
    },
  };

  const removeElementModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  const toggleElementModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  const moveElementModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  const infoSidebarHandler = {
    opened: Object.assign({}, ...useLocalStorage('show-sidebar-info', false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
  };

  const permissionSidebarHandler = {
    opened: Object.assign({}, ...useLocalStorage('show-sidebar-permissions', false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      uploadFiles(e.dataTransfer.files)
    }
  };

  const getPropertyByAttrPk = (item, attributePk) => {
    return item.properties.find((e) => e.attribute.pk === attributePk);
  };

  const uploadFiles = async (files) => {

    const brot = toast.loading(t('widgets.toast.uploading'));
    const fileCount = files.length;
    var successCount = 0;

    const incrementSuccess = () => {
      successCount++;
    };

    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      toast.update(brot, { render: t('widgets.toast.upload-file-progress', { no: i + 1, of: fileCount }) });

      await uploadImageToCollection(file).then((response) => {
        incrementSuccess();
      }).catch((error) => {
        let errorMessage = error.response?.data?.image?.join();
        if (fileCount === 1) {
          toast.update(brot, { render: <div>{t('widgets.toast.error')} {error.response.status}<br /><b>{file.name}</b><br />{errorMessage}</div>, type: "warning", isLoading: false, autoClose: 5000 });
        } else {
          toast.warn(<div>{t('widgets.toast.error')} {error.response.status}<br /><b>{file.name}</b><br />{errorMessage}</div>, { autoClose: 5000 });
        }
      });
    }

    if (successCount > 0) {
      toast.update(brot, { render: t('pages.collections.image-upload-success', { 'success_count': successCount }), type: 'success', isLoading: false, autoClose: 2000 });
      refetch();
    } else if (fileCount > 1) {
      toast.dismiss(brot);
    }

  };

  const uploadImageToCollection = async (file) => {

    const config = { headers: { "Content-Type": "multipart/form-data" }, sleep: 100 };

    let data = new FormData();
    data.append('collection', collection.pk);
    data.append('image', file);

    return await postData('warehouse/elements/image/', data, config);

  };

  const handleDeletionResponse = () => {
    if (collection.parent) {
      navigate(`/private/collections/${collection.parent.pk}`, { state: { doRefetch: true } });
    } else {
      navigate('/private/collections', { state: { doRefetch: true } });
    }
  };

  const renderBreadcrump = ({ showImage = true, className = 'mb-4', bsPrefix = 'breadcrumb' }) => {
    return (
      <Breadcrumb className={className} bsPrefix={bsPrefix}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/private/collections" }}>
          {showImage && <CollectionIcon className="bi img-fluid rounded me-1 align-text-top" width={20} height={20} style={{ maxHeight: '20px', minHeight: '20px' }} />}
          {t('pages.collections.headline')}
        </Breadcrumb.Item>
        {collection && renderParentBreadcrumps({ collection: collection, showImage: showImage })}
      </Breadcrumb>
    );
  };

  const openElement = (elementId) => {
    navigate(`/private/collections/${collection.pk}/${elementId}`);
  };

  const openLightbox = (params) => {
    if (params?.large) setLightbox(params);
  };

  const closeLightbox = (params) => {
    setLightbox();
  };

  const lightboxElement = (element) => {
    if (element.item.cardImageThumbnail || element.item.typeOf?.itemImageThumbnail) {
      openLightbox({
        large: `${process.env.REACT_APP_MEDIA_URL}${element.item.imageFull || element.item.cardImageThumbnail || element.item.typeOf?.itemImageThumbnail}`,
        alt: element.item.name,
      });
    }
  };

  const listenScrollEvent = async (event) => {
    if (elements?.totalCount === 0) return;
    if (event.target.scrollHeight - event.target.scrollTop > event.target.clientHeight * 2.5) return;
    if (!elements?.pageInfo?.hasNextPage || loading) return;

    fetchMore({
      variables: {
        after: elements?.pageInfo?.endCursor,
        first: 40,
      },
    });
  };

  const renderDropDown = (element, yaLightBoxIndex = -1) => {

    if (!element) return null;

    return (
      <Dropdown onClick={(e) => e.stopPropagation()}>
        <Dropdown.Toggle size="sm" className="dropdown-no-toggle ps-1 border-secondary" id={`dropdown-basic-${element?.pk}`}>
          <ThreeDotsVertical className="ms-1" size={14} alt="Select actions" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          {editItemModalHandler && <Dropdown.Item onClick={() => editItemModalHandler.opened.set(element?.item)}>{t('pages.element.card-burger-edit')}</Dropdown.Item>}
          {removeElementModalHandler && <Dropdown.Item onClick={() => removeElementModalHandler.opened.set(element)}>{t('pages.element.card-burger-remove')}</Dropdown.Item>}
          {!element.isHidden && (
            <Dropdown.Item onClick={() => toggleElementModalHandler.opened.set(element)}>
              {t('pages.element.card-burger-hide')}
            </Dropdown.Item>
          )}
          {element.isHidden && (
            <Dropdown.Item onClick={() => toggleElementModalHandler.opened.set(element)}>
              {t('pages.element.card-burger-show')}
            </Dropdown.Item>
          )}
          {deleteItemModalHandler && <Dropdown.Item onClick={() => deleteItemModalHandler.opened.set(element?.item)}>{t('pages.element.card-burger-delete')}</Dropdown.Item>}
          <Dropdown.Item onClick={() => lightboxElement(element)}>
            {t('pages.element.card-burger-preview')}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setYaLightboxIndex(yaLightBoxIndex)}>
            {t('pages.element.card-burger-gallery')}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => moveElementModalHandler.opened.set(element.pk)}>
            {t('pages.element.card-burger-move')}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => openElement(element.pk)}>
            {t('pages.element.card-burger-open')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const filterHiddenElementsByEdge = (elementEdge, index, qs) => {
    return showHidden || !elementEdge.node.isHidden ? elementEdge : null;
  };

  useEffect(() => {
    if (firstFetchHandled === true && disableHistoryScroll !== true) {
      let lastElementMatch = document.querySelector('[data-last-element="true"]')
      lastElementMatch && lastElementMatch.scrollIntoView({ block: "center", behavior: 'smooth' });
    }
  }, [currentListMode, firstFetchHandled, disableHistoryScroll]);

  useEffect(() => {
    if (firstFetchHandled === true && disableHistoryScroll !== true) {
      let lastElementMatch = document.querySelector('[data-last-element="true"]')
      lastElementMatch && lastElementMatch.scrollIntoView({ block: "center", behavior: 'smooth' });
    }
  }, [firstFetchHandled, disableHistoryScroll]);

  useEffect(() => { getCollection(); }, [collectionId, getCollection]);
  useEffect(() => { if (location.state?.doRefetch) refetch(); }, [location.state?.doRefetch, refetch]);

  if (error) return `${error}`;

  if (collection === null) {
    return (
      <>
        <Helmet title={t('public.error.404')} />
        <CollectionToolbar
          collection={collection}
          currentListMode={currentListMode}
          infoSidebarHandler={infoSidebarHandler}
          loading={loading}
          permissionSidebarHandler={permissionSidebarHandler}
          refetch={refetch}
          setCurrentListMode={setCurrentListMode}
          setShowHidden={setShowHidden}
          setYaLightboxIndex={setYaLightboxIndex}
          showAddBookElementDialog={createBookElementModalHandler.opened.set}
          showAddDialog={createModalHandler.opened.set}
          showAddElementDialog={createElementModalHandler.opened.set}
          showAddFoodElementDialog={createFoodElementModalHandler.opened.set}
          showAddImageElementDialog={createImageElementModalHandler.opened.set}
          showAddISBNsDialog={importFromISBNBulkModalHandler.opened.set}
          showDeleteDialog={deleteModalHandler.opened.set}
          showEditDialog={editModalHandler.opened.set}
          showExportDialog={exportModalHandler.opened.set}
          showHidden={showHidden}
          uploadImageCallback={uploadFiles}
        />
        <div className="mainspace d-flex">
          <div className="container align-self-center">
            <div className="row">
              <div className="col-md-4 order-md-2 col-xxl-4 h-100">
                <img src={pathfinder} alt="Pathfinder" className="img-fluid" />
              </div>
              <div className="col-md-8 order-md-1 align-middle col-xxl-8 d-flex">
                <div className="align-self-center">
                  <h1 className="display-5 font-weight-bold">{t('pages.collections.404-headline')}</h1>
                  <p className="text-muted">{t('pages.collections.404-message')}</p>
                  <Button variant="link" className="btn btn-link ps-0" onClick={() => refetch()}>{t('forms.actions.refresh')}</Button>
                  {collectionId && (
                    <Link to={`/private/collections/`} className="btn btn-link ps-0">
                      {t('pages.collections.open-collections')}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {createBookElementModalHandler.opened.value && <ElementModalAddBook handler={createBookElementModalHandler} collection={collection} />}
      {createElementModalHandler.opened.value && <ElementModalAdd handler={createElementModalHandler} collection={collection} />}
      {createFoodElementModalHandler.opened.value && <ElementModalAddFood handler={createFoodElementModalHandler} collection={collection} />}
      {createImageElementModalHandler.opened.value && <ElementModalAddImage handler={createImageElementModalHandler} collection={collection} />}
      {createModalHandler.opened.value && <CollectionModalAdd handler={createModalHandler} parent={collection} />}
      {deleteItemModalHandler.opened.value && <ItemModalDelete handler={deleteItemModalHandler} item={deleteItemModalHandler.opened.value} />}
      {deleteModalHandler.opened.value && <CollectionModalDelete handler={deleteModalHandler} collection={collection} />}
      {editCollectionTableModalHandler.opened.value && <CollectionModalEditTable handler={editCollectionTableModalHandler} collectionPk={collection?.pk} tableConfigPk={tableConfig.id} />}
      {editItemModalHandler.opened.value && <ItemModalEdit handler={editItemModalHandler} original={editItemModalHandler.opened.value} />}
      {editModalHandler.opened.value && <CollectionModalEdit handler={editModalHandler} original={collection} />}
      {exportModalHandler.opened.value && <ModalExport handler={exportModalHandler} collection={exportModalHandler.opened.value} source="collection" />}
      {importFromISBNBulkModalHandler.opened.value && <ImportFromISBNBulkModal handler={importFromISBNBulkModalHandler} collection={collection} backdrop={true} />}
      {moveElementModalHandler.opened.value && <ElementModalMove handler={moveElementModalHandler} elementId={moveElementModalHandler.opened.value} collectionSuggestions={collection?.children?.edges} />}
      {removeElementModalHandler.opened.value && <ElementModalDelete handler={removeElementModalHandler} element={removeElementModalHandler.opened.value} />}
      {toggleElementModalHandler.opened.value && <ElementModalToggle handler={toggleElementModalHandler} element={toggleElementModalHandler.opened.value} />}
      <Helmet title={collection?.name || ''} />
      <CollectionToolbar
        collection={collection}
        elements={elements}
        currentListMode={currentListMode}
        infoSidebarHandler={infoSidebarHandler}
        loading={loading}
        permissionSidebarHandler={permissionSidebarHandler}
        refetch={refetch}
        setCurrentListMode={setCurrentListMode}
        setShowHidden={setShowHidden}
        setYaLightboxIndex={setYaLightboxIndex}
        showAddBookElementDialog={createBookElementModalHandler.opened.set}
        showAddDialog={createModalHandler.opened.set}
        showAddElementDialog={createElementModalHandler.opened.set}
        showAddFoodElementDialog={createFoodElementModalHandler.opened.set}
        showAddImageElementDialog={createImageElementModalHandler.opened.set}
        showAddISBNsDialog={importFromISBNBulkModalHandler.opened.set}
        showDeleteDialog={deleteModalHandler.opened.set}
        showEditDialog={editModalHandler.opened.set}
        showExportDialog={exportModalHandler.opened.set}
        showHidden={showHidden}
        uploadImageCallback={uploadFiles}
      />
      <div className="mainspace">
        <div className="splitter d-flex">
          <div
            className={`panel overflow-auto flex-fill ${(infoSidebarHandler.opened.value || permissionSidebarHandler.opened.value) ? 'd-none d-sm-block' : ''} ${dragActive ? ' drag-active' : ''}`}
            onDrop={e => handleDrop(e)}
            onDragOver={e => handleDragOver(e)}
            onDragEnter={e => handleDragEnter(e)}
            onDragLeave={e => handleDragLeave(e)}
          >
            <div className="position-relative h-100 w-100">
              <div className="module">
                <div className="mainspace" onScroll={listenScrollEvent.bind(this)}>
                  <div className="p-4 pb-0 pt-3">
                    <div className="d-flex">
                      <div className="flex-grow-1 me-3">
                        <h2>{collection?.name || '...'}</h2>
                      </div>
                      <div className="flex-shrink-0 d-none d-sm-flex">
                        <CollectionImageBreadcrump renderParents={false} collection={collection} />
                      </div>
                    </div>
                    <hr className="mt-0 mb-3" />
                    <div className="d-flex">
                      {renderBreadcrump({ className: '', bsPrefix: 'breadcrumb flex-column flex-sm-row' })}
                      {collection?.isPublic === true && (
                        <span className="ms-auto flex-shrink-0">
                          <Badge bg="warning text-dark">{t('forms.pills.collection_is_public')}</Badge>
                        </span>
                      )}
                      {collection?.groupCount > 0 && (
                        <span className={`${collection?.isPublic === false ? 'ms-auto' : 'ms-1'} flex-shrink-0`}>
                          <Badge bg="success">{t('forms.pills.collection_is_shared')}</Badge>
                        </span>
                      )}
                    </div>
                    {collection?.description && (
                      <div className="mb-5">
                        <ReactMarkdown>{collection.description}</ReactMarkdown>
                      </div>
                    )}
                    {collection?.childCount > 0 && (
                      <div className="mt-5">
                        <h3>{t('pages.collections.headline-child-collections')}</h3>
                        <Row>
                          {collection.children.edges.map((subCollection, index) => (
                            <Col key={index} sm={12} md={12} lg={6} xl={4} xxl={3} className='d-flex'>
                              <CollectionCard collection={subCollection.node} />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                    {elements?.totalCount > 0 && (
                      <div className="mt-5">
                        <h3>{t('pages.collections.headline-items')}</h3>
                        {currentListMode === LIST_VIEW_MODES.Vertical && (
                          <Masonry
                            breakpointCols={{ default: 5, 576: 1, 768: 2, 992: 3, 1200: 4 }}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column">
                            {elements?.edges?.filter(filterHiddenElementsByEdge).map((elementEdge, index) => (
                              <ElementCard
                                key={index}
                                collection={collection}
                                deleteItemModalHandler={deleteItemModalHandler}
                                editItemModalHandler={editItemModalHandler}
                                element={elementEdge.node}
                                listViewMode={currentListMode}
                                moveElementModalHandler={moveElementModalHandler}
                                removeElementModalHandler={removeElementModalHandler}
                                selected={!disableHistoryScroll && lastElementIdGathered === elementEdge.node.pk}
                                setYaLightboxIndex={setYaLightboxIndex}
                                toggleElementModalHandler={toggleElementModalHandler}
                                yaLightBoxIndex={index}
                              />
                            ))}
                          </Masonry>
                        )}
                        {[LIST_VIEW_MODES.Horizontal, LIST_VIEW_MODES.Square, LIST_VIEW_MODES.Compress].indexOf(currentListMode) > -1 && (
                          <Row>
                            {elements?.edges.filter(filterHiddenElementsByEdge).map((elementEdge, index) => (
                              <Col xs="12" sm="6" lg="4" xxl="2" className="pb-4" key={index}>
                                <ElementCard
                                  collection={collection}
                                  deleteItemModalHandler={deleteItemModalHandler}
                                  editItemModalHandler={editItemModalHandler}
                                  element={elementEdge.node}
                                  listViewMode={currentListMode}
                                  moveElementModalHandler={moveElementModalHandler}
                                  removeElementModalHandler={removeElementModalHandler}
                                  selected={!disableHistoryScroll && lastElementIdGathered === elementEdge.node.pk}
                                  setYaLightboxIndex={setYaLightboxIndex}
                                  toggleElementModalHandler={toggleElementModalHandler}
                                  yaLightBoxIndex={index}
                                />
                              </Col>
                            ))}
                          </Row>
                        )}
                        {(currentListMode === LIST_VIEW_MODES.ListWithImage || currentListMode === LIST_VIEW_MODES.PlainList) && (
                          <Table className="bg-light" hover bordered striped responsive>
                            <thead>
                              <tr>
                                {currentListMode === LIST_VIEW_MODES.ListWithImage && (
                                  <th className="align-middle text-center" width="60">
                                    <FontAwesomeIcon icon={faImage} />
                                  </th>
                                )}
                                <th className="align-middle">{t('pages.collections.element-table-name')}</th>
                                {tableConfig?.configuredAttributes && tableConfig.configuredAttributes.map((attributeConfig, index) => (
                                  <th className="align-middle" width={attributeConfig.columnWidth > 0 && attributeConfig.columnWidth <= 500 ? attributeConfig.columnWidth : ''} key={`${attributeConfig.attribute.id}-${index}`}>{attributeConfig.attribute.name}</th>
                                ))}
                                {collection.enablePriceOptions === true && (
                                  <th className="align-middle" width="145">{t('pages.collections.element-table-overall-value')}</th>
                                )}
                                {tableConfig?.showColumnUpdateAt !== false && <th className="align-middle" width="145">{t('pages.collections.element-table-updated-at')}</th>}
                                {tableConfig?.showColumnAmount !== false && <th className="align-middle" width="100">{t('pages.collections.element-table-amount')}</th>}
                                {tableConfig?.showColumnTypeOf !== false && <th className="align-middle" width="150">{t('pages.collections.element-table-type_of')}</th>}
                                <th className="align-middle text-center" width="50">
                                  <Button variant={tableConfig.id ? 'primary' : 'outline-primary'} onClick={() => editCollectionTableModalHandler.opened.set(collection)} size="sm">
                                    <FontAwesomeIcon icon={faTableColumns} />
                                  </Button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {elements?.edges?.filter(filterHiddenElementsByEdge).map((elementEdge, index) => (
                                <tr
                                  key={index}
                                  role="button"
                                  data-last-element={lastElementIdGathered === elementEdge.node.pk}
                                  className={`${!disableHistoryScroll && lastElementIdGathered === elementEdge.node.pk ? 'table-primary' : ''}`}
                                >
                                  {currentListMode === LIST_VIEW_MODES.ListWithImage && (
                                    <td className="text-center p-1">
                                      {!(elementEdge.node.item.cardImageSquare || elementEdge.node.item.typeOf?.cardImageSquare) && (
                                        <FontAwesomeIcon icon={faImage} className={`text-secondary${elementEdge.node.isHidden ? ' opacity-50' : ''}`} style={{ fontSize: '45px' }} />
                                      )}
                                      {(elementEdge.node.item.cardImageSquare || elementEdge.node.item.typeOf?.cardImageSquare) && (
                                        <img
                                          alt={elementEdge.node.item.name}
                                          className={`rounded border${elementEdge.node.isHidden ? ' opacity-50' : ''}`}
                                          height="46"
                                          loading="lazy"
                                          role="button"
                                          src={`${process.env.REACT_APP_MEDIA_URL}${elementEdge.node.item.cardImageSquare || elementEdge.node.item.typeOf?.cardImageSquare}`}
                                          width="46"
                                          onClick={() => openLightbox({
                                            large: `${process.env.REACT_APP_MEDIA_URL}${elementEdge.node.item.imageFull}`,
                                            alt: elementEdge.node.item.name,
                                          })}
                                        />
                                      )}
                                    </td>
                                  )}
                                  <td onClick={() => openElement(elementEdge.node.pk)}>{elementEdge.node.item.name}</td>
                                  {tableConfig?.configuredAttributes && tableConfig.configuredAttributes.map((attributeConfig) => (
                                    <td key={`${attributeConfig.attribute.id}-${index}`}>
                                      {getPropertyByAttrPk(elementEdge.node.item, attributeConfig.attribute.pk)?.value}
                                    </td>
                                  ))}
                                  {collection.enablePriceOptions === true && (
                                    <td onClick={() => openElement(elementEdge.node.pk)} className="text-end">
                                      <Formi currencyVal={elementEdge.node.price * elementEdge.node.amount} />
                                    </td>
                                  )}
                                  {tableConfig?.showColumnUpdateAt !== false && (
                                    <td onClick={() => openElement(elementEdge.node.pk)}>
                                      <Moment locale={i18n.resolvedLanguage} date={elementEdge.node.updatedAt} format="L" />
                                    </td>
                                  )}
                                  {tableConfig?.showColumnAmount !== false && <td onClick={() => openElement(elementEdge.node.pk)}>{elementEdge.node.amount}</td>}
                                  {tableConfig?.showColumnTypeOf !== false && <td onClick={() => openElement(elementEdge.node.pk)}>{elementEdge.node.item?.typeOf?.name}</td>}
                                  <td className="text-center">
                                    {renderDropDown(elementEdge.node, index)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}

                        <EndOfSteamHandle loading={loading} hasNext={elements.pageInfo.hasNextPage} />
                      </div>
                    )}
                    {collection?.childCount === 0 && collection?.relayElements?.totalCount === 0 && !loading && collection?.description === '' && (
                      <div className="p-4">
                        <div style={{ marginTop: '100px' }}>&nbsp;</div>
                        <EmptyPanel
                          reloadTrigger={refetch}
                          addEntry={createElementModalHandler.opened.set}
                          maximize={false}
                        />
                      </div>
                    )}
                  </div>
                  {lightbox && <Lightbox large={lightbox.large} alt={lightbox.alt} onClose={closeLightbox} showRotate={true} />}
                  <YALightbox
                    open={yaLightboxIndex >= 0}
                    index={yaLightboxIndex}
                    close={() => setYaLightboxIndex(-1)}
                    slides={elements.edges?.filter(filterHiddenElementsByEdge).map((elementEdge, index) => ({
                      src: `${process.env.REACT_APP_MEDIA_URL}${elementEdge.node.item.imageFull}`,
                      title: elementEdge.node.item.name,
                    })) || []}
                    plugins={[Captions]}
                  />
                </div>
                <StreamFooter
                  subCollectionsLoaded={collection?.childCount}
                  subCollectionCount={collection?.childCount}
                  elementsLoaded={elements?.edges?.length}
                  elementCount={elements?.totalCount}
                  loading={loading}
                  breadcrumps={renderBreadcrump({ showImage: false, className: 'mb-0' })}
                />
              </div>
            </div>
          </div>
          {infoSidebarHandler.opened.value && <CollectionSidebarInfo handler={infoSidebarHandler} collection={collection} elements={elements} />}
          {permissionSidebarHandler.opened.value && <CollectionSidebarPermission handler={permissionSidebarHandler} collection={collection} refetch={refetch} />}
        </div>
      </div>
    </>
  );
};

export default Collection;
