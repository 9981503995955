import React from 'react';

import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/sidebar/Sidebar';
import { SidebarProvider } from '../contexts/SidebarContext';
import i18n from '../i18n';


const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        elements: relayStylePagination(['collection_Id']),
        items: relayStylePagination(),
      },
    },
  },
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (networkError?.statusCode === 401) {
    window.location.reload();
  }
}
);

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("accessToken");

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      'Accept-Language': i18n.resolvedLanguage,
    },
  };
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPH,
});


const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: cache,
});

function AdminLayout({ children }) {
  return (
    <ApolloProvider client={client}>
      <SidebarProvider>
        <main className="app glassed border-top">
          <Sidebar />
          {children}
          <div className="container-fluid position-relative">
            <div className="module">
              <Outlet />
            </div>
          </div>
        </main>
      </SidebarProvider>
    </ApolloProvider>
  );
}

export default AdminLayout;
