import { useTranslation } from 'react-i18next';

export const PushNotificationStatus = () => {
  const { t } = useTranslation();

  return {
    UNKNOWN: t('meta.constants.sw-state-unknown'),
    NO_SERVICE_WORKER: t('meta.constants.sw-state-no-service-worker'),
    NO_PUSH_MANAGER: t('meta.constants.sw-state-no-push-manager'),
    NOT_ALLOWED: t('meta.constants.sw-state-not-allowed'),
    NOT_SUPPORTED: t('meta.constants.sw-state-not-supported'),
    ABORTED: t('meta.constants.sw-state-aborted'),
    SW_REGISTRATION_ERROR: t('meta.constants.sw-state-registration-error'),
    UNKNOWN_ERROR: t('meta.constants.sw-state-unknown-error'),
    REGISTERED: t('meta.constants.sw-state-registered'),
  };
};
