import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';


const ChangeEmailModal = ({ handler, user }) => {

  const [disabled, setDisabled] = useState(false);
  const [initiated, setInitiated] = useState(false);
  const { opened, ok, backdrop, cancel } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleEdit = async (values) => {

    var formData = { ...values };

    setDisabled(true);
    let brot = toast.loading(t('widgets.toast.loading'));

    await postData(`auth/user/change_email/`, formData).then(async (response) => {
      toast.dismiss();
      setInitiated(true)
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: '',
      new_email: '',
      pk: user.pk,
    },
    onSubmit: (values) => handleEdit(values),
  });

  if (initiated) {
    return (
      <>
        <Modal show={opened.value} onHide={handleClose} backdrop={backdrop || disabled ? 'static' : true}>
          <Modal.Header closeButton={!disabled}>
            <Modal.Title>{t('modals.change-email.title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {t('modals.change-email.initiated-msg')}
            </p>
          </Modal.Body>
          <Modal.Footer className="d-flex">
            <Button variant="primary" onClick={handleClose} >{t('forms.actions.ok')}</Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

  return (
    <>
      <Modal show={opened.value} onHide={handleClose} backdrop={backdrop || disabled ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.change-email.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={disabled}>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.change-email.field-password')}</Form.Label>
              <Form.Control
                autoComplete="off"
                isInvalid={formik.errors?.fields?.password}
                name="password"
                onChange={formik.handleChange}
                type="password"
                value={formik.values.password}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.change-email.field-new-email')}</Form.Label>
              <Form.Control
                autoComplete="off"
                isInvalid={formik.errors?.fields?.new_email}
                name="new_email"
                onChange={formik.handleChange}
                type="email"
                value={formik.values.new_email}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.new_email}
              </Form.Control.Feedback>
            </Form.Group>
          </fieldset>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.update')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangeEmailModal;
