import { gql } from '@apollo/client';

const GET_DISPOSITIONS = gql`
  query Dispositions(
      $state: WarehouseDispositionStateChoices
    ) {
    dispositions(
      state: $state
      orderBy: "updatedAt"
    ) {
      totalCount
      edges {
        node {
          amount
          getStateDisplay
          pk
          state
          supplier
          element {
            pk
            collection {
              pk
              name
            }
            item {
              cardImageSquare
              imageFull
              name
              pk
              typeOf {
                name
                cardImageSquare
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edgeCount
    }
  }
`;

export {
  GET_DISPOSITIONS,
};
