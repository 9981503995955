import React, { useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { faWind } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import slugify from 'slugify';
import { postData } from '../../utils/axiosHelper';


const CollectionModalPublish = ({ handler, collection }) => {

  const [disabled, setDisabled] = useState(false);
  const client = useApolloClient();
  const defaultSlug = slugify(collection?.name, { lower: true, remove: /[*+~.()'"!:@]/g });
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleSave = async (values) => {

    var formData = { ...values };

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.saving'));

    await postData(`warehouse/collections/${collection.pk}/publish/`, formData, { sleep: 500 }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.collection-publish.updated-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      await client.clearStore();
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const convertNameToSlug = () => {
    formik.setFieldValue('desired_slug', defaultSlug);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      collection: collection?.pk,
      desired_slug: collection.publicSlug,
      is_public: collection.isPublic,
      public_pk: collection?.publicPk,
    },
    onSubmit: (values) => handleSave(values),
  });

  return (
    <Modal show={opened.value} onHide={handleClose} backdrop={disabled ? 'static' : true}>
      <Modal.Header closeButton={!disabled}>
        <Modal.Title>{t('modals.collection-publish.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <fieldset disabled={disabled}>
          <Form.Group className="mb-3">
            <Form.Label>{t('modals.collection-publish.field-slug')}</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                autoComplete="off"
                isInvalid={formik.errors?.fields?.desired_slug}
                name="desired_slug"
                placeholder={t('modals.collection-publish.slug-placeholder', { 'example': defaultSlug })}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.desired_slug}
              />
              <Button variant="secondary" onClick={convertNameToSlug} disabled={defaultSlug === formik.values.desired_slug}>
                <FontAwesomeIcon icon={faWind} />
              </Button>
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.desired_slug}
              </Form.Control.Feedback>
            </InputGroup>
            {/*<Form.Text className="text-muted">{t('modals.collection-publish.field-slug-help')}</Form.Text>*/}
          </Form.Group>
          {collection?.publicPk && (
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.collection-publish.field-public_pk')}</Form.Label>
              <Form.Control
                autoComplete="off"
                isInvalid={formik.errors?.fields?.public_pk}
                name="public_pk"
                onChange={formik.handleChange}
                disabled
                type="text"
                value={formik.values.public_pk}
              />
              {/*<Form.Text className="text-muted">{t('modals.collection-publish.field-public_pk-help')}</Form.Text>*/}
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.public_pk}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          <Form.Group className="mb-3">
            <Form.Check
              type="switch"
              id="mode-switch"
              checked={formik.values.is_public === true}
              onChange={(event) => formik.setFieldValue('is_public', event.target.checked)}
              label={t('modals.collection-publish.field-release')}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors?.fields?.name}
            </Form.Control.Feedback>
          </Form.Group>
        </fieldset>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
        <Button variant={`${formik.values.is_public ? 'warning' : 'primary'}`} onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.save')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CollectionModalPublish;
