import React from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import teamwork from '../../../assets/img/illustrations/teamwork.png';
import ResetForm from '../../../components/auth/ResetForm';
import useAuth from '../../../hooks/useAuth';


const PWReset = () => {

  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();

  const metaTags = [
    { name: 'description', content: t('public.auth.password-reset-meta-description') },
  ];

  const linkTags = [
    { rel: 'canonical', href: `${process.env.REACT_APP_SELF}${location.pathname}` },
  ];

  if (isAuthenticated) {
    return <Navigate to="/private" replace />
  }

  return (
    <>
      <Helmet title={t('public.auth.password-reset-headline')} meta={metaTags} link={linkTags} />
      <GoogleReCaptchaProvider
        useRecaptchaNet
        reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY}
        scriptProps={{ async: true, defer: true, appendTo: 'body' }}
      >
        <Container>
          <Row style={{ margin: '100px 0 150px 0' }}>
            <Col md={{ span: 10, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 4, offset: 1 }} className="pb-5">
              <h1 className="text-center">{t('public.auth.password-reset-headline')}</h1>
              <p className="text-center lead text-muted">{t('public.auth.password-reset-lead')}</p>
              <br />
              <ResetForm />
            </Col>
            <Col md={{ span: 10, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} className="pb-5">
              <img src={teamwork} alt="Teamwork" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </GoogleReCaptchaProvider>,
    </>
  );

}

export default PWReset;
