import React, { useCallback, useEffect, useState } from 'react';

import { Button, Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import pathfinder from '../../assets/img/illustrations/pathfinder.png';
import UserAvatar from '../../components/auth/UserAvatar';
import PrivateMetaNavHeader from '../../components/basic/PrivateMetaNavHeader';
import useAuth from '../../hooks/useAuth';
import { deleteObj, getObj, patchData, secureHTML } from '../../utils/axiosHelper';


const SeatInvitation = ({ review = false }) => {

  const [seat, setSeat] = useState();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { publicLinkId } = useParams();
  const { user } = useAuth();

  const getSeat = useCallback(async () => {
    const brot = toast.loading(t('widgets.toast.loading'));

    await getObj(`accounting/seats/${review ? 'review/' : ''}${publicLinkId !== 'mine' ? `${publicLinkId}/` : ''}`).then(async (response) => {
      setSeat(response.data);
      toast.dismiss(brot);
    }).catch((error) => {
      setSeat(null);
      toast.update(brot, {render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    });
  }, [publicLinkId, review, t]);

  const acceptInvitation = async () => {

    const brot = toast.loading(t('widgets.toast.loading'));
    const formData = {
      'pk': publicLinkId,
    };

    await patchData(`accounting/seats/accept/${publicLinkId}/`, formData).then(async (response) => {
      setSeat(response.data);
      toast.dismiss(brot);
    }).catch((error) => {
      toast.update(brot, {render: <div dangerouslySetInnerHTML={secureHTML(`<b>${t('widgets.toast.error')} ${error.response.status}</b><br />${error.response.data.pk[0]}`)}></div>, type: 'error', isLoading: false, autoClose: 3000 });
    });
  };

  const discardInvitation = async () => {

    const brot = toast.loading(t('widgets.toast.loading'));
    const formData = {
      'pk': publicLinkId,
    };

    await deleteObj(`accounting/seats/discard/${publicLinkId}/`, formData).then(async (response) => {
      navigate('/private/seats/');
      toast.dismiss(brot);
    }).catch((error) => {
      toast.update(brot, {render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    });
  };

  const deleteInvitation = async () => {

    const brot = toast.loading(t('widgets.toast.loading'));
    const formData = {
      'pk': publicLinkId,
    };


    await deleteObj(`accounting/seats/${seat?.id || seat?.public_link_id}/`, formData).then(async (response) => {
      navigate('/private/seats/');
      toast.dismiss(brot);
    }).catch((error) => {
      toast.update(brot, {render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    });
  };

  useEffect(() => {
    if (user?.pk && publicLinkId) {
      getSeat();
    }
  }, [user?.pk, publicLinkId, getSeat]);

  if (seat === null) {
    return (
      <>
        <Helmet title={t('public.error.404')} />
        <PrivateMetaNavHeader />
        <div className="mainspace d-flex">
          <div className="container align-self-center">
            <div className="row">
              <div className="col-md-4 order-md-2 col-xxl-4 h-100">
                <img src={pathfinder} alt="Pathfinder" className="img-fluid" />
              </div>
              <div className="col-md-8 order-md-1 align-middle col-xxl-8 d-flex">
                <div className="align-self-center">
                  <h1 className="display-5 font-weight-bold">{t('pages.seat.404-headline')}</h1>
                  <p className="text-muted">{t('pages.seat.404-message')}</p>
                  <Link to="/private/seats/" className="btn btn-link ps-0">
                    {t('pages.seat.open-seats')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet title={t('pages.seat.headline')} />
      <PrivateMetaNavHeader />
      <div className="mainspace p-4">
        <Container>
          <h3>{t('pages.seat.promoter')}</h3>
          <hr />
          <dl className="row">
            <dt className="col-sm-3">{t('pages.seat.plan')}:</dt>
            <dd className="col-sm-9 mb-2">{seat?.plan?.name}</dd>
            <dt className="col-sm-3">{t('pages.seat.promoted-by')}:</dt>
            <dd className="col-sm-9 mb-2">{seat?.promoter_repr}</dd>
            <dt className="col-sm-3">{t('pages.seat.created-before')}:</dt>
            <dd className="col-sm-9 mb-2">
              <Moment locale={i18n.resolvedLanguage} fromNow ago>{seat?.created_at}</Moment>
            </dd>
            <dt className="col-sm-3">{t('pages.seat.status')}:</dt>
            <dd className="col-sm-9 mb-2">{seat?.get_state_display}</dd>
            {seat?.invitee_user_repr && (
              <>
                <dt className="col-sm-3">{t('pages.seat.contact')}:</dt>
                <dd className="col-sm-9 mb-2">{seat?.invitee_user_repr}</dd>
              </>
            )}
          </dl>
          {seat?.colleagues?.length > 0 && (
            <>
              <h3>{t('pages.seat.network')}</h3>
              <hr />
              <Row className="mb-5">
                {seat?.colleagues && seat.colleagues.map((colleague, index) => (
                  <Col md="6" key={index}>
                    <div className="d-flex mb-5">
                      <div className="flex-shrink-0">
                      <UserAvatar height={76} width={76} user={colleague} className={'rounded-circle'} />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <div className="fw-bold">{colleague.invitee_user.repr}</div>
                        {/*{JSON.stringify(colleague, null, 4)}*/}
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </>
          )}
          <div className="text-end">
            {seat?.id && (
              <Button variant="danger" className="ms-1" onClick={deleteInvitation}>
                {t('forms.actions.delete')}
              </Button>
            )}
            {!seat?.id && seat?.state === 1 && (
              <>
                <Button variant="warning" className="ms-1" onClick={discardInvitation}>
                  {t('forms.actions.discard')}
                </Button>
                <Button variant="success" className="ms-1" onClick={acceptInvitation}>
                  {t('forms.actions.accept')}
                </Button>
              </>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default SeatInvitation;
