import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Lightbox } from 'react-modal-image';
import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { confirm } from "../../components/basic/Confirmation";
import EmptyPanel from '../../components/basic/EmptyPanel';
import DispositionsToolbar from '../../components/dispositions/Toolbar';
import { GET_DISPOSITIONS } from '../../components/dispositions/queries';
import { postData } from '../../utils/axiosHelper';


const Dispositions = () => {

  const [dispositions, setDispositions] = useState([]);
  const [lightbox, setLightbox] = useState();
  const { t, i18n } = useTranslation();
  const [disabled, setDisabled] = useState(false);

  const [getDispositions, { refetch, loading }] = useLazyQuery(GET_DISPOSITIONS, {
    variables: {
      state: 'A_1',
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setDispositions(data.dispositions.edges);
    },
  });

  const registerDisposition = (dispositionPk) => {

    confirm({
      title: t('pages.element.register-disposition-hint-title'),
      lead: t('pages.element.register-disposition-hint-lead'),
      showCancel: true,
      danger: false,
    }).then(async (response) => {

      if (response === false) return;

      setDisabled(true);
      const brot = toast.loading(t('widgets.toast.saving'));

      await postData(`warehouse/dispositions/${dispositionPk}/register/`, {}, {sleep: 500}).then(async (response) => {
        refetch();
        toast.update(brot, { render: t('pages.element.register-disposition-success'), type: 'success', isLoading: false, autoClose: 2000 });
      }).catch((error) => {
        toast.update(brot, {render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
      }).finally(() => {
        setDisabled(false);
      });

    });
  };

  const openLightbox = (params) => { if (params?.large) setLightbox(params); }
  const closeLightbox = (params) => { setLightbox(); }

  useEffect(() => { getDispositions(); }, [getDispositions]);

  return (
    <>
      <Helmet title={t('pages.dispositions.headline')} />
      <DispositionsToolbar refetch={refetch} loading={loading} />
      <div className="mainspace p-4 pt-3">
        <div className="d-flex">
          <div className="flex-grow-1 me-3">
            <h2>
              {t('pages.dispositions.headline')}
            </h2>
          </div>
          <div className="flex-shrink-0 d-none d-sm-flex">&nbsp;</div>
        </div>
        <hr className="mt-0 mb-3" />
        {dispositions?.length === 0 && !loading && (
          <EmptyPanel reloadTrigger={refetch} maximize={false} large={true} />
        )}
        {dispositions?.length > 0 && (
          <Table className="bg-light" hover bordered striped responsive>
            <thead>
              <tr>
                <th className="text-center" width="60">
                  <FontAwesomeIcon icon={faImage} />
                </th>
                <th>{t('pages.dispositions.table-item-name')}</th>
                <th width="240">{t('pages.dispositions.table-collection-name')}</th>
                <th width="240">{t('pages.dispositions.table-supplier')}</th>
                <th width="145">{t('pages.dispositions.element-table-updated-at')}</th>
                <th width="90">{t('pages.dispositions.table-amount')}</th>
                <th width="150">{t('pages.dispositions.table-type_of')}</th>
                <th width="150">{t('pages.dispositions.table-status')}</th>
                <th width="50"></th>
              </tr>
            </thead>
            <tbody>
              {dispositions.map((disposition, index) => (
                <tr key={index}>
                  <td className="text-center">
                    {(disposition.node.element.item.cardImageSquare || disposition.node.element.item.typeOf?.cardImageSquare) && (
                      <img
                        className="rounded border"
                        width="46"
                        height="46"
                        src={`${process.env.REACT_APP_MEDIA_URL}${disposition.node.element.item.cardImageSquare || disposition.node.element.item.typeOf?.cardImageSquare}`}
                        alt={disposition.node.element.item.name}
                        loading="lazy"
                        role="button"
                        onClick={() => openLightbox({
                          large: `${process.env.REACT_APP_MEDIA_URL}${disposition.node.element.item.imageFull}`,
                          alt: disposition.node.element.item.name,
                        })}
                      />
                    )}
                    {!(disposition.node.element.item.cardImageSquare || disposition.node.element.item.typeOf?.cardImageSquare) && (
                      <FontAwesomeIcon icon={faImage} className="text-secondary" style={{ fontSize: '45px' }} />
                    )}
                  </td>
                  <td>
                    <NavLink to={`/private/collections/${disposition.node.element.collection?.pk}/${disposition.node.element.pk}`} className="text-decoration-none">
                      {disposition.node.element.item?.name}
                    </NavLink>
                  </td>
                  <td>
                    <NavLink to={`/private/collections/${disposition.node.element.collection?.pk}`} className="text-decoration-none">
                      {disposition.node.element.collection?.name}
                    </NavLink>
                  </td>
                  <td>
                    {disposition.node.supplier || t('forms.values.unset')}
                  </td>
                  <td>
                    <Moment locale={i18n.resolvedLanguage} date={disposition.node.updatedAt} format="L" />
                  </td>
                  <td>{disposition.node.amount}</td>
                  <td>{disposition.node.element.item.typeOf?.name}</td>
                  <td>{disposition.node.getStateDisplay}</td>
                  <td className="text-center">
                    {disposition.node.state === 'A_1' && (
                      <div className="d-grid gap-2">
                        <Button variant="secondary" disabled={disabled} size="sm" onClick={() => registerDisposition(disposition.node.pk)}>
                          {t('pages.dispositions.register-disposition')}
                        </Button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {lightbox && <Lightbox large={lightbox.large} alt={lightbox.alt} onClose={closeLightbox} showRotate={true} />}
      </div>
    </>
  );
};

export default Dispositions;
