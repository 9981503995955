import React, { useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { Form } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import UserAvatar from '../../components/auth/UserAvatar';
import useAuth from '../../hooks/useAuth';
import useSidebar from '../../hooks/useSidebar';


const UserDropdown = () => {

  const [show, setShow] = useState(false);
  const client = useApolloClient();
  const { collapsed, autoCollapsed, setCollapsed, setAutoCollapsed } = useSidebar();
  const { i18n, t } = useTranslation();
  const { signOut } = useAuth();
  const { user } = useAuth();

  const handleSignOutClick = () => {
    signOut(client);
  };

  const handleCollapseChange = (event) => {
    setCollapsed(event.target.checked);
  }

  const handleAutoCollapseChange = (event) => {
    setAutoCollapsed(event.target.checked);
  }

  const handleToggle = (newOpenState, event) => {
    setShow(event?.originalEvent?.target?.dataset?.keepOpen === "Y" || newOpenState);
  };

  return (
    <React.Fragment>
      <Dropdown
        show={show}
        onToggle={handleToggle}
      >
        <Dropdown.Toggle as="a" role="button" variant="link" className={`d-flex align-items-center text-decoration-none text-primary-emphasis ${collapsed ? "p-3 border-top" : ""}`}>
          <UserAvatar user={user} className={`rounded-circle ${collapsed ? "" : "me-2"}`} />
          {!collapsed && (<strong>{user?.repr}</strong>)}
        </Dropdown.Toggle>
        <Dropdown.Menu className="shadow ms-1">
          <Dropdown.Item as={Link} to="/private/profile">
            {t('widgets.sidebar.user-dropdown.profile')}
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/private/settings">
            {t('widgets.sidebar.user-dropdown.settings')}
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/private/accounting">
            {t('widgets.sidebar.user-dropdown.accounting')}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            as="div"
            data-keep-open="Y"
            style={{ fontWeight: i18n.resolvedLanguage === 'de' ? 'bold' : 'normal' }}
            role="button"
            className="py-0 py-lg-1"
            onClick={() => {
              i18n.changeLanguage('de');
            }}
          >deutsch</Dropdown.Item>
          <Dropdown.Item
            as="div"
            data-keep-open="Y"
            className="py-0 py-lg-1"
            role="button"
            style={{ fontWeight: (!i18n.resolvedLanguage || i18n.resolvedLanguage === 'en') ? 'bold' : 'normal' }}
            onClick={() => {
              i18n.changeLanguage('en');
            }}
          >english</Dropdown.Item>
          <Dropdown.Item
            as="div"
            role="button"
            data-keep-open="Y"
            style={{ fontWeight: i18n.resolvedLanguage === 'zh-TW' ? 'bold' : 'normal' }}
            onClick={() => {
              i18n.changeLanguage('zh-TW');
            }}
          >台灣</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as="div">
            <Form.Check id="collapse-opt">
              <Form.Check.Input data-keep-open="Y" disabled={autoCollapsed} checked={collapsed} onChange={(event) => handleCollapseChange(event)} />
              <Form.Check.Label data-keep-open="Y" className="w-100">{t('widgets.sidebar.user-dropdown.collapsed')}</Form.Check.Label>
            </Form.Check>
          </Dropdown.Item>
          <Dropdown.Item as="div">
            <Form.Check id="auto-collapse-opt">
              <Form.Check.Input data-keep-open="Y" checked={autoCollapsed} onChange={(event) => handleAutoCollapseChange(event)} />
              <Form.Check.Label data-keep-open="Y" className="w-100">{t('widgets.sidebar.user-dropdown.auto-collapse')}</Form.Check.Label>
            </Form.Check>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => handleSignOutClick()}>{t('widgets.sidebar.user-dropdown.sign-out')}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default UserDropdown;
