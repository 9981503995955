import React, { useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { patchData } from '../../utils/axiosHelper';


const GroupModalLeave = ({ handler, cachedGroup }) => {

  const [disabled, setDisabled] = useState(false);
  const client = useApolloClient();
  const { opened, leftGroup } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      group: cachedGroup?.pk,
      remove_permissions: true,
    },
    onSubmit: (values) => leave(values),
  });

  const leave = async (values) => {

    var formData = { ...values };

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.loading'));
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    await patchData(`network/groups/${cachedGroup.pk}/leave/`, formData, { config }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.group-leave.left-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      await client.clearStore();
      if (leftGroup) leftGroup();
    }).catch((error) => {
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  if (!cachedGroup) {
    return null;
  }

  return (
    <>
      <Modal show={opened.value} onHide={handleClose} size="md" backdrop={disabled ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.group-leave.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-0">{t('modals.group-leave.confirm-msg')}</p>
          <div className="fw-bold">{cachedGroup?.name}</div>
          <fieldset disabled={disabled}>
            <Row>
              <Col md="12">
                <Form.Group className="mb-3">
                  <Form.Control
                    name="name"
                    type="hidden"
                    disabled
                    readOnly
                    value={cachedGroup?.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.group}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Check
                  type="switch"
                  id="cb-remove-permissions"
                  checked={formik.values.remove_permissions}
                  label={t('modals.group-leave.remove-permissions')}
                  onChange={(event) => formik.setFieldValue('remove_permissions', event.target.checked)}
                />
                {formik.values?.remove_permissions === false && (
                  <div className="form-text text-danger mb-3">{t('modals.group-leave.remove-permissions-warning')}</div>
                )}
                <div className="form-text">{t('modals.group-leave.impact-msg')}</div>
              </Col>
            </Row>
          </fieldset>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="warning" onClick={formik.handleSubmit} disabled={disabled}>{t('modals.group-leave.leave')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GroupModalLeave;
