import React from 'react';

// Meta
import AuthGuard from './components/basic/AuthGuard';

// Layouts
import AppLayout from './layouts/App';
import AuthLayout from './layouts/Auth';
import CollectionLayout from './layouts/Collections';
import LandingLayout from './layouts/Landing';
import MetaLayout from './layouts/Meta';

// Public Pages
import Home from './pages/public/Home';
import Public404 from './pages/public/E404';

// Public Meta
import Blog from './pages/public/Blog';
import BlogArticle from './pages/public/BlogArticle';
import CommunityGuidelines from './pages/public/meta/CommunityGuidelines';
import Conditions from './pages/public/meta/Conditions';
import Contact from './pages/public/meta/Contact';
import ContactSend from './pages/public/meta/ContactSend';
import CookieRequired from './pages/public/meta/CookieRequired';
import Donate from './pages/public/meta/Donate';
import FairUse from './pages/public/meta/FairUse';
import Faq from './pages/public/meta/Faq';
import Imprint from './pages/public/meta/Imprint';
import Pricing from './pages/public/meta/Pricing';
import Privacy from './pages/public/meta/Privacy';
import Sources from './pages/public/meta/Sources';

// Public Newsletter Pages
import NewsletterActive from './pages/public/newsletter/Active';
import NewsletterPending from './pages/public/newsletter/Pending';
import NewsletterUnsubscribed from './pages/public/newsletter/Unsubscribed';
import NewsletterConfirm from './pages/public/newsletter/Confirm';

// Public Auth Pages
import AccountDeletionConfirmation from './pages/public/auth/AccountDeletionConfirmation';
import EmailChangeConfirm from './pages/public/auth/EmailChangeConfirm';
import Invitation from './pages/public/Invitation';
import Login from './pages/public/auth/Login';
import PWReset from './pages/public/auth/PWReset';
import PWResetConfirm from './pages/public/auth/PWResetConfirm';
import PWResetConfirmOTP from './pages/public/auth/PWResetConfirmOTP';
import Register from './pages/public/auth/Register';
import RegisterConfirm from './pages/public/auth/RegisterConfirm';
import RegisterConfirmOTP from './pages/public/auth/RegisterConfirmOTP';

// Public collections
import PublicCollection from './pages/public/collections/Collection';
import PublicCollections from './pages/public/collections/Collections';
import PublicElement from './pages/public/collections/Element';

// Private Pages
import Accounting from './pages/private/Accounting';
import Browser from './pages/private/Browser';
import Collection from './pages/private/Collection';
import Collections from './pages/private/Collections';
import Dashboard from './pages/private/Dashboard';
import Dispositions from './pages/private/Dispositions';
import Element from './pages/private/Element';
import Group from './pages/private/Group';
import GroupCollection from './pages/private/GroupCollection';
import Groups from './pages/private/Groups';
import Inventory from './pages/private/Inventory';
import Invoice from './pages/private/Invoice';
import Issues from './pages/private/Issues';
import Item from './pages/private/Item';
import Items from './pages/private/Items';
import Private404 from './pages/private/E404';
import Profile from './pages/private/Profile';
import Seat from './pages/private/Seat';
import Seats from './pages/private/Seats';
import Settings from './pages/private/Settings';


const routes = [
  {
    path: "/",
    element: <LandingLayout />,
    children: [
      {
        path: "",
        element: <Home />,
      },
    ],
  },
  {
    path: "groups",
    element: <AuthLayout />,
    children: [
      {
        path: "invitations/:publicLinkId",
        element: <Invitation />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout requiredCookies={['recaptcha']} />,
    children: [
      {
        path: "register",
        children: [
          {
            path: "",
            element: <Register />,
          },
        ]
      },
      {
        path: "password-reset",
        children: [
          {
            path: "",
            element: <PWReset />,
          },
        ]
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        children: [
          {
            path: "confirm-otp",
            element: <RegisterConfirmOTP />,
          },
          {
            path: "confirm/:token",
            element: <RegisterConfirm />,
          }
        ]
      },
      {
        path: "account-deletion-confirmation/:signature",
        element: <AccountDeletionConfirmation />
      },
      {
        path: "password-reset",
        children: [
          {
            path: "confirm/:userId/:token",
            element: <PWResetConfirm />,
          },
          {
            path: "confirm-otp",
            element: <PWResetConfirmOTP />,
          },
        ]
      },
      {
        path: "email-reset/:signature",
        element: <EmailChangeConfirm />,
      },
    ],
  },
  {
    path: "blog",
    element: <CollectionLayout />,
    children: [
      {
        path: "",
        element: <Blog />,
      },
      {
        path: ":articleSlug",
        element: <BlogArticle />,
      },
    ]
  },
  {
    path: "newsletter",
    element: <MetaLayout />,
    children: [
      {
        path: "active",
        element: <NewsletterActive />,
      },
      {
        path: "pending",
        element: <NewsletterPending />,
      },
      {
        path: "unsubscribed",
        element: <NewsletterUnsubscribed />,
      },
      {
        path: "confirm/:confirmationId",
        element: <NewsletterConfirm />,
      },
    ],
  },
  {
    path: "meta",
    element: <MetaLayout />,
    children: [
      {
        path: "sources",
        element: <Sources />,
      },
      {
        path: "imprint",
        element: <Imprint />,
      },
      {
        path: "conditions",
        element: <Conditions />,
      },
      {
        path: "privacy",
        element: <Privacy />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "contact/send",
        element: <ContactSend />,
      },
      {
        path: "donate",
        element: <Donate />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "community-guidelines",
        element: <CommunityGuidelines />,
      },
      {
        path: "faq",
        element: <Faq />,
      },
      {
        path: "fair-use",
        element: <FairUse />,
      },
      {
        path: "cookie-required",
        element: <CookieRequired />,
      },
    ],
  },
  {
    path: "collections",
    element: <CollectionLayout />,
    children: [
      {
        path: "",
        element: <PublicCollections />,
      },
    ],
  },
  {
    path: "c",
    element: <CollectionLayout />,
    children: [
      {
        path: ":collectionSlug",
        element: <PublicCollection />,
      },
      {
        path: ":collectionSlug/:elementId",
        element: <PublicElement />,
      },
    ],
  },
  {
    path: "private",
    element: (
      <AuthGuard>
        <AppLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "collections",
        children: [
          {
            path: "",
            element: <Collections />,
          },
          {
            path: ":collectionId",
            element: <Collection />,
          },
          {
            path: ":collectionId/:elementId",
            element: <Element />,
          },
        ]
      },
      {
        path: "inventory",
        children: [
          {
            path: "",
            element: <Inventory />,
          },
        ],
      },
      {
        path: "items",
        children: [
          {
            path: "",
            element: <Items />,
          },
          {
            path: ":itemId",
            element: <Item />,
          },
        ],
      },
      {
        path: "browser",
        element: <Browser />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "seats",
        element: <Seats />,
      },
      {
        path: "seats/:publicLinkId",
        element: <Seat />,
      },
      {
        path: "seats/review/:publicLinkId",
        element: <Seat review={true} />,
      },
      {
        path: "dispositions",
        element: <Dispositions />,
      },
      {
        path: "issues",
        element: <Issues />,
      },
      {
        path: "accounting",
        children: [
          {
            path: "",
            element: <Accounting />,
          },
          {
            path: ":invoiceId",
            element: <Invoice />
          },
        ],
      },
      {
        path: "groups",
        children: [
          {
            path: "",
            element: <Groups />,
          },
          {
            path: ":groupId",
            element: <Group />,
          },
          {
            path: ":groupId/c/:collectionId",
            element: <GroupCollection />,
          }
        ]
      },
      {
        path: "*",
        element: <Private404 />,
      }
    ],
  },
  {
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Public404 />,
      }
    ]
  }
];

export default routes;
