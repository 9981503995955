import React from 'react';

import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';


const Imprint = () => {

  const location = useLocation();
  const { t } = useTranslation();

  const metaTags = [
    { name: 'description', content: t('pages.meta.imprint.meta-description') },
  ];

  const linkTags = [
    { rel: 'canonical', href: `${process.env.REACT_APP_SELF}${location.pathname}` },
  ];

  return (
    <>
      <Helmet title={t('pages.meta.imprint.headline')} meta={metaTags} link={linkTags} />

      <Container>
        <h1>{t('pages.meta.imprint.headline')}</h1>
        <p className="lead">Wer sind wir? Ein Software-Entwickler aus Schleswig-Holstein mit Hang zum organisieren.</p>

        <h2 className="mt-5">Angaben gemäß § 5 TMG</h2>
        <address>
          <strong>Bastian Probian</strong><br />
          Zum Lehmbarg 1<br />
          21039 Escheburg<br />
        </address>

        <address className="mb-4">
          <strong>Steuernummer:</strong><br />
          <div className="mb-1">27/085/00020</div>
          <strong>USt-IdNr.:</strong><br />
          <div className="mb-1">DE344899653</div>
        </address>

        <h2>Kontakt</h2>
        <address className="mb-5">
          <abbr title="Email">E:</abbr> info@lagerraum.pro
        </address>

        <h2>Haftung für Links</h2>
        <p className="text-justify mb-4">
          Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        </p>

        <h2>Streitschlichtung</h2>
        <p className="mb-4">
          Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
          <a className="ms-1" href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>
          <br />
          Unsere E-Mail-Adresse finden Sie oben im Impssum.
          Wir sind <u>nicht</u> bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>

        <h2>Haftung für Inhalte</h2>
        <p className="text-justify mb-4">
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entspchenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
        </p>

        <h2>Urheberrecht</h2>
        <p className="text-justify mb-4">
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entspchenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </p>

        <h2>Quellenangaben</h2>
        <p className="text-justify">
          <p>
            Impressum:<br />
            <a href="https://www.e-recht24.de/" target="_blank" rel="noreferrer">e-recht24.de</a><br />
            Weitere Quellen, die dieses Projekt ermöglicht haben, finden Sie unter der eigens für diesen Zweck eingerichtete <NavLink to="/meta/sources">Quellen-Seite</NavLink>.
          </p>
        </p>

      </Container>
    </>
  );
}

export default Imprint;
