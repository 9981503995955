import React from 'react';

import { NavLink } from 'react-router-dom';


const SidebarItem = ({ collapsed, label, Icon, active, to, start, end }) => {
  return (
    <>
      <NavLink to={to} className={`nav-link ${collapsed ? "border-bottom py-3" : "nav-link-expanded"}`} end={end} >
        <Icon className={`bi ${collapsed ? "" : "me-2"}`} width={collapsed ? 24 : 16} height={collapsed ? 24 : 16} />
        {!collapsed && (<span className="ms-1">{label}</span>)}
      </NavLink>
    </>
  );
};

export default SidebarItem;

