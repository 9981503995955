import React, { useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { deleteObj } from '../../utils/axiosHelper';


const GroupModalDelete = ({ handler, group }) => {

  const [disabled, setDisabled] = useState(false);
  const client = useApolloClient();
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleDelete = async (values) => {

    if (values.name !== group.name) {
      formik.setErrors({
        fields: {
          collection: [
            t('modals.group-delete.confirm-missmatch')
          ]
        }
      });
      return;
    }

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.deleting'));

    await deleteObj(`network/groups/${group.pk}/`, { sleep: 500 }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.group-delete.deleted-successfully'), type: 'info', isLoading: false, autoClose: 2000 });
      await client.clearStore();
      ok();
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pk: group.pk,
      name: '',
    },
    onSubmit: (values) => handleDelete(values),
  });

  return (
    <Modal show={opened.value} onHide={handleClose} backdrop={disabled ? 'static' : true}>
      <Modal.Header closeButton={!disabled}>
        <Modal.Title>{t('modals.group-delete.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <fieldset disabled={disabled}>
          <p>{t('modals.group-delete.confirm-msg')}: <b>{group.name}</b></p>
          <Form.Group className="mb-3">
            <Form.Label>{t('modals.group-delete.field-name')}</Form.Label>
            <Form.Control
              name="name"
              onChange={formik.handleChange}
              type="text"
              value={formik.values.name}
              isInvalid={formik.errors?.fields?.collection}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors?.fields?.collection}
            </Form.Control.Feedback>
            <div className="form-text">{t('modals.group-delete.impact-msg')}</div>
          </Form.Group>
        </fieldset>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
        <Button variant="danger" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.delete')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GroupModalDelete;
