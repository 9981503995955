import React, { useEffect, useState, useCallback } from 'react';

import { useFormik } from 'formik';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import teamwork from '../../../assets/img/illustrations/teamwork.png';
import { postData } from '../../../utils/axiosHelper';
import { Button, Form } from 'react-bootstrap';


const EmailChangeConfirm = () => {

  const [disabled, setDisabled] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [resolvedSignature, setResolvedSignature] = useState({ 'old_email': '', 'new_email': '' });
  const { signature } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      signature: signature,
    },
    onSubmit: async (values) => {
      reset(values);
    },
  });

  const checkPayload = useCallback(() => {

    var formData = { signature };

    setDisabled(true);
    setInitialized(true);
    const brot = toast.loading(t('widgets.toast.validating'));

    postData('auth/user/change_email/validation/', formData, { sleep: 2000 }).then(async (response) => {
      setResolvedSignature(response.data);
      setDisabled(false);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      toast.dismiss(brot);
    });
  }, [formik, signature, t]);

  const abort = () => {
    navigate('/auth/login');
  };

  const reset = async (values) => {

    var formData = { ...values };

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.saving'));

    return await postData('auth/user/change_email/confirmation/', formData, { sleep: 500 }).then(async (response) => {
      navigate('/auth/login', { state: { justResetEmail: true } })
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
      return false;
    }).finally(() => {
      setDisabled(false);
      toast.dismiss(brot);
    });
  };

  useEffect(() => {
    if (initialized === false) checkPayload();
  }, [initialized, checkPayload]);

  return (
    <>
      <Helmet title={t('public.auth.email-reset-headline-confirm')} />
      <Container>
        <Row style={{ margin: '100px 0 150px 0' }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 4, offset: 1 }} className="pb-5">
            <h1 className="text-center">{t('public.auth.email-reset-headline-confirm')}</h1>
            <br />
            <Form.Group className="mb-3">
              <Form.Label>{t('public.auth.email-reset-field-old-email')}</Form.Label>
              <Form.Control
                type="email"
                value={resolvedSignature.old_email}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('public.auth.email-reset-field-new-email')}</Form.Label>
              <Form.Control
                type="email"
                value={resolvedSignature.new_email}
                disabled
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.new_password_confirm}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                isInvalid={formik.errors?.fields?.signature}
                type="hidden"
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.signature}
                <div>
                  <Link className="btn btn-link px-0" to="/private/profile">
                    {t('public.auth.email-reset-goto-profile-details')}
                  </Link>
                </div>
              </Form.Control.Feedback>
            </Form.Group>
            <div className="text-center mt-3">
              <div className="d-grid gap-2">
                <Button type="button" onClick={formik.handleSubmit} variant="primary" disabled={disabled}>
                  {t('public.auth.btn-change-email')}
                </Button>
                <Button type="button" onClick={abort} variant="secondary">
                  {t('public.auth.btn-change-email-abort')}
                </Button>
              </div>
            </div>
          </Col>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} className="pb-5">
            <img src={teamwork} alt="Teamwork" className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </>
  );

}

export default EmailChangeConfirm;
