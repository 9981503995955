import React, { useEffect, useCallback, useState } from 'react';
import { THEME } from '../constants';
import useSettingsState from '../hooks/useSettingsState';

const initialState = {
  theme: THEME.AUTO,
};

const ThemeContext = React.createContext(initialState);

function ThemeProvider({ children }) {
  const [theme, setTheme, updateDocument] = useSettingsState('bsTheme', THEME.AUTO);
  const [prefersDarkMode, setPrefersDarkMode] = useState(false);
  const [themeIsDark, setThemeIsDark] = useState(false);

  const updateThemeBasedOnSystem = useCallback(() => {
    setTheme(theme);
    updateDocument('bsTheme', theme);
  }, [theme, setTheme, updateDocument]);

  useEffect(() => {
    setThemeIsDark(theme === THEME.DARK || (theme === THEME.AUTO && prefersDarkMode));
  }, [theme, prefersDarkMode]);

  useEffect(() => {
    const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");
    setPrefersDarkMode(window.matchMedia("(prefers-color-scheme: dark)").matches);

    const handleThemeChange = () => {
      setPrefersDarkMode(window.matchMedia("(prefers-color-scheme: dark)").matches);
      updateThemeBasedOnSystem();
    };

    mediaQueryList.addEventListener('change', handleThemeChange);

    return () => {
      mediaQueryList.removeEventListener('change', handleThemeChange);
    };
  }, [updateThemeBasedOnSystem]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme, prefersDarkMode, themeIsDark }}>
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeContext, ThemeProvider };
