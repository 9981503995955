import React, { useRef, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { Folder2Open, X } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { GET_COLLECTIONS } from '../../components/collections/queries';
import { postData } from '../../utils/axiosHelper';


const ElementModalAddImage = ({ handler, collection }) => {

  const [defaultCollectionOptions, setDefaultCollectionOptions] = useState(collection ? [{ node: collection }] : null);
  const [disabled, setDisabled] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(collection ? { node: collection } : null);
  const cardImageRef = useRef();
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleSave = async (values) => {

    if (!values?.card_image) {
      return;
    }

    uploadFile(values?.card_image);
  };


  const uploadFile = async (file) => {

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.uploading'));

    await uploadImage(file).then((response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.element-create-image.image-upload-success'), type: 'success', isLoading: false, autoClose: 2000 });
      ok(response.data);
    }).catch((error) => {
      let errorMessage = error.response?.data?.image?.join();
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: <div>{t('widgets.toast.error')} {error.response.status}<br /><b>{file.name}</b><br />{errorMessage}</div>, type: 'warning', isLoading: false, autoClose: 5000 });
    });

    setDisabled(false);
  };


  const uploadImage = async (file) => {

    const config = { headers: { "Content-Type": "multipart/form-data" }, sleep: 100 };

    let data = new FormData();
    data.append('image', file);

    if (selectedCollection?.node?.pk) {
      data.append('collection', selectedCollection?.node?.pk);
    }

    return await postData('warehouse/elements/image/', data, config);
  };

  const [getMyCollections] = useLazyQuery(GET_COLLECTIONS, {
    variables: {
      name_Icontains: null,
      mine: true,
      orderBy: 'name',
    },
  });

  const asyncCollectionOptions = async (search) => {
    var response = await getMyCollections({ variables: { name_Icontains: search } });
    setDefaultCollectionOptions(response?.data?.collections?.edges || []);
    return response?.data?.collections?.edges || [];
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      card_image: null,
      cardImageName: t('forms.values.undefined'),
    },
    onSubmit: (values) => handleSave(values),
  });


  return (
    <>
      <Modal show={opened.value} onHide={handleClose} backdrop={disabled ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.element-create-image.title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <fieldset disabled={disabled}>

            <Form.Group className="mb-3">
              <Form.Label>{t('modals.settings-import-isbn-bulk.field-collection')}</Form.Label>
              <Form.Control
                type="hidden"
                isInvalid={formik.errors?.fields?.collection}
              />
              <AsyncSelect
                cacheOptions
                defaultOptions={defaultCollectionOptions}
                getOptionLabel={(e) => e.node.name}
                getOptionValue={(e) => e.node.pk}
                loadOptions={asyncCollectionOptions}
                name="collection"
                onChange={(o) => { setSelectedCollection(o); formik.setFieldValue("collection", o?.node || null) }}
                placeholder={t('modals.settings-import-isbn-bulk.field-collection-placeholder')}
                value={selectedCollection}
                styles={{
                  control: styles => ({
                    ...styles,
                    borderColor: formik.errors?.fields?.collection ? '#dc3545' : styles.borderColor,
                    '&:hover': {
                      borderColor: formik.errors?.fields?.collection ? '#dc3545' : styles['&:hover'].borderColor,
                    }
                  })
                }}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.collection}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{t('modals.element-create-image.field-list-image')}</Form.Label>
              <InputGroup>
                <Form.Control
                  value={formik.values.card_image?.name || formik.values.cardImageName}
                  isInvalid={formik.errors?.fields?.card_image}
                  disabled
                />
                {formik.values.card_image && (
                  <Button
                    variant="outline-secondary"
                    onClick={() => { cardImageRef.current.value = null; formik.setFieldValue('card_image', null) }}
                  >
                    <X />
                  </Button>
                )}
                <label className="file-btn btn btn-primary px-2" style={{ zIndex: '0' }}>
                  <input
                    accept="image/*"
                    id="field-card_image"
                    ref={cardImageRef}
                    name="card_image"
                    onChange={(event) => formik.setFieldValue('card_image', event.currentTarget.files[0])}
                    style={{ position: 'absolute', top: -10000 }}
                    type="file"
                  />
                  <Folder2Open />
                </label>
              </InputGroup>
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.card_image}
              </Form.Control.Feedback>
            </Form.Group>
            {formik.values.card_image && (
              <Form.Group className="mb-3">
                <Form.Label>{t('modals.element-create-image.field-new-list-image-preview')}</Form.Label>
                <img
                  alt={t('modals.element-create-image.field-new-list-image-preview')}
                  className="img-fluid rounded border"
                  src={URL.createObjectURL(formik.values.card_image)}
                />
              </Form.Group>
            )}
          </fieldset>
        </Modal.Body>

        <Modal.Footer className="d-flex">
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="primary" onClick={formik.handleSubmit} disabled={!formik.values.card_image || disabled}>{t('forms.actions.create')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default ElementModalAddImage;
