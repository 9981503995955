import { getObj } from './axiosHelper';


const downloadBinary = (url) => {
  getObj(url, { config: { responseType: 'blob' } }).then(response => {

    const headerval = response.headers['content-disposition'];
    var filename = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '');

    const fileURL = window.URL.createObjectURL(response.data);
    let alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = filename;
    alink.click();
  })
};


export { downloadBinary };
