import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useApolloClient, useLazyQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { Folder2Open, Trash, X } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { GET_COLLECTION_FOR_EDITING, GET_WRITEABLE_COLLECTIONS } from '../../components/collections/queries';
import { putData } from '../../utils/axiosHelper';
import { basename } from '../../utils/formatter';
import CollectionModalDelete from './ModalDelete';


const CollectionModalEdit = ({ handler, original }) => {

  const [collection, setCollection] = useState();
  const [defaultCollectionOptions, setDefaultCollectionOptions] = useState(collection ? [{ node: collection }] : null);
  const [disabled, setDisabled] = useState(false);
  const [selectedCollectionParent, setSelectedCollectionParent] = useState(original?.parent ? { node: original?.parent } : null);
  const [types, setTypes] = useState([]);
  const cardImageRef = useRef();
  const client = useApolloClient();
  const { opened, ok, cancel, backdrop } = handler;
  const { t } = useTranslation();

  const [initialData, setInitialData] = useState({
    cardImageName: basename(original?.cardImage || t('forms.values.undefined')),
    default_type: '',
    defaultType: '',
    delete_card_image: false,
    description: original?.description,
    enable_price_options: original?.enablePriceOptions || false,
    enable_consumption_options: original?.enableConsumptionOptions || false,
    name: original?.name || '',
    new_card_image: null,
    pk: original?.pk,
  });

  const [getMyCollections] = useLazyQuery(GET_WRITEABLE_COLLECTIONS, {
    variables: {
      name_Icontains: null,
    },
  });

  const [getCollectionForEditing] = useLazyQuery(GET_COLLECTION_FOR_EDITING, {
    variables: { collectionId: original?.pk },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setCollection(data.collectionForEditing);
      setTypes(data.types);
      setInitialData({
        ...data.collectionForEditing,
        cardImageName: basename(data.collectionForEditing.cardImage || t('forms.values.undefined')),
        default_type: data.collectionForEditing.defaultType?.pk,
        enable_price_options: data.collectionForEditing.enablePriceOptions,
      });
    },
  });

  const valueFromId = (opts, id) => opts.find((o) => o.node.pk === id);

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleDelete = () => {
    deleteModalHandler.opened.set(true);
  };

  const handleEdit = async (values) => {

    var formData = { ...values };
    formData.default_type = formData.defaultType?.pk || null;
    formData.parent = formData.parent?.pk || null;

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.loading'));
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    await putData(`warehouse/collections/${collection.pk}/`, formData, { config }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.collection-edit.updated-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      await client.clearStore();
      ok();
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const toggleCardImageDeletion = () => {
    formik.setFieldValue('delete_card_image', !formik.values.delete_card_image);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    onSubmit: (values) => handleEdit(values),
  });

  const deleteModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      opened.set(false);
      ok('destroyed');
    },
  };

  const asyncCollectionOptions = useCallback(async (search) => {
    var response = await getMyCollections({ variables: { name_Icontains: search } });
    setDefaultCollectionOptions(response?.data?.writeableCollections?.edges || [{ node: collection }]);
    return response?.data?.writeableCollections?.edges || [];
  }, [getMyCollections, collection, setDefaultCollectionOptions]);

  useEffect(() => {
    getCollectionForEditing();
    asyncCollectionOptions('', true);
  }, [original?.id, getCollectionForEditing, asyncCollectionOptions]);


  if (!collection) {
    return null;
  }

  return (
    <>
      {deleteModalHandler.opened.value && <CollectionModalDelete handler={deleteModalHandler} collection={collection} />}
      <Modal show={opened.value} onHide={handleClose} size="lg" backdrop={disabled || backdrop === true ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.collection-edit.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={disabled}>
            <Row>
              <Col md="8">
                <Row>
                  <Col md="7">
                    <Form.Group className="mb-3">
                      <Form.Label>{t('modals.collection-edit.field-name')}</Form.Label>
                      <Form.Control
                        autoComplete="off"
                        isInvalid={formik.errors?.fields?.name}
                        name="name"
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors?.fields?.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="5">
                    <Form.Group className="mb-3">
                      <Form.Label>{t('modals.collection-edit.field-type')}</Form.Label>
                      <Select
                        getOptionLabel={(e) => e.node.name}
                        getOptionValue={(e) => e.node.pk}
                        placeholder={t('modals.collection-edit.field-type-placeholder')}
                        isClearable={true}
                        name="defaultType"
                        onChange={(o) => formik.setFieldValue('defaultType', o?.node || null)}
                        options={types.edges || []}
                        value={valueFromId(types.edges || [], formik.values.defaultType?.pk || null)}
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors?.fields?.default_type}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md="4">
                <Form.Group className="mb-3">
                  <Form.Label>{t('modals.collection-edit.field-list-image')}</Form.Label>
                  <InputGroup>
                    {collection.cardImage && !formik.values.new_card_image && (
                      <Button variant="outline-secondary" className="px-1" onClick={toggleCardImageDeletion}>
                        <Trash />
                      </Button>
                    )}
                    <Form.Control
                      className={formik.values.delete_card_image ? "text-decoration-line-through" : ""}
                      value={formik.values.new_card_image?.name || formik.values.cardImageName}
                      isInvalid={formik.errors?.fields?.card_image}
                      disabled
                    />
                    {formik.values.new_card_image && (
                      <Button
                        variant="outline-secondary"
                        className="px-1"
                        onClick={() => { cardImageRef.current.value = null; formik.setFieldValue('new_card_image', null) }}
                      >
                        <X />
                      </Button>
                    )}
                    <label className="file-btn btn btn-primary px-2">
                      <input
                        accept="image/*"
                        id="field-new_card_image"
                        ref={cardImageRef}
                        name="new_card_image"
                        onChange={(event) => formik.setFieldValue('new_card_image', event.currentTarget.files[0])}
                        className="d-none"
                        type="file"
                      />
                      <Folder2Open />
                    </label>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.card_image}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={((collection.cardImageThumbnail && !formik.values.delete_card_image && !formik.values.new_card_image) || formik.values.new_card_image || (!formik.values.new_card_image && !formik.values.new_card_image && (!collection.cardImageThumbnail || formik.values.delete_card_image) && formik.values.defaultType?.cardImageThumbnail)) ? 8 : 12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('modals.collection-edit.field-parent')}</Form.Label>
                  <Form.Control
                    type="hidden"
                    isInvalid={formik.errors?.fields?.parent}
                  />
                  <AsyncSelect
                    cacheOptions
                    isClearable={true}
                    defaultOptions={defaultCollectionOptions}
                    getOptionLabel={(e) => e.node.name}
                    getOptionValue={(e) => e.node.pk}
                    loadOptions={asyncCollectionOptions}
                    name="parent"
                    onChange={(o) => { setSelectedCollectionParent(o); formik.setFieldValue('parent', o?.node || null) }}
                    placeholder={t('modals.collection-edit.field-parent-placeholder')}
                    value={selectedCollectionParent}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    styles={{
                      control: styles => ({
                        ...styles,
                        borderColor: formik.errors?.fields?.parent ? '#dc3545' : styles.borderColor,
                        '&:hover': {
                          borderColor: formik.errors?.fields?.parent ? '#dc3545' : styles['&:hover'].borderColor,
                        }
                      })
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.parent}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t('modals.collection-edit.field-description')}</Form.Label>
                  <Form.Control
                    name="description"
                    as="textarea"
                    rows={4}
                    placeholder={t('modals.collection-edit.field-description-info')}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.description}
                    isInvalid={formik.errors?.fields?.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.description}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Check
                    checked={formik.values.enable_price_options}
                    id="mode-price-options"
                    label={t('modals.collection-edit.field-enable-price-options')}
                    onChange={(event) => formik.setFieldValue('enable_price_options', event.target.checked)}
                    type="switch"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors?.fields?.enable_price_options}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Check
                    checked={formik.values.enable_consumption_options}
                    id="mode-consumption-options"
                    label={t('modals.collection-edit.field-enable-consumption-options')}
                    onChange={(event) => formik.setFieldValue('enable_consumption_options', event.target.checked)}
                    type="switch"
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors?.fields?.enable_consumption_options}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="4">
                {collection.cardImageThumbnail && !formik.values.delete_card_image && !formik.values.new_card_image && (
                  <Form.Group className="mb-3">
                    <Form.Label>{t('modals.collection-edit.field-list-image-preview')}</Form.Label>
                    <img
                      alt={t('modals.collection-edit.field-list-image-preview')}
                      className="img-fluid rounded border"
                      src={`${process.env.REACT_APP_MEDIA_URL}${collection.cardImageThumbnail}`}
                    />
                  </Form.Group>
                )}
                {formik.values.new_card_image && (
                  <Form.Group className="mb-3">
                    <Form.Label>{t('modals.collection-edit.field-new-list-image-preview')}</Form.Label>
                    <img
                      alt={t('modals.collection-edit.field-new-list-image-preview')}
                      className="img-fluid rounded border"
                      src={URL.createObjectURL(formik.values.new_card_image)}
                    />
                  </Form.Group>
                )}
                {!formik.values.new_card_image && (!collection.cardImageThumbnail || formik.values.delete_card_image) && formik.values.defaultType?.cardImageThumbnail && (
                  <Form.Group className="mb-3">
                    <Form.Label>{t('modals.collection-edit.field-type-fallback-image-preview')}</Form.Label>
                    <img
                      alt={t('modals.collection-edit.field-type-fallback-image-preview')}
                      className="img-fluid rounded border"
                      src={`${process.env.REACT_APP_MEDIA_URL}${formik.values.defaultType?.cardImageThumbnail}`}
                    />
                  </Form.Group>
                )}
              </Col>
            </Row>
          </fieldset>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Button variant="danger" className="me-auto" onClick={handleDelete} disabled={disabled}>{t('forms.actions.delete')}</Button>
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.update')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CollectionModalEdit;
