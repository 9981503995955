import React, { useCallback, useEffect, useState } from 'react';

import { Button, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import ChangePasswordModal from '../../components/auth/ModalChangePassword';
import ChangeEmailModal from '../../components/auth/ModalChangeEmail';
import UserModalEdit from '../../components/auth/ModalEditUser';
import UserAvatar from '../../components/auth/UserAvatar';
import Formi from '../../components/basic/Formi';
import PrivateMetaNavHeader from '../../components/basic/PrivateMetaNavHeader';
import useAuth from '../../hooks/useAuth';
import useLocalStorage from '../../hooks/useLocalStorage';
import { getObj } from '../../utils/axiosHelper';
import { renderBoolean } from '../../utils/formatter';


const Profile = () => {

  const [liveUser, setLiveUser] = useState();
  const [showDetails, setShowDetails] = useLocalStorage('module.profile.show-details', false);
  const { authPayload, refreshToken, user } = useAuth();
  const { t, i18n } = useTranslation();

  const toggleDetail = () => {
    setShowDetails(!showDetails);
  };

  const divisionByZero = () => {
    throw new Error('Woahaho!', {cause: "Don't be scared"});
  };

  const updateLiveUser = useCallback(async () => {
    const brot = toast.loading(t('widgets.toast.loading'));
    await getObj('auth/user/').then(async (response) => {
      setLiveUser(response.data);
      toast.dismiss(brot);
    }).catch((error) => {
      toast.update(brot, {render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    });
  }, [t]);

  const editModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    backdrop: true,
    ok: (response) => {
      setLiveUser(response);
      refreshToken();
    },
  };

  const changePasswordModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    backdrop: true,
    ok: (response) => {
    },
  };

  const changeEmailModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    backdrop: true,
    ok: (response) => {
    },
  };

  useEffect(() => {
    if (user?.pk) updateLiveUser();
  }, [user.pk, updateLiveUser]);

  return (
    <>
      {liveUser && editModalHandler.opened.value && <UserModalEdit handler={editModalHandler} user={liveUser} allowDelete={true} />}
      {liveUser && changePasswordModalHandler.opened.value && <ChangePasswordModal handler={changePasswordModalHandler} user={liveUser} />}
      {liveUser && changeEmailModalHandler.opened.value && <ChangeEmailModal handler={changeEmailModalHandler} user={liveUser} />}
      <Helmet title={t('pages.profile.headline')} />
      <PrivateMetaNavHeader />
      <div className="mainspace p-4">
        <Container>
          <h3>{t('pages.profile.private.headline')}</h3>
          <hr />
          <dl className="row mb-5">
            <dt className="col-sm-3">{t('pages.profile.private.salutation')}</dt>
            <dd className="col-sm-9">{liveUser?.salutation_display || t('forms.values.unavailable')}</dd>
            <dt className="col-sm-3">{t('pages.profile.private.full-name')}</dt>
            <dd className="col-sm-9">{liveUser?.full_name || t('forms.values.unavailable')}</dd>
            <dt className="col-sm-3">{t('pages.profile.private.email')}</dt>
            <dd className="col-sm-9">{liveUser?.email || t('forms.values.unavailable')}</dd>
            <dt className="col-sm-3">{t('pages.profile.private.avatar')}</dt>
            {(liveUser?.thumbnail || liveUser?.gravatar) && (
              <dd className="col-sm-9"><UserAvatar user={liveUser} height={128} width={128} className="rounded border" /></dd>
            )}
            {(!liveUser?.thumbnail && !liveUser?.gravatar) && (
              <dd className="col-sm-9"><UserAvatar user={{}} height={128} width={128} className="rounded border" /></dd>
            )}
            <dt className="col-sm-3">{t('pages.profile.private.motto')}</dt>
            <dd className="col-sm-9">{liveUser?.motto || t('forms.values.undefined')}</dd>
            <dt className="col-sm-3">{t('pages.profile.private.date-joined')}</dt>
            <dd className="col-sm-9">
              {liveUser?.date_joined && <Moment locale={i18n.resolvedLanguage} duration={liveUser.date_joined} />}
              {!liveUser?.date_joined && t('forms.values.undefined')}
            </dd>
            <dt className="col-sm-3">{t('pages.profile.private.options')}</dt>
            <dd className="col-sm-9">
              <Button variant="link" className="p-0 me-2 text-decoration-none" onClick={() => editModalHandler.opened.set(true)}>
                {t('pages.profile.private.edit')}
              </Button>
              <Button variant="link" className="p-0 me-2 text-decoration-none" onClick={() => updateLiveUser()}>
                {t('pages.profile.private.reload')}
              </Button>
              <Button variant="link" className="p-0 me-2 text-decoration-none" onClick={() => changePasswordModalHandler.opened.set(true)}>
                {t('pages.profile.private.change-password')}
              </Button>
              <Button variant="link" className="p-0 me-2 text-decoration-none" onClick={() => changeEmailModalHandler.opened.set(true)}>
                {t('pages.profile.private.change-email')}
              </Button>
            </dd>
          </dl>
          <h3>{t('pages.profile.session.headline')}</h3>
          <hr />
          <dl className="row mb-5">
            <dt className="col-sm-3">{t('pages.profile.token.is-authenticated')}</dt>
            <dd className="col-sm-9">{authPayload?.isAuthenticated ? t('forms.values.yes') : t('forms.values.no')}</dd>
            <dt className="col-sm-3">{t('pages.profile.user.first-name')}</dt>
            <dd className="col-sm-9">{authPayload?.user.first_name || t('forms.values.unset')}</dd>
            <dt className="col-sm-3">{t('pages.profile.user.last-name')}</dt>
            <dd className="col-sm-9">{authPayload?.user.last_name || t('forms.values.unset')}</dd>
            <dt className="col-sm-3">{t('pages.profile.user.email')}</dt>
            <dd className="col-sm-9">{authPayload?.payload.email}</dd>
            <dt className="col-sm-3">{t('pages.profile.user.id')}</dt>
            <dd className="col-sm-9" role="button" onClick={() => toggleDetail()}>{showDetails ? authPayload?.user.pk : authPayload?.user.pk.substring(0, 8)}</dd>
            <dt className="col-sm-3">{t('pages.profile.user.gravatar')}</dt>
            <dd className="col-sm-9 text-break">
              {!authPayload?.user.gravatar && t('pages.profile.user.gravatar-disabled')}
              {authPayload?.user.gravatar && (
                <>
                  {authPayload.user.gravatar}
                </>
              )}
            </dd>
            <dt className="col-sm-3">{t('pages.profile.session.issued-at')}</dt>
            <dd className="col-sm-9">
              <div><Formi timestampVal={authPayload?.payload?.orig_iat} /></div>
              <Moment locale={i18n.resolvedLanguage} unix fromNow>{authPayload?.payload?.orig_iat}</Moment>
            </dd>
            <dt className="col-sm-3">{t('pages.profile.session.expiration-time')}</dt>
            <dd className="col-sm-9">
              <div>
                <Formi timestampVal={authPayload?.payload?.exp} />
              </div>
              <Moment locale={i18n.resolvedLanguage} unix fromNow>{authPayload?.payload?.exp}</Moment>
              <br />
              <Button variant="link" className="px-0 text-decoration-none" onClick={() => refreshToken()}>
                {t('pages.profile.token.refresh')}
              </Button>
            </dd>
          </dl>
          {showDetails && (
            <>
              <h3 className="mb-4">{t('pages.profile.debug.headline')}</h3>
              <hr />
              <dl className="row mb-5">
                <dt className="col-sm-3">{t('pages.profile.debug.currency')}</dt>
                <dd className="col-sm-9"><Formi currencyVal={19.99} /></dd>
                <dt className="col-sm-3">{t('pages.profile.debug.decimal')}</dt>
                <dd className="col-sm-9"><Formi decimalVal={1230.56} /></dd>
                <dt className="col-sm-3">{t('pages.profile.debug.bytes')}</dt>
                <dd className="col-sm-9"><Formi byteVal={1234567890} /></dd>
                <dt className="col-sm-3">{t('pages.profile.debug.unicode')}</dt>
                <dd className="col-sm-9">👻</dd>
                <dt className="col-sm-3">{t('pages.profile.debug.boolean')}</dt>
                <dd className="col-sm-9">{renderBoolean()} {renderBoolean(true)} {renderBoolean(false)}</dd>
                <dt className="col-sm-3">{t('pages.profile.debug.sentry')}</dt>
                <dd className="col-sm-9">
                  <Button variant="link" className="p-0 text-decoration-none" onClick={divisionByZero}>
                    {t('pages.profile.debug.sentry-trigger')}
                  </Button>
                </dd>
              </dl>
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default Profile;
