import React from 'react';

import { Link } from 'react-router-dom';


const infoFromCollection = (collection) => {

  if (!collection)
    return null;

  if (collection.itemImageThumbnail)
    return {
      to: `/private/collections/${collection.pk}`,
      img: `${collection.itemImageThumbnail}`,
      alt: `${collection.name}`,
    };

  if (!collection.itemImageThumbnail && collection.defaultType?.itemImageThumbnail)
    return {
      to: `/private/collections/${collection.pk}`,
      img: `${collection.defaultType.itemImageThumbnail}`,
      alt: `${collection.name}`,
    };

};

const CollectionImageBreadcrump = ({ collection, renderParents = true }) => {

  var crumps = [];

  crumps.push(infoFromCollection(collection));
  crumps.push(renderParents && infoFromCollection(collection?.parent));
  crumps.push(renderParents && infoFromCollection(collection?.parent?.parent));
  crumps.push(renderParents && infoFromCollection(collection?.parent?.parent?.parent));
  crumps.push(renderParents && infoFromCollection(collection?.parent?.parent?.parent?.parent));
  crumps.push(renderParents && infoFromCollection(collection?.parent?.parent?.parent?.parent?.parent));

  return (
    <>
      {crumps && crumps.filter((e) => e).map((crump, index) => (
        <Link to={crump.to} key={index}>
          <img
            alt={crump.alt}
            title={crump.alt}
            className="img-fluid rounded border bg-white ms-1"
            src={`${process.env.REACT_APP_MEDIA_URL}${crump.img}`}
            style={{ maxHeight: '40px', minHeight: '40px' }}
          />
        </Link>
      ))}
    </>
  );
};

export default CollectionImageBreadcrump;
