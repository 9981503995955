import React from 'react';

import { Link } from 'react-router-dom';


const infoFromItem = (item) => {

  if (!item)
    return null;

  if (item.imageThumbnail)
    return {
      to: `/private/items/${item.pk}`,
      img: `${item.imageThumbnail}`,
      alt: `${item.name}`,
    };

  if (!item.imageThumbnail && item.typeOf?.itemImageThumbnail)
    return {
      to: `/private/items/${item.pk}`,
      img: `${item.typeOf.itemImageThumbnail}`,
      alt: `${item.name}`,
    };

};

const ItemImageBreadcrump = ({ item }) => {

  var crumps = [];

  crumps.push(infoFromItem(item));

  return (
    <>
      {crumps && crumps.filter((e) => e).map((crump, index) => (
        <Link to={crump.to} key={index}>
          <img
            alt={crump.alt}
            title={crump.alt}
            className="img-fluid rounded border bg-white ms-1"
            src={`${process.env.REACT_APP_MEDIA_URL}${crump.img}`}
            style={{ maxHeight: '40px', minHeight: '40px' }}
          />
        </Link>
      ))}
    </>
  );
};

export default ItemImageBreadcrump;
