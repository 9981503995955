import React from 'react';

import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MemberAvatar from '../../components/groups/MemberAvatar';


const GroupSidebarPermission = ({ handler, group }) => {

  const { opened } = handler;
  const { t } = useTranslation();

  return (
    <>
      <div className="panel bg-body-tertiary border-start flex-fill mw-330 overflow-hidden">
        <div className="app">
          <div className="module modal">
            <div className="modal-header">
              <div className="modal-title h5">{t('sidebars.group-permissions.title')}</div>
              <button type="button" className="btn-close" aria-label="Close" onClick={() => opened.set(false)}></button>
            </div>
            <div className="mainspace p-3">
              <dl>
                <dt className="mb-3">{t('sidebars.group-permissions.members')}</dt>
                <dd className="mb-4">
                  {group.members.map((member, index) => (
                    <div className="d-flex mb-2" key={index}>
                      <div className="flex-shrink-0">
                        <MemberAvatar member={member} />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <b>
                          {member.repr}
                          {member.role === 'A_1' && <Badge bg='success' className='float-end'>{t('sidebars.group-permissions.admin-badge')}</Badge>}
                        </b>
                        <p>{member.motto}</p>
                      </div>
                    </div>
                  ))}
                </dd>
                {group.permissions.length > 1 && (
                  <>
                    <dt className="mb-3">{t('sidebars.group-permissions.user-access')}</dt>
                    <dd>
                      {group.permissions.map((permission, index) => (
                        <div className="d-flex mb-2" key={index}>
                          <div className="flex-shrink-0">
                            <MemberAvatar member={permission} />
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <b>
                              {permission.repr}
                              {permission.role === 'A_1' && <Badge bg='success' className='float-end'>{t('sidebars.group-permissions.creator-badge')}</Badge>}
                            </b>
                          </div>
                        </div>
                      ))}
                    </dd>
                  </>
                )}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupSidebarPermission;
