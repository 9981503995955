import React from 'react';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from "./utils/ScrollToTop";

import './i18n';


const basename = process.env.PUBLIC_URL;
const history = createBrowserHistory({ basename: basename });
const root = ReactDOM.createRoot(document.getElementById('root'));


Sentry.init({
  dsn: "https://9d1c7fbcd1e845aab6a879959fef94a2@o1415418.ingest.sentry.io/6756132",
  environment: process.env.NODE_ENV,
  beforeSend(event, hint) {
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
  integrations: [
    new BrowserTracing({
      tracingOrigins: ["*"],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: process.env.NODE_ENV === 'development' ? 0 : 0.01,
});


if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
    .then(registration => {
      if (process.env.NODE_ENV === 'development') {
        console.debug('Service worker registered:');
      }
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error('Error during service worker registration:');
        console.error(error);
      }
    });
}


root.render(
  <BrowserRouter history={history} basename={basename}>
    <ScrollToTop />
    <App />
  </BrowserRouter>,
);

// Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV === 'development') {
  reportWebVitals(console.debug);
}
