import React from 'react';

import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';


const CommunityGuidelines = () => {

  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t('pages.meta.community-guidelines.headline')} />
      <Container>
        <h1>{t('pages.meta.community-guidelines.headline')}</h1>
        <p className="lead">{t('pages.meta.community-guidelines.lead')}</p>
      </Container>
    </>
  );
}

export default CommunityGuidelines;
