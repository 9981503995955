import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import WidgetCollectionTable from '../../components/dashboard/WidgetCollectionTable';
import WidgetElementTable from '../../components/dashboard/WidgetElementTable';
import WidgetElementsByType from '../../components/dashboard/WidgetElementsByType';


const Dashboard = () => {

  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t('pages.dashboard.headline')} />
      <h2 className="p-4">{t('pages.dashboard.headline')}</h2>
      <div className="mainspace p-4">
        <Row>
          <Col md={8} xxl={9}>
            <Row className="mb-4">
              <Col md={12} xxl={6}>
                <div className="card mb-4">
                  <div className="card-body border-top border-primary border-bottom-0 border-start-0 border-end-0 border-2">
                    <h5 className="mb-4">{t('pages.dashboard.latest-elements')}</h5>
                    <WidgetElementTable variant="primary" />
                  </div>
                </div>
              </Col>
              <Col md={12} xxl={6}>
                <div className="card mb-4">
                  <div className="card-body border-top border-danger border-bottom-0 border-start-0 border-end-0 border-2">
                    <h5 className="mb-4">{t('pages.dashboard.latest-collections')}</h5>
                    <WidgetCollectionTable variant="danger" />
                  </div>
                </div>
              </Col>
              <Col md={12} xxl={6}>
                <div className="card mb-4">
                  <div className="card-body border-top border-success border-bottom-0 border-start-0 border-end-0 border-2">
                    <h5 className="mb-4">Custom Inventory Filter #1</h5>
                    <WidgetElementTable variant="success" />
                  </div>
                </div>
              </Col>
              <Col md={12} xxl={6}>
                <div className="card mb-4">
                  <div className="card-body border-top border-success border-bottom-0 border-start-0 border-end-0 border-2">
                    <h5 className="mb-4">Custom Inventory Filter #2</h5>
                    <WidgetElementTable variant="success" />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={4} xxl={3}>
            <div className="card mb-4">
              <div className="card-body border-top border-warning border-bottom-0 border-start-0 border-end-0 border-2">
                <h5 className="mb-4">{t('pages.dashboard.elements-by-type')}</h5>
                <WidgetElementsByType />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
