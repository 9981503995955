import React, { useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { Button, Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';


const ElementModalToggle = ({ handler, element }) => {

  const [disabled, setDisabled] = useState(false);
  const client = useApolloClient();
  const { opened, ok, cancel, backdrop } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) {
      cancel();
    }
  };

  const handleToggle = async () => {

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.saving'));

    let formData = {
      element: element.pk,
    };

    await postData(`warehouse/elements/${element.pk}/toggle/`, formData, { sleep: 500 }).then(async (response) => {

      toast.update(brot, { render: t('modals.element-toggle.updated-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      opened.set(false);
      await client.clearStore();
      ok(response.data);
    }).catch((error) => {
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response?.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };


  return (
    <Modal show={opened.value} onHide={handleClose} backdrop={disabled || backdrop ? 'static' : true}>
      <Modal.Header closeButton={!disabled}>
        {!element?.isHidden && <Modal.Title>{t('modals.element-toggle.title-visible')}</Modal.Title>}
        {element?.isHidden && <Modal.Title>{t('modals.element-toggle.title-hidden')}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>
        <fieldset disabled={disabled}>
          <p>
            {!element.isHidden && (
              <Trans i18nKey="modals.element-toggle.message-hide" values={{ elementName: element?.item?.name }}>
                <span className="fw-bold">element name</span>
              </Trans>
            )}
            {element.isHidden && (
              <Trans i18nKey="modals.element-toggle.message-show" values={{ elementName: element?.item?.name }}>
                <span className="fw-bold">element name</span>
              </Trans>
            )}
          </p>
        </fieldset>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
        {!element.isHidden && <Button variant="primary" onClick={handleToggle} disabled={disabled}>{t('forms.actions.toggle-hide')}</Button>}
        {element.isHidden && <Button variant="primary" onClick={handleToggle} disabled={disabled}>{t('forms.actions.toggle-show')}</Button>}
      </Modal.Footer>
    </Modal>
  );
};

export default ElementModalToggle;
