import React from 'react';

import { useCookies } from 'react-cookie';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Main from '../components/basic/Main';
import Footer from '../components/public/Footer';
import Navbar from '../components/public/Navbar';


const AuthLayout = ({ requiredCookies, children }) => {

  let allCookiesSet = true;
  const location = useLocation();
  const [cookies] = useCookies(requiredCookies || []);

  if (requiredCookies) {
    requiredCookies.forEach((cookieName) => {
      if (!cookies[cookieName]) allCookiesSet = false;
    })
  }

  if (!allCookiesSet) {
    return <Navigate to="/meta/cookie-required" state={{ next: location.pathname, requiredCookies: requiredCookies }} replace={true} />;
  }

  return (
    <>
      <Navbar variant='blue' />
      <Main className="main-landing">
        {children}
        <Outlet />
      </Main>
      <Footer spacer={true} variant='blue' />
    </>
  );
};

export default AuthLayout;
