import React from 'react';

import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useLocation } from 'react-router-dom';


const Conditions = () => {

  const location = useLocation();
  const { t, i18n } = useTranslation();

  const metaTags = [
    { name: 'description', content: t('pages.meta.conditions.meta-description') },
  ];

  const linkTags = [
    { rel: 'canonical', href: `${process.env.REACT_APP_SELF}${location.pathname}` },
  ];

  return (
    <>
      <Helmet title={t('pages.meta.conditions.headline')} meta={metaTags} link={linkTags} />

      <Container>
        <h1>{t('pages.meta.conditions.headline')}</h1>
        <p className="lead">{t('pages.meta.conditions.lead')}</p>
        <hr className="mt-3 mb-5" />
        <h4>1 Allgemeines</h4>
        <p className="mb-5">Es gelten die allgemeinen Vertragsbedingungen des europäischen Gemeinschaftsrechts, sowie im speziellen folgende Abschnitte.</p>

        <h4>2 Kosten</h4>
        <p className="mb-5">
          Nach Ihrer Registrierung befinden Sie sich zunächst im kostenfreien persönlichen Tarif. Wenn Sie das System überzeugen konnte und Sie Lagerraum für geschäftliche Zwecke nutzen wollen, müssen Sie einen kostenpflichten Tarif abschließen.<br />
          Die Kosten für die jeweilige Laufzeit (z.B. ein Monat) entstehen jeweils zu Beginn der Laufzeit, sodass eine weitere Verlängerung jederzeit beendet werden kann.<br />
        </p>

        <h4>3 Kündigungs- und Rücktrittsrecht</h4>
        <p className="mb-5">Sie haben jederzeit die Möglichkeit Ihre Mitgliedschaft und oder Ihren aktuellen Tarif zu beenden bzw. die Verlängerung zu deaktivieren, sodass keine weiteren Kosten entstehen.</p>

        <h4>4 Allgemeine Bestimmungen</h4>
        <p className="mb-5">Wir sind berechtigt, diese Bedingungen gegebenenfalls von Zeit zu Zeit zu überarbeiten. Etwaige Änderungen sind nicht rückwirkend gültig. Die neueste Fassung dieser Bedingungen, die stets unter lagerraum.pro/meta/conditions verfügbar ist, wird unsere Beziehung zu Ihnen regeln. Mit Ausnahme von Änderungen, die neue Funktionen oder rechtliche Gründe betreffen, werden wir Sie 30 Tage im Voraus benachrichtigen, bevor Änderungen an diesen Bedingungen in Bezug auf die Rechte und Pflichten der Vertragsparteien wirksam werden; dies erfolgt z.B. mittels einer Service-Benachrichtigung oder per E-Mail an die mit Ihrem Account verbundene E-Mail-Adresse. Wenn Sie nach Inkrafttreten dieser Änderungen weiterhin auf unsere Dienste zugreifen und diese nutzen, erklären Sie Ihr Einverständnis, rechtlich an die überarbeiteten Bedingungen gebunden zu sein.</p>

        <h4>5 Gerichtsstand</h4>
        <p>Schleswig-Holstein</p>
        <p className="text-end">Stand: <Moment locale={i18n.resolvedLanguage} format="l" /></p>

      </Container>
    </>
  );
}

export default Conditions;
