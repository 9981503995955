import React from 'react';

import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';


const FairUse = () => {

  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t('pages.meta.fair-use.headline')} />

      <Container>
        <h1>{t('pages.meta.fair-use.headline')}</h1>
        <p className="lead">{t('pages.meta.fair-use.lead')}</p>
      </Container>

    </>
  );
}

export default FairUse;
