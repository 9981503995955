import React from 'react';

import defaultGroupImage from '../../assets/img/illustrations/default-group-image.png';


const ISBNLookupResultSelect = ({ result, handleItemSelectionChange, selectedItems }) => {

  const toggleItemSelection = (isbn) => {
    handleItemSelectionChange(isbn, !selectedItems[isbn]);
  };

  return (
    <div className={`d-flex h-100 border rounded p-1 ${selectedItems[result.isbn_13] ? 'bg-success text-light' : 'bg-light text-dark'}`} onClick={() => toggleItemSelection(result.isbn_13)} role="button">
      <div className="flex-shrink-0">
        {result.img_repr_thumbnail && <img src={`${process.env.REACT_APP_MEDIA_URL}${result.img_repr_thumbnail}`} className="rounded" alt={`ISBN-13 ${result['isbn_13']}`} style={{ maxWidth: '64px', width: '64px' }} />}
        {!result.img_repr_thumbnail && <img src={defaultGroupImage} className="rounded" alt={`ISBN-13 ${result['isbn_13']}`} style={{ maxWidth: '64px', width: '64px' }} />}
      </div>
      <div className="flex-grow-1 ms-3">
        <div><b>{result.title}</b></div>
        <div>{result.authors.join(', ')}</div>
      </div>
    </div>
  );
};

export default ISBNLookupResultSelect;
