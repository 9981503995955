import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Col, Dropdown, Row, Table } from 'react-bootstrap';
import { Book as ItemIcon, ThreeDotsVertical } from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';
import { Lightbox } from 'react-modal-image';
import Moment from 'react-moment';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Lightbox as YALightbox } from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import { LIST_VIEW_MODES } from '../../components/basic/Classes';
import EmptyPanel from '../../components/basic/EmptyPanel';
import EndOfSteamHandle from '../../components/basic/EndOfSteamHandle';
import ModalExport from '../../components/basic/ModalExport';
import StreamFooter from '../../components/basic/StreamFooter';
import ElementCard from '../../components/elements/Card';
import ElementModalAdd from '../../components/elements/ModalAdd';
import ElementModalAddBook from '../../components/elements/ModalAddBook';
import ElementModalAddFood from '../../components/elements/ModalAddFood';
import ElementModalAddImage from '../../components/elements/ModalAddImage';
import ElementModalDelete from '../../components/elements/ModalDelete';
import ElementModalMove from '../../components/elements/ModalMove';
import ElementsSidebarFilter from '../../components/elements/SidebarFilter';
import InventorySidebarInfo from '../../components/elements/SidebarInfoInventory';
import ElementsToolbarOverview from '../../components/elements/ToolbarOverview';
import { GET_ELEMENTS } from '../../components/elements/queries';
import ItemModalDelete from '../../components/items/ModalDelete';
import ItemModalEdit from '../../components/items/ModalEdit';
import ImportFromISBNBulkModal from '../../components/settings/ModalImportISBNBulk';
import useLocalStorage from '../../hooks/useLocalStorage';


const Inventory = () => {

  const [currentListMode, setCurrentListMode] = useLocalStorage('list-view-mode', LIST_VIEW_MODES.ListWithImage);
  const [disableHistoryScroll] = useLocalStorage('disable-history-scroll', false);
  const [elements, setElements] = useState([]);
  const [firstFetchHandled, setFirstFetchHandled] = useState(false);
  const [lastElementIdGathered, ] = useLocalStorage('last-element-id-gathered', false);
  const [lightbox, setLightbox] = useState();
  const [performedSearch] = useLocalStorage('q-elements', '');
  const [q, setQ] = useState(performedSearch);
  const [responseData, setResponseData] = useState();
  const [yaLightboxIndex, setYaLightboxIndex] = useState(-1);
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [getElements, queryKing] = useLazyQuery(GET_ELEMENTS, {
    variables: {
      first: 80,
      item_Name_Icontains: performedSearch,
      orderBy: ['item__name'],
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setElements(data.elements.edges);
      setResponseData(data);

      if (firstFetchHandled === false) {
        setFirstFetchHandled(true);
      }
    },
  });

  const createModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      queryKing.refetch();
    },
  };

  const createBookElementModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      queryKing.refetch();
    },
  };

  const createFoodElementModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      queryKing.refetch();
    },
  };

  const createImageElementModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      queryKing.refetch();
    },
  };

  const editItemModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      queryKing.refetch();
    },
  };

  const importFromISBNBulkModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      queryKing.refetch();
    },
  };

  const removeElementModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      queryKing.refetch();
    },
  };

  const moveElementModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      queryKing.refetch();
    },
  };

  const deleteItemModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      queryKing.refetch();
    },
  };

  const exportModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
    },
  };

  const filterSidebarHandler = {
    opened: Object.assign({}, ...useLocalStorage('show-sidebar-filter', false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
  };

  const infoSidebarHandler = {
    opened: Object.assign({}, ...useLocalStorage('show-sidebar-info', false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
  };

  const listenScrollEvent = async (event) => {
    if (responseData?.elements?.edges?.length === 0) return;
    if (event.target.scrollHeight - event.target.scrollTop > event.target.clientHeight * 2.5) return;
    if (!responseData?.elements?.pageInfo.hasNextPage || queryKing.loading) return;

    await queryKing.fetchMore({
      variables: {
        ...queryKing?.variables,
        after: responseData?.elements?.pageInfo.endCursor,
        first: 12,
      },
    });
  };

  const openLightbox = (params) => {
    if (params?.large) setLightbox(params);
  }

  const closeLightbox = (params) => {
    setLightbox();
  }

  const openElement = (element) => {
    navigate(`/private/collections/${element.collection?.pk}/${element.pk}`);
  };

  const renderBreadcrump = ({ showImage = true, className = 'mb-4', bsPrefix = 'breadcrumb' }) => {
    return (
      <Breadcrumb className={className} bsPrefix={bsPrefix}>
        <Breadcrumb.Item active linkAs={Link} linkProps={{ to: "/private/inventory" }}>
          {showImage && <ItemIcon className="bi img-fluid rounded me-1 align-text-top" width={20} height={20} style={{maxHeight: '20px', minHeight: '20px'}} />}
          {t('pages.elements.title')}
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  };

  const lightboxElement = (element) => {
    if (element.item.cardImageThumbnail || element.item.typeOf?.itemImageThumbnail) {
      openLightbox({
        large: `${process.env.REACT_APP_MEDIA_URL}${element.item.imageFull || element.item.cardImageThumbnail || element.item.typeOf?.itemImageThumbnail}`,
        alt: element.item.name,
      });
    }
  };

  const renderDropDown = (element, index = -1) => {

    if (!element) return null;

    return (
      <Dropdown onClick={(e) => e.stopPropagation()}>
        <Dropdown.Toggle variant="light" size="sm" className="dropdown-no-toggle ps-1 border-secondary" id={`dropdown-basic-${element?.pk}`}>
          <ThreeDotsVertical className="ms-1 dropdown-no-toggle" size={14} alt="Select actions" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          {editItemModalHandler && <Dropdown.Item onClick={() => editItemModalHandler.opened.set(element?.item)}>{t('pages.element.card-burger-edit')}</Dropdown.Item>}
          {removeElementModalHandler && <Dropdown.Item onClick={() => removeElementModalHandler.opened.set(element)}>{t('pages.element.card-burger-remove')}</Dropdown.Item>}
          {deleteItemModalHandler && <Dropdown.Item onClick={() => deleteItemModalHandler.opened.set(element?.item)}>{t('pages.element.card-burger-delete')}</Dropdown.Item>}
          <Dropdown.Item onClick={() => lightboxElement(element)}>
            {t('pages.element.card-burger-preview')}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setYaLightboxIndex(index)}>
            {t('pages.element.card-burger-gallery')}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => moveElementModalHandler.opened.set(element.pk)}>
            {t('pages.element.card-burger-move')}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => openElement(element)}>
            {t('pages.element.card-burger-open')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  useEffect(() => {
    if (firstFetchHandled === true && disableHistoryScroll !== true) {
      let lastElementMatch = document.querySelector('[data-last-element="true"]')
      lastElementMatch && lastElementMatch.scrollIntoView({block: "center", behavior: 'smooth'});
    }
  }, [currentListMode, firstFetchHandled, disableHistoryScroll]);

  useEffect(() => {
    if (firstFetchHandled === true && disableHistoryScroll !== true) {
      let lastElementMatch = document.querySelector('[data-last-element="true"]')
      lastElementMatch && lastElementMatch.scrollIntoView({block: "center", behavior: 'smooth'});
    }
  }, [firstFetchHandled, disableHistoryScroll]);

  const refetch = queryKing.refetch;

  useEffect(() => { getElements(); }, [getElements]);
  useEffect(() => {
    if (location?.state?.doRefetch) refetch();
  }, [location.state?.doRefetch, refetch]);

  return (
    <>
      {createBookElementModalHandler.opened.value && <ElementModalAddBook handler={createBookElementModalHandler} />}
      {createFoodElementModalHandler.opened.value && <ElementModalAddFood handler={createFoodElementModalHandler} />}
      {createImageElementModalHandler.opened.value && <ElementModalAddImage handler={createImageElementModalHandler} />}
      {createModalHandler.opened.value && <ElementModalAdd handler={createModalHandler} />}
      {deleteItemModalHandler.opened.value && <ItemModalDelete handler={deleteItemModalHandler} item={deleteItemModalHandler.opened.value} />}
      {editItemModalHandler.opened.value && <ItemModalEdit handler={editItemModalHandler} original={editItemModalHandler.opened.value} />}
      {exportModalHandler.opened.value && <ModalExport handler={exportModalHandler} source='elements' />}
      {importFromISBNBulkModalHandler.opened.value && <ImportFromISBNBulkModal handler={importFromISBNBulkModalHandler} backdrop={true} />}
      {moveElementModalHandler.opened.value && <ElementModalMove handler={moveElementModalHandler} elementId={moveElementModalHandler.opened.value} />}
      {removeElementModalHandler.opened.value && <ElementModalDelete handler={removeElementModalHandler} element={removeElementModalHandler.opened.value} />}
      <Helmet title={t('pages.elements.headline')} />
      <ElementsToolbarOverview
        currentListMode={currentListMode}
        fetchMore={queryKing.fetchMore}
        filterSidebarHandler={filterSidebarHandler}
        getElements={getElements}
        infoSidebarHandler={infoSidebarHandler}
        loading={queryKing.loading}
        q={q}
        refetch={refetch}
        setCurrentListMode={setCurrentListMode}
        setQ={setQ}
        showAddBookElementDialog={createBookElementModalHandler.opened.set}
        showAddDialog={createModalHandler.opened.set}
        showAddFoodElementDialog={createFoodElementModalHandler.opened.set}
        showAddImageDialog={createImageElementModalHandler.opened.set}
        showAddISBNsDialog={importFromISBNBulkModalHandler.opened.set}
        showExportDialog={exportModalHandler.opened.set}
        variables={queryKing.variables}
      />
      <div className="mainspace">
        <div className="splitter d-flex">
          <div className={`panel overflow-auto flex-fill ${filterSidebarHandler.opened.value ? 'd-none d-sm-block' : ''}`}>
            <div className="position-relative h-100 w-100">
              <div className="module">
                <div className="mainspace" onScroll={listenScrollEvent.bind(this)}>
                  {elements?.length === 0 && !queryKing?.loading && (
                    <EmptyPanel
                      reloadTrigger={refetch}
                      addImage={createImageElementModalHandler.opened.set}
                      addEntry={createModalHandler.opened.set}
                      maximize={true}
                    />
                  )}
                  <div className="p-4 pb-0 pt-3">
                    {elements?.length > 0 && (
                      <>
                        <h2>{t('pages.elements.title')}</h2>
                        <hr className="mt-0 mb-3" />
                        {renderBreadcrump({ bsPrefix: 'breadcrumb flex-column flex-sm-row' })}
                        {currentListMode === LIST_VIEW_MODES.Vertical && (
                          <Masonry
                            breakpointCols={{default: 5, 576: 1, 768: 2, 992: 3, 1200: 4}}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                          >
                            {elements.map((element, index) => (
                              <ElementCard
                                collection={element.node.collection}
                                deleteItemModalHandler={deleteItemModalHandler}
                                editItemModalHandler={editItemModalHandler}
                                element={element.node}
                                key={index}
                                listViewMode={currentListMode}
                                moveElementModalHandler={moveElementModalHandler}
                                removeElementModalHandler={removeElementModalHandler}
                                selected={!disableHistoryScroll && lastElementIdGathered === element.node.pk}
                                setYaLightboxIndex={setYaLightboxIndex}
                                yaLightBoxIndex={index}
                              />
                            ))}
                          </Masonry>
                        )}
                        {[LIST_VIEW_MODES.Horizontal, LIST_VIEW_MODES.Square, LIST_VIEW_MODES.Compress].indexOf(currentListMode) > -1 && (
                          <Row>
                            {elements.map((element, index) => (
                              <Col xs="12" sm="6" lg="4" xxl="2" className="pb-4" key={index}>
                                <ElementCard
                                  collection={element.node.collection}
                                  deleteItemModalHandler={deleteItemModalHandler}
                                  editItemModalHandler={editItemModalHandler}
                                  element={element.node}
                                  listViewMode={currentListMode}
                                  moveElementModalHandler={moveElementModalHandler}
                                  removeElementModalHandler={removeElementModalHandler}
                                  selected={!disableHistoryScroll && lastElementIdGathered === element.node.pk}
                                  setYaLightboxIndex={setYaLightboxIndex}
                                  yaLightBoxIndex={index}
                                />
                              </Col>
                            ))}
                          </Row>
                        )}
                        {(currentListMode === LIST_VIEW_MODES.ListWithImage || currentListMode === LIST_VIEW_MODES.PlainList) && (
                          <Table className="bg-light" hover bordered striped responsive>
                            <thead>
                              <tr>
                                {currentListMode === LIST_VIEW_MODES.ListWithImage && (
                                  <th className="text-center" width="60">
                                    <FontAwesomeIcon icon={faImage} />
                                  </th>
                                )}
                                <th>{t('pages.elements.table-item-name')}</th>
                                <th width="240">{t('pages.elements.table-collection-name')}</th>
                                <th width="145">{t('pages.elements.element-table-updated-at')}</th>
                                <th width="90">{t('pages.elements.table-amount')}</th>
                                <th width="150">{t('pages.elements.table-type_of')}</th>
                                <th width="50"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {elements.map((element, index) => (
                                <tr
                                  key={index}
                                  data-last-element={lastElementIdGathered === element.node.pk}
                                  className={`${!disableHistoryScroll && lastElementIdGathered === element.node.pk ? 'table-primary' : ''}`}
                                >
                                  {currentListMode === LIST_VIEW_MODES.ListWithImage && (
                                    <td className="text-center">
                                      {(element.node.item.cardImageSquare || element.node.item.typeOf?.cardImageSquare) && (
                                        <img
                                          className="rounded border"
                                          width="46"
                                          height="46"
                                          src={`${process.env.REACT_APP_MEDIA_URL}${element.node.item.cardImageSquare || element.node.item.typeOf?.cardImageSquare}`}
                                          alt={element?.node.item.name}
                                          loading="lazy"
                                          role="button"
                                          onClick={() => openLightbox({
                                            large: `${process.env.REACT_APP_MEDIA_URL}${element.node.item.imageFull}`,
                                            alt: element?.node.item.name,
                                          })}
                                        />
                                      )}
                                      {!(element.node.item.cardImageSquare || element.node.item.typeOf?.cardImageSquare) && (
                                        <FontAwesomeIcon icon={faImage} className="text-secondary" style={{ fontSize: '45px' }} />
                                      )}
                                    </td>
                                  )}
                                  <td>
                                    <NavLink to={`/private/collections/${element.node.collection?.pk}/${element.node.pk}`} className="text-decoration-none">
                                      {element.node.item?.name}
                                    </NavLink>
                                  </td>
                                  <td>
                                    <NavLink to={`/private/collections/${element.node.collection?.pk}`} className="text-decoration-none">
                                      {element.node.collection?.name}
                                    </NavLink>
                                  </td>
                                  <td>
                                    <Moment locale={i18n.resolvedLanguage} date={element.node.updatedAt} format="L" />
                                  </td>
                                  <td>{element.node.amount}</td>
                                  <td>{element.node.item.typeOf?.name}</td>
                                  <td className="text-center">
                                    {renderDropDown(element.node, index)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                        <EndOfSteamHandle loading={queryKing?.loading} hasNext={responseData?.elements?.pageInfo.hasNextPage} />
                      </>
                    )}
                    {lightbox && <Lightbox large={lightbox.large} alt={lightbox.alt} onClose={closeLightbox} showRotate={true} />}
                    <YALightbox
                      open={yaLightboxIndex >= 0}
                      index={yaLightboxIndex}
                      close={() => setYaLightboxIndex(-1)}
                      slides={elements?.map((element, index) => ({
                        src: `${process.env.REACT_APP_MEDIA_URL}${element.node.item.imageFull}`,
                        title: element.node.item.name,
                      })) || []}
                      plugins={[Captions]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {filterSidebarHandler.opened.value && <ElementsSidebarFilter handler={filterSidebarHandler} variables={queryKing?.variables} getElements={getElements} refetch={refetch} q={q} setQ={setQ} />}
          {infoSidebarHandler.opened.value && <InventorySidebarInfo handler={infoSidebarHandler} responseData={responseData} />}
        </div>
      </div>
      <StreamFooter
        elementsLoaded={elements?.length}
        elementCount={responseData?.elements?.totalCount}
        loading={queryKing?.loading}
      />
    </>
  );
};

export default Inventory;
