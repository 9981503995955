import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Table } from 'react-bootstrap';
import { People as GroupIcon } from 'react-bootstrap-icons';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Masonry from 'react-masonry-css';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Lightbox as YALightbox } from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import { DEFAULT_LIST_VIEW_MODE, LIST_VIEW_MODES } from '../../components/basic/Classes';
import EmptyPanel from '../../components/basic/EmptyPanel';
import StreamFooter from '../../components/basic/StreamFooter';
import CollectionGroupCard from '../../components/collections/CardGroup';
import CollectionImageBreadcrump from '../../components/collections/ImageBreadcrump';
import CollectionModalAdd from '../../components/collections/ModalAdd';
import CollectionModalDelete from '../../components/collections/ModalDelete';
import CollectionModalEdit from '../../components/collections/ModalEdit';
import CollectionSidebarInfo from '../../components/collections/SidebarInfo';
import CollectionSidebarPermission from '../../components/collections/SidebarPermission';
import CollectionToolbar from '../../components/collections/Toolbar';
import { GET_GROUP_COLLECTION_BY_ID } from '../../components/collections/queries';
import ElementCard from '../../components/elements/Card';
import ElementModalAdd from '../../components/elements/ModalAdd';
import ElementModalAddBook from '../../components/elements/ModalAddBook';
import ElementModalAddFood from '../../components/elements/ModalAddFood';
import ElementModalAddImage from '../../components/elements/ModalAddImage';
import ElementModalDelete from '../../components/elements/ModalDelete';
import ItemModalEdit from '../../components/items/ModalEdit';
import ImportFromISBNBulkModal from '../../components/settings/ModalImportISBNBulk';
import useLocalStorage from '../../hooks/useLocalStorage';
import { postData } from '../../utils/axiosHelper';
import { infoFromGroupPermission, renderParentBreadcrumps } from '../../utils/renderHelper';


const Collection = () => {

  const [collection, setCollection] = useState();
  const [currentListMode, setCurrentListMode] = useLocalStorage('list-view-mode', DEFAULT_LIST_VIEW_MODE);
  const [disableHistoryScroll, ] = useLocalStorage('disable-history-scroll', false);
  const [dragActive, setDragActive] = useState(false);
  const [firstFetchHandled, setFirstFetchHandled] = useState(false);
  const [lastElementIdGathered, ] = useLocalStorage('last-element-id-gathered', false);
  const [yaLightboxIndex, setYaLightboxIndex] = useState(-1);
  const location = useLocation();
  const navigate = useNavigate();
  const { collectionId } = useParams();
  const { t } = useTranslation();

  const [getCollection, { loading, error, refetch }] = useLazyQuery(GET_GROUP_COLLECTION_BY_ID, {
    variables: { id: collectionId },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setCollection(data.collectionById);
      if (firstFetchHandled === false) {
        setFirstFetchHandled(true);
      }
    },
  });

  const createElementModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      refetch();
    },
  };

  const createBookElementModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      refetch();
    },
  };

  const createFoodElementModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      refetch();
    },
  };

  const createImageElementModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      refetch();
    },
  };

  const createModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      refetch();
    },
  };

  const editModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      if (response === 'destroyed') {
        handleDeletionResponse();
      } else {
        refetch();
      }
    },
  };

  const deleteModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      handleDeletionResponse();
    },
  };

  const importFromISBNBulkModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      refetch();
    },
  };

  const editItemModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      refetch();
    },
  };

  const removeElementModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      refetch();
    },
  };

  const infoSidebarHandler = {
    opened: Object.assign({}, ...useLocalStorage('show-sidebar-info', false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
  };

  const permissionSidebarHandler = {
    opened: Object.assign({}, ...useLocalStorage('show-sidebar-permissions', false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      uploadFiles(e.dataTransfer.files)
    }
  };

  const uploadFiles = async (files) => {

    const brot = toast.loading(t('widgets.toast.uploading'));
    const fileCount = files.length;
    var successCount = 0;

    const incrementSuccess = () => {
      successCount ++;
    };

    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      toast.update(brot, { render: t('widgets.toast.upload-file-progress', { no: i + 1, of: fileCount })});

      await uploadImageToCollection(file).then( (response) => {
        incrementSuccess();
      }).catch( (error) => {
        let errorMessage = error.response?.data?.image?.join();
        if (fileCount === 1) {
          toast.update(brot, {render: <div>{t('widgets.toast.error')} {error.response.status}<br /><b>{file.name}</b><br />{errorMessage}</div>, type: "warning", isLoading: false, autoClose: 5000 });
        } else {
          toast.warn(<div>{t('widgets.toast.error')} {error.response.status}<br /><b>{file.name}</b><br />{errorMessage}</div>, { autoClose: 5000 });
        }
      });
    }

    if (successCount > 0) {
      toast.update(brot, { render: t('pages.collections.image-upload-success', { 'success_count': successCount } ), type: 'success', isLoading: false, autoClose: 2000 });
      refetch();
    } else if (fileCount > 1) {
      toast.dismiss(brot);
    }

  };

  const uploadImageToCollection = async (file) => {

    const config = { headers: { "Content-Type": "multipart/form-data" }, sleep: 100 };

    let data = new FormData();
    data.append('collection', collection.pk);
    data.append('image', file);

    return await postData('warehouse/elements/image/', data, config);

  };

  const handleDeletionResponse = () => {
    if (collection.parent) {
      navigate(`/private/collections/${collection.parent.pk}`, { state: { doRefetch: true } });
    } else {
      navigate('/private/collections', { state: { doRefetch: true } });
    }
  };

  const renderBreadcrump = ({ showImage = true, className = 'mb-4', bsPrefix = 'breadcrumb' }) => {

    let imgInfo = infoFromGroupPermission(collection?.groupPermissions?.length === 1 && collection?.groupPermissions[0]);
    let group = collection?.groupPermissions?.length === 1 && {
      pk: collection?.groupPermissions[0].group.pk,
      name: collection?.groupPermissions[0].repr,
    }

    return (
      <Breadcrumb className={className} bsPrefix={bsPrefix}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/private/groups" }}>
          {showImage && <GroupIcon className="bi img-fluid rounded me-1 align-text-top" width={20} height={20} style={{maxHeight: '20px', minHeight: '20px'}} />}
          {t('pages.groups.headline')}
        </Breadcrumb.Item>
        {group && (
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/private/groups/${group.pk}` }}>
            {showImage && imgInfo && (
              <img
                alt={group.name}
                title={group.name}
                className="img-fluid rounded-circle me-1 align-text-top"
                src={`${process.env.REACT_APP_MEDIA_URL}${imgInfo.img}`}
                width="20"
                height="20"
                style={{maxHeight: '20px', minHeight: '20px'}}
              />
            )}
            {group?.name}
          </Breadcrumb.Item>
        )}
        {collection && renderParentBreadcrumps({ collection: collection, showImage: showImage, groupId: collection?.groupPermissions?.length === 1 ? collection?.groupPermissions[0].group.pk : null })}
      </Breadcrumb>
    );
  };

  const openElement = (elementId) => {
    navigate(`/private/collections/${collection.pk}/${elementId}`);
  };

  useEffect(() => {
    if (firstFetchHandled === true && disableHistoryScroll !== true) {
      let lastElementMatch = document.querySelector('[data-last-element="true"]')
      lastElementMatch && lastElementMatch.scrollIntoView({block: "center", behavior: 'smooth'});
    }
  }, [currentListMode, firstFetchHandled, disableHistoryScroll]);

  useEffect(() => {
    if (firstFetchHandled === true && disableHistoryScroll !== true) {
      let lastElementMatch = document.querySelector('[data-last-element="true"]')
      lastElementMatch && lastElementMatch.scrollIntoView({block: "center", behavior: 'smooth'});
    }
  }, [firstFetchHandled, disableHistoryScroll]);

  useEffect(() => { getCollection(); }, [collectionId, getCollection]);
  useEffect(() => { if (location.state?.doRefetch) refetch(); }, [location.state?.doRefetch, refetch]);

  if (error) return `${error}`;

  return (
    <>
      {createBookElementModalHandler.opened.value && <ElementModalAddBook handler={createBookElementModalHandler} collection={collection} />}
      {createElementModalHandler.opened.value && <ElementModalAdd handler={createElementModalHandler} collection={collection} />}
      {createFoodElementModalHandler.opened.value && <ElementModalAddFood handler={createFoodElementModalHandler} collection={collection} />}
      {createImageElementModalHandler.opened.value && <ElementModalAddImage handler={createImageElementModalHandler} collection={collection} />}
      {createModalHandler.opened.value && <CollectionModalAdd handler={createModalHandler} parent={collection} />}
      {deleteModalHandler.opened.value && <CollectionModalDelete handler={deleteModalHandler} collection={collection} />}
      {editItemModalHandler.opened.value && <ItemModalEdit handler={editItemModalHandler} original={editItemModalHandler.opened.value} />}
      {editModalHandler.opened.value && <CollectionModalEdit handler={editModalHandler} original={collection} />}
      {importFromISBNBulkModalHandler.opened.value && <ImportFromISBNBulkModal handler={importFromISBNBulkModalHandler} collection={collection} backdrop={true} />}
      {removeElementModalHandler.opened.value && <ElementModalDelete handler={removeElementModalHandler} element={removeElementModalHandler.opened.value} />}
      <Helmet title={collection?.name} />
      <CollectionToolbar
        collection={collection}
        currentListMode={currentListMode}
        infoSidebarHandler={infoSidebarHandler}
        loading={loading}
        permissionSidebarHandler={permissionSidebarHandler}
        refetch={refetch}
        setCurrentListMode={setCurrentListMode}
        setYaLightboxIndex={setYaLightboxIndex}
        showAddBookElementDialog={createBookElementModalHandler.opened.set}
        showAddDialog={createModalHandler.opened.set}
        showAddElementDialog={createElementModalHandler.opened.set}
        showAddFoodElementDialog={createFoodElementModalHandler.opened.set}
        showAddImageElementDialog={createImageElementModalHandler.opened.set}
        showAddISBNsDialog={importFromISBNBulkModalHandler.opened.set}
        showDeleteDialog={deleteModalHandler.opened.set}
        showEditDialog={editModalHandler.opened.set}
        uploadImageCallback={uploadFiles}
      />
      <div className="mainspace">
        <div className="splitter d-flex">
          <div
            className={`panel overflow-auto flex-fill ${(infoSidebarHandler.opened.value || permissionSidebarHandler.opened.value) ? 'd-none d-sm-block' : ''} ${dragActive ? ' drag-active' : ''}`}
            onDrop={e => handleDrop(e)}
            onDragOver={e => handleDragOver(e)}
            onDragEnter={e => handleDragEnter(e)}
            onDragLeave={e => handleDragLeave(e)}
          >

            {/*
              Add custom collection style? Could be nice
              style={{backgroundColor: '#156b9e', color: 'white'}}
            */}

            <div className="position-relative h-100 w-100">
              <div className="module">
                <div className="mainspace">
                  <div className="p-4 pb-0 pt-3">
                    <div className="d-flex">
                      <div className="flex-grow-1 me-3">
                        <h2>
                          {collection?.name || '...'}
                        </h2>
                      </div>
                      <div className="flex-shrink-0 d-none d-sm-flex">
                        <CollectionImageBreadcrump renderParents={false} collection={collection} />
                      </div>
                    </div>
                    <hr className="mt-0 mb-3" />
                    {renderBreadcrump({ bsPrefix: 'breadcrumb flex-column flex-sm-row' })}
                    {collection?.description && (
                      <div className="mb-5">
                        <ReactMarkdown>{collection.description}</ReactMarkdown>
                      </div>
                    )}
                    {collection?.childCount > 0 && (
                      <div className="mt-5">
                        <h3>{t('pages.collections.headline-child-collections')}</h3>
                        <Row>
                          {collection.children.edges.map((subCollection, index) => (
                            <Col key={index} sm={12} md={12} lg={6} xl={4} xxl={3} className='d-flex'>
                              <CollectionGroupCard collection={subCollection.node} />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}

                    {collection?.elementCount > 0 && (
                      <div className="mt-5">
                        <h3>{t('pages.collections.headline-items')}</h3>
                        {currentListMode === LIST_VIEW_MODES.Vertical && (
                          <Masonry
                            breakpointCols={{default: 5, 576: 1, 768: 2, 992: 3, 1200: 4}}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column">
                            {collection.elements.map((element, index) => (
                              <ElementCard
                                collection={collection}
                                editItemModalHandler={editItemModalHandler}
                                element={element}
                                key={index}
                                listViewMode={currentListMode}
                                removeElementModalHandler={removeElementModalHandler}
                                selected={lastElementIdGathered === element.pk}
                                setYaLightboxIndex={setYaLightboxIndex}
                                yaLightBoxIndex={index}
                              />
                            ))}
                          </Masonry>
                        )}

                        {[LIST_VIEW_MODES.Horizontal, LIST_VIEW_MODES.Square, LIST_VIEW_MODES.Compress].indexOf(currentListMode) > -1 && (
                          <Row>
                            {collection.elements.map((element, index) => (
                              <Col xs="12" sm="6" lg="4" xxl="2" className="pb-4" key={index}>
                                <ElementCard
                                  collection={collection}
                                  editItemModalHandler={editItemModalHandler}
                                  element={element}
                                  key={index}
                                  listViewMode={currentListMode}
                                  removeElementModalHandler={removeElementModalHandler}
                                  selected={lastElementIdGathered === element.pk}
                                  setYaLightboxIndex={setYaLightboxIndex}
                                  yaLightBoxIndex={index}
                                />
                              </Col>
                            ))}
                          </Row>
                        )}

                        {(currentListMode === LIST_VIEW_MODES.ListWithImage || currentListMode === LIST_VIEW_MODES.PlainList) && (
                          <Table className="bg-light" hover bordered striped responsive>
                            <thead>
                              <tr>
                                {currentListMode === LIST_VIEW_MODES.ListWithImage && (
                                  <th className="text-center" width="60">
                                    <FontAwesomeIcon icon={faImage} />
                                  </th>
                                )}
                                <th>{t('pages.collections.element-table-name')}</th>
                                <th width="150">{t('pages.collections.element-table-type_of')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {collection.elements.map((element, index) => (
                                <tr
                                  key={index}
                                  role="button"
                                  data-last-element={lastElementIdGathered === element.pk}
                                  className={`${lastElementIdGathered === element.pk ? 'table-primary' : ''}`}
                                  onClick={() => openElement(element.pk)}
                                >
                                  {currentListMode === LIST_VIEW_MODES.ListWithImage && (
                                    <td className="text-center p-1">
                                      {element.item.cardImageSquare && (
                                        <>
                                          {element.item.cardImageSquare && <img className="rounded border" width="46" height="46" src={`${process.env.REACT_APP_MEDIA_URL}${element.item.cardImageSquare}`} alt={element.item.name} />}
                                          {!element.item.cardImageSquare && element.item.typeOf?.cardImageSquare && <img className="rounded border" width="46" height="46" src={`${process.env.REACT_APP_MEDIA_URL}${element.item.typeOf.cardImageSquare}`} alt={element.item.name} />}
                                        </>
                                      )}
                                      {!element.item.cardImageSquare && (
                                        <FontAwesomeIcon icon={faImage} className="text-secondary" style={{ fontSize: '45px' }} />
                                      )}
                                    </td>
                                  )}
                                  <td>{element.item.name}</td>
                                  <td>{element.item?.typeOf?.name}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}

                        {/*<EndOfSteamHandle loading={queryKing.loading} hasNext={queryKing?.data?.collectionElements?.pageInfo.hasNextPage} />*/}
                      </div>
                    )}

                    {collection?.childCount === 0 && collection?.elementCount === 0 && !loading && collection?.description === '' && (
                      <div className="p-4">
                        <div style={{ marginTop: '100px' }}>&nbsp;</div>
                        <EmptyPanel
                          reloadTrigger={refetch}
                          addEntry={createElementModalHandler.opened.set}
                          maximize={false}
                        />
                      </div>
                    )}
                  </div>
                  <YALightbox
                    open={yaLightboxIndex >= 0}
                    index={yaLightboxIndex}
                    close={() => setYaLightboxIndex(-1)}
                    slides={collection?.elements?.map((element, index) => ({
                      src: `${process.env.REACT_APP_MEDIA_URL}${element.item.imageFull}`,
                      title: element.item.name,
                    })) || []}
                    plugins={[Captions]}
                  />
                </div>
                <StreamFooter
                  subCollectionsLoaded={collection?.childCount}
                  subCollectionCount={collection?.childCount}
                  elementsLoaded={collection?.elements?.length}
                  elementCount={collection?.elementCount}
                  loading={loading}
                  breadcrumps={renderBreadcrump({ showImage: false, className: 'mb-0' })}
                />
              </div>
            </div>
          </div>
          {infoSidebarHandler.opened.value && <CollectionSidebarInfo handler={infoSidebarHandler} collection={collection} />}
          {permissionSidebarHandler.opened.value && <CollectionSidebarPermission handler={permissionSidebarHandler} collection={collection} refetch={refetch} />}
        </div>
      </div>
    </>
  );
};

export default Collection;
