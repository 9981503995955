import { gql } from '@apollo/client';

const GET_GROUPS = gql`
  query Groups {
    groups(
      orderBy: "name"
    ) {
      totalCount
      edges {
        node {
          cardImageThumbnail
          memberCount
          name
          pk
          myPermission {
            role
          }
          myMembership {
            role
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edgeCount
    }
  }
`;


const GET_GROUP_FOR_EDITING = gql`
  query EditCollection(
    $groupId: String!
  ) {
    groupForEditing(id: $groupId) {
      id
      name
      description
      cardImage
      cardImageThumbnail
      pk
    }
  }
`;

const GET_GROUP_BY_ID = gql`
  query GroupById(
    $id: String!
    ) {
    groupById(
      id: $id
    ) {
      cardImageThumbnail
      collectionCount
      createdAt
      description
      icon
      memberCount
      name
      pk
      myPermission {
        role
      }
      myMembership {
        role
      }
      members {
        gravatar
        motto
        pk
        repr
        role
        thumbnail
      }
      permissions {
        role
        repr
        gravatar
        thumbnail
      }
      collections {
        cardImageThumbnail
        childCount
        elementCount
        name
        parent {
          pk
        }
        pk
        defaultType {
          pk
          name
          cardImageThumbnail
        }
      }
    }
  }
`;


export {
  GET_GROUP_BY_ID,
  GET_GROUP_FOR_EDITING,
  GET_GROUPS,
};
