import { useCallback, useEffect, useState } from 'react';

import { Breadcrumb, Col, Container, Row } from 'react-bootstrap';
import { Bookmarks as BookmarkIcon } from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import ArticleCard from '../../components/blog/ArticleCard';
import { getObjs } from '../../utils/axiosHelper';


const Wiki = () => {

  const [, setLoading] = useState(false);
  const [pages, setPages] = useState([]);
  const location = useLocation();
  const { t } = useTranslation();

  const metaTags = [
    { name: 'description', content: t('pages.blog.meta-description') },
  ];

  const linkTags = [
    { rel: 'canonical', href: `${process.env.REACT_APP_SELF}${location.pathname}` },
  ];

  const getPages = useCallback(async () => {
    setLoading(true);
    await getObjs('blog/').then(async (response) => {
      setPages(response.data);
    }).catch((error) => {
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  const renderBreadcrump = ({ className = 'mb-4', bsPrefix = 'breadcrumb' }) => {
    return (
      <Breadcrumb className={className} bsPrefix={bsPrefix}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/blog" }} active>
          <BookmarkIcon className="bi img-fluid rounded me-1 align-text-top" width={20} height={20} style={{ maxHeight: '20px', minHeight: '20px' }} />
          {t('public.blog.home-breadcrump')}
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  };

  useEffect(() => {
    getPages();
  }, [getPages]);

  return (
    <>
      <Helmet title={t('pages.blog.headline')} meta={metaTags} link={linkTags} />
      <Container>
        <div className="d-flex">
          <div className="flex-grow-1 me-3">
            <h2>{t('pages.blog.headline')}</h2>
          </div>
          <div className="flex-shrink-0 d-none d-sm-flex">
          </div>
        </div>
        <hr className="mt-0 mb-3" />
        {renderBreadcrump({ bsPrefix: 'breadcrumb flex-column flex-sm-row' })}
        <p className="fs-5 text-muted mt-4">{t('pages.blog.lead')}</p>
        <Row>
          {pages && pages.map((article, index) => (
            <Col md="4" key={index} className="mb-4">
              <ArticleCard article={article} />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default Wiki;
