import React, { useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';


const ElementModalAddDisposition = ({ handler, element, initialAmount = 1 }) => {

  const [disabled, setDisabled] = useState(false);
  const client = useApolloClient();
  const { opened, ok, cancel, backdrop } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleSave = async (values) => {

    var formData = { ...values };

    setDisabled(true);

    if (formData['delivery_date'] === '') {
      delete formData['delivery_date'];
    }

    const brot = toast.loading(t('widgets.toast.saving'));

    await postData('warehouse/dispositions/', formData, { sleep: 500 }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.disposition-create.created-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      await client.clearStore();
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: initialAmount,
      delivery_date: '',
      element: element.pk,
      notes: '',
      supplier: '',
      to: '',
    },
    onSubmit: (values) => handleSave(values),
  });

  return (
    <>
      <Modal show={opened.value} onHide={handleClose} backdrop={disabled || backdrop ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.disposition-create.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={disabled}>
            <Row>
              <Col xs={3}>
                <Form.Group>
                  <Form.Label>{t('modals.disposition-create.field-amount')}</Form.Label>
                  <Form.Control
                    name="amount"
                    onChange={formik.handleChange}
                    autoComplete="off"
                    type="number"
                    value={formik.values.amount}
                    isInvalid={formik.errors?.fields?.amount}
                  />
                </Form.Group>
              </Col>
              <Col xs={9}>
                <Form.Group>
                  <Form.Label>{t('modals.disposition-create.field-element')}</Form.Label>
                  <Form.Control type="text" value={element.item.name} isInvalid={formik.errors?.fields?.element} disabled />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.element}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Control type="hidden" isInvalid={formik.errors?.fields?.amount} />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.amount}
              </Form.Control.Feedback>
            </Form.Group>
            <Row>
              <Col md="8">
                <Form.Group className="mb-3">
                  <Form.Label>{t('modals.disposition-create.field-supplier')}</Form.Label>
                  <Form.Control
                    name="supplier"
                    onChange={formik.handleChange}
                    autoComplete="off"
                    type="text"
                    value={formik.values.supplier}
                    isInvalid={formik.errors?.fields?.supplier}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.to}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group className="mb-3">
                  <Form.Label>{t('modals.disposition-create.field-delivery-date')}</Form.Label>
                  <Form.Control
                    name="delivery_date"
                    onChange={formik.handleChange}
                    autoComplete="off"
                    type="date"
                    value={formik.values.delivery_date}
                    isInvalid={formik.errors?.fields?.delivery_date}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.delivery_date}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.disposition-create.field-notes')}</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                name="notes"
                onChange={formik.handleChange}
                autoComplete="off"
                type="text"
                value={formik.values.notes}
                isInvalid={formik.errors?.fields?.notes}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.notes}
              </Form.Control.Feedback>
            </Form.Group>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.create')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ElementModalAddDisposition;
