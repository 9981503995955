import React from 'react';

import { Accordion, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';


const Faq = () => {

  const location = useLocation();
  const { t } = useTranslation();

  const metaTags = [
    { name: 'description', content: t('pages.meta.faq.meta-description') },
  ];

  const linkTags = [
    { rel: 'canonical', href: `${process.env.REACT_APP_SELF}${location.pathname}` },
  ];

  const faqs = [
    {
      question: t('pages.meta.faq.questions.difference-to-v1'),
      answer: t('pages.meta.faq.answers.difference-to-v1'),
    },
    {
      question: t('pages.meta.faq.questions.add-to-homescreen'),
      answer: t('pages.meta.faq.answers.add-to-homescreen'),
    },
    {
      question: t('pages.meta.faq.questions.is-there-an-app'),
      answer: t('pages.meta.faq.answers.is-there-an-app'),
    },
    {
      question: t('pages.meta.faq.questions.data-from-v1'),
      answer: t('pages.meta.faq.answers.data-from-v1'),
    },
  ];

  return (
    <>
      <Helmet title={t('pages.meta.faq.headline')} meta={metaTags} link={linkTags} />

      <Container>
        <h1>{t('pages.meta.faq.headline')}</h1>
        <p className="lead mb-5">{t('pages.meta.faq.lead')}</p>

        <Accordion defaultActiveKey="0">
          {faqs.map((faq, index) => (
            <Accordion.Item eventKey={index} key={index}>
              <Accordion.Header>{faq.question}</Accordion.Header>
              <Accordion.Body>
                <ReactMarkdown>{faq.answer}</ReactMarkdown>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Container>

    </>
  );
}

export default Faq;
