import React from 'react';

import { faCircleInfo, faComments, faFingerprint, faFolderTree, faHouse, faMinus, faPenToSquare, faPrint, faRotate, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Container, Nav, Navbar, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { renderParentBreadcrumps } from '../../utils/renderHelper';

const ElementToolbar = ({
  commentSidebarHandler,
  element,
  infoSidebarHandler,
  loading,
  printModalHandler,
  refetch,
  showBreadcrumps = false,
  showDispositionDialog,
  showElementMoveDialog,
  showElementRemoveDialog,
  showItemEditDialog,
}) => {

  const { t } = useTranslation();

  return (
    <Navbar className="bg-body-tertiary border-bottom" expand={true}>
      <Container fluid={true}>
        <Navbar.Toggle aria-controls="collection-navbar-nav" />
        <Navbar.Collapse id="collection-navbar-nav">
          {showBreadcrumps && (
            <Nav className="d-none d-sm-flex">
              <Breadcrumb>
                <Breadcrumb.Item className="d-none d-md-block" linkAs={Link} linkProps={{ to: "/private/collections" }}>{t('pages.collections.headline')}</Breadcrumb.Item>
                <Breadcrumb.Item className="d-block d-md-none" linkAs={Link} linkProps={{ to: "/private/collections" }}><FontAwesomeIcon icon={faHouse} /></Breadcrumb.Item>
                {element?.collection && (
                  <>
                    {renderParentBreadcrumps(element.collection)}
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/private/collections/${element.collection.pk}` }}>{element.collection.name}</Breadcrumb.Item>
                  </>
                )}
                <Breadcrumb.Item active>{element?.item.name}</Breadcrumb.Item>
                {loading && <Breadcrumb.Item active><Spinner animation="border" size="sm"></Spinner></Breadcrumb.Item>}
              </Breadcrumb>
            </Nav>
          )}
          <Nav>
            <Nav.Link disabled={loading || !element} onClick={() => showElementRemoveDialog(true)}>
              <FontAwesomeIcon size="lg" icon={faMinus} fixedWidth />
            </Nav.Link>
            <Nav.Link disabled={loading || !element?.item?.userCanEdit} onClick={() => showItemEditDialog(true)}>
              <FontAwesomeIcon size="lg" icon={faPenToSquare} fixedWidth />
            </Nav.Link>
            <Nav.Link disabled={loading || !element} onClick={() => printModalHandler.opened.set(true)}>
              <FontAwesomeIcon size="lg" icon={faPrint} fixedWidth />
            </Nav.Link>
            <Nav.Link disabled={loading || !element} onClick={() => showDispositionDialog(true)} className="d-none d-sm-block">
              <FontAwesomeIcon size="lg" icon={faTruck} fixedWidth />
            </Nav.Link>
            <Nav.Link disabled={loading || !element} onClick={() => showElementMoveDialog(element.pk)}>
              <FontAwesomeIcon size="lg" icon={faFolderTree} fixedWidth />
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto ps-2">&nbsp;</Nav>
          <Nav className="ms-2 border-start ps-2">
            <Nav.Link disabled={loading} onClick={() => refetch()}>
              <FontAwesomeIcon size="lg" icon={faRotate} fixedWidth />
            </Nav.Link>
            <Nav.Link disabled={loading || !element} active={commentSidebarHandler.opened.value} onClick={() => commentSidebarHandler.opened.set(!commentSidebarHandler.opened.value)} className="d-none d-sm-block">
              <FontAwesomeIcon size="lg" icon={faComments} fixedWidth />
            </Nav.Link>
            <Nav.Link disabled={true} className="d-none d-sm-block">
              <FontAwesomeIcon size="lg" icon={faFingerprint} fixedWidth />
            </Nav.Link>
            <Nav.Link disabled={loading || !element} active={element && infoSidebarHandler.opened.value} onClick={() => infoSidebarHandler.opened.set(!infoSidebarHandler.opened.value)}>
              <FontAwesomeIcon size="lg" icon={faCircleInfo} fixedWidth />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default ElementToolbar;
