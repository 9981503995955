import React, { useCallback, useEffect, useState } from 'react';

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import EmptyPanel from '../../components/basic/EmptyPanel';
import useAuth from '../../hooks/useAuth';
import useLocalStorage from '../../hooks/useLocalStorage';
import { getObjs } from '../../utils/axiosHelper';

ChartJS.register(ArcElement, Tooltip, Legend);


const data = {
  labels: [],
  datasets: [
    {
      label: 'Type distribution',
      data: [],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)'
      ],
      borderWidth: 1,
    },
  ],
};

const options = {
  plugins: {
    legend: {
      position: 'bottom',
      align: 'start',
    }
  }
};

const WidgetElementsByType = () => {

  const [doughnutData, setDoughnutData] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const { t } = useTranslation();
  const { user } = useAuth();
  const [hideNoneStor, setHideNoneStor] = useLocalStorage('dashboard-widget0-hide-none', false);
  const [hideOneStor, setHideOneStor] = useLocalStorage('dashboard-widget0-hide-one', false);
  const [hideNone, setHideNone] = useState(hideNoneStor);
  const [hideOne, setHideOne] = useState(hideOneStor);

  const writeResponseDataToDoughnut = useCallback((typeDistributions) => {

    // Update filter and sort data
    typeDistributions = typeDistributions?.sort((a, b) => a.amount > b.amount ? -1 : 1) || [];

    if (hideNone === true) {
      typeDistributions = typeDistributions.filter((e) => e.type_of);
    }
    if (hideOne === true) {
      typeDistributions = typeDistributions.filter((e) => e.amount > 1);
    }

    // Update doughnut with template
    let template = data.datasets[0];
    template.data = typeDistributions.map((e) => e.amount);

    setDoughnutData({
      ...data,
      labels: typeDistributions.map((e) => e.type_of || t('forms.values.undefined')),
      datasets: [template],
    })
  }, [hideNone, hideOne, t]);

  const getTypeDistribution = useCallback(async () => {

    if (initialized) {
      return;
    }

    await getObjs('warehouse/types/distribution/', { sleep: 250 }).then(async (response) => {
      setResponseData(response.data);
      writeResponseDataToDoughnut(response.data);
    }).catch((error) => {
      toast.error(`${t('widgets.toast.error')} ${error.response.status}`);
    }).finally(() => {
      setInitialized(true);
    });

  }, [initialized, t, writeResponseDataToDoughnut]);


  const updateHideNone = (state) => {
    setHideNone(state);
    setHideNoneStor(state);
  };

  const updateHideOne = (state) => {
    setHideOne(state);
    setHideOneStor(state);
  };

  useEffect(() => {
    writeResponseDataToDoughnut(responseData);
  }, [hideNone, hideOne, responseData, writeResponseDataToDoughnut]);

  useEffect(() => {
    if (user?.pk) getTypeDistribution();
  }, [user.pk, getTypeDistribution]);

  return (
    <div>
      {initialized && responseData?.length > 0 && (
        <>
          <Doughnut data={doughnutData} options={options} />
          <Form.Group className="mt-3" controlId="lsDashboardTypeDisplayNone">
            <Form.Check
              type="switch"
              label={t('pages.dashboard.widget-type.hide-none')}
              checked={hideNone}
              onChange={(e) => updateHideNone(e.target.checked)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="lsDashboardTypeDisplayOne">
            <Form.Check
              type="switch"
              label={t('pages.dashboard.widget-type.hide-one')}
              checked={hideOne}
              onChange={(e) => updateHideOne(e.target.checked)}
            />
          </Form.Group>
        </>
      )}
      {initialized && responseData?.length === 0 && (
        <div className="my-5">
          <EmptyPanel showNoActionMsg={false} imgWidth={200} />
        </div>
      )}
      {!initialized && (
        <>
          <div className="text-center py-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </>
      )}
    </div>
  );
};

export default WidgetElementsByType;
