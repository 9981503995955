import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';


function RegisterForm() {

  const [disabled, setDisabled] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const register = async (values) => {

    if (!executeRecaptcha) {
      toast.error(t('pages.login.toast-recaptcha-error'), { autoClose: 2500 });
      return;
    }

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.processing'));
    var formData = { ...values };

    formData['recaptcha_response'] = await executeRecaptcha('dynamicAction');

    await postData('auth/register/', formData, { sleep: 500 }).then(async (response) => {
      navigate('/auth/register/confirm-otp', { state: { email: values.email, timestamp: response.data?.timestamp } })
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.error(`${t('widgets.toast.error')} ${error.response.status}`, { autoClose: 2000 });
    }).finally(() => {
      setDisabled(false);
      toast.dismiss(brot);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      accepted_tac: false,
      email: '',
      password_1: '',
      password_2: '',
    },
    onSubmit: (values) => register(values),
  });

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{t('public.auth.field-email')}</Form.Label>
        <Form.Control
          autoComplete="off"
          isInvalid={formik.errors?.fields?.email}
          name="email"
          onChange={formik.handleChange}
          type="email"
          value={formik.values.email}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors?.fields?.email}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>{t('public.auth.field-password')}</Form.Label>
        <Form.Control
          autoComplete="off"
          isInvalid={formik.errors?.fields?.password_1}
          name="password_1"
          onChange={formik.handleChange}
          type="password"
          value={formik.values.password_1}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors?.fields?.password_1}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>{t('public.auth.field-password-confirm')}</Form.Label>
        <Form.Control
          autoComplete="off"
          isInvalid={formik.errors?.fields?.password_2}
          name="password_2"
          onChange={formik.handleChange}
          type="password"
          value={formik.values.password_2}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors?.fields?.password_2}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Control type="hidden" isInvalid={formik.errors?.fields?.accepted_tac} />
        <Form.Check
          id="tac-switch"
          checked={formik.values.accepted_tac}
          onChange={(event) => formik.setFieldValue('accepted_tac', event.target.checked)}
          label={
            <>
              {t('public.auth.field-hereby-accept-tac-and-pp')}
              <br />
              <a target="_blank" rel="noreferrer" href="/meta/conditions" className="text-decoration-none">{t('public.auth.field-link-conditions')}</a>
              <br />
              <a target="_blank" rel="noreferrer" href="/meta/privacy" className="text-decoration-none">{t('public.auth.field-link-privacy')}</a>
            </>
          }
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors?.fields?.accepted_tac}
        </Form.Control.Feedback>
      </Form.Group>
      <div className="text-center mt-3">
        <div className="d-grid gap-2">
          <Button type="button" onClick={formik.handleSubmit} variant="primary" disabled={disabled}>
            {t('public.auth.btn-register')}
          </Button>
        </div>
      </div>
      <Form.Group className="mb-3">
        <Form.Control type="hidden" isInvalid={formik.errors?.fields?.non_field_errors} />
        <Form.Control.Feedback type="invalid">
          {formik.errors?.fields?.non_field_errors}
        </Form.Control.Feedback>
      </Form.Group>
      <small>
        <Link to="/auth/login" className="text-decoration-none">{t('public.auth.btn-login-instead')}</Link>
      </small>
    </>
  );
}

export default RegisterForm;
