const LIST_VIEW_MODES = {
  Vertical: 1,
  Horizontal: 2,
  ListWithImage: 3,
  PlainList: 4,
  Square: 5,
  Compress: 6,
};

const DEFAULT_LIST_VIEW_MODE = LIST_VIEW_MODES.Horizontal;

export { LIST_VIEW_MODES, DEFAULT_LIST_VIEW_MODE };
