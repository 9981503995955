import { gql } from '@apollo/client';

const GET_COLLECTIONS = gql`
  query Collections(
    $first: Int
    $name_Icontains: String
    $orderBy: [String]
    $parent_Isnull: Boolean
    $mine: Boolean
    ) {
    collections(
      first: $first
      name_Icontains: $name_Icontains
      orderBy: $orderBy
      parent_Isnull: $parent_Isnull
      mine: $mine
    ) {
      totalCount
      edges {
        node {
          cardImageThumbnail
          childCount
          elementCount
          enableConsumptionOptions
          enablePriceOptions
          groupAccess
          icon
          isPublic
          name
          pk
          publicPk
          publicSlug
          publicTrustLevel
          defaultType {
            pk
            name
            cardImageThumbnail
          }
          parent {
            pk
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edgeCount
    }
  }
`;

const GET_BROWSER_COLLECTIONS = gql`
  query Collections(
    $parent_Isnull: Boolean
    $name_Icontains: String
    $first: Int
    ) {
    collections(
      parent_Isnull: $parent_Isnull
      name_Icontains: $name_Icontains
      orderBy: "name"
      first: $first
    ) {
      totalCount
      edges {
        node {
          cardImageThumbnail
          childCount
          elementCount
          enableConsumptionOptions
          enablePriceOptions
          groupAccess
          isPublic
          name
          pk
          publicPk
          publicSlug
          publicTrustLevel
          groupPermissions {
            repr
            pk
            mode
            getModeDisplay
            group {
              pk
              name
              icon
            }
          }
          defaultType {
            pk
            name
            cardImageThumbnail
          }
          parent {
            pk
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edgeCount
    }
  }
`;

const GET_WRITEABLE_COLLECTIONS = gql`
  query WriteableCollections(
    $parent_Isnull: Boolean
    $name_Icontains: String
    ) {
    writeableCollections(
      parent_Isnull: $parent_Isnull
      name_Icontains: $name_Icontains
      orderBy: "name"
    ) {
      totalCount
      edges {
        node {
          cardImageThumbnail
          childCount
          elementCount
          enableConsumptionOptions
          enablePriceOptions
          isPublic
          name
          pk
          publicPk
          publicSlug
          publicTrustLevel
          defaultType {
            pk
            name
            cardImageThumbnail
          }
          parent {
            pk
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edgeCount
    }
  }
`;

const GET_COLLECTION_BY_ID = gql`
  query CollectionById(
    $id: String!
    ) {
    tableConfigsForCollection(
      collectionId: $id
    ) {
      id
      name
      pk
      showColumnAmount
      showColumnTypeOf
      showColumnUpdateAt
      useAsDefault
      configuredAttributes {
        columnWidth
        ordering
        textAlignClass
        attribute {
          id
          pk
          name
        }
      }
    }
    collectionById(
      id: $id
    ) {
      cardImage
      cardImageThumbnail
      childCount
      createdAt
      description
      elementCount
      enableConsumptionOptions
      enablePriceOptions
      groupAccess
      icon
      isPublic
      itemImageThumbnail
      name
      pk
      publicPk
      publicSlug
      publicTrustLevel
      defaultType {
        cardImageThumbnail
        icon
        itemImageThumbnail
        name
        pk
      }
      parent {
        cardImageThumbnail
        icon
        itemImageThumbnail
        name
        pk
        defaultType {
          cardImageThumbnail
          icon
          itemImageThumbnail
        }
        parent {
          cardImageThumbnail
          icon
          itemImageThumbnail
          name
          pk
          defaultType {
            cardImageThumbnail
            icon
            itemImageThumbnail
          }
          parent {
            cardImageThumbnail
            icon
            itemImageThumbnail
            name
            pk
            defaultType {
              cardImageThumbnail
              icon
              itemImageThumbnail
            }
            parent {
              cardImageThumbnail
              icon
              itemImageThumbnail
              name
              pk
              defaultType {
                cardImageThumbnail
                icon
                itemImageThumbnail
              }
              parent {
                cardImageThumbnail
                icon
                itemImageThumbnail
                name
                pk
                defaultType {
                  cardImageThumbnail
                  icon
                  itemImageThumbnail
                }
              }
            }
          }
        }
      }
      groupCount
      groupPermissions {
        repr
        mode
        getModeDisplay
        group {
          pk
          name
          cardImageThumbnail
        }
      }
      permissions {
        getRoleDisplay
        gravatar
        pk
        repr
        role
        thumbnail
      }
      children {
        edges {
          node {
            cardImageThumbnail
            childCount
            elementCount
            name
            pk
            defaultType {
              pk
              name
              cardImageThumbnail
            }
          }
        }
      }
      elements {
        amount
        isHidden
        pk
        price
        updatedAt
        item {
          cardImageSquare
          cardImageThumbnail
          imageFull
          name
          pk
          properties {
            attribute {
              pk
            }
            value
          }
          typeOf {
            itemImageThumbnail
            cardImageSquare
            createdAt
            name
            pk
          }
        }
      }
    }
  }
`;

const GET_COLLECTION_BY_ID_WITH_PAGINATION = gql`
  query CollectionById(
    $collectionPk: String!
    $collectionId: ID
    $first: Int
    $after: String
    $orderBy: [String]
    ) {
    tableConfigsForCollection(
      collectionId: $collectionPk
    ) {
      id
      name
      pk
      showColumnAmount
      showColumnTypeOf
      showColumnUpdateAt
      useAsDefault
      configuredAttributes {
        columnWidth
        ordering
        textAlignClass
        attribute {
          id
          pk
          name
        }
      }
    }
    collectionById(
      id: $collectionPk
    ) {
      cardImage
      cardImageThumbnail
      childCount
      createdAt
      description
      elementCount
      enableConsumptionOptions
      enablePriceOptions
      groupAccess
      icon
      isPublic
      itemImageThumbnail
      name
      pk
      publicPk
      publicSlug
      publicTrustLevel
      defaultType {
        cardImageThumbnail
        icon
        itemImageThumbnail
        name
        pk
      }
      parent {
        cardImageThumbnail
        icon
        itemImageThumbnail
        name
        pk
        defaultType {
          cardImageThumbnail
          icon
          itemImageThumbnail
        }
        parent {
          cardImageThumbnail
          icon
          itemImageThumbnail
          name
          pk
          defaultType {
            cardImageThumbnail
            icon
            itemImageThumbnail
          }
          parent {
            cardImageThumbnail
            icon
            itemImageThumbnail
            name
            pk
            defaultType {
              cardImageThumbnail
              icon
              itemImageThumbnail
            }
            parent {
              cardImageThumbnail
              icon
              itemImageThumbnail
              name
              pk
              defaultType {
                cardImageThumbnail
                icon
                itemImageThumbnail
              }
              parent {
                cardImageThumbnail
                icon
                itemImageThumbnail
                name
                pk
                defaultType {
                  cardImageThumbnail
                  icon
                  itemImageThumbnail
                }
              }
            }
          }
        }
      }
      groupCount
      groupPermissions {
        repr
        mode
        getModeDisplay
        group {
          pk
          name
          cardImageThumbnail
        }
      }
      permissions {
        getRoleDisplay
        gravatar
        pk
        repr
        role
        thumbnail
      }
      children {
        edges {
          node {
            cardImageThumbnail
            childCount
            elementCount
            name
            pk
            defaultType {
              pk
              name
              cardImageThumbnail
            }
          }
        }
      }
    }
    elements(
      after: $after
      collection_Id: $collectionId
      first: $first
      orderBy: $orderBy
    ) {
      totalCount
      totalValue
      edges {
        node {
          amount
          isHidden
          pk
          price
          createdAt
          updatedAt
          item {
            cardImageSquare
            cardImageThumbnail
            icon
            imageFull
            name
            pk
            properties {
              attribute {
                pk
              }
              value
            }
            typeOf {
              itemImageThumbnail
              cardImageSquare
              createdAt
              name
              pk
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edgeCount
    }
  }
`;



const GET_GROUP_COLLECTION_BY_ID = gql`
  query CollectionById(
    $id: String!
    ) {
    collectionById(
      id: $id
    ) {
      cardImage
      cardImageThumbnail
      childCount
      createdAt
      description
      elementCount
      enableConsumptionOptions
      enablePriceOptions
      groupAccess
      icon
      isPublic
      itemImageThumbnail
      name
      pk
      publicPk
      publicSlug
      publicTrustLevel
      defaultType {
        cardImageThumbnail
        icon
        itemImageThumbnail
        name
        pk
      }
      parent {
        cardImageThumbnail
        icon
        itemImageThumbnail
        name
        pk
        defaultType {
          cardImageThumbnail
          icon
          itemImageThumbnail
        }
        parent {
          cardImageThumbnail
          icon
          itemImageThumbnail
          name
          pk
          defaultType {
            cardImageThumbnail
            icon
            itemImageThumbnail
          }
          parent {
            cardImageThumbnail
            icon
            itemImageThumbnail
            name
            pk
            defaultType {
              cardImageThumbnail
              icon
              itemImageThumbnail
            }
            parent {
              cardImageThumbnail
              icon
              itemImageThumbnail
              name
              pk
              defaultType {
                cardImageThumbnail
                icon
                itemImageThumbnail
              }
              parent {
                cardImageThumbnail
                icon
                itemImageThumbnail
                name
                pk
                defaultType {
                  cardImageThumbnail
                  icon
                  itemImageThumbnail
                }
              }
            }
          }
        }
      }
      groupCount
      groupPermissions {
        repr
        mode
        getModeDisplay
        group {
          pk
          name
          icon
          cardImageThumbnail
        }
      }
      permissions {
        repr
        thumbnail
        gravatar
        getRoleDisplay
      }
      children {
        edges {
          node {
            cardImageThumbnail
            childCount
            elementCount
            name
            pk
            defaultType {
              pk
              name
              cardImageThumbnail
            }
            groupPermissions {
              repr
              mode
              getModeDisplay
              group {
                pk
                name
                icon
                cardImageThumbnail
              }
            }
          }
        }
      }
      elements {
        pk
        amount
        item {
          cardImageSquare
          cardImageThumbnail
          imageFull
          name
          pk
          typeOf {
            itemImageThumbnail
            cardImageSquare
            createdAt
            name
            pk
          }
        }
      }
    }
  }
`;

const GET_COLLECTION_FOR_EDITING = gql`
  query EditCollection(
    $collectionId: String!
  ) {
    collectionForEditing(id: $collectionId) {
      cardImage
      cardImageThumbnail
      description
      enableConsumptionOptions
      enablePriceOptions
      groupAccess
      id
      isPublic
      name
      pk
      publicPk
      publicSlug
      publicTrustLevel
      defaultType {
        pk
        name
        cardImageThumbnail
      }
      parent {
        pk
        name
      }
    }
    types(orderBy: "name") {
      edges {
        node {
          id
          name
          cardImageThumbnail
          pk
          isPublic
        }
      }
    }
  }
`;


const GET_TYPES = gql`
  query ListTypes {
    types(orderBy: "name") {
      edges {
        node {
          id
          name
          cardImageThumbnail
          itemImageThumbnail
          pk
          isPublic
        }
      }
    }
  }
`;


const GET_ATTRIBUTES = gql`
  query ListAttributes {
    attributes(orderBy: "name") {
      edges {
        node {
          getTypeOf
          id
          isPublic
          name
          pk
          typeOf
        }
      }
    }
  }
`;


const GET_ATTRIBUTE_FOR_EDITING = gql`
  query EditAttribute(
    $attributeId: String!
  ) {
    attributeForEditing(id: $attributeId) {
      id
      name
      pk
      typeOf
      sortChoicesAlphabetical
      choices {
        label
        ordering
        pk
        value
      }
    }
  }
`;


const GET_CHOICE_FOR_EDITING = gql`
  query EditChoice(
    $choiceId: String!
  ) {
    choiceForEditing(id: $choiceId) {
      id
      label
      pk
      value
      attribute {
        pk
      }
    }
  }
`;


const GET_TYPE_FOR_EDITING = gql`
  query EditType(
    $typeId: String!
  ) {
    typeForEditing(id: $typeId) {
      id
      isPublic
      name
      pk
      showBestBefore
      showConsumption
      definitions {
        id
        ordering
        pk
        attribute {
          getTypeOf
          isPublic
          name
          pk
          typeOf
          choices {
            isDefault
            label
            pk
            value
          }
        }
      }
    }
  }
`;


const GET_TYPES_WITH_DEFINITION = gql`
  query ListTypes {
    types(orderBy: "name") {
      edges {
        node {
          cardImageThumbnail
          id
          isPublic
          itemImageThumbnail
          name
          pk
          showBestBefore
          showConsumption
          definitions {
            ordering
            attribute {
              getTypeOf
              name
              pk
              typeOf
              choices {
                isDefault
                label
                value
              }
            }
          }
        }
      }
    }
  }
`;


const GET_PUBLIC_COLLECTION_BY_SLUG = gql`
  query CollectionBySlug(
    $slug: String!
    ) {
    collectionBySlug(
      slug: $slug
    ) {
      cardImage
      cardImageThumbnail
      childCount
      createdAt
      description
      elementCount
      enableConsumptionOptions
      enablePriceOptions
      icon
      isPublic
      itemImageThumbnail
      name
      pk
      publicPk
      publicSlug
      publicTrustLevel
      defaultType {
        cardImageThumbnail
        icon
        itemImageThumbnail
        name
        pk
      }
      parent {
        cardImageThumbnail
        icon
        itemImageThumbnail
        name
        pk
        publicPk
        publicSlug
        defaultType {
          cardImageThumbnail
          icon
          itemImageThumbnail
        }
        parent {
          cardImageThumbnail
          icon
          itemImageThumbnail
          name
          pk
          publicPk
          publicSlug
          defaultType {
            cardImageThumbnail
            icon
            itemImageThumbnail
          }
          parent {
            cardImageThumbnail
            icon
            itemImageThumbnail
            name
            pk
            publicPk
            publicSlug
            defaultType {
              cardImageThumbnail
              icon
              itemImageThumbnail
            }
            parent {
              cardImageThumbnail
              icon
              itemImageThumbnail
              name
              pk
              publicPk
              publicSlug
              defaultType {
                cardImageThumbnail
                icon
                itemImageThumbnail
              }
              parent {
                cardImageThumbnail
                icon
                itemImageThumbnail
                name
                pk
                publicPk
                publicSlug
                defaultType {
                  cardImageThumbnail
                  icon
                  itemImageThumbnail
                }
              }
            }
          }
        }
      }
      groupCount
      groupPermissions {
        repr
        mode
        getModeDisplay
        group {
          pk
          name
          cardImageThumbnail
        }
      }
      permissions {
        repr
        thumbnail
        gravatar
        getRoleDisplay
      }
      children {
        edges {
          node {
            cardImageThumbnail
            childCount
            elementCount
            name
            pk
            publicPk
            publicSlug
            defaultType {
              pk
              name
              cardImageThumbnail
            }
          }
        }
      }
      elements {
        pk
        item {
          cardImageSquare
          cardImageThumbnail
          imageFull
          name
          pk
          typeOf {
            itemImageThumbnail
            cardImageSquare
            createdAt
            name
            pk
          }
        }
      }
    }
  }
`;

const GET_PUBLIC_COLLECTIONS = gql`
  query Collections {
    collections(
      orderBy: "name"
    ) {
      totalCount
      edges {
        node {
          cardImageThumbnail
          childCount
          elementCount
          enableConsumptionOptions
          enablePriceOptions
          isPublic
          name
          pk
          publicPk
          publicSlug
          publicTrustLevel
          defaultType {
            pk
            name
            cardImageThumbnail
          }
          parent {
            pk
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edgeCount
    }
  }
`;


export {
  GET_ATTRIBUTES,
  GET_ATTRIBUTE_FOR_EDITING,
  GET_BROWSER_COLLECTIONS,
  GET_CHOICE_FOR_EDITING,
  GET_COLLECTION_BY_ID,
  GET_COLLECTION_BY_ID_WITH_PAGINATION,
  GET_COLLECTION_FOR_EDITING,
  GET_COLLECTIONS,
  GET_GROUP_COLLECTION_BY_ID,
  GET_PUBLIC_COLLECTION_BY_SLUG,
  GET_PUBLIC_COLLECTIONS,
  GET_TYPE_FOR_EDITING,
  GET_TYPES,
  GET_TYPES_WITH_DEFINITION,
  GET_WRITEABLE_COLLECTIONS,
};
