import React, { useEffect, useState } from 'react';

import { useApolloClient, useLazyQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { GET_TYPES } from '../../components/collections/queries';
import { postData } from '../../utils/axiosHelper';


const CollectionModalAdd = ({ handler, parent }) => {

  const [disabled, setDisabled] = useState(false);
  const [types, setTypes] = useState([]);
  const client = useApolloClient();
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const [getTypes] = useLazyQuery(GET_TYPES, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setTypes(data.types);
    },
  });

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleSave = async (values) => {

    var formData = { ...values };
    formData.default_type = formData.defaultType?.pk || null;

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.saving'));

    await postData('warehouse/collections/', formData, { sleep: 500 }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.collection-create.created-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      await client.clearStore();
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      default_type: '',
      defaultType: parent?.defaultType || null,
      parent: parent?.pk,
    },
    onSubmit: (values) => handleSave(values),
  });

  const valueFromId = (opts, id) => opts.find((o) => o.node.pk === id);

  useEffect(() => {
    getTypes();
  }, [getTypes]);

  return (
    <Modal show={opened.value} onHide={handleClose} backdrop={disabled ? 'static' : true}>
      <Modal.Header closeButton={!disabled}>
        <Modal.Title>{t(parent ? 'modals.collection-create.title-child-collection' : 'modals.collection-create.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <fieldset disabled={disabled}>
          {parent && (
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.collection-create.field-parent')}</Form.Label>
              <Form.Control
                disabled
                isInvalid={formik.errors?.fields?.parent}
                name="name"
                onChange={formik.handleChange}
                type="text"
                value={parent.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.parent}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          <Row>
            <Col md={7}>
              <Form.Group className="mb-3">
                <Form.Label>{t('modals.collection-create.field-name')}</Form.Label>
                <Form.Control
                  autoComplete="off"
                  isInvalid={formik.errors?.fields?.name}
                  name="name"
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors?.fields?.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={5}>
              <Form.Group className="mb-3">
                <Form.Label>{t('modals.collection-create.field-type')}</Form.Label>
                <Select
                  getOptionLabel={(e) => e.node.name}
                  getOptionValue={(e) => e.node.pk}
                  isClearable={true}
                  name="defaultType"
                  onChange={(o) => formik.setFieldValue('defaultType', o?.node || null)}
                  options={types.edges || []}
                  placeholder={t('modals.collection-create.field-type-placeholder')}
                  value={valueFromId(types.edges || [], formik.values.defaultType?.pk)}
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors?.fields?.default_type}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </fieldset>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
        <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.create')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CollectionModalAdd;
