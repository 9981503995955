import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Col, Row } from 'react-bootstrap';
import { Collection as CollectionIcon } from 'react-bootstrap-icons';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import EmptyPanel from '../../components/basic/EmptyPanel';
import StreamFooter from '../../components/basic/StreamFooter';
import CollectionCard from '../../components/collections/Card';
import CollectionModalAdd from '../../components/collections/ModalAdd';
import CollectionSidebarInfoOverview from '../../components/collections/SidebarInfoOverview';
import CollectionsToolbar from '../../components/collections/ToolbarOverview';
import { GET_COLLECTIONS } from '../../components/collections/queries';
import useLocalStorage from '../../hooks/useLocalStorage';


const Collections = () => {

  const [collections, setCollections] = useState([]);
  const location = useLocation();
  const { t } = useTranslation();

  const [getCollections, { refetch, loading }] = useLazyQuery(GET_COLLECTIONS, {
    variables: {
      parent_Isnull: true,
      mine: true,
      orderBy: 'name',
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setCollections(data.collections.edges);
    },
  });

  const createModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      refetch();
    },
  };

  const infoSidebarHandler = {
    opened: Object.assign({}, ...useLocalStorage('show-sidebar-info', false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
  };

  const renderBreadcrump = (showImage = true, className = 'mb-4') => {
    return (
      <Breadcrumb className={className}>
        <Breadcrumb.Item active linkAs={Link} linkProps={{ to: "/private/collections" }}>
          {showImage && <CollectionIcon className="bi img-fluid rounded me-1 align-text-top" width={20} height={20} style={{maxHeight: '20px', minHeight: '20px'}} />}
          {t('pages.collections.headline')}
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  };

  useEffect(() => { getCollections(); }, [getCollections]);
  useEffect(() => {
    if (location?.state?.doRefetch) refetch();
  }, [location.state?.doRefetch, refetch]);

  return (
    <>
      {createModalHandler.opened.value && <CollectionModalAdd handler={createModalHandler} />}
      <Helmet title={t('pages.collections.headline')} />
      <CollectionsToolbar
        refetch={refetch}
        loading={loading}
        infoSidebarHandler={infoSidebarHandler}
        showAddDialog={createModalHandler.opened.set}
      />
      <div className="mainspace">
        <div className="splitter d-flex">
          <div className={`panel overflow-auto flex-fill ${infoSidebarHandler.opened.value ? 'd-none d-sm-flex' : ''}`}>
            <div className="position-relative h-100 w-100">
              <div className="module">
                <div className="mainspace p-4 pt-3">
                  {collections?.length > 0 && (
                    <>
                      <h2>{t('pages.collections.headline')}</h2>
                      <hr className="mt-0 mb-3" />
                      {renderBreadcrump()}
                      <Row>
                        {collections && collections.map((collection, index) => (
                          <Col key={index} sm={12} md={12} lg={6} xl={4} xxl={3} className='d-flex'>
                            <CollectionCard collection={collection.node} />
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                  {collections?.length === 0 && !loading && (
                    <EmptyPanel reloadTrigger={refetch} addEntry={createModalHandler.opened.set} />
                  )}
                </div>
                <StreamFooter
                  collectionsLoaded={collections?.length}
                  collectionCount={collections?.length}
                  loading={loading}
                  breadcrumps={renderBreadcrump(false, 'mb-0')}
                />
              </div>
            </div>
          </div>
          {infoSidebarHandler.opened.value && <CollectionSidebarInfoOverview handler={infoSidebarHandler} collections={collections} />}
        </div>
      </div>
    </>
  );
};

export default Collections;
