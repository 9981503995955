import React, { useState } from 'react';

import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { patchData, secureHTML } from '../../utils/axiosHelper';


const ElementConsumptionInput = ({ element, refetch }) => {

  const [consumption, setConsumption] = useState(element?.consumption || 0);
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();

  const save = async () => {

    const brot = toast.loading(t('widgets.toast.loading'));
    let formData = {
      element: element.pk,
      consumption: consumption,
    }
    setDisabled(true);

    await patchData(`warehouse/elements/${element.pk}/`, formData, { sleep: 500 }).then(async (response) => {
      refetch();
      toast.dismiss(brot);
    }).catch((error) => {
      let message = error?.response?.data?.element ? error.response.data.element.join() : '';
      toast.update(brot, { render: <div dangerouslySetInnerHTML={secureHTML(`${t('widgets.toast.error')} ${error.response.status}<br />${message}`)}></div>, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });

  };

  if (!element) return null;

  return (
    <div className="d-inline-block">
      <InputGroup size="sm">
        <InputGroup.Text id="basic-addon4" className="bg-body" style={{ width: '100px' }}>
          <Form.Range defaultValue={consumption} onChange={(event) => setConsumption(event.target.value)} />
        </InputGroup.Text>
        <InputGroup.Text id="basic-addon3" style={{ width: '70px' }} className="justify-content-end">{consumption}%</InputGroup.Text>
        {(`${consumption}` !== `${element?.consumption}`) && (
          <Button variant="primary" onClick={save} id="button-save" disabled={disabled}>
            <FontAwesomeIcon icon={faFloppyDisk} fixedWidth />
          </Button>
        )}
      </InputGroup>
    </div>
  );

};

export default ElementConsumptionInput;
