import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';


function ResetConfirmForm() {

  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const { userId, token } = useParams();
  const navigate = useNavigate();

  if (!userId || !token) {
    navigate('/auth/password-reset');
  }

  const reset = async (values) => {

    var formData = { ...values };

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.saving'));

    return await postData('auth/password-reset/confirm/', formData, { sleep: 500 }).then(async (response) => {
      navigate('/auth/login', { state: { justChanged: true } })
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
      return false;
    }).finally(() => {
      setDisabled(false);
      toast.dismiss(brot);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      uid: userId,
      token: token,
      new_password: '',
      new_password_confirm: '',
    },
    onSubmit: async (values) => {
      reset(values);
    },
  });

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{t('public.auth.field-new-password')}</Form.Label>
        <Form.Control
          autoComplete="off"
          isInvalid={formik.errors?.fields?.new_password}
          name="new_password"
          onChange={formik.handleChange}
          type="password"
          value={formik.values.new_password}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors?.fields?.new_password}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>{t('public.auth.field-new-password-confirm')}</Form.Label>
        <Form.Control
          autoComplete="off"
          isInvalid={formik.errors?.fields?.new_password_confirm}
          name="new_password_confirm"
          onChange={formik.handleChange}
          type="password"
          value={formik.values.new_password_confirm}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors?.fields?.new_password_confirm}
        </Form.Control.Feedback>
      </Form.Group>
      {formik.errors?.fields?.token && (
        <Form.Group className="mb-3">
          <Form.Label>{t('public.auth.field-reset-token')}</Form.Label>
          <Form.Control
            autoComplete="off"
            isInvalid={formik.errors?.fields?.token}
            name="token"
            disabled
            type="text"
            value={formik.values.token}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors?.fields?.token}
          </Form.Control.Feedback>
        </Form.Group>
      )}
      <div className="text-center mt-3">
        <div className="d-grid gap-2">
          <Button type="button" onClick={formik.handleSubmit} variant="primary" disabled={disabled}>
            {t('public.auth.btn-change-password')}
          </Button>
        </div>
      </div>
      <small>
        <Link to="/auth/login" className="text-decoration-none">&lt; {t('forms.actions.cancel')}</Link>
      </small>
    </>
  );
}

export default ResetConfirmForm;
