import React, { useCallback, useEffect, useState } from 'react';

import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getData, secureHTML } from '../../utils/axiosHelper';
import PlanChooserModal from '../accounting/ModalPlanChooser';
import Moment from 'react-moment';


const ModalLog = ({ handler }) => {

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [log, setLog] = useState();
  const [commits, setCommits] = useState([]);
  const [commitsByMonth, setCommitsByMonth] = useState({});
  const { opened, cancel, backdrop } = handler;
  const { t, i18n } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const getLogs = useCallback(async () => {

    setLoading(true);
    setErrors();

    await getData('advanced/gitlog/', { sleep: 1000 }).then(async (response) => {
      setLog(response.data);
      setCommits(response.data.commits);
      let commitsByMonth = {};
      let messagesByMonth = [];
      response.data.commits.forEach((commit) => {
        let month = commit.date.substring(0, 7);
        commitsByMonth[month] ??= { commits: [], date: commit.date };
        messagesByMonth[month] ??= [];
        if (!messagesByMonth[month].includes(commit.message)) {
          commitsByMonth[month].commits.push(commit);
          messagesByMonth[month].push(commit.message);
        }
      });
      setCommitsByMonth(commitsByMonth);
    }).catch((error) => {
      setErrors({ fields: error.response.data });
      toast.error(`${t('widgets.toast.error')} ${error.response.status}`);
    });

    setLoading(false);
  }, [t]);

  const planChooserModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      getLogs();
    },
  };

  useEffect(() => { getLogs(); }, [getLogs]);

  return (
    <>
      {planChooserModalHandler.opened.value && <PlanChooserModal handler={planChooserModalHandler} />}
      <Modal size="lg" show={opened.value} onHide={handleClose} backdrop={loading || backdrop === true ? 'static' : true} scrollable>
        <Modal.Header closeButton={true}>
          <Modal.Title>{t('modals.log.title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <Form.Group>
            <Form.Control
              type="hidden"
              isInvalid={errors?.fields?.log}
            />
            <Form.Control.Feedback type="invalid" className="text-center py-5">
              <p className="lead">{errors?.fields?.log}</p>
              <Button variant="success" onClick={() => planChooserModalHandler.opened.set(true)}>{t('pages.accounting.switch-plan-btn')}</Button>
            </Form.Control.Feedback>
          </Form.Group>

          {loading && (
            <div className="my-5 text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <div>
                {t('modals.log.loading')}
              </div>
            </div>
          )}

          {commitsByMonth && !loading && (
            <div className="">
              {Object.values(commitsByMonth).map((month, index) => (
                <React.Fragment key={index}>
                  <h4>
                    <Moment locale={i18n.resolvedLanguage} format="MMMM YYYY">{month.date}</Moment>
                  </h4>
                  <ul>
                    {month.commits.map((commit, commitIndex) => (
                      <li key={commitIndex}>{commit.message}</li>
                    ))}
                  </ul>
                </React.Fragment>
              ))}
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>{t('forms.actions.close')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalLog;
