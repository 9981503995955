import React from 'react';

import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faBars, faCompress, faGripVertical, faList, faWater } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LIST_VIEW_MODES } from './Classes';


const ListModeNavToolbar = ({ current, setCurrent, loading, disabled, className = 'me-4', linkClassNameAddOn = '', size = 'lg' }) => {

  const { t } = useTranslation();

  const renderCurrentIcon = () => {
    if (current === LIST_VIEW_MODES.Horizontal) return faWater;
    if (current === LIST_VIEW_MODES.ListWithImage) return faList;
    if (current === LIST_VIEW_MODES.PlainList) return faBars;
    if (current === LIST_VIEW_MODES.Square) return faSquare;
    if (current === LIST_VIEW_MODES.Vertical) return faGripVertical;
    if (current === LIST_VIEW_MODES.Compress) return faCompress;
  };

  return (
    <>
      <Nav className={`d-flex d-sm-none ${className}`}>
        <NavDropdown
          disabled={loading || disabled}
          title=<FontAwesomeIcon size={size} icon={renderCurrentIcon()} className="my-0 py-0" fixedWidth />
          className="m-0 p-0 navdropdown-no-toggle"
          id="nav-add-dropdown"
          align="end"
          bsPrefix={`nav-link ${linkClassNameAddOn || ''}`}
        >
          <NavDropdown.Item disabled={loading || disabled} active={!disabled && current === LIST_VIEW_MODES.Vertical} onClick={() => setCurrent(LIST_VIEW_MODES.Vertical)}>
            <FontAwesomeIcon size={size} className="me-2" icon={faGripVertical} fixedWidth />
            {t('widgets.toolbars.viewmode.vertical')}
          </NavDropdown.Item>
          <NavDropdown.Item disabled={loading || disabled} active={!disabled && current === LIST_VIEW_MODES.Horizontal} onClick={() => setCurrent(LIST_VIEW_MODES.Horizontal)}>
            <FontAwesomeIcon size={size} className="me-2" icon={faWater} fixedWidth />
            {t('widgets.toolbars.viewmode.horizontal')}
          </NavDropdown.Item>
          <NavDropdown.Item disabled={loading || disabled} active={!disabled && current === LIST_VIEW_MODES.Square} onClick={() => setCurrent(LIST_VIEW_MODES.Square)}>
            <FontAwesomeIcon size={size} className="me-2" icon={faSquare} fixedWidth />
            {t('widgets.toolbars.viewmode.square')}
          </NavDropdown.Item>
          <NavDropdown.Item disabled={loading || disabled} active={!disabled && current === LIST_VIEW_MODES.Compress} onClick={() => setCurrent(LIST_VIEW_MODES.Compress)}>
            <FontAwesomeIcon size={size} className="me-2" icon={faCompress} fixedWidth />
            {t('widgets.toolbars.viewmode.compress')}
          </NavDropdown.Item>
          <NavDropdown.Item disabled={loading || disabled} active={!disabled && current === LIST_VIEW_MODES.ListWithImage} onClick={() => setCurrent(LIST_VIEW_MODES.ListWithImage)}>
            <FontAwesomeIcon size={size} className="me-2" icon={faList} fixedWidth />
            {t('widgets.toolbars.viewmode.list-with-image')}
          </NavDropdown.Item>
          <NavDropdown.Item disabled={loading || disabled} active={!disabled && current === LIST_VIEW_MODES.PlainList} onClick={() => setCurrent(LIST_VIEW_MODES.PlainList)}>
            <FontAwesomeIcon size={size} className="me-2" icon={faBars} fixedWidth />
            {t('widgets.toolbars.viewmode.list')}
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
      <Nav className={`d-none d-sm-flex ${className}`}>
        <Nav.Link className={`${linkClassNameAddOn || ''}`} disabled={loading || disabled} active={!disabled && current === LIST_VIEW_MODES.Vertical} onClick={() => setCurrent(LIST_VIEW_MODES.Vertical)}>
          <FontAwesomeIcon size={size} icon={faGripVertical} />
        </Nav.Link>
        <Nav.Link className={`${linkClassNameAddOn || ''}`} disabled={loading || disabled} active={!disabled && current === LIST_VIEW_MODES.Horizontal} onClick={() => setCurrent(LIST_VIEW_MODES.Horizontal)}>
          <FontAwesomeIcon size={size} icon={faWater} />
        </Nav.Link>
        <Nav.Link className={`${linkClassNameAddOn || ''}`} disabled={loading || disabled} active={!disabled && current === LIST_VIEW_MODES.Square} onClick={() => setCurrent(LIST_VIEW_MODES.Square)}>
          <FontAwesomeIcon size={size} icon={faSquare} />
        </Nav.Link>
        <Nav.Link className={`${linkClassNameAddOn || ''}`} disabled={loading || disabled} active={!disabled && current === LIST_VIEW_MODES.Compress} onClick={() => setCurrent(LIST_VIEW_MODES.Compress)}>
          <FontAwesomeIcon size={size} icon={faCompress} />
        </Nav.Link>
        <Nav.Link className={`${linkClassNameAddOn || ''}`} disabled={loading || disabled} active={!disabled && current === LIST_VIEW_MODES.ListWithImage} onClick={() => setCurrent(LIST_VIEW_MODES.ListWithImage)}>
          <FontAwesomeIcon size={size} icon={faList} />
        </Nav.Link>
        <Nav.Link className={`${linkClassNameAddOn || ''}`} disabled={loading || disabled} active={!disabled && current === LIST_VIEW_MODES.PlainList} onClick={() => setCurrent(LIST_VIEW_MODES.PlainList)}>
          <FontAwesomeIcon size={size} icon={faBars} />
        </Nav.Link>
      </Nav>
    </>
  );
};

export default ListModeNavToolbar;
