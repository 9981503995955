import React, { useCallback, useEffect, useState } from 'react';

import { faEject, faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import UserAvatar from '../../components/auth/UserAvatar';
import { confirm } from '../../components/basic/Confirmation';
import PrivateMetaNavHeader from '../../components/basic/PrivateMetaNavHeader';
import SeatInvitationModalAdd from '../../components/settings/ModalAddSeat';
import useAuth from '../../hooks/useAuth';
import { deleteObj, getObj } from '../../utils/axiosHelper';


const Seats = () => {

  const [currentSeat, setCurrentSeat] = useState();
  const [currentSubscription, setCurrentSubscription] = useState();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { user } = useAuth();

  const getCurrentSubscription = useCallback(async () => {
    const brot = toast.loading(t('widgets.toast.loading'));
    setCurrentSubscription();

    await getObj('accounting/subscription/').then(async (response) => {
      setCurrentSubscription(response.data);
      toast.dismiss(brot);
    }).catch((error) => {
      if (error.response.status !== 404) {
        toast.update(brot, {render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 2000 });
      } else {
        toast.dismiss(brot);
      }
    });
  }, [t]);

  const getCurrentSeat = useCallback(async () => {
    const brot = toast.loading(t('widgets.toast.loading'));
    setCurrentSeat();

    await getObj('accounting/seats/').then(async (response) => {
      setCurrentSeat(response.data);
      toast.dismiss(brot);
    }).catch((error) => {
      if (error.response.status !== 404) {
        toast.update(brot, {render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
      } else {
        toast.dismiss(brot);
      }
    });
  }, [t]);

  const createSeatHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      getCurrentSubscription();
      getCurrentSeat();
    },
  };

  const eject = async () => {
    const brot = toast.loading(t('widgets.toast.deleting'));

    let result = await confirm({
      title: t('pages.seats.confirm-eject-title'),
      lead: t('pages.seats.confirm-eject-lead'),
      okLabel: t('pages.seats.confirm-eject-btn'),
      showCancel: true,
      danger: true,
    })

    if (result === false) {
      toast.dismiss(brot);
      return;
    }


    await deleteObj(`accounting/seats/eject/${currentSeat?.id}/`).then(async (response) => {
      toast.dismiss(brot);
      getCurrentSubscription();
      getCurrentSeat();
    }).catch((error) => {
      toast.update(brot, {render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    });
  };

  const openSeat = (seatPk, accepted = true) => {
    navigate(`/private/seats${accepted ? '' : '/review'}/${seatPk}`)
  };

  const excludeBaseSeatFilter = (seat) => {
    return seat.id !== currentSeat.id;
  };

  useEffect(() => {
    if (user?.pk) {
      getCurrentSubscription();
      getCurrentSeat();
    }
  }, [user?.pk, getCurrentSubscription, getCurrentSeat]);

  return (
    <>
      {createSeatHandler.opened.value && <SeatInvitationModalAdd handler={createSeatHandler} />}
      <Helmet title={t('pages.seats.headline')} />
      <PrivateMetaNavHeader />
      <div className="mainspace p-4">
        <Container>
          <h3>{t('pages.seats.my-relation')}</h3>
          <hr />
          <dl className="row mb-0">
            <dt className="col-sm-3">{t('pages.seats.plan')}:</dt>
            <dd className="col-sm-9 mb-2">{currentSubscription?.plan?.name || currentSeat?.plan?.name || t('pages.seats.free-plan')}</dd>
            {currentSeat && (
              <>
                <dt className="col-sm-3">{t('pages.seats.promoted-by')}:</dt>
                <dd className="col-sm-9 mb-2">{currentSeat.promoter_repr}</dd>
              </>
            )}
            {currentSubscription?.plan?.seats && (
              <>
                <dt className="col-sm-3">{t('pages.seats.available-seats')}:</dt>
                <dd className="col-sm-9 mb-2">
                  <span>{(currentSubscription?.plan?.seats - currentSubscription?.seats?.length) || 0}/{currentSubscription?.plan?.seats}</span>
                </dd>
              </>
            )}
          </dl>
          <p className="lead mt-3 mb-1">{t('pages.seats.advantages-message')}</p>
          <ul>
            <li>{t('pages.seats.advantage-plan-sharing')}</li>
            <li>{t('pages.seats.advantage-collection-sharing')}</li>
            <li>{t('pages.seats.advantage-issue-sharing')}</li>
            <li>{t('pages.seats.advantage-disposition-sharing')}</li>
          </ul>
          {currentSeat && (
            <div className="text-end mb-0">
              <Button variant="warning" onClick={eject}>
                <FontAwesomeIcon icon={faEject} className="me-1" />
                {t('pages.seats.eject')}
              </Button>
            </div>
          )}
          <h3 className="mt-5">{t('pages.seats.seat-overview')}</h3>
          <hr />
          <div className="bg-white mb-4">
            <Table className="pointer mb-0" bordered striped hover responsive>
              <thead>
                <tr>
                  <th width="40" className="text-center" style={{ maxWidth: '40px' }}>
                    <FontAwesomeIcon icon={faImage} />
                  </th>
                  <th>{t('pages.seats.table-user')}</th>
                  <th width="160">{t('pages.seats.table-age')}</th>
                  <th width="190">{t('pages.seats.table-state')}</th>
                </tr>
              </thead>
              <tbody>
                {(!currentSeat && (!currentSubscription || currentSubscription?.seats?.length === 0) && (!currentSeat || currentSeat?.colleagues?.filter(excludeBaseSeatFilter).length === 0)) && (
                  <tr>
                    <td colSpan="100" className="text-center py-5">- {t('pages.seats.no-connections-found')}-</td>
                  </tr>
                )}
                {currentSubscription?.seats && currentSubscription.seats.map((seat, index) => (
                  <tr key={index} onClick={() => openSeat(seat.id, !!!seat.accepted_at)} role="button">
                    <td className="p-0">
                      <UserAvatar height={40} width={40} user={seat} className={'rounded-0'} />
                    </td>
                    <td>{seat.invitee_user_repr}</td>
                    <td>
                      {(seat.accepted_at && <Moment locale={i18n.resolvedLanguage} fromNow ago>{seat.accepted_at}</Moment>) || '-'}
                    </td>
                    <td>{seat.get_state_display}</td>
                  </tr>
                ))}
                {currentSeat && currentSeat.state === 2 && (
                  <tr>
                    <td className="p-0">
                      <UserAvatar height={40} width={40} user={currentSeat} className={'rounded-0'} />
                    </td>
                    <td>{currentSeat.promoter_repr}</td>
                    <td>-/-</td>
                    <td>{t('pages.seats.promoter')}</td>
                  </tr>
                )}
                {currentSeat?.colleagues && currentSeat?.colleagues.filter(excludeBaseSeatFilter).map((seat, index) => (
                  <tr key={index}>
                    <td className="p-0">
                      <UserAvatar height={40} width={40} user={seat.invitee_user} className={'rounded-0'} />
                    </td>
                    <td>{seat.invitee_user_repr}</td>
                    <td>
                      <Moment locale={i18n.resolvedLanguage} fromNow ago>{seat.accepted_at}</Moment>
                    </td>
                    <td>{t('pages.seats.neighbor')} ({seat.get_state_display})</td>
                  </tr>
                ))}
                {currentSeat && (
                  <tr onClick={() => openSeat('mine')} role="button">
                    <td className="p-0">
                        <UserAvatar height={40} width={40} user={user} className={'rounded-0'} />
                    </td>
                    <td>
                      {user.repr}
                      <sup className="ms-1 fw-bold">{t('pages.seats.mine')}</sup>
                    </td>
                    <td>
                      <Moment locale={i18n.resolvedLanguage} fromNow ago>{currentSeat.accepted_at || currentSeat.created_at}</Moment>
                    </td>
                    <td>
                      {currentSeat.state === 1 && <Button variant="primary" onClick={() => openSeat(currentSeat.id)}>{t('pages.seats.review')}</Button>}
                      {currentSeat.state !== 1 && currentSeat.get_state_display}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {currentSubscription?.plan?.seats > 0 && (
            <div className="text-end">
              <Button variant="primary" onClick={() => createSeatHandler.opened.set(true)}>{t('pages.seats.share-seat')}</Button>
            </div>
          )}
          {/*<pre>{JSON.stringify(currentSubscription, null, 4)}</pre>*/}
        </Container>
      </div>
    </>
  );
};

export default Seats;
