import React from 'react';

import { useTranslation } from 'react-i18next';
import pathfinder from '../../assets/img/illustrations/pathfinder.png';


const E404 = () => {

  const { t } = useTranslation();

  return (
    <div className="mainspace">
      <div className="d-flex align-items-center h-100">
        <div className="row m-0 align-items-center justify-content-center no-gutters">
          <div className="col-md-5 order-md-2">
            <img src={pathfinder} alt="Pathfinder" className="img-fluid" />
          </div>
          <div className="col-md-5 order-md-1">
            <h1 className="display-5 font-weight-bold text-center">{t('pages.error.module-error.headline')}</h1>
            <p className="mb-5 text-center text-muted">{t('pages.error.module-error.message')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default E404;
