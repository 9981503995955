import React from 'react';

import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';


const AttributeFilterInput = ({ formik, attribute }) => {

  const { t } = useTranslation();

  const valueFromConst = (opts, val) => opts.find((o) => o.value === val);

  const renderType = (attribute) => {

    switch (attribute.typeOf) {

      case 'A_3':
        return <Select
          getOptionLabel={(e) => (e.label || e.value)}
          getOptionValue={(e) => e.value}
          isClearable={true}
          name={`attr_${attribute.pk}`}
          onChange={(o) => formik.setFieldValue(`attr_${attribute.pk}`, o?.value || '')}
          options={[]}
          placeholder={t('modals.item-edit.field-type-placeholder')}
          value={valueFromConst([], formik.values[`attr_${attribute.pk}`] || '')}
          className="react-select-container"
          classNamePrefix="react-select"
        />;

      case 'A_9':
        return <Form.Control
          autoComplete="off"
          name={`attr_${attribute.pk}`}
          type="date"
          onChange={formik.handleChange}
          value={formik.values[`attr_${attribute.pk}`] || ''}
        />;

      case 'A_11':
        return <Form.Control
          autoComplete="off"
          name={`attr_${attribute.pk}`}
          type="number"
          onChange={formik.handleChange}
          value={(formik.values[`attr_${attribute.pk}`] && parseInt(formik.values[`attr_${attribute.pk}`])) || ''}
        />;

      default:
        return <Form.Control
          autoComplete="off"
          name={`attr_${attribute.pk}`}
          type="text"
          onChange={formik.handleChange}
          value={formik.values[`attr_${attribute.pk}`] || ''}
        />;
    }

  };

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{attribute?.name}</Form.Label>
        {renderType(attribute)}
        <Form.Control.Feedback type="invalid">
          {formik.errors?.fields && formik.errors?.fields[`attr_${attribute.pk}`]}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );

};

export default AttributeFilterInput;
