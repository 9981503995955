import React from 'react';

import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Col, Container, Row } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import hamburgerVolksbankd from '../../../assets/img/brands/hamvoba/hamburger-volksbank.jpg';
import meditation from '../../../assets/img/illustrations/meditation.png';
import pathfinder from '../../../assets/img/illustrations/pathfinder.png';


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


const Donate = () => {

  const location = useLocation();
  const { t } = useTranslation();

  const metaTags = [
    { name: 'description', content: t('pages.meta.donate.meta-description') },
  ];

  const linkTags = [
    { rel: 'canonical', href: `${process.env.REACT_APP_SELF}${location.pathname}` },
  ];

  const labels = [
    t('forms.values.months.january-abbr'),
    t('forms.values.months.february-abbr'),
    t('forms.values.months.march-abbr'),
    t('forms.values.months.april-abbr'),
    t('forms.values.months.mai-abbr'),
    t('forms.values.months.june-abbr'),
    t('forms.values.months.july-abbr'),
    t('forms.values.months.august-abbr'),
    t('forms.values.months.september-abbr'),
    t('forms.values.months.october-abbr'),
    t('forms.values.months.november-abbr'),
    t('forms.values.months.december-abbr'),
  ];

  const options = {
    plugins: {
      title: {
        display: false
      },
      legend: {
        position: 'top',
        align: 'start',
        labels: {
          padding: 0,
          font: {
            size: 14,
            lineHeight: '150%',
          }
        },
      },
    },
    responsive: true,
    scales: {
      x: { stacked: true },
      y: {
        stacked: true,
        ticks: {
          callback: function (value, index, values) {
            return `${value} €`;
          }
        }
      },
    },
  };

  const data2024 = {
    labels,
    datasets: [
      { label: 'PayPal  ', data: [0, 0, 0, 0, 10, 0, 0, 0, 0, 0, 0, 0], backgroundColor: '#edc893' },
      { label: 'Direct Transfer in €  ', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], backgroundColor: '#325ee9' },
    ],
  };

  const data2023 = {
    labels,
    datasets: [
      { label: 'PayPal  ', data: [10.61, 0, 5.49, 10, 0, 0, 0, 0, 0, 0, 0, 0], backgroundColor: '#edc893' },
      { label: 'Direct Transfer in €  ', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], backgroundColor: '#325ee9' },
    ],
  };

  const data2022 = {
    labels,
    datasets: [
      { label: 'PayPal  ', data: [0, 0, 15, 10, 0, 9.68, 0, 0, 4.53, 16, 1, 10], backgroundColor: '#edc893' },
      { label: 'Direct Transfer in €  ', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], backgroundColor: '#325ee9' },
    ],
  };

  const data2021 = {
    labels,
    datasets: [
      { label: 'PayPal  ', data: [0, 0, 0, 13.93, 5, 0, 0, 0, 0, 0, 0, 0], backgroundColor: '#edc893' },
      { label: 'Direct Transfer in €  ', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], backgroundColor: '#325ee9' },
    ],
  };

  const data2020 = {
    labels,
    datasets: [
      { label: 'PayPal  ', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], backgroundColor: '#edc893' },
      { label: 'Direct Transfer in €  ', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], backgroundColor: '#325ee9' },
    ],
  };

  return (
    <>
      <Helmet title={t('pages.meta.donate.headline')} meta={metaTags} link={linkTags} />

      <Container>
        <h1>{t('pages.meta.donate.headline')}</h1>
        <p className="lead">{t('pages.meta.donate.lead')}</p>

        <h2 className="mt-5">{t('pages.meta.donate.time-and-brain')}</h2>
        <hr />
        <Row>
          <Col md="10">
            <p className="lead">
              <Trans i18nKey="pages.meta.donate.time-and-brain-lead"><NavLink to="/meta/contact">contact</NavLink></Trans>
            </p>
            <p className="lead">{t('pages.meta.donate.spread-the-word')}</p>
            <p className="lead">
              <Trans i18nKey="pages.meta.donate.time-and-brain-api"><a href={process.env.REACT_APP_MEDIA_URL}>API</a></Trans>
            </p>
          </Col>
          <Col md="2">
            <img src={pathfinder} className="w-100" alt="Meditation" />
          </Col>
        </Row>

        <h2 className="mt-5">{t('pages.meta.donate.loot')}</h2>
        <hr />
        <Row>
          <Col md="2">
            <img src={meditation} className="w-100" alt="Meditation" />
          </Col>
          <Col md="10">
            <p className="lead">{t('pages.meta.donate.loot-lead')}</p>
          </Col>
        </Row>

        <div className="mt-3">&nbsp;</div>

        <Row>
          <Col md={6}>
            <h3 className="mt-5">{t('pages.meta.donate.donation-account')}</h3>
            <hr />
            <address>
              <b>Lagerraum - Bastian Probian</b><br />
              IBAN: DE67 2019 0003 0045 3584 19<br />
              BIC: GENODEF1HH2<br />
              <img src={hamburgerVolksbankd} className="align-text-top" height="16" alt="Hamburger Volksbank" /> Hamburger Volksbank
            </address>
          </Col>
          <Col md={6}>
            <h3 className="mt-5 text-end">{t('pages.meta.donate.donate-with-paypal')}</h3>
            <hr />
            <form className="mt-3 text-end" action='https://www.paypal.com/donate' method='post' target='_top'>
              <input type="hidden" name="hosted_button_id" value="F6G6NAA7DA3ZJ" />
              <input type="image" src="https://www.paypalobjects.com/webstatic/de_DE/i/de-pp-logo-200px.png" alt="PayPal" border="0" name="submit" title="PayPal - The safer, easier way to pay online!' alt='Donate with PayPal button" />
              <img alt="" border="0" src="https://www.paypal.com/de_DE/i/scr/pixel.gif" width="1" height="1" />
            </form>
            <form className="mt-3 text-end" action="https://www.paypal.com/donate" method="post" target="_top">
              <input type="hidden" name="hosted_button_id" value="F6G6NAA7DA3ZJ" />
              <input type="image" src="https://www.paypalobjects.com/de_DE/DE/i/btn/btn_donate_LG.gif" border="0" alt="PayPal" name="submit" title="PayPal - The safer, easier way to pay online!' alt='Donate with PayPal button" />
              <img alt="" border="0" src="https://www.paypal.com/de_DE/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </Col>
        </Row>

        <div className="mt-3">&nbsp;</div>

        <h3 className="mt-5">{t('pages.meta.donate.donation-history')}</h3>
        <hr />
        <p>{t('pages.meta.donate.donation-history-lead')}</p>

        <Row className="mt-4">
          <Col md={6} className="pt-2 pb-4">
            <b>2024</b>
            <Bar options={options} data={data2024} />
          </Col>
          <Col md={6} className="pt-2 pb-4">
            <b>2023</b>
            <Bar options={options} data={data2023} />
          </Col>
          <Col md={6} className="pt-2 pb-4">
            <b>2022</b>
            <Bar options={options} data={data2022} />
          </Col>
          <Col md={6} className="pt-2 pb-4">
            <b>2021</b>
            <Bar options={options} data={data2021} />
          </Col>
          <Col md={6} className="pt-2 pb-4">
            <b>2020</b>
            <Bar options={options} data={data2020} />
          </Col>
        </Row>

      </Container>

      <div className="mt-3">&nbsp;</div>
    </>
  );
}

export default Donate;
