import React, { useRef, useState } from 'react';

import { useFormik } from 'formik';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { Folder2Open, X } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';


const ItemModalAddImage = ({ handler }) => {

  const [disabled, setDisabled] = useState(false);
  const cardImageRef = useRef();
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleSave = async (values) => {

    if (!values?.card_image) {
      return;
    }

    uploadFile(values?.card_image);
  };


  const uploadFile = async (file) => {

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.uploading'));

    await uploadImage(file).then((response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.item-create-image.image-upload-success'), type: 'success', isLoading: false, autoClose: 2000 });
      ok(response.data);
    }).catch((error) => {
      let errorMessage = error.response?.data?.image?.join();
      toast.update(brot, { render: <div>{t('widgets.toast.error')} {error.response.status}<br /><b>{file.name}</b><br />{errorMessage}</div>, type: 'warning', isLoading: false, autoClose: 5000 });
    });

    setDisabled(false);
  };

  const uploadImage = async (file) => {

    const config = { headers: { "Content-Type": "multipart/form-data" }, sleep: 100 };

    let data = new FormData();
    data.append('image', file);

    return await postData('warehouse/items/image/', data, config);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      card_image: null,
      cardImageName: t('forms.values.undefined'),
    },
    onSubmit: (values) => handleSave(values),
  });

  return (
    <>
      <Modal show={opened.value} onHide={handleClose} backdrop={disabled ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.item-create-image.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={disabled}>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.item-create-image.field-list-image')}</Form.Label>
              <InputGroup>
                <Form.Control
                  value={formik.values.card_image?.name || formik.values.cardImageName}
                  isInvalid={formik.errors?.fields?.card_image}
                  disabled
                />
                {formik.values.card_image && (
                  <Button
                    variant="outline-secondary"
                    onClick={() => { cardImageRef.current.value = null; formik.setFieldValue('card_image', null) }}
                  >
                    <X />
                  </Button>
                )}
                <label className="file-btn btn btn-primary px-2">
                  <input
                    accept="image/*"
                    id="field-card_image"
                    ref={cardImageRef}
                    name="card_image"
                    onChange={(event) => formik.setFieldValue('card_image', event.currentTarget.files[0])}
                    style={{ position: 'absolute', top: -10000 }}
                    type="file"
                  />
                  <Folder2Open />
                </label>
              </InputGroup>
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.card_image}
              </Form.Control.Feedback>
            </Form.Group>
            {formik.values.card_image && (
              <Form.Group className="mb-3">
                <Form.Label>{t('modals.item-create-image.field-new-list-image-preview')}</Form.Label>
                <img
                  alt={t('modals.item-create-image.field-new-list-image-preview')}
                  className="img-fluid rounded border"
                  src={URL.createObjectURL(formik.values.card_image)}
                />
              </Form.Group>
            )}
          </fieldset>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="primary" onClick={formik.handleSubmit} disabled={!formik.values.card_image || disabled}>{t('forms.actions.create')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default ItemModalAddImage;
