import React from 'react';

import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import imgAnsible from '../../../assets/img/brands/sources/ansible.png';
import imgApollo from '../../../assets/img/brands/sources/apollo.png';
import imgAxios from '../../../assets/img/brands/sources/axios.png';
import imgBabeledit from '../../../assets/img/brands/sources/babeledit.png';
import imgBootstrap from '../../../assets/img/brands/sources/bootstrap.png';
import imgCelery from '../../../assets/img/brands/sources/celery.png';
import imgChatGPT from '../../../assets/img/brands/sources/chatgpt.png';
import imgChrome from '../../../assets/img/brands/sources/chrome.png';
import imgDebian from '../../../assets/img/brands/sources/debian.png';
import imgDjango from '../../../assets/img/brands/sources/django.png';
import imgDocker from '../../../assets/img/brands/sources/docker.png';
import imgFabric from '../../../assets/img/brands/sources/fabric.png';
import imgFontAwesome from '../../../assets/img/brands/sources/font-awesome.png';
import imgGit from '../../../assets/img/brands/sources/git.png';
import imgGitlab from '../../../assets/img/brands/sources/gitlab.png';
import imgGraphql from '../../../assets/img/brands/sources/graphql.png';
import imgGunicorn from '../../../assets/img/brands/sources/gunicorn.png';
import imgNginx from '../../../assets/img/brands/sources/nginx.png';
import imgPostgres from '../../../assets/img/brands/sources/postgresql.png';
import imgPython from '../../../assets/img/brands/sources/python.png';
import imgReactBootstrap from '../../../assets/img/brands/sources/react-bootstrap.png';
import imgReact from '../../../assets/img/brands/sources/react.png';
import imgRedis from '../../../assets/img/brands/sources/redis.png';
import imgSentry from '../../../assets/img/brands/sources/sentry.png';
import imgStackoverflow from '../../../assets/img/brands/sources/stackoverflow.png';
import imgSublimeText from '../../../assets/img/brands/sources/sublime-text.png';
import imgSwift from '../../../assets/img/brands/sources/swift.png';
import imgVSCode from '../../../assets/img/brands/sources/vscode.png';
import imgWeasyprint from '../../../assets/img/brands/sources/weasyprint.png';
import imgXcode from '../../../assets/img/brands/sources/xcode.png';


const Sources = () => {

  const location = useLocation();
  const { t } = useTranslation();

  const metaTags = [
    { name: 'description', content: t('pages.meta.sources.meta-description') },
  ];

  const linkTags = [
    { rel: 'canonical', href: `${process.env.REACT_APP_SELF}${location.pathname}` },
  ];

  return (
    <>
      <Helmet title={t('pages.meta.sources.headline')} meta={metaTags} link={linkTags} />

      <Container>

        <h1>{t('pages.meta.sources.headline')}</h1>
        <p className="lead">{t('pages.meta.sources.lead')}</p>

        <div className="text-center my-5">
          <img className="my-3 me-5" src={imgAnsible} alt="Ansible" title="Ansible" height="26" width="186" />
          <img className="my-3 me-5" src={imgApollo} alt="Apollo" title="Apollo" height="50" width="145" />
          <img className="my-3 me-5" src={imgAxios} alt="Axios" title="Axios" height="20" width="136" />
          <img className="my-3 me-5" src={imgBabeledit} alt="BabelEdit" title="BabelEdit" height="50" width="50" />
          <img className="my-3 me-5" src={imgBootstrap} alt="Bootstrap" title="Bootstrap" height="45" width="178" />
          <img className="my-3 me-5" src={imgCelery} alt="Celery" title="Celery" height="20" width="235" />
          <img className="my-3 me-5" src={imgChatGPT} alt="ChatGPT" title="ChatGPT" height="50" width="50" />
          <img className="my-3 me-5" src={imgChrome} alt="Chrome" title="Chrome" height="50" width="161" />
          <img className="my-3 me-5" src={imgDebian} alt="Debian" title="Debian" height="28" />
          <img className="my-3 me-5" src={imgDjango} alt="Django" title="Django" height="40" width="115" />
          <img className="my-3 me-5" src={imgDocker} alt="Docker" title="Docker" height="60" width="226" />
          <img className="my-3 me-5" src={imgFabric} alt="Fabric" title="Fabric" height="50" width="66" />
          <img className="my-3 me-5" src={imgFontAwesome} alt="Font Awesome" title="Font Awesome" height="50" width="50" />
          <img className="my-3 me-5" src={imgGit} alt="Git" title="Git" height="40" width="96" />
          <img className="my-3 me-5" src={imgGitlab} alt="Gitlab" title="Gitlab" height="34" width="139" />
          <img className="my-3 me-5" src={imgGraphql} alt="Graphql" title="Graphql" height="65" width="215" />
          <img className="my-3 me-5" src={imgGunicorn} alt="Gunicorn" title="Gunicorn" height="40" width="183" />
          <img className="my-3 me-5" src={imgNginx} alt="NGINX" title="NGINX" height="30" width="144" />
          <img className="my-3 me-5" src={imgPostgres} alt="Postgres" title="Postgres" height="40" width="200" />
          <img className="my-3 me-5" src={imgPython} alt="Python" title="Python" height="45" width="155" />
          <img className="my-3 me-5" src={imgReact} alt="React" title="React" height="50" width="163" />
          <img className="my-3 me-5" src={imgReactBootstrap} alt="ReactBootstrap" title="ReactBootstrap" height="52" width="55" />
          <img className="my-3 me-5" src={imgRedis} alt="Redis" title="Redis" height="50" width="150" />
          <img className="my-3 me-5" src={imgSentry} alt="Sentry" title="Sentry" height="45" width="163" />
          <img className="my-3 me-5" src={imgStackoverflow} alt="Stackoverflow" title="Stackoverflow" height="55" width="251" />
          <img className="my-3 me-5" src={imgSublimeText} alt="Sublime Text" title="Sublime Text" height="30" width="270" />
          <img className="my-3 me-5" src={imgSwift} alt="Swift" title="Swift" height="40" width="129" />
          <img className="my-3 me-5" src={imgVSCode} alt="Visual Studio Code" title="Visual Studio Code" height="50" width="50" />
          <img className="my-3 me-5" src={imgWeasyprint} alt="Weasyprint" title="Weasyprint" height="50" width="302" />
          <img className="my-3 me-5" src={imgXcode} alt="Xcode" title="Xcode" height="50" width="50" />
        </div>

        <p className="lead">{t('pages.meta.sources.images-freepik')}</p>

        <ul>
          <li><a className="text-decoration-none" href="https://de.freepik.com/fotos/alpen-berge">Alpen berge Foto erstellt von wirestock - de.freepik.com</a></li>
          <li><a className="text-decoration-none" href="https://de.freepik.com/fotos/lesen">Lesen Foto erstellt von freepik - de.freepik.com</a></li>
          <li><a className="text-decoration-none" href="https://de.freepik.com/fotos/altes-buch">Altes buch Foto erstellt von master1305 - de.freepik.com</a></li>
          <li><a className="text-decoration-none" href="https://de.freepik.com/fotos/saft">Saft Foto erstellt von KamranAydinov - de.freepik.com</a></li>
          <li><a className="text-decoration-none" href="https://de.freepik.com/fotos/hundefutter">Hundefutter Foto erstellt von rawpixel.com - de.freepik.com</a></li>
          <li><a className="text-decoration-none" href="https://de.freepik.com/fotos/stricken-wolle">Stricken wolle Foto erstellt von pvproductions - de.freepik.com</a></li>
          <li><a className="text-decoration-none" href="https://de.freepik.com/fotos/gitarre">Gitarre Foto erstellt von pvproductions - de.freepik.com</a></li>
          <li><a className="text-decoration-none" href="https://de.freepik.com/fotos/wellness-spa">Wellness spa Foto erstellt von pvproductions - de.freepik.com</a></li>
          <li><a className="text-decoration-none" href="https://de.freepik.com/fotos/blaetter-hintergrund">Blaetter hintergrund Foto erstellt von rawpixel.com - de.freepik.com</a></li>
          <li><a className="text-decoration-none" href="https://de.freepik.com/psd/mockup-kids">Mockup kids PSD erstellt von vanitjan - de.freepik.com</a></li>
          <li><a className="text-decoration-none" href="https://de.freepik.com/fotos/schnell">Schnell Foto erstellt von benzoix - de.freepik.com</a></li>
          <li><a className="text-decoration-none" href="https://de.freepik.com/fotos/creme">Creme Foto erstellt von jcomp - de.freepik.com</a></li>
          <li><a className="text-decoration-none" href="https://de.freepik.com/fotos/erste-hilfe">Erste hilfe Foto erstellt von xb100 - de.freepik.com</a></li>
          <li><a className="text-decoration-none" href="https://de.freepik.com/fotos-kostenlos/modernes-stilvolles-skandinavisches-kuecheninterieur-mit-kuechenzubehoer-hellweisse-kueche-mit-haushaltsgegenstaenden_10108467.htm#query=k%C3%BCche&position=1&from_view=search">Bild von pvproductions</a></li>
        </ul>


      </Container>

    </>
  );
}

export default Sources;
