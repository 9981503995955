import React from 'react';

import { ToastContainer } from 'react-toastify';
import useTheme from '../../hooks/useTheme';


function TContainer() {

  const { themeIsDark } = useTheme();

  return (
    <ToastContainer theme={themeIsDark === true ? 'dark' : 'light'} />
  );
}

export default TContainer;
