import React from 'react';

import { Outlet } from 'react-router-dom';
import Main from '../components/basic/Main';
import Footer from '../components/public/Footer';
import Navbar from '../components/public/Navbar';


const Landing = ({ children }) => (
  <>
    <Navbar />
    <Main className="main-public">
      {children}
      <Outlet />
    </Main>
    <Footer />
  </>
);

export default Landing;
