import React, { useState } from 'react';

import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UserAvatar from '../../components/auth/UserAvatar';
import GroupThumbnail from '../../components/groups/GroupThumbnail';
import CollectionModalAddGroupPermission from './ModalAddGroupPermission';
import CollectionModalAddUserPermission from './ModalAddUserPermission';
import CollectionModalPublish from './ModalPublish';
import CollectionModalRemoveGroupPermission from './ModalRemoveGroupPermission';
import CollectionModalRemoveUserPermission from './ModalRemoveUserPermission';


const CollectionSidebarPermission = ({ handler, collection, refetch }) => {

  const navigate = useNavigate();
  const { opened } = handler;
  const { t } = useTranslation();

  const openGroup = (groupId) => {
    navigate(`/private/groups/${groupId}`);
  };

  const handleClose = () => {
    opened.set(false);
  };

  const removeUserPermission = (permission) => {
    removeUserPermissionModalHandler.opened.set(permission);
  };

  const removeGroupPermission = (group) => {
    removeGroupPermissionModalHandler.opened.set(group);
  };

  const removeUserPermissionModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  const removeGroupPermissionModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  const addGroupPermissionModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  const addUserPermissionModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  const publishModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  return (
    <>
      {addGroupPermissionModalHandler.opened.value && <CollectionModalAddGroupPermission handler={addGroupPermissionModalHandler} collection={collection} />}
      {addUserPermissionModalHandler.opened.value && <CollectionModalAddUserPermission handler={addUserPermissionModalHandler} collection={collection} />}
      {publishModalHandler.opened.value && <CollectionModalPublish handler={publishModalHandler} collection={collection} />}
      {removeGroupPermissionModalHandler.opened.value && <CollectionModalRemoveGroupPermission handler={removeGroupPermissionModalHandler} collection={collection} group={removeGroupPermissionModalHandler.opened.value} />}
      {removeUserPermissionModalHandler.opened.value && <CollectionModalRemoveUserPermission handler={removeUserPermissionModalHandler} collection={collection} permission={removeUserPermissionModalHandler.opened.value} />}
      <div className="panel bg-body-tertiary border-start flex-fill mw-330 overflow-hidden">
        <div className="app">
          <div className="module modal">
            <div className="modal-header">
              <div className="modal-title h5">{t('sidebars.collection-permissions.title')}</div>
              <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
            </div>
            <div className="mainspace p-3">
              <dl>
                <dt className="mb-2">{t('sidebars.collection-permissions.is-public')}</dt>
                {collection?.isPublic === true && (
                  <>
                    <div className="alert alert-warning p-2" role="alert">
                      {t('sidebars.collection-permissions.is-public-info')}
                    </div>
                    {collection.publicTrustLevel < 50 && <dd className="mb-4">{t('sidebars.collection-permissions.is-public-impact-below-50', { trust_level: collection.publicTrustLevel })}</dd>}
                    {collection.publicTrustLevel >= 50 && (
                      <>
                        <dd className="mb-4">
                          {t('sidebars.collection-permissions.is-public-impact-above-or-like-50', { trust_level: collection.publicTrustLevel })}
                          <br />
                          <a target="_blank" rel="noreferrer" href={`/c/${collection.publicSlug || collection.publicPk}`}>
                            /c/{collection.publicSlug || collection.publicPk}
                          </a>
                        </dd>
                      </>
                    )}
                    <div className="d-grid gap-2">
                      <Button variant="secondary" className="mb-3" onClick={() => publishModalHandler.opened.set(true)}>{t('sidebars.collection-permissions.unpublish')}</Button>
                    </div>
                  </>
                )}
                {collection?.isPublic === false && (
                  <div className="d-grid gap-2">
                    <Button variant="secondary" className="mb-3" onClick={() => publishModalHandler.opened.set(true)}>{t('sidebars.collection-permissions.publish')}</Button>
                  </div>
                )}
                <dt className="mb-3">{t('sidebars.collection-permissions.user-access')}</dt>
                <dd className="mb-4">
                  {collection?.permissions?.map((userPermission, index) => (
                    <div className="d-flex mb-2" key={index}>
                      <div className="flex-shrink-0">
                        <UserAvatar user={userPermission} />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <b>{userPermission.repr}</b>
                        <div>{userPermission.getRoleDisplay}</div>
                      </div>
                      {userPermission.role !== 'A_1' && (
                        <div className="flex-shrink-0">
                          <Button variant="link" onClick={() => removeUserPermission(userPermission)}>
                            <FontAwesomeIcon icon={faTrashCan} />
                          </Button>
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="d-grid gap-2">
                    <Button variant="secondary" className="mt-2" onClick={() => addUserPermissionModalHandler.opened.set(true)}>
                      {t('sidebars.collection-permissions.add-user')}
                    </Button>
                  </div>
                </dd>
                <dt className="mb-0">{t('sidebars.collection-permissions.group-access')}</dt>
                <dd>
                  <div className="mb-3">{collection?.groupCount === 0 && t('sidebars.collection-permissions.no-groups')}</div>
                  {collection?.groupCount > 0 && collection?.groupPermissions.map((groupPermission, index) => (
                    <div className="d-flex" key={index}>
                      <div className="flex-shrink-0" role="button" onClick={() => openGroup(groupPermission.group.pk)}>
                        <GroupThumbnail group={groupPermission.group} />
                      </div>
                      <div className="flex-grow-1 ms-3" role="button" onClick={() => openGroup(groupPermission.group.pk)}>
                        <b>{groupPermission.group.name}</b>
                        <div>{groupPermission.getModeDisplay}</div>
                      </div>
                      <div className="flex-shrink-0">
                        <Button variant="link" onClick={() => removeGroupPermission(groupPermission.group)}>
                          <FontAwesomeIcon icon={faTrashCan} />
                        </Button>
                      </div>
                    </div>
                  ))}
                  <div className="d-grid gap-2">
                    <Button variant="secondary" className="mt-2" onClick={() => addGroupPermissionModalHandler.opened.set(true)}>
                      {t('sidebars.collection-permissions.add-group')}
                    </Button>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionSidebarPermission;
