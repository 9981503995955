import React from 'react';

import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import { Box, Collection } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import defaultCollectionImage from '../../assets/img/illustrations/default-collection-image.jpg';


const CollectionGroupCard = ({ collection, usePublicAddr = false, group = null }) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const openCollection = (collectionId) => {
    if (usePublicAddr) {
      navigate(`/c/${collection.publicSlug || collection.publicPk}`);
    }

    if (group) {
      navigate(`/private/groups/${group.pk}/c/${collectionId}`);
    } else if (collection?.groupPermissions?.length === 1) {
      navigate(`/private/groups/${collection?.groupPermissions[0].group.pk}/c/${collectionId}`);
    } else {
      navigate(`/private/collections/${collectionId}`);
    }
  };

  return (
    <>
      <Card className="mb-4 collection" role="button" onClick={() => openCollection(collection.pk)}>
        {collection.cardImageThumbnail && <img className="card-img-top" loading="lazy" src={`${process.env.REACT_APP_MEDIA_URL}${collection.cardImageThumbnail}`} alt={collection.name} />}
        {!collection.cardImageThumbnail && collection.defaultType?.cardImageThumbnail && <img className="card-img-top" loading="lazy" src={`${process.env.REACT_APP_MEDIA_URL}${collection.defaultType.cardImageThumbnail}`} alt={collection.name} />}
        {!collection.cardImageThumbnail && !collection.defaultType?.cardImageThumbnail && <img className="card-img-top" loading="lazy" src={defaultCollectionImage} alt={collection.name} />}
        <Card.ImgOverlay className="fw-500">
          <div className={`shadowed-details text-end${collection.childCount === 0 ? ' d-none' : ''}`}>
            {collection.childCount}
            <Collection className="ms-2" size={16} alt={t('pages.collections.card.child-count')} />
          </div>
          <div className={`shadowed-details text-end${collection.elementCount === 0 ? ' d-none' : ''}`}>
            {collection.elementCount}
            <Box className="ms-2" size={16} alt={t('pages.collections.card.element-count')} />
          </div>
        </Card.ImgOverlay>
        <Card.Body className="border-top d-none">
          <dl className="row mb-0">
            <dt className="col-sm-6">{t('pages.collections.card.element-count')}</dt>
            <dd className="col-sm-6 mb-0">{collection.elementCount}</dd>
            <dt className="col-sm-6">{t('pages.collections.card.child-count')}</dt>
            <dd className="col-sm-6 mb-0">{collection.childCount}</dd>
          </dl>
        </Card.Body>
        <Card.Footer className="d-flex">
          <span>{collection.name}</span>
          {collection?.groupPermissions && collection?.groupPermissions.length > 0 && (
            <span className="ms-auto text-muted">
              <FontAwesomeIcon icon={faUserGroup} />
            </span>
          )}
        </Card.Footer>
        {collection?.groupPermissions?.length > 1 && collection?.groupPermissions.map((groupPermission, index) => (
          <Card.Footer key={index}>{t('pages.collections.card.group')}: {groupPermission.repr}</Card.Footer>
        ))}
      </Card>
    </>
  );
};

export default CollectionGroupCard;
