import React, { useEffect, useRef, useState } from 'react';

import { useApolloClient, useLazyQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { Folder2Open, X } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { GET_TYPES_WITH_DEFINITION } from '../../components/collections/queries';
import { postData } from '../../utils/axiosHelper';
import AttributeInput from './AttributeInput';


const ItemModalAdd = ({ handler, parent }) => {

  const [disabled, setDisabled] = useState(false);
  const [types, setTypes] = useState([]);
  const cardImageRef = useRef();
  const client = useApolloClient();
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const [getTypes] = useLazyQuery(GET_TYPES_WITH_DEFINITION, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setTypes(data.types);
    },
  });

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleSave = async (values) => {

    var formData = { ...values };
    formData.type_of = formData.defaultType?.pk || null;
    formData['properties'] = JSON.stringify(formData.properties.map((p) => { return { attribute: p.attribute, value: p.value }; }));

    delete formData['cardImageName'];
    delete formData['defaultType'];

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.saving'));
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    await postData('warehouse/items/', formData, { config, sleep: 500 }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.item-create.created-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      await client.clearStore();
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      card_image: null,
      cardImageName: t('forms.values.undefined'),
      default_type: '',
      description: '',
      name: '',
      parent: parent?.pk,
      properties: [],
      website: '',
    },
    onSubmit: (values) => handleSave(values),
  });

  const valueFromId = (opts, id) => opts.find((o) => o.node.pk === id);
  const setFieldValue = formik.setFieldValue;

  useEffect(() => {
    getTypes();
  }, [getTypes]);

  useEffect(() => {
    if (formik.values.defaultType) {
      const newProperties = [];

      formik.values.defaultType.definitions.forEach((definition) => {
        newProperties.push({ definition, attribute: definition.attribute.pk, value: '' });
      })

      setFieldValue('properties', newProperties)
    }
  }, [formik.values.defaultType, setFieldValue]);

  return (
    <>
      <Modal show={opened.value} onHide={handleClose} size="lg" backdrop={disabled ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.item-create.title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <fieldset disabled={disabled}>
            <Row>
              <Col md="9">
                <Row>
                  <Col md="7">
                    <Form.Group className="mb-3">
                      <Form.Label>{t('modals.item-create.field-name')}</Form.Label>
                      <Form.Control
                        autoComplete="off"
                        isInvalid={formik.errors?.fields?.name}
                        name="name"
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors?.fields?.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="5">
                    <Form.Group className="mb-3">
                      <Form.Label>{t('modals.item-create.field-type')}</Form.Label>
                      <Select
                        getOptionLabel={(e) => e.node.name}
                        getOptionValue={(e) => e.node.pk}
                        placeholder={t('modals.item-create.field-type-placeholder')}
                        className="border-0 react-select-container"
                        classNamePrefix="react-select"
                        isClearable={true}
                        name="defaultType"
                        onChange={(o) => formik.setFieldValue('defaultType', o?.node || null)}
                        options={types.edges || []}
                        value={valueFromId(types.edges || [], formik.values.defaultType?.pk)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors?.fields?.default_type}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label>{t('modals.item-edit.field-website')}</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    isInvalid={formik.errors?.fields?.website}
                    name="website"
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.website}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.website}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t('modals.item-create.field-description')}</Form.Label>
                  <Form.Control
                    name="description"
                    as="textarea"
                    rows={4}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.description}
                    isInvalid={formik.errors?.fields?.description}
                  />
                  <div className="form-text">{t('modals.item-create.field-description-info')}</div>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group className="mb-3">
                  <Form.Label>{t('modals.item-create.field-list-image')}</Form.Label>
                  <InputGroup>
                    <Form.Control
                      value={formik.values.card_image?.name || formik.values.cardImageName}
                      isInvalid={formik.errors?.fields?.card_image}
                      disabled
                    />
                    {formik.values.card_image && (
                      <Button
                        variant="outline-secondary"
                        className="px-1"
                        onClick={() => { cardImageRef.current.value = null; formik.setFieldValue('card_image', null) }}
                      >
                        <X />
                      </Button>
                    )}
                    <label className="file-btn btn btn-primary px-2">
                      <input
                        accept="image/*"
                        id="field-card_image"
                        ref={cardImageRef}
                        name="card_image"
                        onChange={(event) => formik.setFieldValue('card_image', event.currentTarget.files[0])}
                        style={{ position: 'absolute', top: -10000 }}
                        type="file"
                      />
                      <Folder2Open />
                    </label>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.card_image}
                  </Form.Control.Feedback>
                </Form.Group>
                {formik.values.card_image && (
                  <Form.Group className="mb-3">
                    <Form.Label>{t('modals.item-create.field-new-list-image-preview')}</Form.Label>
                    <img
                      alt={t('modals.item-create.field-new-list-image-preview')}
                      className="img-fluid rounded border"
                      src={URL.createObjectURL(formik.values.card_image)}
                    />
                  </Form.Group>
                )}
                {!formik.values.card_image && formik.values.defaultType?.itemImageThumbnail && (
                  <Form.Group className="mb-3">
                    <Form.Label>{t('modals.collection-edit.field-type-fallback-image-preview')}</Form.Label>
                    <img
                      alt={t('modals.collection-edit.field-type-fallback-image-preview')}
                      className="img-fluid rounded border"
                      src={`${process.env.REACT_APP_MEDIA_URL}${formik.values.defaultType?.itemImageThumbnail}`}
                    />
                  </Form.Group>
                )}
              </Col>
            </Row>

            <Row>
              {formik.values?.properties.map((property, index) => (
                <Col md={4} key={index}>
                  <AttributeInput
                    property={property}
                    formik={formik}
                    index={index}
                  />
                </Col>
              ))}
            </Row>

          </fieldset>
        </Modal.Body>

        <Modal.Footer className="d-flex">
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.create')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default ItemModalAdd;
