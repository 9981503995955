import React, { useEffect, useRef, useState } from 'react';

import { useFormik } from 'formik';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { Folder2Open, Trash, X } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { confirm } from '../../components/basic/Confirmation';
import { getOptions, putData } from '../../utils/axiosHelper';
import { basename } from '../../utils/formatter';
import UserModalDelete from './ModalDeleteUser';
import guestAvatar from '../../assets/img/artwork/guest_avatar.png';


const UserModalEdit = ({ handler, user, allowDelete = false }) => {

  const [disabled, setDisabled] = useState(false);
  const [options, setOptions] = useState({});
  const { opened, ok, backdrop, cancel } = handler;
  const { t } = useTranslation();
  const cardImageRef = useRef();

  const deleteModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      if (response?.delete === true) {

        confirm({
          danger: false,
          lead: t('modals.user-edit.deletion-confirm-lead'),
          okLabel: t('modals.user-edit.deletion-confirm-ok'),
          showCancel: false,
          title: t('modals.user-edit.deletion-confirm-title'),
        });

        handleClose();
      }
    },
  };

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleEdit = async (values) => {

    var formData = { ...values };
    formData.default_type = formData.defaultType?.pk || null;

    setDisabled(true);
    let brot = toast.loading(t('widgets.toast.loading'));
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    await putData(`auth/user/update/`, formData, { config }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.user-edit.updated-successfully'), type: 'success', autoClose: 1200, isLoading: false });
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const toggleCardImageDeletion = () => {
    formik.setFieldValue('delete_avatar', !formik.values.delete_avatar);
  };

  const getFormOptions = async () => {
    setOptions({});
    const response = await getOptions('auth/user/update/');
    if (response) {
      setOptions(response.data.actions.PUT);
    }
  };

  const valueFromId = (opts, id) => opts.find((o) => o.value === id);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      avatarImageName: basename(user.avatar || t('forms.values.undefined')),
      delete_avatar: false,
      first_name: user.first_name,
      last_name: user.last_name,
      motto: user.motto,
      new_avatar: null,
      pk: user.pk,
      salutation: user.salutation,
      use_gravatar: user.use_gravatar,
    },
    onSubmit: (values) => handleEdit(values),
  });

  useEffect(() => {
    getFormOptions();
  }, [user.pk]);

  return (
    <>
      {deleteModalHandler.opened.value && <UserModalDelete handler={deleteModalHandler} user={user} />}
      <Modal show={opened.value} onHide={handleClose} size="lg" backdrop={backdrop || disabled ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.user-edit.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={disabled}>
            <Row>
              <Col md="8">
                <Form.Group className="mb-3">
                  <Form.Label>{t('modals.user-edit.field-salutation')}</Form.Label>
                  <Select
                    options={options?.salutation?.choices}
                    getOptionLabel={(option) => option.display_name}
                    getOptionValue={(option) => option.value}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isSearchable={false}
                    isClearable={true}
                    placeholder={t('modals.user-edit.field-salutation-placeholder')}
                    value={valueFromId(
                      options?.salutation?.choices || [],
                      formik.values?.salutation
                    )}
                    onChange={(opt) =>
                      formik.setFieldValue('salutation', opt?.value || '')
                    }
                  />
                </Form.Group>
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-3">
                      <Form.Label>{t('modals.user-edit.field-first-name')}</Form.Label>
                      <Form.Control
                        autoComplete="off"
                        isInvalid={formik.errors?.fields?.first_name}
                        name="first_name"
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.first_name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors?.fields?.first_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-3">
                      <Form.Label>{t('modals.user-edit.field-last-name')}</Form.Label>
                      <Form.Control
                        autoComplete="off"
                        isInvalid={formik.errors?.fields?.last_name}
                        name="last_name"
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.last_name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors?.fields?.last_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>{t('modals.user-edit.field-email')}</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    isInvalid={formik.errors?.fields?.email}
                    name="email"
                    disabled={true}
                    onChange={formik.handleChange}
                    type="text"
                    value={user.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t('modals.user-edit.field-motto')}</Form.Label>
                  <Form.Control
                    name="motto"
                    as="textarea"
                    rows={3}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.motto}
                    isInvalid={formik.errors?.fields?.motto}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.motto}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group className="mb-3">
                  <Form.Label>{t('modals.user-edit.field-avatar')}</Form.Label>
                  <InputGroup>
                    {user.avatar && !formik.values.new_avatar && (
                      <Button variant="outline-secondary" className="px-1" onClick={toggleCardImageDeletion}>
                        <Trash />
                      </Button>
                    )}
                    <Form.Control
                      className={formik.values.delete_avatar ? "text-decoration-line-through" : ""}
                      value={formik.values.new_avatar?.name || formik.values.avatarImageName}
                      isInvalid={formik.errors?.fields?.card_image}
                      disabled
                    />
                    {formik.values.new_avatar && (
                      <Button
                        variant="outline-secondary"
                        className="px-1"
                        onClick={() => { cardImageRef.current.value = null; formik.setFieldValue('new_avatar', null) }}
                      >
                        <X />
                      </Button>
                    )}
                    <label className="file-btn btn btn-primary px-2">
                      <input
                        accept="image/*"
                        id="field-new_avatar"
                        ref={cardImageRef}
                        name="new_avatar"
                        onChange={(event) => formik.setFieldValue('new_avatar', event.currentTarget.files[0])}
                        style={{ position: 'absolute', top: -10000 }}
                        type="file"
                      />
                      <Folder2Open />
                    </label>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.card_image}
                  </Form.Control.Feedback>
                </Form.Group>
                {user.thumbnail && !formik.values.delete_avatar && !formik.values.new_avatar && (
                  <Form.Group className="mb-3">
                    <img
                      alt={t('modals.user-edit.field-avatar-preview')}
                      className="img-fluid rounded border"
                      height="237"
                      src={`${process.env.REACT_APP_MEDIA_URL}${user.thumbnail}`}
                    />
                  </Form.Group>
                )}
                {formik.values.new_avatar && (
                  <Form.Group className="mb-3">
                    <img
                      alt={t('modals.user-edit.field-new-avatar-preview')}
                      className="img-fluid rounded border w-100"
                      height="237"
                      src={URL.createObjectURL(formik.values.new_avatar)}
                    />
                  </Form.Group>
                )}
                {!formik.values.new_avatar && (!user.avatar || formik.values.delete_avatar) && !formik.values.use_gravatar && (
                  <Form.Group className="mb-3">
                    <img alt={t('modals.user-edit.field-avatar-fallback')} className="img-fluid rounded border" height="237" src={guestAvatar} />
                  </Form.Group>
                )}
                {formik.values.use_gravatar && !formik.values.new_avatar && (!user.avatar || formik.values.delete_avatar) && user.gravatar && (
                  <Form.Group className="mb-3">
                    <img alt={t('modals.user-edit.field-gravatar-fallback-image')} className="img-fluid rounded border" height="237" src={user.gravatar} />
                  </Form.Group>
                )}
                <Form.Group className="mb-3">
                  <Form.Check
                    type="switch"
                    id="gravatar-switch"
                    checked={formik.values.use_gravatar}
                    onChange={(event) => formik.setFieldValue('use_gravatar', event.target.checked)}
                    label={t('modals.user-edit.field-gravatar-switch')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.use_gravatar}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </fieldset>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          {allowDelete && <Button variant="danger" onClick={() => deleteModalHandler.opened.set(true)} disabled={disabled} className="me-auto">{t('modals.user-edit.delete-account')}</Button>}
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.update')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserModalEdit;
