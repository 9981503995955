import React, { useCallback, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { faPowerOff, faTrashCan, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, ButtonGroup, Container, Form, Row, Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import defaultGroupImage from '../../assets/img/illustrations/default-group-image.png';
import { confirm } from '../../components/basic/Confirmation';
import ModalExport from '../../components/basic/ModalExport';
import ModalLog from '../../components/basic/ModalLog';
import PrivateMetaNavHeader from '../../components/basic/PrivateMetaNavHeader';
import { GET_TYPES_WITH_DEFINITION } from '../../components/collections/queries';
import TypeModalAdd from '../../components/settings/ModalAddType';
import TypeModalEdit from '../../components/settings/ModalEditType';
import ImportFromInstagram from '../../components/settings/ModalImportFromInstagram';
import ImportFromV1Modal from '../../components/settings/ModalImportFromV1';
import ImportFromISBNBulkModal from '../../components/settings/ModalImportISBNBulk';
import { THEME } from '../../constants';
import useAuth from '../../hooks/useAuth';
import useLocalStorage from '../../hooks/useLocalStorage';
import useTheme from '../../hooks/useTheme';
import { deleteObj, getObjs, patchData, postData } from '../../utils/axiosHelper';
import { PushNotificationStatus } from '../../utils/constants';
import { browserName, urlB64ToUint8Array } from '../../utils/formatter';


const Settings = () => {
  const notificationStatus = PushNotificationStatus();

  const [, setCurrentState] = useState(notificationStatus.UNKNOWN);
  const [currentSubscription, setCurrentSubscription] = useState();
  const [disableHistoryScroll, setDisableHistoryScroll] = useLocalStorage('disable-history-scroll', false);
  const [disableSubscriptionActions, setDisableSubscriptionActions] = useState(false);
  const [showSidebarInfo, setShowSidebarInfo] = useLocalStorage('show-sidebar-info', false);
  const [showSidebarPerms, setShowSidebarPerms] = useLocalStorage('show-sidebar-permissions', false);
  const [types, setTypes] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const { i18n, t } = useTranslation();
  const { theme, setTheme } = useTheme();
  const { user } = useAuth();

  const userNotificationSettingsInitials = {
    best_before_warning_in_days: 7,
    enabled_best_before_expired: false,
    enabled_best_before: true,
    enabled_collection_updates: true,
    send_best_before_at: '12:00',
    user_timezone: 'UTC',
    use_email: true,
    use_push_notifications: true,
  };

  const [userNotificationSettings, setUserNotificationSettings] = useState(userNotificationSettingsInitials);

  const [getTypes, { refetch }] = useLazyQuery(GET_TYPES_WITH_DEFINITION, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setTypes(data.types);
    },
  });

  const importFromV1ModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => { },
  };

  const importFromISBNBulkModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => { },
  };

  const importFromInstagramHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => { },
  };

  const createTypeModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  const editTypeModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  const exportModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
    },
  };

  const logModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
    },
  };

  const deleteSubscription = async (subscription) => {

    setDisableSubscriptionActions(true);

    await deleteObj(`auth/user/push-subscriptions/${subscription.auth}/`).then(async (response) => {
      getUserSubscriptions();
    }).catch((error) => {
      if (error.response.status !== 404) {
        toast.error(`${t('widgets.toast.error')} ${error.response.status}`, { isLoading: false, autoClose: 3000 });
      }
    }).finally(() => {
      setDisableSubscriptionActions(false);
    });
  };

  const saveSubscription = async (subscription) => {

    setDisableSubscriptionActions(true);
    const brot = toast.loading(t('widgets.toast.saving'));
    const formData = {
      'subscription': subscription,
      'browser': browserName(navigator?.userAgent),
      'user_agent': navigator?.userAgent,
    };

    await postData(`auth/user/push-subscriptions/`, formData).then(async (response) => {
      toast.dismiss(brot);
      getUserSubscriptions();
      confirm({ okLabel: t('forms.actions.ok'), showCancel: false, lead: t('pages.settings.confirm-sw-register-success'), title: t('pages.settings.confirm-sw-register-title') });
    }).catch((error) => {
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisableSubscriptionActions(false);
    });
  };

  const getUserSubscriptions = useCallback(async () => {
    await getObjs('auth/user/push-subscriptions/').then(async (response) => {
      setUserSubscriptions(response.data);
    }).catch((error) => {
      console.debug('error', error);
    });
  }, [setUserSubscriptions]);

  const updateUserNotificationSetting = async (key, value) => {

    setDisableSubscriptionActions(true);
    const formData = {};
    formData[key] = value;
    await patchData('auth/user/notification-settings/update/', formData).then(async (response) => {
      setUserNotificationSettings(response.data);
    }).catch((error) => {
      toast.error(`${t('widgets.toast.error')} ${error.response.status}`, { autoClose: 3000 });
    }).finally(() => {
      setDisableSubscriptionActions(false);
    });
  };


  const getUserNotificationSettings = useCallback(async () => {
    await getObjs('auth/user/notification-settings/').then(async (response) => {
      if (response.data[0]) {
        setUserNotificationSettings(response.data[0]);
      }
    }).catch((error) => {
      console.debug('error', error);
    });
  }, [setUserNotificationSettings]);

  const registerPushNotifications = () => {

    setDisableSubscriptionActions(true);

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY),
        })
          .then(subscription => {
            setCurrentSubscription(subscription);
            saveSubscription(subscription);
          })
          .catch(error => {

            if (error.name === 'NotAllowedError') {
              toast.error(notificationStatus.NOT_ALLOWED, { autoClose: 2000 });
            }

            if (error.name === 'NotSupportedError') {
              toast.error(notificationStatus.NOT_SUPPORTED, { autoClose: 2000 });
            }

            if (error.name === 'AbortError') {
              toast.error(notificationStatus.ABORTED, { autoClose: 2000 });
            }

            if (error.name === 'ServiceWorkerRegistrationError') {
              toast.error(notificationStatus.SW_REGISTRATION_ERROR, { autoClose: 2000 });
            }

          });
      }).finally(() => {

        setDisableSubscriptionActions(false);

      });
    }
  };

  const unregisterDevice = async (subscription) => {

    setDisableSubscriptionActions(true);

    if (!await confirm({
      okLabel: t('forms.actions.ok'),
      warning: true,
      lead: t('pages.settings.confirm-sw-deregister'),
      title: t('pages.settings.confirm-sw-register-title')
    })) {
      setDisableSubscriptionActions(false);
      return;
    }

    try {

      if (currentSubscription?.endpoint === subscription?.endpoint) {
        await currentSubscription.unsubscribe();
        setCurrentSubscription();
      }

      deleteSubscription(subscription);
    } catch (error) {
      console.error('Fehler beim Abmelden von Push-Benachrichtigungen:', error);
    }
  };

  const checkSubscriptionStatus = useCallback(async () => {
    if ('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.ready;

        if ('pushManager' in registration) {
          const subscription = await registration.pushManager.getSubscription();

          if (subscription) {
            setCurrentState(notificationStatus.REGISTERED)
            setCurrentSubscription(subscription);
          }
        } else {
          setCurrentState(notificationStatus.NO_PUSH_MANAGER)
        }
      } catch (error) {
        console.error('Error getting subscription:', error);

        if (error.name === 'NotAllowedError') {
          setCurrentState(notificationStatus.NOT_ALLOWED)
          return;
        }

        if (error.name === 'NotSupportedError') {
          setCurrentState(notificationStatus.NOT_SUPPORTED)
          return;
        }

        if (error.name === 'AbortError') {
          setCurrentState(notificationStatus.ABORTED)
          return;
        }

        if (error.name === 'ServiceWorkerRegistrationError') {
          setCurrentState(notificationStatus.SW_REGISTRATION_ERROR)
          return;
        }

        setCurrentState(notificationStatus.UNKNOWN_ERROR)
      }
    } else {
      setCurrentState(notificationStatus.NO_SERVICE_WORKER)
    }
  }, [notificationStatus]);

  useEffect(() => { checkSubscriptionStatus() }, [checkSubscriptionStatus]);

  useEffect(() => { getTypes() }, [getTypes]);

  useEffect(() => {
    if (user?.pk) {
      getUserSubscriptions();
    }
  }, [getUserSubscriptions, user.pk]);

  useEffect(() => {
    if (user?.pk) {
      getUserNotificationSettings();
    }
  }, [getUserNotificationSettings, user.pk]);


  return (
    <>
      {createTypeModalHandler.opened.value && <TypeModalAdd handler={createTypeModalHandler} />}
      {editTypeModalHandler.opened.value && <TypeModalEdit handler={editTypeModalHandler} original={editTypeModalHandler.opened.value} />}
      {exportModalHandler.opened.value && <ModalExport handler={exportModalHandler} source="elements" />}
      {importFromInstagramHandler.opened.value && <ImportFromInstagram handler={importFromInstagramHandler} />}
      {importFromISBNBulkModalHandler.opened.value && <ImportFromISBNBulkModal handler={importFromISBNBulkModalHandler} backdrop={true} />}
      {importFromV1ModalHandler.opened.value && <ImportFromV1Modal handler={importFromV1ModalHandler} />}
      {logModalHandler.opened.value && <ModalLog handler={logModalHandler} />}
      <Helmet title={t('pages.settings.headline')} />
      <PrivateMetaNavHeader />
      <div className="mainspace p-4">
        <Container>
          <Row>
            <Col md="6">
              <h3>{t('pages.settings.local-storage-headline')}</h3>
              <hr />
              <Form.Group className="mb-3" controlId="lsSidebarInfo">
                <Form.Check
                  type="checkbox"
                  label={t('pages.settings.showSidebarInfo')}
                  checked={showSidebarInfo}
                  onChange={(e) => setShowSidebarInfo(e.target.checked)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="lsSidebarPerms">
                <Form.Check
                  type="checkbox"
                  label={t('pages.settings.showSidebarPermissions')}
                  checked={showSidebarPerms}
                  onChange={(e) => setShowSidebarPerms(e.target.checked)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="lsHistoryScroll">
                <Form.Check
                  type="checkbox"
                  label={t('pages.settings.disableHistoryScroll')}
                  checked={disableHistoryScroll}
                  onChange={(e) => setDisableHistoryScroll(e.target.checked)}
                />
              </Form.Group>
              <Form.Group className="mt-4 mb-2">
                <Form.Label>{t('pages.settings.system-language')}</Form.Label>
                <br />
                <ButtonGroup>
                  <Button variant={i18n.resolvedLanguage === 'de' ? 'primary' : 'secondary'} onClick={() => { i18n.changeLanguage('de') }}>deutsch</Button>
                  <Button variant={(!i18n.resolvedLanguage || i18n.resolvedLanguage === 'en') ? 'primary' : 'secondary'} onClick={() => { i18n.changeLanguage('en') }}>english</Button>
                  <Button variant={i18n.resolvedLanguage === 'zh-TW' ? 'primary' : 'secondary'} onClick={() => { i18n.changeLanguage('zh-TW') }}>台灣</Button>
                </ButtonGroup>
              </Form.Group>
              <Form.Group className="mt-4 mb-2">
                <Form.Label>{t('pages.settings.theme')}</Form.Label>
                <br />
                <ButtonGroup>
                  <Button variant={theme === THEME.LIGHT ? 'primary' : 'secondary'} onClick={() => setTheme(THEME.LIGHT)} active={theme === THEME.LIGHT}>{t('meta.constants.theme-light')}</Button>
                  <Button variant={theme === THEME.DARK ? 'primary' : 'secondary'} onClick={() => setTheme(THEME.DARK)} active={theme === THEME.DARK}>{t('meta.constants.theme-dark')}</Button>
                  <Button variant={theme === THEME.AUTO ? 'primary' : 'secondary'} onClick={() => setTheme(THEME.AUTO)} active={theme === THEME.AUTO}>{t('meta.constants.theme-auto')}</Button>
                </ButtonGroup>
              </Form.Group>
              <div className="mt-2 mb-5 fs-6 text-end">
                <a href="https://developer.mozilla.org/de/docs/Web/API/Window/localStorage" target="_blank" rel="noreferrer">
                  <span>{t('pages.settings.local-storage-ext-help')}</span>
                  <FontAwesomeIcon icon={faUpRightFromSquare} className="ms-1" />
                </a>
              </div>
              <h3>{t('pages.settings.notifications-headline')}</h3>
              <hr />
              <Table size="sm" bordered striped responsive>
                <thead>
                  <tr>
                    <th>
                      {t('pages.settings.subscription-device')}
                    </th>
                    <th width="100" className="text-center">
                      {t('pages.settings.subscription-device-updated')}
                    </th>
                    <th width="50" className="text-center">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentSubscription && userSubscriptions.findIndex((sub) => `${sub.endpoint}` === `${currentSubscription.endpoint}`) === -1 && (
                    <tr className="table-danger">
                      <td className="align-top lh-1">
                        <div className="mb-0">
                          {t('pages.settings.subscription-this-device')}
                          <span className="ms-1">
                            ({t('pages.settings.subscription-out-of-sync')})
                          </span>
                        </div>
                        <div className="text-muted mt-0">
                          <small>{currentSubscription.endpoint && new URL(currentSubscription.endpoint).hostname}</small>
                        </div>
                      </td>
                      <td className="text-center align-top">
                        <Moment locale={i18n.resolvedLanguage} format="L"></Moment>
                      </td>
                      <td className="text-center align-top">
                        <Button size="sm" variant="warning" onClick={() => unregisterDevice(currentSubscription)}>
                          <FontAwesomeIcon icon={faPowerOff} fixedWidth />
                        </Button>
                      </td>
                    </tr>
                  )}
                  {userSubscriptions.sort((a, b) => b.updated_at.localeCompare(a.updated_at)).map((subscription, index) => (
                    <tr key={index}>
                      <td className="align-top lh-1">
                        <div className="mb-0">
                          {subscription.browser}
                          {subscription.endpoint === currentSubscription?.endpoint && (
                            <span className="ms-1 text-primary">
                              ({t('pages.settings.subscription-this-device')})
                            </span>
                          )}
                        </div>
                        <div className="text-muted mt-0">
                          <small>{subscription.endpoint && new URL(subscription.endpoint).hostname}</small>
                        </div>
                      </td>
                      <td className="text-center align-top">
                        <Moment locale={i18n.resolvedLanguage} format="L">{subscription.updated_at}</Moment>
                      </td>
                      <td className="text-center align-top">
                        <Button size="sm" variant="warning" onClick={() => unregisterDevice(subscription)}>
                          <FontAwesomeIcon icon={faTrashCan} fixedWidth />
                        </Button>
                      </td>
                    </tr>
                  ))}
                  {!currentSubscription && (!userSubscriptions || userSubscriptions.length === 0) && (
                    <tr>
                      <td colSpan={100} className="text-center py-4">
                        - {t('pages.settings.subscription-no-devices-registered')} -
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="gap-2 mb-3">
                {(!currentSubscription || userSubscriptions.findIndex((sub) => `${sub.endpoint}` === `${currentSubscription.endpoint}`) === -1) && (
                  <Button className="me-2 mb-2" variant="primary" onClick={registerPushNotifications} disabled={disableSubscriptionActions || !('Notification' in window)}>
                    {t('pages.settings.register-device-for-push-notifications')}
                  </Button>
                )}
              </div>
              <Form.Check
                type="switch"
                id="cb-settings-best-before"
                disabled={disableSubscriptionActions}
                checked={userNotificationSettings?.enabled_best_before}
                label={t('pages.settings.notification-sett-best-before', { days: userNotificationSettings?.best_before_warning_in_days, time: userNotificationSettings?.send_best_before_at, timezone: userNotificationSettings?.user_timezone })}
                onChange={(e) => updateUserNotificationSetting('enabled_best_before', e.currentTarget.checked)}
              />
              <Form.Check
                type="switch"
                id="cb-settings-best-before-expired"
                disabled={disableSubscriptionActions}
                checked={userNotificationSettings?.enabled_best_before_expired}
                label={t('pages.settings.notification-sett-best-before-expired', { time: userNotificationSettings?.send_best_before_at, timezone: userNotificationSettings?.user_timezone })}
                onChange={(e) => updateUserNotificationSetting('enabled_best_before_expired', e.currentTarget.checked)}
              />
              <Form.Check
                type="switch"
                id="cb-settings-collection-updates"
                disabled={disableSubscriptionActions}
                checked={userNotificationSettings?.enabled_collection_updates}
                label={t('pages.settings.notification-sett-collection-updates')}
                onChange={(e) => updateUserNotificationSetting('enabled_collection_updates', e.currentTarget.checked)}
              />
              <Form.Check
                type="switch"
                id="cb-settings-use-emails"
                disabled={disableSubscriptionActions}
                checked={userNotificationSettings?.use_email}
                label={t('pages.settings.notification-sett-notify-by-email')}
                onChange={(e) => updateUserNotificationSetting('use_email', e.currentTarget.checked)}
              />
              <Form.Check
                type="switch"
                id="cb-settings-use-push"
                disabled={disableSubscriptionActions}
                checked={userNotificationSettings?.use_push_notifications}
                label={t('pages.settings.notification-sett-notify-by-push')}
                onChange={(e) => updateUserNotificationSetting('use_push_notifications', e.currentTarget.checked)}
                className="mb-5"
              />
            </Col>
            <Col md="6">
              <h3>{t('pages.settings.typing-headline')}</h3>
              <hr />
              <div className="mb-5">
                {types && types?.edges?.filter((e) => !e.node.isPublic).length > 0 && (
                  <>
                    {types?.edges?.filter((e) => !e.node.isPublic).map((e, index) => (
                      <Button key={index} variant="secondary" className="text-decoration-none mb-2 me-2 text-start" onClick={() => editTypeModalHandler.opened.set(e.node)}>
                        {e.node?.itemImageThumbnail && <img width={16} alt={e.node.name} className="border bg-white me-2 rounded-circle" height={16} src={`${process.env.REACT_APP_MEDIA_URL}${e.node?.itemImageThumbnail}`} />}
                        {!e.node?.itemImageThumbnail && <img width={16} alt={e.node.name} className="border bg-white me-2 rounded-circle" height={16} src={defaultGroupImage} />}
                        {e.node.name}
                      </Button>
                    ))}
                  </>
                )}
                {types && types?.edges?.filter((e) => !e.node.isPublic).length > 0 && (
                  <br />
                )}
                <Button onClick={() => createTypeModalHandler.opened.set(true)}>{t('pages.settings.add-type-btn')}</Button>
              </div>
              <h3>{t('pages.settings.im-and-export-headline')}</h3>
              <hr />
              <div className="mb-5">
                <Button className="me-2 mb-2" variant="secondary" onClick={() => exportModalHandler.opened.set(true)}>{t('pages.settings.open-export-btn')}</Button>
                <br />
                <Button className="me-2 mb-2" onClick={() => importFromISBNBulkModalHandler.opened.set(true)}>{t('pages.settings.importer-bulk-isbn-btn')}</Button>
                <Button className="me-2 mb-2" onClick={() => importFromV1ModalHandler.opened.set(true)}>{t('pages.settings.importer-v1-btn')}</Button>
              </div>
              <h3>{t('pages.settings.advanced')}</h3>
              <hr />
              <div className="mb-5">
                <Button className="me-2 mb-2" variant="secondary" onClick={() => logModalHandler.opened.set(true)}>{t('pages.settings.show-git-log')}</Button>
                <a className="me-2 mb-2 btn btn-secondary" href="https://testflight.apple.com/join/iUrbxCMX" target="_blank" rel="noreferrer">{t('pages.settings.ios-testflight')}</a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Settings;
