import React, { useCallback, useEffect, useState } from 'react';

import { Container, Spinner } from 'react-bootstrap';
import { Check } from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import Formi from '../../../components/basic/Formi';
import { getObjs } from '../../../utils/axiosHelper';


const Pricing = () => {

  const [loading, setLoading] = useState([]);
  const [plans, setPlans] = useState([]);
  const location = useLocation();
  const { t } = useTranslation();

  const metaTags = [
    { name: 'description', content: t('pages.meta.pricing.meta-description') },
  ];

  const linkTags = [
    { rel: 'canonical', href: `${process.env.REACT_APP_SELF}${location.pathname}` },
  ];

  const features = [
    { key: 'private', global: true, label: t('pages.meta.pricing.features.private') },
    { key: 'public', global: true, label: t('pages.meta.pricing.features.public') },
    { key: 'permissions', global: true, label: t('pages.meta.pricing.features.user-group-permissions') },
    { key: 'email_support', global: true, label: t('pages.meta.pricing.features.email-support') },
    { key: 'nested', global: true, label: t('pages.meta.pricing.features.nested-collections') },
    { key: 'custom_types', global: true, label: t('pages.meta.pricing.features.custom-types-and-attributes') },
    { key: 'remote_support', global: false, label: t('pages.meta.pricing.features.remote-support') },
    { key: 'dev_logs', global: false, label: t('pages.meta.pricing.features.developer_logs') },
  ];

  const loadPlans = useCallback(async () => {
    setLoading([1, 2]);
    await getObjs('accounting/plans/?popular=true').then(async (response) => {
      setPlans(response.data);
    }).catch((error) => {
    }).finally(() => {
      setLoading([]);
    });
  }, []);


  useEffect(() => {
    loadPlans();
  }, [loadPlans]);

  return (
    <>
      <Helmet title={t('pages.meta.pricing.headline')} meta={metaTags} link={linkTags} />

      <Container style={{ maxWidth: '960px' }}>

        <div className="pricing-header p-3 pb-md-4 mx-auto text-center mb-5">
          <h1 className="display-4 fw-normal">{t('pages.meta.pricing.headline')}</h1>
          <p className="fs-5 text-muted mt-5">{t('pages.meta.pricing.lead')}</p>
        </div>

        <div className="row row-cols-1 row-cols-md-3 mb-5 text-center">
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">{t('pages.meta.pricing.free-plan-title')}</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title mb-0">0 €<small className="text-muted fw-light">/{t('pages.meta.pricing.month-abbr')}</small></h1>
                <div className="fst-italic">{t('pages.meta.pricing.tax-included')}</div>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>{t('pages.meta.pricing.free-plan-users')}</li>
                  <li>{t('pages.meta.pricing.free-pdf-export-limit')}</li>
                  <li>{t('pages.meta.pricing.free-plan-support')}</li>
                </ul>
                <NavLink to="/auth/register" className="w-100 btn btn-lg btn-outline-primary">{t('pages.meta.pricing.sign-up-for-free')}</NavLink>
              </div>
            </div>
          </div>
          {loading.length > 0 && loading.map((index) => (
            <div className="col" key={index}>
              <div className={`card mb-4 rounded-3 shadow-sm`}>
                <div className={`card-header py-3`}>
                  <h4 className="my-0 fw-normal">
                    <Spinner as="span" variant="secondary" animation="border" size="sm" role="status" aria-hidden="true" />
                    <Spinner as="span" variant="secondary" className="mx-2" size="sm" animation="border" role="status" aria-hidden="true" />
                    <Spinner as="span" variant="secondary" animation="border" size="sm" role="status" aria-hidden="true" />
                  </h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title">
                    X
                    <small className="text-muted fw-light">/x</small>
                  </h1>
                  <ul className="list-unstyled mt-3 mb-4">
                    <li>...</li>
                    <li>...</li>
                    <li>...</li>
                  </ul>
                  <NavLink to="/auth/register" className={`w-100 btn btn-lg btn-outline-primary`}>
                    {t('pages.meta.pricing.get-started')}
                  </NavLink>
                </div>
              </div>
            </div>
          ))}
          {loading.length === 0 && plans.map((plan, index) => (
            <div className="col" key={index}>
              <div className={`card mb-4 rounded-3 shadow-sm ${plan.highlight ? 'border-primary' : ''}`}>
                <div className={`card-header py-3 ${plan.highlight ? 'text-white bg-primary border-primary' : ''}`}>
                  <h4 className="my-0 fw-normal">{plan.name}</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title mb-0">
                    <Formi currencyVal={plan.price} />
                    <small className="text-muted fw-light">/{t('pages.meta.pricing.month-abbr')}</small>
                  </h1>
                  <div className="fst-italic">{t('pages.meta.pricing.tax-included')}</div>
                  <ul className="list-unstyled mt-3 mb-4">
                    {plan.bullet_points.split('\n').map((point, index) => (
                      <li key={index}>{point}</li>
                    ))}
                  </ul>
                  <NavLink to="/private/accounting" className={`w-100 btn btn-lg ${plan.highlight ? 'btn-primary' : 'btn-outline-primary'}`}>
                    {t('pages.meta.pricing.get-started')}
                  </NavLink>
                </div>
              </div>
            </div>
          ))}
        </div>

        <h2 className="display-6 text-center mb-4">{t('pages.meta.pricing.headline-compare')}</h2>

        <div className="table-responsive mt-5">
          <table className="table text-center">
            <thead>
              <tr>
                <th style={{ width: '34%' }}></th>
                <th style={{ width: '22%' }}>{t('pages.meta.pricing.free-plan-title')}</th>
                {loading.length === 0 && plans.map((plan, index) => (
                  <th style={{ width: '22%' }} key={index}>{plan.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {features.map((feature, index) => (
                <tr key={`feat${index}`}>
                  <th scope="row" className="text-start">{feature.label}</th>
                  <td>
                    {feature.global && <Check height="24" width="24" />}
                  </td>
                  {loading.length === 0 && plans.map((plan, index2) => (
                    <th style={{ width: '22%' }} key={`featplan${index2}`}>
                      {(feature.global || plan.features.split("\r\n").indexOf(feature.key) > -1) && <Check height="24" width="24" />}
                    </th>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    </>
  );
}

export default Pricing;
