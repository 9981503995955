import React from 'react';

import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import UserAvatar from '../../components/auth/UserAvatar';
import useAuth from '../../hooks/useAuth';


const ChatBox = ({ messages = [], loading = false }) => {

  const { i18n } = useTranslation();
  const { user } = useAuth();

  const resolvedMessages = messages.map((message, index) => {
    return {
      ...message,
      my: user.pk.indexOf(message.shortId) === 0,
      user: {
        gravatar: message.gravatar,
        repr: message.repr,
        shortId: message.shortId,
        thumbnail: message.thumbnail,
      },
    }
  });

  const renderMessage = (message) => {

    if (message.my) return (
      <div className="text-end flex-grow-1 me-3 pt-2">
        <span className="bg-primary text-white p-2 rounded">{message.message}</span>
        <div className="mt-1">
          <small className="pe-2">
            <Moment locale={i18n.resolvedLanguage} date={message.createdAt} format="L" />
          </small>
        </div>
      </div>
    )

    return (
      <div className="flex-grow-1 ms-3 pt-2">
        <span className="bg-primary text-white p-2 rounded">{message.message}</span>
        <div className="mt-1">
          <small className="ps-2">
            <Moment locale={i18n.resolvedLanguage} date={message.createdAt} format="L" />
          </small>
        </div>
      </div>
    );
  };

  if (loading) return (
    <div className="text-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );

  if (!messages) return <div className="text-danger">Messages undefined</div>;

  return (
    <ul className="list-unstyled">
      {resolvedMessages.map((message, index) => (
        <li className="mb-3" key={index}>
          <div className="d-flex">
            {message?.my === true && renderMessage(message)}
            <div className="flex-shrink-0">
              <UserAvatar user={message.user} alt={message.user.repr} className="rounded" width="48" height="48" />
            </div>
            {!message?.my === true && renderMessage(message)}
          </div>
        </li>
      ))}
    </ul>
  );

};

export default ChatBox;
