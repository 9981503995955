import { useEffect, useState } from 'react';

import { THEME } from '../constants';
import useLocalStorage from './useLocalStorage';


function useSettingsState(key, initialValue) {
  const [value, setValue] = useLocalStorage(key, initialValue);
  const [isDark, setIsDark] = useState(false);

  const updateDocument = (key, value) => {
    let newValue = value;

    if (key === 'bsTheme') {
      const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
      if (value === THEME.DARK || (value === THEME.AUTO && prefersDarkMode)) {
        newValue = 'dark';
      } else {
        newValue = 'light';
      }

      setIsDark(newValue === 'dark' ? true : false);
    }

    document.documentElement.dataset[key] = newValue;
  }

  useEffect(() => {
    updateDocument(key, value);
  }, [value, key]);

  return [value, setValue, updateDocument, isDark];
}

export default useSettingsState;
