import React from 'react';

import { useTranslation } from 'react-i18next';


const CollectionSidebarInfoOverview = ({ handler, collections }) => {

  const { opened } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
  };

  if (!collections) return null;

  return (
    <>
      <div className="panel bg-body-tertiary border-start flex-fill mw-330 overflow-hidden">
        <div className="app">
          <div className="module modal">
            <div className="modal-header">
              <div className="modal-title h5">{t('sidebars.collection-info.title')}</div>
              <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
            </div>
            <div className="mainspace p-3">
              <dl>
                <dt>{t('sidebars.collection-info.overview-count')}</dt>
                <dd>{collections.length}</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionSidebarInfoOverview;
