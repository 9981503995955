import React, { useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { GET_COLLECTIONS } from '../../components/collections/queries';
import ISBNLookupResultSelect from '../../components/items/ISBNLookupResultSelect';
import { postData, secureHTML } from '../../utils/axiosHelper';


const ImportFromISBNBulkModal = ({ handler, collection, backdrop }) => {

  const [defaultCollectionOptions, setDefaultCollectionOptions] = useState(collection ? [{ node: collection }] : null);
  const [disabled, setDisabled] = useState(false);
  const [resolvedItems, setResolvedItems] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(collection ? { node: collection } : null);
  const [selectedItems, setSelectedItems] = useState({});
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleItemSelectionChange = (isbn, state) => {
    setSelectedItems({ ...selectedItems, [isbn]: state });
  }

  const [getMyCollections] = useLazyQuery(GET_COLLECTIONS, {
    variables: {
      name_Icontains: null,
      mine: true,
      orderBy: 'name',
    },
  });

  const asyncCollectionOptions = async (search) => {
    var response = await getMyCollections({ variables: { name_Icontains: search } });
    setDefaultCollectionOptions(response?.data?.collections?.edges || []);
    return response?.data?.collections?.edges || [];
  };

  const handleLookup = async (values) => {
    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.processing'));
    setResolvedItems([]);

    await postData('warehouse/bulk-lookup/isbn13', values, { sleep: 300 }).then(async (response) => {
      toast.update(brot, { type: "success", isLoading: false, autoClose: 1000 });

      if (response.data?.resolved_numbers && response.data.resolved_numbers.length > 0) {
        formik.setFieldValue('isbnblock', response.data.resolved_numbers.join('\n'));
      }

      setResolvedItems(response.data.items);
      setSelectedItems(Object.assign({}, ...response.data.items.map((x) => ({ [x.isbn_13]: true }))));
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 2000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const handleImport = async () => {
    const selectedISBNNumbers = Object.entries(selectedItems).filter(e => e[1] === true).map((e) => e[0]);

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.importing'));
    var values = {
      collection: selectedCollection?.node?.pk,
      isbn_13_numbers: selectedISBNNumbers,
    };

    await postData('warehouse/elements/books/', values, { sleep: 300 }).then(async (response) => {

      opened.set(false);

      toast.update(brot, {
        render: <div dangerouslySetInnerHTML={secureHTML(t('modals.settings-import-isbn-bulk.toast-success', {
          'isbn_count': selectedISBNNumbers.length,
          'new_elements': response.data?.new_elements,
          'new_items': response.data?.new_items,
        }).replaceAll('\n', '<br />&nbsp;&nbsp;'))}></div>, type: "success", isLoading: false, autoClose: 3000
      });

      ok(response.data);

    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });

  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      isbnblock: '',
      collection: '',
    },
    onSubmit: (values) => handleLookup(values),
  });

  return (
    <Modal show={opened.value} onHide={handleClose} backdrop={(disabled || backdrop) ? 'static' : true} size="lg" scrollable>
      <Modal.Header closeButton={!disabled}>
        <Modal.Title>{t('modals.settings-import-isbn-bulk.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <fieldset disabled={disabled}>
          <Form.Group className="mb-3">
            <Form.Label>{t('modals.settings-import-isbn-bulk.field-collection')}</Form.Label>
            <Form.Control
              type="hidden"
              isInvalid={formik.errors?.fields?.collection}
            />
            <AsyncSelect
              cacheOptions
              className="react-select-container"
              classNamePrefix="react-select"
              defaultOptions={defaultCollectionOptions}
              getOptionLabel={(e) => e.node.name}
              getOptionValue={(e) => e.node.pk}
              loadOptions={asyncCollectionOptions}
              name="collection"
              onChange={(o) => { setSelectedCollection(o); formik.setFieldValue("collection", o?.node || null) }}
              placeholder={t('modals.settings-import-isbn-bulk.field-collection-placeholder')}
              value={selectedCollection}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors?.fields?.collection}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t('modals.settings-import-isbn-bulk.field-isbnblock')}</Form.Label>
            <Form.Control
              name="isbnblock"
              onChange={formik.handleChange}
              as="textarea"
              rows={4}
              value={formik.values.isbnblock}
              isInvalid={formik.errors?.fields?.isbnblock}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors?.fields?.isbnblock}
            </Form.Control.Feedback>
          </Form.Group>
          {resolvedItems && resolvedItems.length > 0 && (
            <>
              <Form.Group className="mb-1">
                <Form.Label>{t('modals.settings-import-isbn-bulk.field-lookup-result')}: {t('modals.settings-import-isbn-bulk.field-lookup-result-info', { item_count: resolvedItems.length, selected_count: Object.entries(selectedItems).filter(e => e[1] === true).length })}</Form.Label>
              </Form.Group>
              <Row>
                {resolvedItems.map((isbnLookup, index) => (
                  <Col md={6} className="pb-2" key={index}>
                    <ISBNLookupResultSelect key={index} result={isbnLookup} handleItemSelectionChange={handleItemSelectionChange} selectedItems={selectedItems} />
                  </Col>
                ))}
              </Row>
            </>
          )}
        </fieldset>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
        {!Object.entries(selectedItems).find(e => e[1] === true) && <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.lookup')}</Button>}
        {Object.entries(selectedItems).find(e => e[1] === true) && <Button variant="secondary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.lookup')}</Button>}
        {Object.entries(selectedItems).find(e => e[1] === true) && <Button variant="primary" onClick={handleImport} disabled={disabled}>{t('modals.settings-import-isbn-bulk.btn-import', { selected: Object.entries(selectedItems).filter(e => e[1] === true).length })}</Button>}
      </Modal.Footer>
    </Modal>
  );
};

export default ImportFromISBNBulkModal;
