import React, { useEffect } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import teamwork from '../../../assets/img/illustrations/teamwork.png';
import SignIn from '../../../components/auth/SignIn';
import useAuth from '../../../hooks/useAuth';


const Login = () => {
  const location = useLocation();

  const justActivated = location?.state?.justActivated;
  const justChanged = location?.state?.justChanged;
  const justResetEmail = location?.state?.justResetEmail;
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();

  const metaTags = [
    { name: 'description', content: t('pages.login.meta-description') },
  ];

  const linkTags = [
    { rel: 'canonical', href: `${process.env.REACT_APP_SELF}${location.pathname}` },
  ];

  useEffect(() => {
    if (justChanged) {
      toast.success(t('public.auth.toast-pw-changed-successfully'), { autoClose: 5000 });
    }
  }, [justChanged, t]);

  useEffect(() => {
    if (justActivated) {
      toast.success(t('public.auth.toast-activated-successfully'), { autoClose: 5000 });
    }
  }, [justActivated, t]);

  useEffect(() => {
    if (justResetEmail) {
      toast.success(t('public.auth.toast-reset-email-successfully'), { autoClose: 5000 });
    }
  }, [justResetEmail, t]);

  if (isAuthenticated) {
    return <Navigate to="/private" replace />
  }

  return (
    <>
      <Helmet title={t('pages.login.headline')} meta={metaTags} link={linkTags} />

      <Container>
        <Row style={{ margin: '100px 0 150px 0' }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 4, offset: 1 }} className="pb-5">
            <h1 className="text-center">{t('pages.login.headline')}</h1>
            <p className="text-center lead text-muted">{t('pages.login.lead')}</p>
            <br />
            <SignIn />
          </Col>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} className="pb-5">
            <img src={teamwork} alt="Teamwork" className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </>
  );

}

export default Login;
