import React, { useEffect, useRef, useState } from 'react';

import { useApolloClient, useLazyQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { Folder2Open, Trash, X } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GET_GROUP_FOR_EDITING } from '../../components/groups/queries';
import { putData } from '../../utils/axiosHelper';
import { basename } from '../../utils/formatter';
import GroupModalDelete from './ModalDelete';


const GroupModalEdit = ({ handler, original }) => {

  const [disabled, setDisabled] = useState(false);
  const [group, setGroup] = useState();
  const cardImageRef = useRef();
  const client = useApolloClient();
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const [initialData, setInitialData] = useState({
    cardImageName: basename(original.cardImage || t('forms.values.undefined')),
    default_type: '',
    delete_card_image: false,
    description: original.description,
    name: original.name,
    new_card_image: null,
    pk: original.pk,
  });

  const [getGroupForEditing] = useLazyQuery(GET_GROUP_FOR_EDITING, {
    variables: { groupId: original.pk },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setGroup(data.groupForEditing);
      setInitialData({
        ...data.groupForEditing,
        cardImageName: basename(data.groupForEditing.cardImage || t('forms.values.undefined')),
      });
    },
  });

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleDelete = () => {
    deleteModalHandler.opened.set(true);
  };

  const handleEdit = async (values) => {

    var formData = { ...values };

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.loading'));
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    await putData(`network/groups/${group.pk}/`, formData, { config }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.group-edit.updated-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      await client.clearStore();
      ok();
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const toggleCardImageDeletion = () => {
    formik.setFieldValue('delete_card_image', !formik.values.delete_card_image);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    onSubmit: (values) => handleEdit(values),
  });

  const deleteModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      opened.set(false);
      ok('destroyed');
    },
  };

  useEffect(() => { getGroupForEditing(); }, [original.id, getGroupForEditing]);

  if (!group) {
    return null;
  }

  return (
    <>
      {deleteModalHandler.opened.value && <GroupModalDelete handler={deleteModalHandler} group={group} />}
      <Modal show={opened.value} onHide={handleClose} size="lg" backdrop={disabled ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.group-edit.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={disabled}>
            <Row>
              <Col md="8">
                <Row>
                  <Col md="12">
                    <Form.Group className="mb-3">
                      <Form.Label>{t('modals.group-edit.field-name')}</Form.Label>
                      <Form.Control
                        autoComplete="off"
                        isInvalid={formik.errors?.fields?.name}
                        name="name"
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors?.fields?.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>{t('modals.group-edit.field-description')}</Form.Label>
                  <Form.Control
                    name="description"
                    as="textarea"
                    rows={6}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.description}
                    isInvalid={formik.errors?.fields?.description}
                  />
                  <div className="form-text">{t('modals.group-edit.field-description-info')}</div>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group className="mb-3">
                  <Form.Label>{t('modals.group-edit.field-list-image')}</Form.Label>
                  <InputGroup>
                    {group.cardImage && !formik.values.new_card_image && (
                      <Button
                        variant="outline-secondary"
                        className="px-1"
                        onClick={toggleCardImageDeletion}
                      >
                        <Trash />
                      </Button>
                    )}
                    <Form.Control
                      className={formik.values.delete_card_image ? "text-decoration-line-through" : ""}
                      value={formik.values.new_card_image?.name || formik.values.cardImageName}
                      isInvalid={formik.errors?.fields?.card_image}
                      disabled
                    />
                    {formik.values.new_card_image && (
                      <Button
                        variant="outline-secondary"
                        className="px-1"
                        onClick={() => { cardImageRef.current.value = null; formik.setFieldValue('new_card_image', null) }}
                      >
                        <X />
                      </Button>
                    )}
                    <label className="file-btn btn btn-primary px-2">
                      <input
                        accept="image/*"
                        id="field-new_card_image"
                        ref={cardImageRef}
                        name="new_card_image"
                        onChange={(event) => formik.setFieldValue('new_card_image', event.currentTarget.files[0])}
                        style={{ position: 'absolute', top: -10000 }}
                        type="file"
                      />
                      <Folder2Open />
                    </label>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.card_image}
                  </Form.Control.Feedback>
                </Form.Group>
                {group.cardImageThumbnail && !formik.values.delete_card_image && !formik.values.new_card_image && (
                  <Form.Group className="mb-3">
                    <Form.Label>{t('modals.group-edit.field-list-image-preview')}</Form.Label>
                    <img
                      alt={t('modals.group-edit.field-list-image-preview')}
                      className="img-fluid rounded border"
                      src={`${process.env.REACT_APP_MEDIA_URL}${group.cardImageThumbnail}`}
                    />
                  </Form.Group>
                )}
                {formik.values.new_card_image && (
                  <Form.Group className="mb-3">
                    <Form.Label>{t('modals.group-edit.field-new-list-image-preview')}</Form.Label>
                    <img
                      alt={t('modals.group-edit.field-new-list-image-preview')}
                      className="img-fluid rounded border"
                      src={URL.createObjectURL(formik.values.new_card_image)}
                    />
                  </Form.Group>
                )}
                {!formik.values.new_card_image && (!group.cardImageThumbnail || formik.values.delete_card_image) && formik.values.defaultType?.cardImageThumbnail && (
                  <Form.Group className="mb-3">
                    <Form.Label>{t('modals.group-edit.field-type-fallback-image-preview')}</Form.Label>
                    <img
                      alt={t('modals.group-edit.field-type-fallback-image-preview')}
                      className="img-fluid rounded border"
                      src={`${process.env.REACT_APP_MEDIA_URL}${formik.values.defaultType?.cardImageThumbnail}`}
                    />
                  </Form.Group>
                )}
              </Col>
            </Row>
          </fieldset>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Button variant="danger" className="me-auto" onClick={handleDelete} disabled={disabled}>{t('forms.actions.delete')}</Button>
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.update')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GroupModalEdit;
