import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';


const ChangePasswordModal = ({ handler, user }) => {

  const [disabled, setDisabled] = useState(false);
  const { opened, ok, backdrop, cancel } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleEdit = async (values) => {

    var formData = { ...values };

    setDisabled(true);
    let brot = toast.loading(t('widgets.toast.loading'));

    await postData(`auth/user/change_password/`, formData).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.change-password.updated-successfully'), type: 'success', autoClose: 1200, isLoading: false });
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      new_password_confirmation: '',
      new_password: '',
      old_password: '',
      pk: user.pk,
    },
    onSubmit: (values) => handleEdit(values),
  });

  return (
    <>
      <Modal show={opened.value} onHide={handleClose} backdrop={backdrop || disabled ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.change-password.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={disabled}>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.change-password.field-old-password')}</Form.Label>
              <Form.Control
                autoComplete="off"
                isInvalid={formik.errors?.fields?.old_password}
                name="old_password"
                onChange={formik.handleChange}
                type="password"
                value={formik.values.old_password}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.old_password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.change-password.field-new-password')}</Form.Label>
              <Form.Control
                autoComplete="off"
                isInvalid={formik.errors?.fields?.new_password}
                name="new_password"
                onChange={formik.handleChange}
                type="password"
                value={formik.values.new_password}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.new_password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.change-password.field-new-password-confirmation')}</Form.Label>
              <Form.Control
                autoComplete="off"
                isInvalid={formik.errors?.fields?.new_password_confirmation}
                name="new_password_confirmation"
                onChange={formik.handleChange}
                type="password"
                value={formik.values.new_password_confirmation}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.new_password_confirmation}
              </Form.Control.Feedback>
            </Form.Group>
          </fieldset>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.update')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
