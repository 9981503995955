import React, { useCallback, useEffect, useState } from 'react';

import { useApolloClient, useLazyQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { GET_WRITEABLE_COLLECTIONS } from '../../components/collections/queries';
import { GET_ELEMENT_BY_ID } from '../../components/elements/queries';
import { patchData } from '../../utils/axiosHelper';


const ElementModalMove = ({ handler, elementId, collectionSuggestions = null }) => {

  const [disabled, setDisabled] = useState(true);
  const [element, setElement] = useState();
  const [suggestedCollections, setSuggestedCollections] = useState([]);
  const [, setSelectedableCollectionOptions] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState([]);
  const client = useApolloClient();
  const { opened, ok, cancel, backdrop } = handler;
  const { t } = useTranslation();

  const getSuggestedCollections = useCallback(() => {

    if (!element) return [];

    let collectionsOfInterest = [];

    collectionsOfInterest.push({ node: element.collection });

    if (collectionSuggestions) {
      collectionSuggestions.forEach((collectionNode) => {
        collectionsOfInterest.push(collectionNode);
      });
    }

    setSuggestedCollections(collectionsOfInterest);
  }, [element, collectionSuggestions]);

  const [initialData, setInitialData] = useState({
    element: element?.pk,
    collection: element?.collection.pk,
    copy: false,
  });

  const [getElement, { loading }] = useLazyQuery(GET_ELEMENT_BY_ID, {
    variables: { elementId: elementId },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      let element = data.elementById;

      setElement(element);
      setSelectedableCollectionOptions(element.collection ? [{ node: element.collection }] : null);
      setInitialData({
        element: element.pk,
        collection: element.collection,
        copy: false,
      });
      setSelectedCollection({ node: element.collection });
      setDisabled(false);
    },
  });

  useEffect(() => { if (elementId) { getElement(); } }, [elementId, getElement]);
  useEffect(() => { if (element) { getSuggestedCollections(); } }, [element, getSuggestedCollections]);

  const handleClose = () => {
    opened.set(false);
    if (cancel) {
      cancel();
    }
  };

  const [getMyCollections] = useLazyQuery(GET_WRITEABLE_COLLECTIONS, {
    variables: {
      name_Icontains: null,
    },
  });

  const handleMove = async (values) => {

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.deleting'));

    let formData = { ...values };
    formData.collection = formData.collection?.pk;

    await patchData(`warehouse/elements/${element.pk}/move/`, formData, { sleep: 500 }).then(async (response) => {
      if (response.data.copy) {
        toast.update(brot, { render: <Trans i18nKey="modals.element-move.copied-successfully" values={{ elementName: response.data.item.name, collectionName: response.data.destination.name, collectionPk: '789' }}><b>123</b><Link to={`/private/collections/${response.data.destination.pk}`}><b>collection name</b></Link></Trans>, type: 'success', isLoading: false, autoClose: 5000 });
      } else {
        toast.update(brot, { render: <Trans i18nKey="modals.element-move.moved-successfully" values={{ elementName: response.data.item.name, collectionName: response.data.destination.name, collectionPk: '789' }}><b>123</b><Link to={`/private/collections/${response.data.destination.pk}`}><b>collection name</b></Link></Trans>, type: 'success', isLoading: false, autoClose: 5000 });
      }

      opened.set(false);
      await client.clearStore();
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    onSubmit: (values) => handleMove(values),
  });

  const asyncCollectionOptions = useCallback(async (search) => {
    var response = await getMyCollections({ variables: { name_Icontains: search } });
    setSelectedableCollectionOptions(response?.data?.writeableCollections?.edges || [{ node: element?.collection }]);
    return response?.data?.writeableCollections?.edges || [];
  }, [getMyCollections, element?.collection, setSelectedableCollectionOptions]);

  return (
    <Modal show={opened.value} onHide={handleClose} backdrop={disabled || loading || backdrop ? 'static' : true}>
      <Modal.Header closeButton={!disabled}>
        <Modal.Title>{t('modals.element-move.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <fieldset disabled={disabled}>
          <Form.Group className="mb-3">
            <Form.Label>{t('modals.element-move.field-element')}</Form.Label>
            <Form.Control
              autoComplete="off"
              isInvalid={formik.errors?.fields?.element}
              type="text"
              defaultValue={element?.item?.name}
              disabled
              readOnly
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors?.fields?.element}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t('modals.element-move.field-collection')}</Form.Label>
            <Form.Control
              type="hidden"
              isInvalid={formik.errors?.fields?.collection}
            />
            <AsyncSelect
              cacheOptions
              defaultOptions={suggestedCollections}
              getOptionLabel={(e) => e.node.name}
              getOptionValue={(e) => e.node.pk}
              isClearable={true}
              loadOptions={asyncCollectionOptions}
              name="collection"
              onChange={(o) => { setSelectedCollection(o); formik.setFieldValue('collection', o?.node || null) }}
              placeholder={t('modals.element-move.field-collection-placeholder')}
              value={selectedCollection}
              className="react-select-container"
              classNamePrefix="react-select"
              styles={{
                control: styles => ({
                  ...styles,
                  borderColor: formik.errors?.fields?.collection ? '#dc3545' : styles.borderColor,
                  '&:hover': {
                    borderColor: formik.errors?.fields?.collection ? '#dc3545' : styles['&:hover'].borderColor,
                  }
                })
              }}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors?.fields?.collection}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              type="switch"
              id="mode-switch"
              checked={formik.values.copy === true}
              onChange={(event) => formik.setFieldValue('copy', event.target.checked)}
              label={t('modals.element-move.field-copy')}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors?.fields?.name}
            </Form.Control.Feedback>
          </Form.Group>

        </fieldset>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
        <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t(formik.values.copy ? 'forms.actions.copy' : 'forms.actions.move')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ElementModalMove;
