import React from 'react';

import { Container } from 'react-bootstrap';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ContactForm from '../../../components/public/ContactForm';


const Contact = () => {

  const { t } = useTranslation();
  const location = useLocation();

  const metaTags = [
    { name: 'description', content: t('pages.meta.contact.meta-description') },
  ];

  const linkTags = [
    { rel: 'canonical', href: `${process.env.REACT_APP_SELF}${location.pathname}` },
  ];

  return (
    <>
      <Helmet title={t('pages.meta.contact.headline')} meta={metaTags} link={linkTags} />

      <GoogleReCaptchaProvider
        useRecaptchaNet
        reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY}
        scriptProps={{ async: true, defer: true, appendTo: 'body' }}
      >

        <Container>
          <ContactForm />
        </Container>
      </GoogleReCaptchaProvider>
    </>
  );
}

export default Contact;
