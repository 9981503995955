import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';


const ElementModalPrint = ({ handler, element }) => {

  const [disabled, setDisabled] = useState(false);
  const { opened, cancel } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handlePrint = async (values) => {

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.processing'));

    await postData(`warehouse/elements/${element.pk}/print/`, values, { config: { responseType: 'blob' } }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.element-print.print-finished'), type: 'success', isLoading: false, autoClose: 2000 });
      startDownload(response);
    }).catch(async (error) => {
      let responseObj = JSON.parse(await error.response.data.text());
      formik.setErrors({ fields: responseObj });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });

  };

  const startDownload = (response) => {
    let contentDisposition = response.headers['content-disposition'];
    let filename = contentDisposition.split(';')[1].split('=')[1].replace('"', '').replace('"', '');

    const fileURL = window.URL.createObjectURL(response.data);
    let alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = filename;
    alink.click();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pk: element.pk,
      include_issues: false,
      include_comments: false,
    },
    onSubmit: (values) => handlePrint(values),
  });

  return (
    <Modal show={opened.value} onHide={handleClose} backdrop={disabled ? 'static' : true}>
      <Modal.Header closeButton={!disabled}>
        <Modal.Title>{t('modals.element-print.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <fieldset disabled={disabled}>
          <Form.Group>
            <Form.Check
              checked={formik.values.include_issues}
              id="include_issues"
              label={t('modals.element-print.field-include-issues')}
              onChange={(event) => formik.setFieldValue('include_issues', event.target.checked)}
              type="checkbox"
            />
            <Form.Control.Feedback type="invalid">{formik.errors?.fields?.include_issues}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Check
              checked={formik.values.include_comments}
              id="include_comments"
              label={t('modals.element-print.field-include-comments')}
              onChange={(event) => formik.setFieldValue('include_comments', event.target.checked)}
              type="checkbox"
            />
            <Form.Control.Feedback type="invalid">{formik.errors?.fields?.include_comments}</Form.Control.Feedback>
          </Form.Group>
        </fieldset>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
        <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.print')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ElementModalPrint;
