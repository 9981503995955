import React, { useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';


const GroupModalAdd = ({ handler, parent }) => {

  const [disabled, setDisabled] = useState(false);
  const client = useApolloClient();
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleSave = async (values) => {
    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.saving'));

    await postData('network/groups/', values, { sleep: 500 }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.group-create.created-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      await client.clearStore();
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      parent: parent?.pk,
    },
    onSubmit: (values) => handleSave(values),
  });

  return (
    <Modal show={opened.value} onHide={handleClose} backdrop={disabled ? 'static' : true}>
      <Modal.Header closeButton={!disabled}>
        <Modal.Title>{t('modals.group-create.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <fieldset disabled={disabled}>
          <Form.Group className="mb-3">
            <Form.Label>{t('modals.group-create.field-name')}</Form.Label>
            <Form.Control
              name="name"
              onChange={formik.handleChange}
              autoComplete="off"
              type="text"
              value={formik.values.name}
              isInvalid={formik.errors?.fields?.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors?.fields?.name}
            </Form.Control.Feedback>
          </Form.Group>
        </fieldset>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
        <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.create')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GroupModalAdd;
