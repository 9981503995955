import React, { useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { faFloppyDisk, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';


const ElementAmountInputGroup = ({ element, setDisabled, setElement, disabled }) => {

  const { t } = useTranslation();
  const client = useApolloClient();
  const [amount, setAmount] = useState(parseInt(element?.amount || 0));

  const updateAmount = async (summand) => {
    setAmount(parseInt(amount) + parseInt(summand));
  };

  const save = async () => {

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.saving'));

    await postData(`warehouse/elements/${element.pk}/amount/`, { element: element.pk, amount: amount }).then(async (response) => {
      setElement({ ...element, amount: response.data.amount })
      toast.dismiss(brot);
      await client.clearStore();
    }).catch((error) => {
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status} ${error.response.data.element ? ` - ${error.response.data.element}` : ''}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });

  };

  return (
    <InputGroup size="sm">
      <Form.Control
        style={{ minWidth: '100px', width: '150px', maxWidth: '150px' }}
        value={amount}
        type="number"
        min="0"
        max="10000"
        step="1"
        onChange={(e) => setAmount(e.target.value)}
      />
      <Button variant="outline-secondary" onClick={() => updateAmount(-1)} id="button-decrease" disabled={disabled || amount <= 0}>
        <FontAwesomeIcon icon={faMinus} fixedWidth />
      </Button>
      <Button variant="outline-secondary" onClick={() => updateAmount(1)} id="button-increase" disabled={disabled}>
        <FontAwesomeIcon icon={faPlus} fixedWidth />
      </Button>
      {`${amount}` !== `${element?.amount}` && (
        <Button variant="primary" onClick={save} id="button-save">
          <FontAwesomeIcon icon={faFloppyDisk} fixedWidth />
        </Button>
      )}
    </InputGroup>
  );

};

export default ElementAmountInputGroup;
