import React, { useState } from 'react';

import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import UserAvatar from '../../components/auth/UserAvatar';
import ItemModalAddUserPermission from './ModalAddUserPermission';
import ItemModalRemoveUserPermission from './ModalRemoveUserPermission';


const ItemSidebarPermission = ({ handler, item, refetch }) => {

  const { opened } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
  };

  const removeUserPermission = (permission) => {
    removeUserPermissionModalHandler.opened.set(permission);
  };

  const removeUserPermissionModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  const addUserPermissionModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      refetch();
    },
  };

  return (
    <>
      {addUserPermissionModalHandler.opened.value && <ItemModalAddUserPermission handler={addUserPermissionModalHandler} item={item} />}
      {removeUserPermissionModalHandler.opened.value && <ItemModalRemoveUserPermission handler={removeUserPermissionModalHandler} item={item} permission={removeUserPermissionModalHandler.opened.value} />}
      <div className="panel bg-body-tertiary border-start flex-fill mw-330 overflow-hidden">
        <div className="app">
          <div className="module modal">
            <div className="modal-header">
              <div className="modal-title h5">{t('sidebars.item-permissions.title')}</div>
              <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
            </div>
            <div className="mainspace p-3">
              <dl>
                <dt className="mb-3">{t('sidebars.item-permissions.user-access')}</dt>
                <dd className="mb-4">
                  {item?.permissions?.map((userPermission, index) => (
                    <div className="d-flex mb-2" key={index}>
                      <div className="flex-shrink-0">
                        <UserAvatar user={userPermission} />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <b>{userPermission.repr}</b>
                        <div>{userPermission.getRoleDisplay}</div>
                      </div>
                      {userPermission.role !== 'A_1' && (
                        <div className="flex-shrink-0">
                          <Button variant="link" onClick={() => removeUserPermission(userPermission)}>
                            <FontAwesomeIcon icon={faTrashCan} />
                          </Button>
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="d-grid gap-2">
                    <Button variant="secondary" className="mt-2" onClick={() => addUserPermissionModalHandler.opened.set(true)}>
                      {t('sidebars.item-permissions.add-user')}
                    </Button>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemSidebarPermission;
