import React, { useState } from 'react';

import { faArrowTurnDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import UserAvatar from '../../components/auth/UserAvatar';
import useAuth from '../../hooks/useAuth';
import { postData } from '../../utils/axiosHelper';


const ItemMessageBox = ({ instance, endpoint, visible = true, refetch = null }) => {

  const [disabled, setDisabled] = useState();
  const { t } = useTranslation();
  const { user } = useAuth();

  const save = async (values) => {

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.saving'));

    await postData(endpoint, values, { sleep: 500 }).then(async (response) => {
      toast.update(brot, { render: t('widgets.message-box.created-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      if (refetch) refetch();
      formik.setFieldValue('message', '');
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      message: '',
      ident: instance.pk,
    },
    onSubmit: (values) => save(values),
  });

  if (visible !== true) return null;
  if (!instance) return <div className="text-danger">Item undefined</div>;
  if (!endpoint || endpoint === '') return <div className="text-danger">Endpoint for MessageBox undefined!</div>;

  return (
    <>
      <div className="d-flex">
        <div className="flex-shrink-0">
          <UserAvatar user={user} className="rounded border" width="39" height="39" />
        </div>
        <div className="flex-grow-1 ms-1">
          <InputGroup className="mb-3">
            <Form.Control
              as="textarea"
              rows="1"
              placeholder={t('widgets.message-box.message-placeholder')}
              isInvalid={formik.errors?.fields?.message}
              autoComplete="off"
              name="message"
              onChange={formik.handleChange}
              value={formik.values.message}
              style={{ resize: 'none' }}
            />
            <Button variant="primary" id="button-submit-comment" onClick={formik.handleSubmit} disabled={disabled || formik.values.message === ''}>
              <FontAwesomeIcon icon={faArrowTurnDown} rotation={90} fixedWidth />
            </Button>
            <Form.Control.Feedback type="invalid">
              {formik.errors?.fields?.message}
            </Form.Control.Feedback>
          </InputGroup>
          <Form.Group className="mb-3"></Form.Group>
        </div>
      </div>
    </>
  );

};

export default ItemMessageBox;
