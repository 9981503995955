import React from 'react';

import { faBox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { LIST_VIEW_MODES } from '../basic/Classes';


const ItemCard = ({ item, listViewMode, selected }) => {

  const navigate = useNavigate();

  const openItem = (itemId) => {
    navigate(`/private/items/${itemId}`);
  };

  if (!listViewMode || listViewMode === LIST_VIEW_MODES.Vertical)
    return (
      <>
        <Card className="mb-4 item card-warehouse" data-last-item={selected} role="button" onClick={() => openItem(item.pk)}>
          {item.cardImageThumbnail && <img className="card-img-top" loading="lazy" src={`${process.env.REACT_APP_MEDIA_URL}${item.cardImageThumbnail}`} alt={item.name} />}
          {!item.cardImageThumbnail && item.typeOf?.itemImageThumbnail && <img className="card-img-top" loading="lazy" src={`${process.env.REACT_APP_MEDIA_URL}${item.typeOf.itemImageThumbnail}`} alt={item.name} />}
          {!item.cardImageSquare && !item.typeOf?.cardImageSquare && <FontAwesomeIcon icon={faBox} size="lg" className="my-5 py-1 display-1 text-warning" />}
          <Card.Body className={`p-2 rounded-bottom ${selected ? 'text-bg-primary' : ''}`}>{item.name}</Card.Body>
        </Card>
      </>
    );

  if (listViewMode && listViewMode === LIST_VIEW_MODES.Horizontal)
    return (
      <>
        <Card className="item h-100 card-warehouse" data-last-item={selected} role="button" onClick={() => openItem(item.pk)}>
          {item.cardImageSquare && <img className="card-img-top ratio ratio-1" loading="lazy" src={`${process.env.REACT_APP_MEDIA_URL}${item.cardImageSquare}`} alt={item.name} />}
          {!item.cardImageSquare && item.typeOf?.cardImageSquare && <img className="card-img-top ratio ratio-1" loading="lazy" src={`${process.env.REACT_APP_MEDIA_URL}${item.typeOf.cardImageSquare}`} alt={item.name} />}
          {!item.cardImageSquare && !item.typeOf?.cardImageSquare && <FontAwesomeIcon icon={faBox} size="lg" className="my-5 py-1 display-1 text-warning" />}
          <Card.Body className={`p-2 rounded-bottom ${selected ? 'text-bg-primary' : ''}`}>{item.name}</Card.Body>
        </Card>
      </>
    );

  if (listViewMode && listViewMode === LIST_VIEW_MODES.Square)
    return (
      <>
        <Card className="item rounded-4 h-100 card-warehouse" data-last-item={selected} role="button" onClick={() => openItem(item.pk)}>
          {item.cardImageSquare && <img className="card-img-top rounded-4 ratio ratio-1" loading="lazy" src={`${process.env.REACT_APP_MEDIA_URL}${item.cardImageSquare}`} alt={item.name} />}
          {!item.cardImageSquare && item.typeOf?.cardImageSquare && <img className="card-img-top rounded-4 ratio ratio-1" loading="lazy" src={`${process.env.REACT_APP_MEDIA_URL}${item.typeOf.cardImageSquare}`} alt={item.name} />}
          {!item.cardImageSquare && !item.typeOf?.cardImageSquare && <FontAwesomeIcon icon={faBox} size="lg" className="my-5 py-1 display-1 text-warning" />}
        </Card>
      </>
    );

    if (listViewMode && listViewMode === LIST_VIEW_MODES.Compress)
    return (
      <>
        <Card className="item rounded-3 h-100 card-warehouse" data-last-item={selected} role="button" onClick={() => openItem(item.pk)}>
          {item.cardImageSquare && <img className="card-img-top rounded-3 ratio ratio-1 object-fit-cover" loading="lazy" src={`${process.env.REACT_APP_MEDIA_URL}${item.cardImageSquare}`} alt={item.name} height="64" />}
          {!item.cardImageSquare && item.typeOf?.cardImageSquare && <img className="card-img-top rounded-3 ratio ratio-1 object-fit-cover" loading="lazy" src={`${process.env.REACT_APP_MEDIA_URL}${item.typeOf.cardImageSquare}`} alt={item.name} height="64" />}
          {!item.cardImageSquare && !item.typeOf?.cardImageSquare && <FontAwesomeIcon icon={faBox} size="lg" className="my-5 py-1 display-1 text-warning" />}
        </Card>
      </>
    );
};

export default ItemCard;
