import React from 'react';

import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import meditation from '../../assets/img/illustrations/meditation.png';


const EmptyPanel = ({ reloadTrigger, addEntry, addBook, addFood, addImage, maximize = true, large = false, imgWidth = 300, showNoActionMsg = true }) => {

  const { t } = useTranslation();
  const hasAction = [!!reloadTrigger, !!addEntry, !!addBook, !!addImage].find(e => !!e);

  return (
    <div className={`${maximize ? 'd-flex w-100 h-100' : ''} ${large && !maximize ? 'd-flex w-100 h-500px' : ''}`}>
      <div className="center-hint align-self-center flex-grow-1">
        <img src={meditation} alt={t('widgets.empty-panel.image-alt-meditation')} className="mw-100" width={imgWidth} style={{ opacity: 0.3 }} />
        <div className="fs-6" style={{ opacity: 0.3 }}>
          {hasAction !== true && showNoActionMsg && t('widgets.empty-panel.msg-has-no-action')}
          {hasAction === true && t('widgets.empty-panel.msg-has-action')}
        </div>
        {addEntry && (<Button variant="link" onClick={() => addEntry(true)} className="text-decoration-none px-1">{t('widgets.empty-panel.btn-add-entry')}</Button>)}
        {addImage && (<Button variant="link" onClick={() => addImage(true)} className="text-decoration-none px-1">{t('widgets.empty-panel.btn-add-image')}</Button>)}
        {addFood && (<Button variant="link" onClick={() => addFood(true)} className="text-decoration-none px-1">{t('widgets.empty-panel.btn-add-food')}</Button>)}
        {addBook && (<Button variant="link" onClick={() => addBook(true)} className="text-decoration-none px-1">{t('widgets.empty-panel.btn-add-book')}</Button>)}
        {reloadTrigger && (<Button variant="link" onClick={() => reloadTrigger()} className="text-decoration-none px-1">{t('widgets.empty-panel.btn-reload')}</Button>)}
      </div>
    </div>
  );
};

export default EmptyPanel;


