import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import EmptyPanel from '../../components/basic/EmptyPanel';
import { GET_COLLECTIONS } from '../../components/collections/queries';
import { sleep } from '../../utils/sleep';


const WidgetCollectionTable = ({ variant = '' }) => {

  const [collections, setCollections] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [getElements] = useLazyQuery(GET_COLLECTIONS, {
    variables: {
      first: 10,
      orderBy: ['-createdAt'],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: async (data) => {
      // await sleep(Math.random() * (1200 - 300) + 300);
      await sleep(200);
      setCollections(data.collections.edges);
      if (initialized === false) {
        setInitialized(true);
      }
    },
  });

  const openCollection = (collection) => {
    navigate(`/private/collections/${collection.pk}`);
  };

  useEffect(() => { getElements(); }, [getElements]);

  return (
    <Table bordered striped responsive hover size="sm" className="mb-0">
      <thead>
        <tr>
          <td className="text-center" width="32">
            <FontAwesomeIcon icon={faImage} />
          </td>
          <td>{t('dashboard.widgets.collection-table.col-name')}</td>
          <td width="100">{t('dashboard.widgets.collection-table.col-created_at')}</td>
        </tr>
      </thead>
      <tbody>
        {!initialized && collections.length === 0 && (
          <tr>
            <td colSpan="100" className="text-center py-5">
              <Spinner animation="border" variant={variant} role="status" className="my-5">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </td>
          </tr>
        )}
        {initialized && collections.length === 0 && (
          <tr>
            <td colSpan="100" className="text-center py-5">
              <EmptyPanel showNoActionMsg={false} imgWidth={200} />
            </td>
          </tr>
        )}
        {collections.map((collection, index) => (
          <tr key={index} role="button" onClick={() => openCollection(collection.node)}>
            <td className="text-center">
              {collection.node.icon && (
                <img
                  alt={collection.node.name}
                  title={collection.node.name}
                  className="rounded-circle"
                  src={`${process.env.REACT_APP_MEDIA_URL}${collection.node.icon}`}
                  width="16"
                  height="16"
                  style={{ maxHeight: '16px', minHeight: '16px' }}
                />
              )}
            </td>
            <td>{collection.node.name}</td>
            <td className="text-center"><Moment locale={i18n.resolvedLanguage} date={collection.node.createdAt} format="L" /></td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default WidgetCollectionTable;
