import React from 'react';


const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const resizeHandle = () => setWidth(window.innerWidth);
    window.addEventListener("resize", resizeHandle);
    return () => window.removeEventListener("resize", resizeHandle);
  }, []);

  return { width };
}


export default useViewport;
