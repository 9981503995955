import React from 'react';

import { Button } from 'react-bootstrap';
import { Facebook, Instagram, Twitter } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import NewsletterWidget from './WidgetNewsletter';


const Footer = ({ variant = 'default', spacer = false }) => {

  const { i18n, t } = useTranslation();

  const na = (nameOfFancyService) => {
    alert(t('public.footer.na-yet', { nameOfFancyService: nameOfFancyService }));
  };

  return (
    <>
      <div className="mt-5"></div>

      {spacer && variant === 'blue' && (
        <div className="position-relative">
          <div className="shape shape-bottom shape-fluid-x svg-shim text-dark">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor"></path>
            </svg>
          </div>
        </div>
      )}

      {spacer && variant === 'parcel' && (
        <div className="position-relative">
          <div className="shape shape-bottom shape-fluid-x svg-shim fg-ci-secondary">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor"></path>
            </svg>
          </div>
        </div>
      )}

      <div className={`footer-parent container-fluid ${variant === 'blue' ? 'bg-dark text-light' : ''} ${variant === 'parcel' ? 'bg-ci-secondary text-light' : ''}`}>
        <div className="container">
          <footer className="pt-5">
            <div className="row">
              <div className="col-md-8 text-light-sub-actions">
                <div className="row mb-4 d-flex">
                  <div className="col-sm-6 col-md-6 col-lg-3 align-self-stretch pb-4">
                    <p className="h5">{t('public.footer.section-translations')}</p>
                    <ul className="nav flex-column">
                      <li className="nav-item mb-2">
                        <Button variant="link" className="nav-link p-0" role="button" onClick={() => { i18n.changeLanguage('de') }}>
                          deutsch
                        </Button>
                      </li>
                      <li className="nav-item mb-2">
                        <Button variant="link" className="nav-link p-0" role="button" onClick={() => { i18n.changeLanguage('en') }}>
                          english
                        </Button>
                      </li>
                      <li className="nav-item mb-2">
                        <Button variant="link" className="nav-link p-0" role="button" onClick={() => { i18n.changeLanguage('zh-TW') }}>
                          繁體中文
                        </Button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3 align-self-stretch pb-4">
                    <p className="h5">{t('public.footer.products')}</p>
                    <ul className="nav flex-column">
                      <li className="nav-item mb-2">
                        <Link to="/collections" className="nav-link p-0">{t('public.footer.demo-collections')}</Link>
                      </li>
                      <li className="nav-item mb-2">
                        <a href="https://apps.apple.com/us/app/lagerraum-pro-reader/id6451327902" rel="noreferrer" target="_blank" className="nav-link p-0">{t('public.footer.ios-appstore')}</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3 align-self-stretch pb-4">
                    <p className="h5">{t('public.footer.connect')}</p>
                    <ul className="nav flex-column">
                      <li className="nav-item mb-2">
                        <Link to="/meta/contact" className="nav-link p-0">{t('public.footer.contact')}</Link>
                      </li>
                      <li className="nav-item mb-2">
                        <Link to="/meta/pricing" className="nav-link p-0">{t('public.footer.pricing')}</Link>
                      </li>
                      <li className="nav-item mb-2">
                        <Link to="/meta/donate" className="nav-link p-0">{t('public.footer.donate')}</Link>
                      </li>
                      <li className="nav-item mb-2">
                        <a href={`${process.env.REACT_APP_MEDIA_URL}`} className="nav-link p-0">{t('public.footer.api')}</a>
                      </li>
                      <li className="nav-item mb-2">
                        <Link to="/blog" className="nav-link p-0">{t('public.footer.blog')}</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3 align-self-stretch pb-4">
                    <p className="h5">{t('public.footer.meta')}</p>
                    <ul className="nav flex-column">
                      <li className="nav-item mb-2">
                        <Link to="/meta/privacy" className="nav-link p-0">{t('public.footer.privacy')}</Link>
                      </li>
                      <li className="nav-item mb-2">
                        <Link to="/meta/conditions" className="nav-link p-0">{t('public.footer.conditions')}</Link>
                      </li>
                      <li className="nav-item mb-2">
                        <Link to="/meta/imprint" className="nav-link p-0">{t('public.footer.imprint')}</Link>
                      </li>
                      <li className="nav-item mb-2">
                        <Link to="/meta/faq" className="nav-link p-0">{t('public.footer.faq')}</Link>
                      </li>
                      <li className="nav-item mb-2">
                        <Link to="/meta/sources" className="nav-link p-0">{t('public.footer.sources')}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <NewsletterWidget />
              </div>
            </div>
            <div className="d-flex justify-content-between py-4 my-4 border-top">
              <p>© {new Date().getFullYear()} Lagerraum.pro</p>
              <ul className="list-unstyled d-flex">
                <li className="ms-3">
                  <span onClick={() => na('Twitter')} className={`p-0 ${variant === 'blue' ? 'text-light' : ''} ${variant === 'parcel' ? 'text-light' : ''}`}><Twitter size={24} /></span>
                </li>
                <li className="ms-3">
                  <span onClick={() => na('Instagram')} className={`p-0 ${variant === 'blue' ? 'text-light' : ''} ${variant === 'parcel' ? 'text-light' : ''}`}><Instagram size={24} /></span>
                </li>
                <li className="ms-3">
                  <span onClick={() => na('Facebook')} className={`p-0 ${variant === 'blue' ? 'text-light' : ''} ${variant === 'parcel' ? 'text-light' : ''}`}><Facebook size={24} /></span>
                </li>
              </ul>
            </div>
          </footer>
        </div>
      </div>
    </>
  )
};

export default Footer;
