import React from 'react';

import { useApolloClient } from '@apollo/client';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';


const ElementDeincreaseInputGroup = ({ element, setDisabled, setElement, disabled }) => {

  const { t } = useTranslation();
  const client = useApolloClient();

  const decreaseAmount = async () => {

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.saving'));

    await postData(`warehouse/elements/${element.pk}/decrease/`, { element: element.pk }).then(async (response) => {
      setElement({ ...element, amount: response.data.amount })
      toast.dismiss(brot);
      await client.clearStore();
    }).catch((error) => {
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status} ${error.response.data.element ? ` - ${error.response.data.element}` : ''}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });

  };

  const increaseAmount = async () => {
    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.saving'));

    await postData(`warehouse/elements/${element.pk}/increase/`, { element: element.pk }).then(async (response) => {
      setElement({ ...element, amount: response.data.amount })
      toast.dismiss(brot);
      await client.clearStore();
    }).catch((error) => {
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status} ${error.response.data.element ? ` - ${error.response.data.element}` : ''}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });

  };

  return (
    <InputGroup size="sm">
      <InputGroup.Text style={{ minWidth: '100px', width: '150px' }}>
        {element?.amount || '...'}
      </InputGroup.Text>
      <Button variant="outline-secondary" onClick={decreaseAmount} id="button-decrease" disabled={disabled}>
        <FontAwesomeIcon icon={faMinus} fixedWidth />
      </Button>
      <Button variant="outline-secondary" onClick={increaseAmount} id="button-increase" disabled={disabled}>
        <FontAwesomeIcon icon={faPlus} fixedWidth />
      </Button>
    </InputGroup>
  );

};

export default ElementDeincreaseInputGroup;
