import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import { Archive as ItemIcon } from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import EmptyPanel from '../../components/basic/EmptyPanel';
import CollectionCard from '../../components/collections/Card';
import CollectionGroupCard from '../../components/collections/CardGroup';
import CollectionModalAdd from '../../components/collections/ModalAdd';
import CollectionToolbarBrowser from '../../components/collections/ToolbarBrowser';
import { GET_BROWSER_COLLECTIONS } from '../../components/collections/queries';


const Browser = () => {

  const [collections, setCollections] = useState([]);
  const { t } = useTranslation();

  const [getCollections, { refetch, loading }] = useLazyQuery(GET_BROWSER_COLLECTIONS, {
    variables: {
      first: 1000,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setCollections(data.collections.edges);
    },
  });

  const renderBreadcrump = (showImage = true, className = 'mb-4') => {
    return (
      <Breadcrumb className={className}>
        <Breadcrumb.Item active linkAs={Link} linkProps={{ to: "/private/collections" }}>
          {showImage && <ItemIcon className="bi img-fluid rounded me-1 align-text-top" width={20} height={20} style={{maxHeight: '20px', minHeight: '20px'}} />}
          {t('pages.collections.headline')}
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  };

  const createModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      refetch();
    },
  };

  useEffect(() => { getCollections(); }, [getCollections]);

  return (
    <>
      {createModalHandler.opened.value && <CollectionModalAdd handler={createModalHandler} />}
      <Helmet title={t('pages.browser.headline')} />
      <CollectionToolbarBrowser refetch={refetch} loading={loading} showAddDialog={createModalHandler.opened.set} />
      <div className="mainspace p-4 pt-3">
        {collections?.length > 0 && collections.filter(collection => collection.node.groupAccess === true && !collection.node.parent).length > 0 && (
          <>
            <h2>{t('pages.browser.group-collections')}</h2>
            <hr className="mt-0 mb-3" />

            {renderBreadcrump()}

            <Row className="mb-4">
              {collections.filter(collection => collection.node.groupAccess === true && !collection.node.parent).map((collection, index) => (
                <Col key={index} sm={12} md={12} lg={6} xl={4} xxl={3} className='d-flex'>
                  <CollectionGroupCard collection={collection.node} />
                </Col>
              ))}
            </Row>
          </>
        )}
        {collections?.length > 0 && collections.filter(collection => collection.node.groupAccess === false).length > 0 && (
          <>
            <h2>{t('pages.browser.my-collections')}</h2>
            <hr className="mt-0 mb-3" />

            {renderBreadcrump()}

            <Row>
              {collections.filter(collection => collection.node.groupAccess === false).length > 0 && collections.filter(collection => collection.node.groupAccess === false).map((collection, index) => (
                <Col key={index} sm={12} md={12} lg={6} xl={4} xxl={3} className='d-flex'>
                  <CollectionCard collection={collection.node} />
                </Col>
              ))}
            </Row>
          </>
        )}

        {collections?.length === 0 && !loading && (
          <EmptyPanel reloadTrigger={refetch} addEntry={createModalHandler.opened.set} />
        )}

        {collections?.length === 0 && loading && (
          <div className="text-center mt-5 pt-5 fs-1 text-muted">
            <FontAwesomeIcon icon={faGear} size="lg" spin />
          </div>
        )}
      </div>
    </>
  );
};

export default Browser;
