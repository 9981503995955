import React, { useCallback, useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { Button, Form, Modal, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { getObjs, getOptions, postData } from '../../utils/axiosHelper';


const PaymentInformationModalEdit = ({ handler, user }) => {

  const [disabled, setDisabled] = useState(false);
  const [options, setOptions] = useState({});
  const { opened, ok, cancel, backdrop } = handler;
  const { t } = useTranslation();
  const baseFormData = {
    address: '',
    company: '',
    payment_method: null,
  };

  const [initialValues, setInitialValues] = useState(baseFormData);

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleEdit = async (values) => {

    var formData = { ...values };
    formData.default_type = formData.defaultType?.pk || null;

    setDisabled(true);
    let brot = toast.loading(t('widgets.toast.loading'));

    await postData('accounting/payment-data/', formData).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.payment-information-edit.updated-successfully'), type: 'success', autoClose: 1200, isLoading: false });
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const getFormOptions = useCallback(async () => {
    setOptions({});
    const response = await getOptions('accounting/payment-data/');
    if (response) {
      setOptions(response.data?.actions?.POST);
    }
  }, []);

  const getInitialValues = useCallback(async () => {
    setDisabled(true);
    const response = await getObjs('accounting/payment-data/', { sleep: 100 });
    if (response) {
      if (Array.isArray(response.data) && response.data.length > 0) {
        setInitialValues(response.data[0]);
      }
    }
    setDisabled(false);
  }, []);

  const valueFromId = (opts, id) => opts.find((o) => o.value === id);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: (values) => handleEdit(values),
  });

  useEffect(() => {
    getInitialValues();
    getFormOptions();
  }, [user.pk, getFormOptions, getInitialValues]);

  return (
    <>
      <Modal show={opened.value} onHide={handleClose} size="lg" backdrop={disabled || backdrop ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.payment-information-edit.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={disabled}>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.payment-information-edit.field-company')}</Form.Label>
              <Form.Control
                isInvalid={formik.errors?.fields?.company}
                name="company"
                onChange={formik.handleChange}
                type="text"
                value={formik.values.company}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.company}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.payment-information-edit.field-address')}</Form.Label>
              <Form.Control
                name="address"
                as="textarea"
                rows={3}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.address}
                isInvalid={formik.errors?.fields?.address}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.address}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.payment-information-edit.field-payment-method')}</Form.Label>
              <Form.Control type="hidden" isInvalid={formik.errors?.fields?.payment_method} />
              <Select
                options={options?.payment_method?.choices}
                getOptionLabel={(option) => option.display_name}
                getOptionValue={(option) => option.value}
                isSearchable={false}
                className="react-select-container"
                classNamePrefix="react-select"
                isClearable={false}
                isDisabled={disabled}
                placeholder={t('modals.payment-information-edit.field-payment-method-placeholder')}
                value={valueFromId(options?.payment_method?.choices || [], formik.values?.payment_method)}
                onChange={(opt) => formik.setFieldValue('payment_method', opt?.value || '')}
                styles={{
                  control: styles => ({
                    ...styles,
                    borderColor: formik.errors?.fields?.payment_method ? '#dc3545' : styles.borderColor,
                    '&:hover': {
                      borderColor: formik.errors?.fields?.payment_method ? '#dc3545' : styles['&:hover'].borderColor,
                    }
                  })
                }}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.payment_method}
              </Form.Control.Feedback>
            </Form.Group>
            {formik.values?.payment_method && formik.values.payment_method === 2 && (
              <>
                <Row>
                  <Col md="8">
                    <Form.Group className="mb-3">
                      <Form.Label>{t('modals.payment-information-edit.field-iban')}</Form.Label>
                      <Form.Control
                        isInvalid={formik.errors?.fields?.iban}
                        maxLength={22}
                        name="iban"
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.iban}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors?.fields?.iban}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group className="mb-3">
                      <Form.Label>{t('modals.payment-information-edit.field-bic')}</Form.Label>
                      <Form.Control
                        isInvalid={formik.errors?.fields?.bic}
                        maxLength={11}
                        name="bic"
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.bic}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors?.fields?.iban}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>{t('modals.payment-information-edit.field-bank-account-holder')}</Form.Label>
                  <Form.Control
                    isInvalid={formik.errors?.fields?.bank_account_holder}
                    maxLength={127}
                    name="bank_account_holder"
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.bank_account_holder}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.bank_account_holder}
                  </Form.Control.Feedback>
                </Form.Group>
              </>
            )}
          </fieldset>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.update')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaymentInformationModalEdit;
