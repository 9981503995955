import React, { useState } from 'react';

import { faFloppyDisk, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, InputGroup } from 'react-bootstrap';
import IssueModalAdd from '../../components/elements/ModalAddIssue';


const ElementAddIssueInputGroup = ({ element, refetch }) => {

  const [amount, setAmount] = useState(null);

  const updateAmount = async (summand) => {
    setAmount(amount + summand);
  };

  const addIssueModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      setAmount(0);
      refetch();
    },
  };

  if (!element) return null;

  return (
    <>
      {addIssueModalHandler.opened.value && <IssueModalAdd handler={addIssueModalHandler} element={element} initialAmount={amount} />}
      <InputGroup size="sm">
        <InputGroup.Text id="inputGroup-sizing-sm" style={{ minWidth: '100px', width: '150px' }}>
          {amount || '...'}
        </InputGroup.Text>
        <Button variant="outline-secondary" onClick={() => updateAmount(-1)} id="button-decrease" disabled={amount <= 0}>
          <FontAwesomeIcon icon={faMinus} fixedWidth />
        </Button>
        <Button variant="outline-secondary" onClick={() => updateAmount(1)} id="button-increase">
          <FontAwesomeIcon icon={faPlus} fixedWidth />
        </Button>
        {amount > 0 && (
          <Button variant="primary" onClick={() => addIssueModalHandler.opened.set(true)} id="button-save">
            <FontAwesomeIcon icon={faFloppyDisk} fixedWidth />
          </Button>
        )}
      </InputGroup>
    </>
  );

};

export default ElementAddIssueInputGroup;
