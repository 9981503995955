import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';


const ContactForm = () => {

  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation();

  const submitForm = async (values) => {

    if (!executeRecaptcha) {
      toast.error(t('pages.login.toast-recaptcha-error'), { autoClose: 2500 });
      return;
    }

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.processing'));
    var formData = { ...values };

    formData['recaptcha_response'] = await executeRecaptcha('dynamicAction');

    await postData('contact/', formData, { sleep: 500 }).then(async (response) => {
      navigate('/meta/contact/send');
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.error(`${t('widgets.toast.error')} ${error.response.status}`, { autoClose: 2000 });
    }).finally(() => {
      setDisabled(false);
      toast.dismiss(brot);
    });

  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      accepted_tac: false,
      email: '',
      first_name: '',
      last_name: '',
      message: '',
      phone: '',
      subject: '',
    },
    onSubmit: (values) => submitForm(values),
  });

  return (
    <>
      <h1>{t('pages.meta.contact.headline')}</h1>
      <p className="lead">{t('pages.meta.contact.lead')}</p>
      <hr className="mb-5" />
      <h2 className="text-muted mb-3">{t('pages.meta.contact.contact-data')}</h2>
      <Row className="mb-4">
        <Col md="6">
          <Form.Group className="mb-3">
            <Form.Label>{t('pages.meta.contact.field-first-name')}</Form.Label>
            <Form.Control
              autoComplete="off"
              disabled={disabled}
              isInvalid={formik.errors?.fields?.first_name}
              name="first_name"
              onChange={formik.handleChange}
              type="text"
              value={formik.values.first_name}
            />
            <Form.Control.Feedback type="invalid">{formik.errors?.fields?.first_name}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md="6">
          <Form.Group className="mb-3">
            <Form.Label>{t('pages.meta.contact.field-last-name')}</Form.Label>
            <Form.Control
              autoComplete="off"
              disabled={disabled}
              isInvalid={formik.errors?.fields?.last_name}
              name="last_name"
              onChange={formik.handleChange}
              type="text"
              value={formik.values.last_name}
            />
            <Form.Control.Feedback type="invalid">{formik.errors?.fields?.last_name}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md="6">
          <Form.Group className="mb-3">
            <Form.Label>{t('pages.meta.contact.field-email')}</Form.Label>
            <Form.Control
              autoComplete="off"
              disabled={disabled}
              isInvalid={formik.errors?.fields?.email}
              name="email"
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
            />
            <Form.Control.Feedback type="invalid">{formik.errors?.fields?.email}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md="6">
          <Form.Group className="mb-3">
            <Form.Label>{t('pages.meta.contact.field-phone')}</Form.Label>
            <Form.Control
              autoComplete="off"
              disabled={disabled}
              isInvalid={formik.errors?.fields?.phone}
              name="phone"
              onChange={formik.handleChange}
              type="text"
              value={formik.values.phone}
            />
            <Form.Control.Feedback type="invalid">{formik.errors?.fields?.phone}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <h2 className="text-muted mb-3">{t('pages.meta.contact.your-message')}</h2>
      <Form.Group className="mb-3">
        <Form.Label>{t('pages.meta.contact.field-subject')}</Form.Label>
        <Form.Control
          autoComplete="off"
          disabled={disabled}
          isInvalid={formik.errors?.fields?.subject}
          name="subject"
          onChange={formik.handleChange}
          type="text"
          value={formik.values.subject}
        />
        <Form.Control.Feedback type="invalid">{formik.errors?.fields?.subject}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>{t('pages.meta.contact.field-message')}</Form.Label>
        <Form.Control
          as="textarea"
          autoComplete="off"
          disabled={disabled}
          isInvalid={formik.errors?.fields?.message}
          name="message"
          onChange={formik.handleChange}
          rows="8"
          value={formik.values.message}
        />
        <Form.Control.Feedback type="invalid">{formik.errors?.fields?.message}</Form.Control.Feedback>
      </Form.Group>
      <h2 className="mt-4 text-muted">{t('pages.meta.contact.data-protection')}</h2>
      <Form.Group className="mb-3">
        <Form.Control type="hidden" isInvalid={formik.errors?.fields?.accepted_tac} />
        <Form.Check
          checked={formik.values.accepted_tac}
          disabled={disabled}
          id="tac-switch"
          isInvalid={formik.errors?.fields?.accepted_tac}
          onChange={(event) => formik.setFieldValue('accepted_tac', event.target.checked)}
          label={
            <>
              {t('pages.meta.contact.data-protection-statement')}
              <br />
              <a target="_blank" rel="noreferrer" href="/meta/conditions" className="text-decoration-none">{t('public.auth.field-link-conditions')}</a>
              <br />
              <a target="_blank" rel="noreferrer" href="/meta/privacy" className="text-decoration-none">{t('public.auth.field-link-privacy')}</a>
            </>
          }
        />
      </Form.Group>
      <div className="text-end">
        <Button variant="primary" disabled={disabled} onClick={formik.handleSubmit}>{t('pages.meta.contact.btn-send')}</Button>
      </div>
    </>
  );
}

export default ContactForm;
