import React, { useCallback, useEffect, useState } from 'react';

import { Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CurrentPaymenInformation from '../../components/accounting/CurrentPaymenInformation';
import InvoiceTable from '../../components/accounting/InvoiceTable';
import SubscriptionOverview from '../../components/accounting/SubscriptionOverview';
import PrivateMetaNavHeader from '../../components/basic/PrivateMetaNavHeader';
import useAuth from '../../hooks/useAuth';
import { getObj, getObjs } from '../../utils/axiosHelper';


const Accounting = () => {

  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [allInvoicesSettled, setAllInvoicesSettled] = useState(true);
  const [currentPaymentInformation, setCurrentPaymentInformation] = useState(null);
  const [liveUser, setLiveUser] = useState();
  const { t } = useTranslation();
  const { user } = useAuth();

  const loadAccounting = useCallback(async () => {
    const brot = toast.loading(t('widgets.toast.loading'));

    await getObj('auth/user/').then(async (response) => {
      setLiveUser(response.data);
    }).catch((error) => {
      toast.update(brot, {render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    });

    await getObjs('accounting/invoices/').then(async (response) => {
      setInvoices(response.data);
      setAllInvoicesSettled(response.data.find(invoice => invoice.paid === false) === undefined);
    }).catch((error) => {
      toast.update(brot, {render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    });

    await getObjs('accounting/payment-data/').then(async (response) => {
      if (Array.isArray(response.data) && response.data.length > 0) {
        setCurrentPaymentInformation(response.data[0]);
      }
    }).catch((error) => {
      toast.update(brot, {render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    });

    await getObj('accounting/subscription/').then(async (response) => {
      setCurrentSubscription(response.data);
      toast.dismiss(brot);
    }).catch((error) => {
      if (error.response.status !== 404) {
        toast.update(brot, {render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
      } else {
        toast.dismiss(brot);
      }
    });
  }, [t]);


  useEffect(() => {
    if (user?.pk) {
      loadAccounting();
    }
  }, [user?.pk, loadAccounting]);

  return (
    <>
      <Helmet title={t('pages.accounting.headline')} />
      <PrivateMetaNavHeader />
      <div className="mainspace p-4">
        <div className="container">
          <h3 className="mb-3">{t('pages.accounting.subscription')}</h3>
          <SubscriptionOverview subscription={currentSubscription} setSubscription={setCurrentSubscription} reloadSubscription={loadAccounting} />
          <h3 className="mb-3">{t('pages.accounting.active-payment-information')}</h3>
          <CurrentPaymenInformation information={currentPaymentInformation} user={liveUser} setCurrentPaymentInformation={setCurrentPaymentInformation} />
          {invoices.length > 0 && (
            <>
              <h3 className="mt-5 mb-3">{t('pages.accounting.invoices')}</h3>
              <Card className="mb-4 rounded-0 rounded-top" border={allInvoicesSettled ? "success" : "warning"}>
                <Card.Header className={`${allInvoicesSettled ? "bg-success text-light" : "bg-warning text-dark"}`} as="h5">
                  {allInvoicesSettled && t('pages.accounting.invoices-settled')}
                  {!allInvoicesSettled && t('pages.accounting.invoices-unsettled')}
                </Card.Header>
                <Card.Body className="p-0">
                  <InvoiceTable invoices={invoices} />
                </Card.Body>
              </Card>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Accounting;
