import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, ButtonGroup, Dropdown, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { GET_ATTRIBUTES, GET_TYPES_WITH_DEFINITION, GET_WRITEABLE_COLLECTIONS } from '../../components/collections/queries';
import useLocalStorage from '../../hooks/useLocalStorage';
import AttributeFilterInput from '../items/AttributeFilterInput';


const ElementsSidebarFilter = ({ handler, variables, getElements, refetch, q, setQ }) => {

  const [attributes, setAttributes] = useState([]);
  const [collections, setCollections] = useState([]);
  const [lastInventoryFilter, setLastInventoryFilter] = useLocalStorage('last-inventory-filter', null);
  const [types, setTypes] = useState([]);
  const lastVariablesState = useMemo(() => JSON.stringify(variables), [variables]);
  const { opened } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
  };

  const [initialFilterValues, setInitialFilterValues] = useState({
    collection_In: [],
    item_Name_Icontains: q || '',
    item_TypeOf_In: [],
    ...(lastInventoryFilter ? JSON.parse(lastInventoryFilter) : {}),
  });

  const [getTypes] = useLazyQuery(GET_TYPES_WITH_DEFINITION, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setTypes(data.types);
    },
  });

  const [getCollections] = useLazyQuery(GET_WRITEABLE_COLLECTIONS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setCollections(data.writeableCollections);
    },
  });

  const [getAttributes] = useLazyQuery(GET_ATTRIBUTES, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setAttributes(data.attributes);
      let initials = { ...initialFilterValues };
      data.attributes.edges.forEach(attr => {
        if (initials[`attr_${attr.node.pk}`] === undefined) {
          initials[`attr_${attr.node.pk}`] = '';
        }
      });
      setInitialFilterValues(initials);
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFilterValues,
    onSubmit: (values) => handleSave(values),
  });

  const setValues = formik.setValues;

  const resetForm = useCallback(() => {

    let initials = { ...initialFilterValues };

    if (attributes?.edges) {
      attributes.edges.forEach(attr => {
        initials[`attr_${attr.node.pk}`] = '';
      });
    }

    initials['item_Name_Icontains'] = '';
    initials['item_TypeOf_In'] = [];
    setQ('');

    formik.setValues(initials);
    formik.handleSubmit();
  }, [attributes.edges, formik, initialFilterValues, setQ]);


  const submitAndSave = () => {
    alert('This feature is not available yet');
  };

  const handleSave = async (values) => {

    let filterValues = { ...values, attributes: [] };

    if (attributes?.edges) {
      attributes.edges.forEach(attr => {
        let value = values[`attr_${attr.node.pk}`];
        if (value && value !== "") {
          switch (attr.node.typeOf) {
            case 'A_9':
              return filterValues['attributes'].push({ pk: attr.node.pk, date_value: value });
            case 'A_11':
              return filterValues['attributes'].push({ pk: attr.node.pk, integer_value: value });
            default:
              return filterValues['attributes'].push({ pk: attr.node.pk, char_value: value });
          };

        };
      });
    }

    filterValues['attributes'] = JSON.stringify(filterValues['attributes']);

    setLastInventoryFilter(JSON.stringify(values));

    if (q) {
      filterValues['item_Name_Icontains'] = q;
    }

    let newVariables = {
      ...variables,
      ...filterValues,
    };

    if (newVariables?.collection_In && newVariables?.collection_In.length === 0) {
      delete newVariables?.collection_In;
    }

    if (newVariables?.item_TypeOf_In && newVariables?.item_TypeOf_In.length === 0) {
      delete newVariables?.item_TypeOf_In;
    }

    await getElements({
      variables: newVariables,
    });

    refetch();
  };

  useEffect(() => { getTypes() }, [getTypes]);
  useEffect(() => {

    let currentVariablesState = JSON.stringify(variables);

    if (currentVariablesState !== lastVariablesState) {
      resetForm();
    }

  }, [variables, lastVariablesState, resetForm]);
  useEffect(() => { getCollections() }, [getCollections]);
  useEffect(() => { getAttributes() }, [getAttributes]);
  useEffect(() => {
    if (lastInventoryFilter !== null) {
      setValues(JSON.parse(lastInventoryFilter));
    }
  }, [lastInventoryFilter, setValues]);

  return (
    <>
      <div className="panel bg-body-tertiary border-start flex-fill mw-330 overflow-hidden">
        <div className="app">
          <div className="module modal">
            <div className="modal-header">
              <div className="modal-title h5">{t('sidebars.element-filter.title')}</div>
              <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
            </div>
            <div className="mainspace p-3">

              <Form.Group className="mb-3">
                <Form.Label>{t('sidebars.element-filter.field-q')}</Form.Label>
                <Form.Control
                  autoComplete="off"
                  isInvalid={formik.errors?.fields?.item_Name_Icontains}
                  onChange={(e) => setQ(e.target.value)}
                  type="search"
                  value={q}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors?.fields?.item_Name_Icontains}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>{t('sidebars.element-filter.field-types')}</Form.Label>
                <Select
                  getOptionLabel={(e) => e.node.name}
                  getOptionValue={(e) => e.node.pk}
                  isClearable={true}
                  isMulti={true}
                  menuPortalTarget={document.querySelector('body')}
                  name="item_TypeOf_In"
                  onChange={(selectedOptions) => formik.setFieldValue('item_TypeOf_In', (selectedOptions || []).map(e => e.node.pk))}
                  options={types.edges || []}
                  placeholder={''}
                  value={(types.edges || []).filter((o) => (formik.values.item_TypeOf_In || []).indexOf(o.node.pk) > -1)}
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>{t('sidebars.element-filter.field-collections')}</Form.Label>
                <Select
                  getOptionLabel={(e) => e.node.name}
                  getOptionValue={(e) => e.node.pk}
                  isClearable={true}
                  isMulti={true}
                  menuPortalTarget={document.querySelector('body')}
                  name="collection_In"
                  onChange={(selectedOptions) => formik.setFieldValue('collection_In', (selectedOptions || []).map(e => e.node.pk))}
                  options={collections.edges || []}
                  placeholder={''}
                  value={(collections.edges || []).filter((o) => (formik.values?.collection_In || []).indexOf(o.node.pk) > -1)}
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </Form.Group>

              {attributes?.edges && attributes.edges.map((attribute, index) => (
                <React.Fragment key={index}>
                  <AttributeFilterInput formik={formik} mode="edit" attribute={attribute.node} values={formik.values} />
                </React.Fragment>
              ))}

            </div>
            <div className="modal-footer">
              <div className="d-grid gap-2 flex-grow-1">
                <button className="btn btn-secondary" type="reset" onClick={resetForm}>
                  {t('forms.actions.reset')}
                </button>
                <Dropdown as={ButtonGroup}>
                  <Button variant="primary" onClick={formik.handleSubmit} className="w-100">{t('forms.actions.apply')}</Button>
                  <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" className="px-3" />
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={submitAndSave}>{t('forms.actions.apply-and-save')}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ElementsSidebarFilter;
