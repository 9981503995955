import React from 'react';

import { useTranslation } from 'react-i18next';
import defaultGroupImage from '../../assets/img/illustrations/default-group-image.png';


const GroupThumbnail = ({ group, height = 32, width = 32, className = 'rounded', border = 'border border-1 border-dark-subtle' }) => {

  const { t } = useTranslation();

  return (
    <>
      {group.cardImageThumbnail && (
        <img
          alt={t('forms.alt-tags.avatar')}
          className={`${className} ${border}`}
          height={height}
          src={`${process.env.REACT_APP_MEDIA_URL}${group.cardImageThumbnail}`}
          width={width}
        />
      )}

      {!group.cardImageThumbnail && (
        <img
          alt={t('forms.alt-tags.avatar')}
          src={defaultGroupImage}
          className={`${className} ${border}`}
          height={height}
          width={width}
        />
      )}
    </>
  );
};

export default GroupThumbnail;
