import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { Binoculars } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { confirm } from '../../components/basic/Confirmation';
import FoodLookupResult from '../../components/items/FoodLookupResult';
import { getObjs, postData } from '../../utils/axiosHelper';


const ItemModalAddFood = ({ handler }) => {

  const [disabled, setDisabled] = useState(false);
  const [foodLookup, setFoodLookup] = useState([]);
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const onSaveClick = () => {

    if (foodLookup.length === 0) {
      return;
    }

    if (foodLookup.length > 1) {
      confirm({
        title: t('modals.item-create-food.choose-hint-title'),
        lead: t('modals.item-create-food.choose-hint-lead'),
        showCancel: false,
        danger: false,
      });

      return
    }

    create(foodLookup[0].pk);
  };

  const create = async (pk) => {

    setDisabled(true);
    formik.setErrors({});
    const brot = toast.loading(t('widgets.toast.saveing'));

    await postData('warehouse/items/food/', { resource_pk: pk }, { sleep: 100 }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.item-create-food.created-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
    }).finally(() => {
      toast.dismiss(brot);
      setDisabled(false);
    });

  };

  const lookupBarcode = async (barcode) => {

    setDisabled(true);
    setFoodLookup([]);
    formik.setErrors({});
    const brot = toast.loading(t('widgets.toast.loading'));

    await getObjs(`warehouse/lookup/ean?barcode=${barcode}`, { sleep: 1000 }).then(async (response) => {
      setFoodLookup(response.data);
      if (response.data.length === 0) {
        formik.setErrors({ fields: { barcode: t('modals.item-create-food.product-not-found') } });
      }
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
    }).finally(() => {
      toast.dismiss(brot);
      setDisabled(false);
    });

  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      barcode: '',
    },
  });

  return (
    <>
      <Modal show={opened.value} onHide={handleClose} backdrop={disabled ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.item-create-food.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={disabled}>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.item-create-food.field-barcode')}</Form.Label>
              <InputGroup>
                <Form.Control
                  isInvalid={formik.errors?.fields?.barcode}
                  name="barcode"
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.barcode}
                />
                <Button variant="outline-primary" disabled={!formik.values.barcode} onClick={() => lookupBarcode(formik.values.barcode)}>
                  <Binoculars />
                </Button>
              </InputGroup>
              <Form.Control isInvalid={formik.errors?.fields?.barcode} type="hidden" />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.barcode}
              </Form.Control.Feedback>
            </Form.Group>
            {foodLookup.length > 0 && foodLookup.map((food, index) => (
              <FoodLookupResult key={index} result={food} resultSize={foodLookup.length} createCallback={create} />
            ))}
          </fieldset>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="primary" onClick={onSaveClick} disabled={disabled || foodLookup.length <= 0}>{t('forms.actions.create')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default ItemModalAddFood;
