import React from 'react';

import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Formi from '../../components/basic/Formi';


const StreamFooter = ({ collectionCount = 0, collectionsLoaded = 0, subCollectionCount = 0, subCollectionsLoaded = 0, elementsLoaded = 0, elementCount = 0, itemsLoaded = 0, itemCount = 0, breadcrumps, loading }) => {

  const { t } = useTranslation();

  return (
    <div className="bg-body-tertiary border-top d-flex footer-stream">
      {breadcrumps && (
        <small className="me-auto border-end flex-grow-1 d-none d-sm-block p-2 px-2">
          {breadcrumps}
        </small>
      )}
      <small className="p-2 user-select-none">

        {subCollectionCount > 0 && (
          <>
            {subCollectionsLoaded !== subCollectionCount && (
              <>
                <Formi decimalVal={subCollectionsLoaded} />
                <span className="">/</span>
              </>
            )}
            <Formi decimalVal={subCollectionCount} />
            <span className="ms-1">{t('public.plurals.subcollections', { count: subCollectionCount })}</span>
          </>
        )}

        {subCollectionCount > 0 && collectionCount > 0 && <span className="me-1">, </span>}

        {collectionCount > 0 && (
          <>
            {collectionsLoaded !== collectionCount && (
              <>
                <Formi decimalVal={collectionsLoaded} />
                <span className="">/</span>
              </>
            )}
            <Formi decimalVal={collectionCount} />
            <span className="ms-1">{t('public.plurals.collections', { count: collectionCount })}</span>
          </>
        )}

        {(subCollectionCount > 0 || collectionCount > 0) && elementCount > 0 && <span className="me-1">, </span>}

        {elementCount > 0 && (
          <>
            {elementsLoaded !== elementCount && (
              <>
                <Formi decimalVal={elementsLoaded} />
                <span className="">/</span>
              </>
            )}
            <Formi decimalVal={elementCount} />
            <span className="ms-1">{t('public.plurals.elements', { count: elementCount })}</span>
          </>
        )}

        {(collectionCount > 0 || elementCount > 0) && itemCount > 0 && <span className="me-1">, </span>}

        {itemCount > 0 && (
          <>
            {itemsLoaded !== itemCount && (
              <>
                <Formi decimalVal={itemsLoaded} />
                <span className="">/</span>
              </>
            )}
            <Formi decimalVal={itemCount} />
            <span className="ms-1">{t('public.plurals.items', { count: itemCount })}</span>
          </>
        )}

        {elementCount === 0 && itemCount === 0 && subCollectionCount === 0 && collectionCount === 0 && (
          <span>{t('widgets.stream-footer.empty')}</span>
        )}
      </small>
      <small className="ms-auto border-start p-2 px-4">
        <FontAwesomeIcon icon={faCog} spin={loading} size="lg" className="text-secondary" fixedWidth />
      </small>
    </div>
  )
};

export default StreamFooter;
