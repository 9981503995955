import React from 'react';

import { faCommentDots, faCookieBite, faEllipsis, faGhost } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import google from '../../../assets/img/brands/google/Google__G__Logo.svg';


const CookieRequired = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const next = location?.state?.next || '/';
  const requiredCookies = location?.state?.requiredCookies || [];
  const [, setCookie] = useCookies(requiredCookies);

  const cookieDescriptions = {
    recaptcha: {
      img: google,
      title: t('meta.cookies.repatcha-title'),
      description: t('meta.cookies.repatcha-description'),
    },
  };

  const agree = () => {
    let expires = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    requiredCookies.forEach((cookieName) => {
      setCookie(cookieName, true, { path: '/', secure: process.env.NODE_ENV !== 'development', expires: expires });
    });
    navigate(next);
  };

  if (!requiredCookies || requiredCookies.length === 0) {
    return <Navigate to={next} replace={true} />;
  }

  return (
    <>
      <Helmet title={t('pages.meta.cookie-required.headline')} />

      <Container>
        <h1>{t('pages.meta.cookie-required.headline')}</h1>
        <div className="lead mb-5">
          <ReactMarkdown>
            {t('pages.meta.cookie-required.lead')}
          </ReactMarkdown>
        </div>
        {requiredCookies.map((cookieName, index) => (
          <div className="d-flex mb-5" key={index}>
            <div className="flex-shrink-0">
              <img src={cookieDescriptions[cookieName].img} width="50" alt={cookieName} />
            </div>
            <div className="flex-grow-1 ms-3">
              <h3>{cookieDescriptions[cookieName].title}</h3>
              <p className="lead">{cookieDescriptions[cookieName].description}</p>
            </div>
          </div>
        ))}

        <div className="text-end mb-5">

          <div className="d-grid d-sm-block gap-2">
            <Link to="/meta/privacy" className="btn btn-secondary">
              {t('pages.meta.cookie-required.privacy-and-data-protection')}
            </Link>
            <Button className="ms-sm-1" onClick={agree}>
              {t('pages.meta.cookie-required.agree-and-proceed')}
            </Button>
          </div>
        </div>
        <br />
        <div className="text-center mb-5 mt-5">
          <FontAwesomeIcon icon={faGhost} className="mx-1 text-primary" />
          <sup><FontAwesomeIcon icon={faCommentDots} className="mx-1 text-dark" /></sup>
          <FontAwesomeIcon icon={faEllipsis} className="mx-1 text-dark" />
          <FontAwesomeIcon icon={faCookieBite} className="mx-1 text-warning" />
          <FontAwesomeIcon icon={faCookieBite} className="me-1 text-warning" />
          <FontAwesomeIcon icon={faCookieBite} className="me-1 text-warning" />
        </div>

      </Container>
    </>
  );
}

export default CookieRequired;
