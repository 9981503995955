import { gql } from '@apollo/client';


const GET_ELEMENT_BY_ID = gql`
  query ElementById(
    $elementId: String!
    ) {
    elementById(
      elementId: $elementId
    ) {
      amount
      bestBefore
      consumption
      createdAt
      isHidden
      pk
      price
      priceIsBon
      purchasingPrice
      collection {
        cardImageThumbnail
        enablePriceOptions
        icon
        itemImageThumbnail
        name
        pk
        defaultType {
          cardImageThumbnail
          icon
          itemImageThumbnail
        }
        parent {
          cardImageThumbnail
          icon
          itemImageThumbnail
          name
          pk
          defaultType {
            cardImageThumbnail
            icon
            itemImageThumbnail
          }
          parent {
            cardImageThumbnail
            icon
            itemImageThumbnail
            name
            pk
            defaultType {
              cardImageThumbnail
              icon
              itemImageThumbnail
            }
            parent {
              cardImageThumbnail
              icon
              itemImageThumbnail
              name
              pk
              defaultType {
                cardImageThumbnail
                icon
                itemImageThumbnail
              }
              parent {
                cardImageThumbnail
                icon
                itemImageThumbnail
                name
                pk
                defaultType {
                  cardImageThumbnail
                  icon
                  itemImageThumbnail
                }
                parent {
                  cardImageThumbnail
                  icon
                  itemImageThumbnail
                  name
                  pk
                  defaultType {
                    cardImageThumbnail
                    icon
                    itemImageThumbnail
                  }
                }
              }
            }
          }
        }
      }
      item {
        createdAt
        description
        icon
        imageFull
        imageThumbnail
        name
        pk
        updatedAt
        userCanEdit
        website
        elements {
          collection {
            name
            pk
          }
        }
        properties {
          value
          attribute {
            name
            pk
            typeOf
          }
        }
        typeOf {
          itemImageThumbnail
          name
          pk
          showBestBefore
          showConsumption
        }
      }
      issues {
        amount
        createdAt
        notes
        pk
        state
        to
        updatedAt
      }
      dispositions {
        amount
        createdAt
        deliveryDate
        notes
        pk
        state
        supplier
        updatedAt
      }
    }
  }
`;


const GET_ELEMENT_EVENTS_BY_ID = gql`
  query ElementEventsById(
    $elementId: String!
    ) {
    elementEventsById(
      elementId: $elementId
    ) {
      amount
      bestBefore
      consumption
      createdAt
      pk
      item {
        createdAt
        description
        icon
        imageFull
        imageThumbnail
        name
        pk
        updatedAt
      }
      events {
        createdAt
        information
        pk
        updatedAt
        user {
          gravatar
          repr
          thumbnail
        }
      }
    }
  }
`;


const GET_PUBLIC_ELEMENT_BY_ID = gql`
  query ElementById(
    $elementId: String!
    $slug: String!
    ) {
    elementById(
      elementId: $elementId
      slug: $slug
    ) {
      amount
      bestBefore
      consumption
      createdAt
      pk
      collection {
        cardImageThumbnail
        icon
        itemImageThumbnail
        name
        publicPk
        publicSlug
        pk
        defaultType {
          cardImageThumbnail
          icon
          itemImageThumbnail
        }
        parent {
          cardImageThumbnail
          icon
          itemImageThumbnail
          name
          pk
          publicPk
          publicSlug
          defaultType {
            cardImageThumbnail
            icon
            itemImageThumbnail
          }
          parent {
            cardImageThumbnail
            icon
            itemImageThumbnail
            name
            pk
            publicPk
            publicSlug
            defaultType {
              cardImageThumbnail
              icon
              itemImageThumbnail
            }
            parent {
              cardImageThumbnail
              icon
              itemImageThumbnail
              name
              pk
              publicPk
              publicSlug
              defaultType {
                cardImageThumbnail
                icon
                itemImageThumbnail
              }
              parent {
                cardImageThumbnail
                icon
                itemImageThumbnail
                name
                pk
                publicPk
                publicSlug
                defaultType {
                  cardImageThumbnail
                  icon
                  itemImageThumbnail
                }
                parent {
                  cardImageThumbnail
                  icon
                  itemImageThumbnail
                  name
                  pk
                  publicPk
                  publicSlug
                  defaultType {
                    cardImageThumbnail
                    icon
                    itemImageThumbnail
                  }
                }
              }
            }
          }
        }
      }
      item {
        createdAt
        description
        icon
        imageFull
        imageThumbnail
        name
        pk
        updatedAt
        website
        properties {
          value
          attribute {
            name
            pk
            typeOf
          }
        }
        typeOf {
          itemImageThumbnail
          name
          pk
          showConsumption
        }
      }
    }
  }
`;


const GET_ELEMENTS = gql`
  query Elements(
    $after: String
    $attributes: String
    $collection_In: [ID]
    $first: Int
    $item_Name_Icontains: String
    $item_TypeOf_In: [ID]
    $orderBy: [String]
    $pk: ID
    ) {
    elements(
      after: $after
      attributes: $attributes
      collection_In: $collection_In
      first: $first
      id: $pk
      item_Name_Icontains: $item_Name_Icontains
      item_TypeOf_In: $item_TypeOf_In
      orderBy: $orderBy
    ) {
      totalCount
      edges {
        node {
          amount
          createdAt
          pk
          updatedAt
          collection {
            name
            pk
          }
          item {
            cardImageSquare
            cardImageThumbnail
            icon
            imageFull
            name
            pk
            properties {
              attribute {
                pk
              }
              value
            }
            typeOf {
              cardImageSquare
              createdAt
              itemImageThumbnail
              name
              pk
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edgeCount
    }
    inventorySummary {
      totalAmount
      totalElements
      totalPurchasingPrice
      totalValue
    }
  }
`;


export {
  GET_ELEMENT_BY_ID,
  GET_ELEMENT_EVENTS_BY_ID,
  GET_ELEMENTS,
  GET_PUBLIC_ELEMENT_BY_ID,
};

