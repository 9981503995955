import React from 'react';

import { Archive, ArrowDownUp, Book, Box, Collection, People, Speedometer2, Truck } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Brand } from '../../assets/img/ci/brand.svg';
import { ReactComponent as Icon } from '../../assets/img/ci/icon.svg';
import useSidebar from '../../hooks/useSidebar';
import useTheme from '../../hooks/useTheme';
import './Sidebar.css';
import SidebarItem from './SidebarItem';
import UserDropdown from './UserDropdown';


const Sidebar = () => {

  const { collapsed } = useSidebar();
  const { t } = useTranslation();
  const { themeIsDark } = useTheme();

  return (
    <>
      <div className={`d-flex border-end flex-column flex-shrink-0 ${themeIsDark ? 'bg-dark' : 'bg-light'} main-sidebar ${collapsed ? "collapsed" : "p-3"}`}>
        <NavLink to="/private" className={`d-flex align-items-center mb-md-0 me-md-auto link-dark text-decoration-none ${collapsed ? "p-3" : "mb-3"}`}>
          <Icon
            alt="Lagerraum"
            width={`${collapsed ? 40 : 32}`}
            height={`${collapsed ? 40 : 32}`}
          />
          {!collapsed && (<Brand className="ms-2 mt-1" width="90" />)}
        </NavLink>
        {!collapsed && <hr />}
        <ul className={`nav nav-pills flex-column mb-auto ${collapsed ? "nav-flush text-center" : ""}`}>
          <li className={`nav-item ${collapsed ? "" : "mb-1"}`}>
            <SidebarItem collapsed={collapsed} label={t('widgets.sidebar.items.dashboard')} Icon={Speedometer2} to="/private" end={true} />
          </li>
          <li className={`nav-item ${collapsed ? "" : "mb-1"}`}>
            <SidebarItem collapsed={collapsed} label={t('widgets.sidebar.items.collections')} Icon={Collection} to="/private/collections" end={false} />
          </li>
          <li className={`nav-item ${collapsed ? "" : "mb-1"}`}>
            <SidebarItem collapsed={collapsed} label={t('widgets.sidebar.items.inventory')} Icon={Book} to="/private/inventory" end={false} />
          </li>
          <li className={`nav-item ${collapsed ? "" : "mb-1"}`}>
            <SidebarItem collapsed={collapsed} label={t('widgets.sidebar.items.items')} Icon={Box} to="/private/items" end={false} />
          </li>
          <li className={`nav-item ${collapsed ? "" : "mb-1"}`}>
            <SidebarItem collapsed={collapsed} label={t('widgets.sidebar.items.browser')} Icon={Archive} to="/private/browser" end={false} />
          </li>
          <li className={`nav-item ${collapsed ? "" : "mb-1"}`}>
            <SidebarItem collapsed={collapsed} label={t('widgets.sidebar.items.groups')} Icon={People} to="/private/groups" end={false} />
          </li>
          <li className={`nav-item ${collapsed ? "" : "mb-1"}`}>
            <SidebarItem collapsed={collapsed} label={t('widgets.sidebar.items.issues')} Icon={ArrowDownUp} to="/private/issues" end={false} />
          </li>
          <li className={`nav-item ${collapsed ? "" : "mb-1"}`}>
            <SidebarItem collapsed={collapsed} label={t('widgets.sidebar.items.dispositions')} Icon={Truck} to="/private/dispositions" end={false} />
          </li>
        </ul>
        {!collapsed && <hr />}
        <UserDropdown />
      </div>
    </>
  );
};

export default Sidebar;
