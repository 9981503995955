import React from 'react';

import axios from 'axios';
import { toast } from 'react-toastify';
import i18n from '../i18n';
import { sleep } from './sleep';


const secureHTML = (content) => {
  return {
    __html: content,
  };
};

const toastDjangoError = (statusCode, errors) => {
  var message = '';

  const resolveArrayErrors = (errorA) => {
    var msg = '';

    errorA.forEach((error) => {
      if (!Array.isArray(error) && typeof error === 'object') {
        msg += resolveDictErrors(error);
      }

      if (typeof error === 'string') {
        msg += `${error}<br>`;
      }
    });

    return msg;
  };

  const resolveDictErrors = (errorD) => {
    var msg = '';

    for (var field in errorD) {
      // Fehlerhaftes Feld: Überschrift
      if (field !== 'detail') {
        msg += field + '<br />';
      }

      // Einfache Datentypen
      if (typeof errorD[field] === 'string') {
        msg += `${errorD[field]}<br>`;
      }

      if (!Array.isArray(errorD[field]) && typeof errorD[field] === 'object') {
        msg += resolveDictErrors(errorD[field]);
      }

      if (Array.isArray(errorD[field])) {
        msg += resolveArrayErrors(errorD[field]);
      }
    }

    return msg;
  };

  if (typeof errors !== 'object')
    return secureHTML(`<div><b>Fehler ${statusCode}</b></div>`);

  message += resolveDictErrors(errors);

  return secureHTML(`<div><b>Fehler ${statusCode}</b><br />${message}</div>`);
};

const toastError = (axiosError, brot, autoClose = 2000) => {
  if (axiosError.response) {
    if (brot) {
      toast.update(brot, { render: <div dangerouslySetInnerHTML={toastDjangoError(axiosError.response.status, axiosError.response.data)}></div>, type: 'error', isLoading: false, autoClose: autoClose });
    } else {
      toast.error(<div dangerouslySetInnerHTML={toastDjangoError(axiosError.response.status, axiosError.response.data)}></div>, { autoClose: autoClose });
    }
  } else if (axiosError.request) {
    if (brot) {
      toast.update(brot, { render: 'Connection Error', type: 'error', isLoading: false, autoClose: autoClose });
    } else {
      toast.error('Connection Error', { autoClose: autoClose });
    }
  } else {
    toast.error('Setup Error', { autoClose: autoClose });
  }
};


const postData = async (url, data, opts: {}) => {
  axios.defaults.headers.common['Accept-Language'] = i18n.resolvedLanguage;

  const options = { ...{ sleep: null, prependRestURL: true }, ...opts };
  const fullUrl = options.prependRestURL ? `${process.env.REACT_APP_REST}${url}` : url;

  if (options.sleep) {
    await sleep(options.sleep);
  }

  return await axios.post(fullUrl, data, options.config || {});
};


const putData = async (url, data, opts: {}) => {
  const options = { ...{ sleep: null, prependRestURL: true }, ...opts };
  const fullUrl = options.prependRestURL ? `${process.env.REACT_APP_REST}${url}` : url;

  if (options.sleep) {
    await sleep(options.sleep);
  }

  return await axios.put(fullUrl, data, options.config || {});
};


const patchData = async (url, data, opts: {}) => {
  const options = { ...{ sleep: null, prependRestURL: true }, ...opts };
  const fullUrl = options.prependRestURL ? `${process.env.REACT_APP_REST}${url}` : url;

  if (options.sleep) {
    await sleep(options.sleep);
  }

  return await axios.patch(fullUrl, data, options.config || {});
};


const deleteObj = async (url, opts: {}) => {
  const options = { ...{ sleep: null, prependRestURL: true }, ...opts };
  const fullUrl = options.prependRestURL ? `${process.env.REACT_APP_REST}${url}` : url;

  if (options.sleep) {
    await sleep(options.sleep);
  }

  return await axios.delete(fullUrl);
};


const getObj = async (url, opts: {}) => {

  axios.defaults.headers.common['Accept-Language'] = i18n.resolvedLanguage;

  const options = { ...{ sleep: null, prependRestURL: true }, ...opts };
  const fullUrl = options.prependRestURL ? `${process.env.REACT_APP_REST}${url}` : url;

  if (options.sleep) {
    await sleep(options.sleep);
  }

  return await axios.get(fullUrl, options.config || {});
};


const getObjs = getObj;
const getData = getObj;


const getOptions = async (url, opts: {}) => {
  axios.defaults.headers.common['Accept-Language'] = i18n.resolvedLanguage;

  const options = { ...{ sleep: null, prependRestURL: true }, ...opts };
  const fullUrl = options.prependRestURL ? `${process.env.REACT_APP_REST}${url}` : url;

  if (options.sleep) {
    await sleep(options.sleep);
  }

  return await axios.options(fullUrl);
};


export { deleteObj, getData, getObj, getObjs, getOptions, patchData, postData, putData, secureHTML, toastDjangoError, toastError };
