import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';


const UserModalEdit = ({ handler, user }) => {

  const [disabled, setDisabled] = useState(false);
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleDelete = async (values) => {
    setDisabled(true);
    let brot = toast.loading(t('widgets.toast.loading'));

    await postData('auth/user/delete/', values, { sleep: 1500 }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.user-delete.request-send-successfully'), type: 'success', autoClose: 1200, isLoading: false });
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user.email,
      password: '',
      pk: user.id,
    },
    onSubmit: (values) => handleDelete(values),
  });

  return (
    <>
      <Modal show={opened.value} onHide={handleClose} size="md" backdrop={disabled ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.user-delete.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={disabled}>
            <p>{t('modals.user-delete.lead-message')}</p>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.user-delete.field-password-confirmation')}</Form.Label>
              <Form.Control
                autoComplete="off"
                isInvalid={formik.errors?.fields?.password}
                name="password"
                onChange={formik.handleChange}
                type="password"
                value={formik.values.password}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.password}
              </Form.Control.Feedback>
            </Form.Group>
          </fieldset>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="danger" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.delete')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserModalEdit;
