import React, { useCallback, useEffect, useState } from 'react';

import { useApolloClient, useLazyQuery } from '@apollo/client';
import { faBarsStaggered, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { List, arrayMove } from 'react-movable';
import { toast } from 'react-toastify';
import { confirm } from '../../components/basic/Confirmation';
import { GET_ATTRIBUTES, GET_COLLECTION_FOR_EDITING } from '../../components/collections/queries';
import { deleteObj, getObj, postData, putData } from '../../utils/axiosHelper';


const CollectionTableModalEdit = ({ handler, collectionPk, tableConfigPk }) => {

  const [attributes, setAttributes] = useState([]);
  const [collection, setCollection] = useState();
  const [wantedTableConfigPk, setWantedTableConfigPk] = useState(tableConfigPk);
  const [disabled, setDisabled] = useState(false);
  const [usedAttributes, setUsedAttributes] = useState([]);
  const [widths, setWidths] = useState([]);
  const client = useApolloClient();
  const { opened, ok, destroyed, cancel, backdrop } = handler;
  const { t } = useTranslation();

  const [initialData, setInitialData] = useState({
    collection: collectionPk,
    id: wantedTableConfigPk,
    name: '',
    show_column_amount: true,
    show_column_type_of: true,
    show_column_update_at: true,
    use_as_default: false,
    use_as_default_for_collection: false,
  });

  const [getCollectionForEditing] = useLazyQuery(GET_COLLECTION_FOR_EDITING, {
    variables: { collectionId: collectionPk },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setCollection(data.collectionForEditing);
    },
  });

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const getTableConfig = useCallback(async () => {

    setDisabled(true);

    await getObj(`warehouse/table-configs/${wantedTableConfigPk}/`).then(async (response) => {
      setInitialData(response.data);
      setUsedAttributes(response.data['configured_attributes'].map(e => {
        return {
          columnWidth: e.column_width,
          getTypeOf: e.attribute.get_type_of_display,
          id: e.attribute.pk,
          isPublic: e.attribute.id_public,
          name: e.attribute.name,
          pk: e.attribute.pk,
          type_of: `A_${e.attribute.type_of}`,
        }
      }));
    }).catch((error) => {
      setWantedTableConfigPk();
      if (error.response.status !== 404) {
        toast.error(`${t('widgets.toast.error')} ${error.response.status}`);
      }
    }).finally(() => {
      setDisabled(false);
    });
  }, [t, wantedTableConfigPk]);


  const handleDelete = async () => {

    let result = await confirm({
      danger: true,
      lead: t('modals.collection-table-edit.delete-lead'),
      okLabel: t('modals.collection-table-edit.delete-ok'),
      showCancel: true,
      title: t('modals.collection-table-edit.delete-title'),
    });

    if (result === false) return;

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.deleting'));

    await deleteObj(`warehouse/table-configs/${formik.values.id}/`, { sleep: 500 }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.collection-table-edit.deleted-successfully'), type: 'info', isLoading: false, autoClose: 2000 });
      await client.clearStore();
      destroyed();
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });

  };

  const handleUpdateOrCreate = async (values) => {

    var formData = { ...values };
    formData.default_type = formData.defaultType?.pk || null;
    formData.configured_attributes = [];
    formData.id = wantedTableConfigPk;

    usedAttributes.forEach((config, index) => {
      formData.configured_attributes.push({
        attribute: config.pk,
        column_width: config.columnWidth,
        ordering: index,
      });
    });

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.loading'));
    const method = formData.id ? putData : postData;

    await method(`warehouse/table-configs/${formData.id ? `${formData.id}/` : ''}`, formData).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.collection-table-edit.updated-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      await client.clearStore();
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    onSubmit: (values) => handleUpdateOrCreate(values),
  });

  const [getAttributes] = useLazyQuery(GET_ATTRIBUTES, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setAttributes(data.attributes);
    },
  });

  const saveAsNew = () => {
    setWantedTableConfigPk();
    formik.handleSubmit();
  };

  const attributeSorting = (a, b) => {
    if (a.node.isPublic < b.node.isPublic) {
      return -1;
    } else if (a.node.isPublic > b.node.isPublic) {
      return 1;
    }

    return a.node.name.localeCompare(b.node.name);
  }

  const updateAttributeWidth = (event, index) => {
    let newUsedAttributes = [...usedAttributes];
    newUsedAttributes[index].columnWidth = event.target.value;
    setUsedAttributes(newUsedAttributes);
  }

  const reorderAttributes = (oldIndex, newIndex) => {
    let newUsedAttributes = [...usedAttributes];
    newUsedAttributes = arrayMove(newUsedAttributes, oldIndex, newIndex);
    setUsedAttributes(newUsedAttributes);
  };

  const removeAttribute = (attribute) => {
    let index = usedAttributes.findIndex((e) => e.pk === attribute.pk);
    if (!index) return;
    let newUsedAttributes = [...usedAttributes];
    newUsedAttributes.splice(index, 1);
    setUsedAttributes(newUsedAttributes);
  };

  const addAttribute = (attributeNode) => {
    let newUsedAttributes = [...usedAttributes];
    if (newUsedAttributes.length >= 10) {
      confirm({
        danger: false,
        lead: t('modals.collection-table-edit.column-limit-reached-lead'),
        okLabel: t('modals.collection-table-edit.column-limit-reached-ok'),
        showCancel: false,
        title: t('modals.collection-table-edit.column-limit-reached-title'),
      });
      return;
    }
    newUsedAttributes.splice(0, 0, { columnWidth: 0, ...attributeNode });
    setUsedAttributes(newUsedAttributes);
  };

  useEffect(() => {
    getCollectionForEditing();
  }, [collectionPk, getCollectionForEditing]);

  useEffect(() => {
    getAttributes();
  }, [getAttributes, collectionPk]);

  useEffect(() => {
    if (wantedTableConfigPk) getTableConfig();
  }, [getTableConfig, wantedTableConfigPk]);

  if (!collection) {
    return null;
  }

  return (
    <>
      <Modal show={opened.value} onHide={handleClose} size="lg" backdrop={disabled || backdrop === true ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.collection-table-edit.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={disabled}>
            <Row>
              <Col md="4">
                <Form.Group className="mb-3">
                  <Form.Label>{t('modals.collection-table-edit.field-name')}</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    isInvalid={formik.errors?.fields?.name}
                    name="name"
                    size="lg"
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    autoComplete="off"
                    isInvalid={formik.errors?.fields?.collection}
                    name="collection"
                    onChange={formik.handleChange}
                    type="hidden"
                    value={formik.values.collection}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.collection}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="8">
                <Form.Group>
                  <Form.Label>{t('modals.collection-table-edit.options')}</Form.Label>
                </Form.Group>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    id="mode-cb-date"
                    checked={formik.values.show_column_update_at === true}
                    onChange={(event) => formik.setFieldValue('show_column_update_at', event.target.checked)}
                    label={t('modals.collection-table-edit.field-update_at')}
                  />
                  <Form.Check
                    type="checkbox"
                    id="mode-cb-amount"
                    checked={formik.values.show_column_amount === true}
                    onChange={(event) => formik.setFieldValue('show_column_amount', event.target.checked)}
                    label={t('modals.collection-table-edit.field-amount')}
                  />
                  <Form.Check
                    type="checkbox"
                    id="mode-cb-type"
                    checked={formik.values.show_column_type_of === true}
                    onChange={(event) => formik.setFieldValue('show_column_type_of', event.target.checked)}
                    label={t('modals.collection-table-edit.field-type_of')}
                  />
                  <Form.Check
                    type="checkbox"
                    id="mode-cb-general"
                    checked={formik.values.use_as_default === true}
                    onChange={(event) => formik.setFieldValue('use_as_default', event.target.checked)}
                    label={t('modals.collection-table-edit.field-use_as_default')}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    id="mode-cb-defaultcollection"
                    checked={formik.values.use_as_default_for_collection === true}
                    isInvalid={formik.errors?.fields?.use_as_default_for_collection}
                    onChange={(event) => formik.setFieldValue('use_as_default_for_collection', event.target.checked)}
                    label={t('modals.collection-table-edit.field-use_as_default_for_collection')}
                  />
                  {formik.errors?.fields?.use_as_default_for_collection && (
                    <div className="text-danger ps-4" type="invalid">
                      {formik.errors?.fields?.use_as_default_for_collection}
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Form.Label>{t('modals.collection-table-edit.field-available-attributes')}</Form.Label>
            <div className="mb-3">
              {attributes?.edges && [...attributes.edges].sort(attributeSorting).map((edge) => (
                <Button
                  key={edge.node.pk}
                  size="sm"
                  className="mb-1 me-1 px-1 py-0"
                  onClick={() => addAttribute(edge.node)}
                  variant={edge.node.isPublic === true ? 'secondary' : 'warning'}
                  disabled={usedAttributes.find((f) => f.pk === edge.node.pk) !== undefined}
                >
                  {edge.node.name}
                </Button>
              ))}
            </div>
            <Form.Label>{t('modals.collection-table-edit.field-used-attributes')}</Form.Label>
            <List
              lockVertically
              beforeDrag={({ elements, index }) => {
                const cells = Array.from(elements[index].children);
                const widths = cells.map((cell) => window.getComputedStyle(cell).width);
                if (widths.length > 1) {
                  setWidths(widths);
                }
              }}
              values={usedAttributes || []}
              onChange={({ oldIndex, newIndex }) => {
                if (newIndex !== -1) reorderAttributes(oldIndex, newIndex);
              }}
              renderList={({ children, props, isDragged }) => (
                <Table size="sm" bordered striped hover>
                  <thead>
                    <tr>
                      <th>{t('modals.collection-table-edit.column-attribute')}</th>
                      <th width="140">{t('modals.collection-table-edit.column-type-of')}</th>
                      <th width="140">{t('modals.collection-table-edit.column-width')}</th>
                      <th width="50" className="text-center"></th>
                      <th width="50" className="text-center"></th>
                    </tr>
                  </thead>
                  <tbody {...props}>{children}</tbody>
                  {usedAttributes && usedAttributes.length === 0 && (
                    <tbody>
                      <tr>
                        <td colSpan="100" className="py-5 text-center">
                          {t('modals.collection-table-edit.no-attributes-assigned')}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              )}
              renderItem={({ value, props, isDragged, isSelected, isOutOfBounds }) => {
                const row = (
                  <tr {...props} style={{ zIndex: '1000' }}>
                    <td style={{ width: widths[0] }}>
                      {value.name}
                      {formik.errors?.fields?.configured_attributes?.[props.key] && (
                        <div className="text-danger">
                          {formik.errors.fields.configured_attributes[props.key].attribute}
                        </div>
                      )}
                    </td>
                    <td style={{ width: widths[1] }}>{value.getTypeOf}</td>
                    <td style={{ width: widths[2] }}>
                      <input type="number" min="0" max="600" className="form-control form-control-sm" step="10" value={value.columnWidth} onChange={(event) => updateAttributeWidth(event, props.key)} />
                    </td>
                    <td style={{ width: widths[4] }} className="text-center">
                      <Button size="sm" variant="secondary" className="py-0 px-1" onClick={() => removeAttribute(value)}>
                        <FontAwesomeIcon icon={faTrashCan} fixedWidth />
                      </Button>
                    </td>
                    <td style={{ width: widths[3] }} className="text-center">
                      <Button size="sm" variant="secondary" className="py-0 px-1" data-movable-handle>
                        <FontAwesomeIcon icon={faBarsStaggered} fixedWidth />
                      </Button>
                    </td>
                  </tr>
                );
                return isDragged ? (<Table style={{ zIndex: 2000, ...props.style }} className="table-primary" size="sm" bordered striped hover><tbody>{row}</tbody></Table>) : (row);
              }}
            />
          </fieldset>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          {wantedTableConfigPk && <Button variant="danger" className="me-auto" onClick={handleDelete} disabled={disabled}>{t('forms.actions.delete')}</Button>}
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          {wantedTableConfigPk && (
            <>
              <Button variant="secondary" onClick={saveAsNew} disabled={disabled}>{t('forms.actions.save-as-new')}</Button>
              <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.save')}</Button>
            </>
          )}
          {!wantedTableConfigPk && (
            <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.create')}</Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CollectionTableModalEdit;
