import React from 'react';

import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import defaultArticleImage from '../../assets/img/illustrations/default-article-image.jpg';


const ArticleCard = ({ article }) => {

  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const openArticle = (articleSlug) => {
    navigate(`/blog/${articleSlug}`);
  };

  return (
    <>
      <Card className="mb-4 collection h-100" role="button" onClick={() => openArticle(article.slug)}>
        {article.card_image_thumbnail && <img className="card-img-top" loading="lazy" src={`${process.env.REACT_APP_MEDIA_URL}${article.card_image_thumbnail}`} alt={article.name} />}
        {!article.card_image_thumbnail && <img className="card-img-top" loading="lazy" src={defaultArticleImage} alt={article.name} />}
        <Card.Body className="border-top pb-0">
          <h5 className="card-title">{article.title}</h5>
          <p className="card-text">{article.summary}</p>
        </Card.Body>
        <Card.Footer className="text-end text-muted">
          <Moment locale={i18n.resolvedLanguage} date={article.date} format="L" />
        </Card.Footer>
      </Card>
    </>
  );
};

export default ArticleCard;
