import React from 'react';

import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const EndOfSteamHandle = ({ hasNext = true, loading = false }) => {

  return (
    <>
      <div className="text-end mb-3">
        {hasNext && <FontAwesomeIcon icon={faCog} spin={loading} className="text-secondary fs-1 my-4" />}
        {!hasNext && <code className="text-muted fs-6 my-4">&lt;/end of stream&gt;</code>}
      </div>
    </>
  );
};

export default EndOfSteamHandle;
