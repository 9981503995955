import React, { useCallback, useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { getObjs, postData } from '../../utils/axiosHelper';


const ItemModalAddGroupPermission = ({ handler, item }) => {

  const [disabled, setDisabled] = useState(true);
  const [users, setUsers] = useState([]);
  const client = useApolloClient();
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const getUsers = useCallback(async () => {

    setDisabled(true);

    await getObjs('network/partner/').then(async (response) => {
      setUsers(response.data);
    }).catch((error) => {
      toast.error(`${t('widgets.toast.error')} ${error.response.status}`);
    }).finally(() => {
      setDisabled(false);
    });
  }, [t]);

  const valueFromId = (opts, id) => opts.find((o) => o.pk === id);

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleSave = async (values) => {

    var formData = { ...values };
    formData.user = formData.user?.pk || undefined;

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.saving'));

    await postData(`warehouse/items/${item.pk}/add_user_permission/`, formData, { sleep: 500 }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.item-add-user.created-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      await client.clearStore();
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      role: 2,
      item: item?.pk,
      user: '',
    },
    onSubmit: (values) => handleSave(values),
  });

  useEffect(() => { getUsers(); }, [getUsers]);

  return (
    <Modal show={opened.value} onHide={handleClose} backdrop={disabled ? 'static' : true}>
      <Modal.Header closeButton={!disabled}>
        <Modal.Title>{t('modals.item-add-user.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <fieldset disabled={disabled}>
          {item && formik.errors?.fields?.item && (
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.item-add-user.field-item')}</Form.Label>
              <Form.Control
                name="collection"
                onChange={formik.handleChange}
                type="text"
                value={item.name}
                isInvalid={formik.errors?.fields?.item}
                disabled
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.item}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          <Form.Group className="mb-3">
            <Form.Label>
              {t('modals.item-add-user.field-user')}
              <OverlayTrigger placement="right" overlay=<Tooltip>{t('modals.item-add-user.field-user-tooltip')}</Tooltip>>
                <FontAwesomeIcon icon={faCircleQuestion} className="ms-1 text-secondary" size="sm" fixedWidth />
              </OverlayTrigger>
            </Form.Label>
            <Select
              getOptionLabel={(e) => `${e.repr} <${e.email}>`}
              getOptionValue={(e) => e.pk}
              placeholder={t('modals.collection-edit.field-type-placeholder')}
              isClearable={true}
              name="user"
              onChange={(o) => formik.setFieldValue('user', o || null)}
              options={users || []}
              value={valueFromId(users || [], formik.values.user)}
              className="react-select-container"
              classNamePrefix="react-select"
            />
            <Form.Control type="hidden" isInvalid={formik.errors?.fields?.user} disabled />
            <Form.Control.Feedback type="invalid">{formik.errors?.fields?.user}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="switch"
              id="role-switch"
              checked={formik.values.role === 3}
              onChange={(event) => formik.setFieldValue('role', event.target.checked ? 3 : 2)}
              label={t('modals.item-add-user.field-role-switch')}
            />
            <Form.Control.Feedback type="invalid">{formik.errors?.fields?.role}</Form.Control.Feedback>
          </Form.Group>
        </fieldset>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
        <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.add')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ItemModalAddGroupPermission;
