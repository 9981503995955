import React from 'react';

import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';


const AttributeInput = ({ formik, property, index, mode }) => {

  const { t } = useTranslation();

  const valueFromConst = (opts, val) => opts.find((o) => o.value === val);

  const booleanOptions = [
    { label: t('forms.values.yes'), value: 'true' },
    { label: t('forms.values.no'), value: 'false' },
  ]

  const renderType = (property) => {

    switch (property.attribute.typeOf || property?.definition?.attribute?.typeOf) {

      case 'A_3':
        return <Select
          getOptionLabel={(e) => (e.label || e.value)}
          getOptionValue={(e) => e.value}
          isClearable={true}
          name={`properties.${index}.value`}
          onChange={(o) => formik.setFieldValue(`properties.${index}.value`, o?.value || '')}
          options={property?.definition?.attribute?.choices || []}
          placeholder={t('modals.item-edit.field-type-placeholder')}
          value={valueFromConst(property?.definition?.attribute?.choices || [], formik.values.properties[index].value)}
          className="react-select-container"
          classNamePrefix="react-select"
        />;

      case 'A_9':
        return <Form.Control
          autoComplete="off"
          isInvalid={formik.errors?.fields?.properties && formik.errors?.fields?.properties[index]?.value}
          name={`properties.${index}.value`}
          type="date"
          onChange={formik.handleChange}
          value={formik.values.properties[index]?.value}
        />;

      case 'A_11':
        return <Form.Control
          autoComplete="off"
          isInvalid={formik.errors?.fields?.properties && formik.errors?.fields?.properties[index]?.value}
          name={`properties.${index}.value`}
          type="number"
          onChange={formik.handleChange}
          value={formik.values.properties[index]?.value}
        />;

      case 'A_1':
        return <Select
          getOptionLabel={(e) => (e.label || e.value)}
          getOptionValue={(e) => e.value}
          isClearable={true}
          name={`properties.${index}.value`}
          onChange={(o) => formik.setFieldValue(`properties.${index}.value`, o?.value || '')}
          options={booleanOptions}
          placeholder={t('modals.item-edit.field-type-placeholder')}
          value={valueFromConst(booleanOptions, `${formik.values.properties[index].value}`)}
          className="react-select-container"
          classNamePrefix="react-select"
        />;

      default:
        return <Form.Control
          autoComplete="off"
          isInvalid={formik.errors?.fields?.properties && formik.errors?.fields?.properties[index]?.value}
          name={`properties.${index}.value`}
          type="text"
          onChange={formik.handleChange}
          value={formik.values.properties[index]?.value}
        />;
    }

  };


  if (mode === 'edit') {

    return (
      <>
        <Form.Group className="mb-3">
          <Form.Label>{property.attribute?.name}</Form.Label>
          {renderType(property)}
          <Form.Control.Feedback type="invalid">
            {formik.errors?.fields?.properties && formik.errors?.fields?.properties[index]?.value}
          </Form.Control.Feedback>
        </Form.Group>
      </>
    );

  }

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{property.definition?.attribute.name}</Form.Label>
        {renderType(property)}
        <Form.Control.Feedback type="invalid">
          {formik.errors?.fields?.properties && formik.errors?.fields?.properties[index]?.value}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default AttributeInput;
