import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Button, Modal, OverlayTrigger, Spinner, Table, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import UserAvatar from '../../components/auth/UserAvatar';
import Formi from '../../components/basic/Formi';
import { GET_ELEMENT_EVENTS_BY_ID } from '../../components/elements/queries';


const ElementModalEvents = ({ handler, elementId }) => {

  const [disabled, setDisabled] = useState(true);
  const [element, setElement] = useState();
  const { opened, ok, backdrop } = handler;
  const { t } = useTranslation();

  const [getElementLog, { loading, refetch }] = useLazyQuery(GET_ELEMENT_EVENTS_BY_ID, {
    variables: { elementId: elementId },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      let element = data.elementEventsById;
      setElement(element);
      setDisabled(false);
    },
  });

  const renderEvent = (event) => {

    let user = event.user.repr;

    if (event.information.action === 'increased') {
      return t('modals.element-events.type-increased', { value: event.information.details.value });
    }

    if (event.information.action === 'decreased') {
      return t('modals.element-events.type-decreased', { value: event.information.details.value });
    }

    if (event.information.action === 'created_issue') {
      return t('modals.element-events.type-created-issue', { count: event.information.details.value });
    }

    if (event.information.action === 'returned_issue') {
      return t('modals.element-events.type-returned-issue', { count: event.information.details.value });
    }

    if (event.information.action === 'created') {
      return t('modals.element-events.type-created');
    }

    if (event.information.msg) {
      console.debug(`Fallback event rendering for action ${event.information.action}`);
      return event.information.msg;
    }

    return (
      <div>{user}: {event.information.action}</div>
    )
  };

  const reload = () => {
    setElement();
    refetch();
  };

  const handleClose = () => {
    opened.set(false);
    ok();
  };

  useEffect(() => { if (elementId) { getElementLog(); } }, [elementId, getElementLog]);

  return (
    <Modal show={opened.value} onHide={handleClose} backdrop={disabled || loading || backdrop ? 'static' : true}>
      <Modal.Header closeButton={!disabled}>
        <Modal.Title>{t('modals.element-events.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-0">
        <fieldset disabled={disabled}>
          {loading && (
            <div className="text-center my-5">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          {!loading && (
            <div style={{ maxHeight: '400px', overflow: 'auto' }}>
              <Table size="sm" className="mb-0" bordered striped responsive>
                <tbody>
                  {element?.events && element?.events.length > 0 && element?.events.map((event, index) => (
                    <tr key={index}>
                      <td className="px-3 pt-1 pb-2">
                        <div className="d-flex">
                          <div className="flex-shrink-0">
                            <OverlayTrigger placement="right" overlay=<Tooltip>{event.user.repr}</Tooltip>>
                              <span>
                                <UserAvatar user={event.user} className="rounded-circle" height="24" width="24" />
                              </span>
                            </OverlayTrigger>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <div className="text-end d-none"><strong><small></small></strong></div>
                            <div>{renderEvent(event)}</div>
                          </div>
                        </div>
                      </td>
                      <td width="75" className="text-center">
                        <Formi shortDateVal={event.createdAt} />
                      </td>
                      <td width="60" className="text-center">
                        <Formi timeVal={event.createdAt} />
                      </td>
                    </tr>
                  ))}
                  {(!element?.events || element?.events.length === 0) && (
                    <tr>
                      <td className="text-center py-4">- {t('modals.element-events.no-entries')} -</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </fieldset>
      </Modal.Body>
      <Modal.Footer className="d-flex">
        <Button variant="secondary" className="me-auto" onClick={reload}>{t('forms.actions.refresh')}</Button>
        <Button variant="primary" onClick={handleClose}>{t('forms.actions.close')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ElementModalEvents;
