import React from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import pathfinder from '../../assets/img/illustrations/pathfinder.png';


const E404 = ({ msg }) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title="Error 404" />
      <div className="container">
        <Row style={{ margin: '80px 0 80px 0' }}>
          <Col md={{ span: 3, offset: 1 }}>
            <div style={{ paddingTop: '100px' }}></div>
            <h1 className="font-weight-bold text-center">
              {t('public.error.uhoh')}<br />
              {t('public.error.404')}
            </h1>
            <p className="mb-5 text-center text-muted">
              {msg || t('public.error.404-lead')}
            </p>
            <div className="text-center">
              <Link to="/">
                <Button>{t('public.error.btn-back-to-safety')}</Button>
              </Link>
            </div>
          </Col>
          <Col md={{ span: 4, offset: 2 }}>
            <img src={pathfinder} alt="Teamwork" className="img-fluid" />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default E404;
