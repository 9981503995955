import React from 'react';

import { useTranslation } from 'react-i18next';
import { ReactComponent as GuestAvatar } from '../../assets/img/artwork/guest_avatar.svg';


const MemberAvatar = ({ member, height = 32, width = 32, className = 'rounded-circle', border = 'border border-1 border-dark-subtle' }) => {

  const { t } = useTranslation();

  return (
    <>
      {member.thumbnail && (
        <img
          alt={t('forms.alt-tags.avatar')}
          className={`${className} ${border}`}
          height={height}
          src={`${process.env.REACT_APP_MEDIA_URL}${member.thumbnail}`}
          width={width}
        />
      )}

      {!member.thumbnail && member.gravatar && (
        <img
          alt={t('forms.alt-tags.avatar')}
          className={`${className} ${border}`}
          height={height}
          src={member.gravatar}
          width={width}
        />
      )}

      {!member.thumbnail && !member.gravatar && (
        <GuestAvatar
          alt={t('forms.alt-tags.avatar')}
          className={`${className} ${border}`}
          height={height}
          width={width}
        />
      )}
    </>
  );
};

export default MemberAvatar;
