import React from 'react';

import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const FreeFallbackModal = ({ handler, currentSubscription }) => {

  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleOk = () => {
    opened.set(false);
    ok();
  };

  return (
    <Modal show={opened.value} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('modals.free-fallback.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{t('modals.free-fallback.lead')}</p>
        <p>
          {t('modals.free-fallback.renewal')}:
          <br />
          {currentSubscription.extend && <span className="text-success">{t('modals.free-fallback.active')}</span>}
          {!currentSubscription.extend && <span className="text-success">{t('modals.free-fallback.inactive')}</span>}
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={handleOk}>{t('forms.actions.ok')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FreeFallbackModal;
