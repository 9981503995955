import React, { useCallback, useEffect, useState } from 'react';

import { useApolloClient, useLazyQuery } from '@apollo/client';
import { faPencil, faSort, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { confirm } from '../../components/basic/Confirmation';
import { GET_ATTRIBUTE_FOR_EDITING } from '../../components/collections/queries';
import { deleteObj, getOptions, postData, putData } from '../../utils/axiosHelper';
import ChoiceModal from './ModalChoice';
import ModalDeleteAttribute from './ModalDeleteAttribute';


const AttributeModal = ({ handler, attributePk }) => {

  const [disabled, setDisabled] = useState(false);
  const [thisAttributePk, setThisAttributePk] = useState(attributePk);
  const [options, setOptions] = useState({});
  const client = useApolloClient();
  const { opened, ok, cancel, backdrop } = handler;
  const { t } = useTranslation();

  const valueFromId = (opts, id) => opts.find((o) => `${o.value}` === id);

  const [initialData, setInitialData] = useState({
    name: '',
    pk: thisAttributePk !== false && thisAttributePk,
    sort_choices_alphabetical: false,
    type_of: '',
  });

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleDelete = () => {
    deleteModalHandler.opened.set(true);
  };

  const handleEdit = async (values, closeOnSave = true) => {

    var formData = { ...values };

    setDisabled(true);

    const brot = toast.loading(t('widgets.toast.saveing'));

    let method = thisAttributePk ? putData : postData;

    return await method(`warehouse/attributes/${thisAttributePk ? `${thisAttributePk}/` : ''}`, formData).then(async (response) => {
      if (thisAttributePk) {
        toast.update(brot, { render: t('modals.attribute.updated-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      } else {
        toast.update(brot, { render: t('modals.attribute.saved-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      }

      await client.clearStore();
      ok(response.data);

      if (closeOnSave) {
        opened.set(false);
      }

      return response.data;
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 2000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const [getAttributeForEditing, { refetch }] = useLazyQuery(GET_ATTRIBUTE_FOR_EDITING, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setInitialData({
        choices: data.attributeForEditing.choices,
        name: data.attributeForEditing.name,
        pk: data.attributeForEditing.pk,
        sort_choices_alphabetical: data.attributeForEditing.sortChoicesAlphabetical,
        type_of: data.attributeForEditing.typeOf.substr(2),
      });
    },
  });

  const getFormOptions = useCallback(async () => {
    setOptions({});
    const response = await getOptions('warehouse/attributes/');
    if (response) {
      setOptions(response.data.actions.POST);
    }
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    onSubmit: (values) => handleEdit(values),
  });

  const removeChoice = async (choicePk) => {

    let result = await confirm({
      title: t('modals.attribute.delete-choice-hint-title'),
      lead: t('modals.attribute.delete-choice-hint-lead'),
      showCancel: true,
      danger: false,
    })

    if (result === false) return;

    setDisabled(true);

    const brot = toast.loading(t('widgets.toast.saveing'));

    await deleteObj(`warehouse/choices/${choicePk}/`, { pk: choicePk }).then(async (response) => {
      toast.update(brot, { render: t('modals.attribute.choice-deleted-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      await client.clearStore();
      refetch();
    }).catch((error) => {
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });

  };

  const choiceModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: async (response) => {
      refetch();
    },
  };

  const deleteModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      opened.set(false);
      ok('destroyed');
    },
  };

  const openChoiceModal = async () => {

    if (thisAttributePk === false) {
      let newRecord = await handleEdit(formik.values, false);
      setThisAttributePk(newRecord.pk);
      choiceModalHandler.opened.set(true);
    }

    choiceModalHandler.opened.set(true);
  };

  useEffect(() => {
    getFormOptions();
  }, [getFormOptions, thisAttributePk]);

  useEffect(() => {
    if (thisAttributePk !== false) {
      getAttributeForEditing({
        variables: { attributeId: thisAttributePk }
      });
    }
  }, [getAttributeForEditing, thisAttributePk]);

  return (
    <>
      {choiceModalHandler.opened.value && <ChoiceModal handler={choiceModalHandler} attributePk={thisAttributePk} choicePk={choiceModalHandler.opened.value !== true && choiceModalHandler.opened.value} />}
      {deleteModalHandler.opened.value && <ModalDeleteAttribute handler={deleteModalHandler} attributePk={thisAttributePk} />}
      <Modal show={opened.value} onHide={handleClose} backdrop={disabled || backdrop ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>
            {formik.values.pk && t('modals.attribute.title-edit')}
            {!formik.values.pk && t('modals.attribute.title-create')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={disabled}>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.attribute.field-name')}</Form.Label>
              <Form.Control
                autoComplete="off"
                isInvalid={formik.errors?.fields?.name}
                name="name"
                onChange={formik.handleChange}
                type="text"
                value={formik.values.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.attribute.field-type-of')}</Form.Label>
              <Form.Control type="hidden" isInvalid={formik.errors?.fields?.type_of} />
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                getOptionLabel={(option) => option.display_name}
                getOptionValue={(option) => option.value}
                isClearable={false}
                isDisabled={disabled}
                isSearchable={false}
                onChange={(opt) => formik.setFieldValue('type_of', opt?.value || '')}
                options={options?.type_of?.choices}
                placeholder={t('modals.attribute.field-type-of-placeholder')}
                value={valueFromId(options?.type_of?.choices || [], formik.values?.type_of)}
                styles={{
                  control: styles => ({
                    ...styles,
                    borderColor: formik.errors?.fields?.type_of ? '#dc3545' : styles.borderColor,
                    '&:hover': {
                      borderColor: formik.errors?.fields?.type_of ? '#dc3545' : styles['&:hover'].borderColor,
                    }
                  })
                }}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.type_of}
              </Form.Control.Feedback>
            </Form.Group>
            {`${formik.values.type_of}` === '3' && (
              <>
                <Form.Group className="mb-0">
                  <Form.Label>{t('modals.attribute.field-options')}</Form.Label>
                </Form.Group>
                <Table size="sm" className="mb-0" striped hover bordered>
                  <thead>
                    <tr>
                      <th width="30" className="text-center">
                        <FontAwesomeIcon icon={faSort} size="sm" />
                      </th>
                      <th>{t('modals.attribute.attr-table-label')}</th>
                      <th width="100">{t('modals.attribute.attr-table-value')}</th>
                      <th width="60"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values.choices && formik.values.choices.map((choice, index) => (
                      <tr key={index} className={`${!choice.pk ? 'table-warning' : ''}`}>
                        <td className="text-center">{choice.ordering}</td>
                        <td>{choice.label}</td>
                        <td>{choice.value}</td>
                        <td className="text-left ps-2">
                          <FontAwesomeIcon icon={faTrashCan} size="sm" onClick={() => removeChoice(choice.pk)} fixedWidth />
                          <FontAwesomeIcon icon={faPencil} size="sm" className="ps-2" onClick={() => choiceModalHandler.opened.set(choice.pk)} fixedWidth />
                        </td>
                      </tr>
                    ))}
                    {(!formik.values.choices || formik.values.choices.length === 0) && (
                      <tr>
                        <td colSpan="100" className="text-center py-4">
                          - {t('modals.attribute.no-choices-assigned')} -
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="d-flex pt-2">
                  <Form.Check
                    inline
                    type="checkbox"
                    id="mode-cb-sca"
                    checked={formik.values.sort_choices_alphabetical === true}
                    onChange={(event) => formik.setFieldValue('sort_choices_alphabetical', event.target.checked)}
                    label={t('modals.attribute.field-sort-choices-alphabetical')}
                  />
                  <Button variant="link" className="ms-auto pe-0 pt-0" onClick={openChoiceModal}>
                    {t('modals.attribute.add-new-option')}
                  </Button>
                </div>
              </>
            )}
          </fieldset>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Button variant="danger" className="me-auto" onClick={handleDelete} disabled={disabled}>{t('forms.actions.delete')}</Button>
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>
            {formik.values.pk && t('forms.actions.save')}
            {!formik.values.pk && t('forms.actions.create')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AttributeModal;
