import React from 'react';

import { faCircleInfo, faFingerprint, faHouse, faPenToSquare, faPersonWalkingArrowRight, faRotate, faTrashCan, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Nav, Navbar, Spinner } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


const GroupToolbar = ({
  group,
  infoSidebarHandler,
  loading,
  permissionSidebarHandler,
  refetch,
  showBreadcrumps = false,
  showDeleteDialog,
  showEditDialog,
  showInviteDialog,
  showLeaveGroupDialog,
}) => {
  const { t } = useTranslation();

  return (
    <Navbar className="bg-body-tertiary border-bottom" expand={true}>
      <Container fluid={true}>
        <Navbar.Toggle aria-controls="group-navbar-nav" />
        <Navbar.Collapse id="group-navbar-nav">
          {showBreadcrumps && (
            <Nav className="d-none d-sm-flex">
              <Breadcrumb>
                <Breadcrumb.Item className="d-none d-md-block" linkAs={Link} linkProps={{ to: "/private/groups" }}>{t('pages.groups.headline')}</Breadcrumb.Item>
                <Breadcrumb.Item className="d-block d-md-none" linkAs={Link} linkProps={{ to: "/private/groups" }}><FontAwesomeIcon icon={faHouse} /></Breadcrumb.Item>
                <Breadcrumb.Item active>{group.name}</Breadcrumb.Item>
                {loading && <Breadcrumb.Item active><Spinner animation="border" size="sm"></Spinner></Breadcrumb.Item>}
              </Breadcrumb>
            </Nav>
          )}
          <Nav className="pe-2">
            <Nav.Link disabled={loading} onClick={() => showInviteDialog(true)}>
              <FontAwesomeIcon size="lg" icon={faUserPlus} fixedWidth />
            </Nav.Link>
          </Nav>
          {group.myMembership?.role === 'A_1' && (
            <Nav className="border-start px-2">
              <Nav.Link disabled={loading} onClick={() => showEditDialog(true)}>
                <FontAwesomeIcon size="lg" icon={faPenToSquare} fixedWidth />
              </Nav.Link>
              <Nav.Link disabled={loading} onClick={() => showDeleteDialog(true)}>
                <FontAwesomeIcon size="lg" icon={faTrashCan} fixedWidth />
              </Nav.Link>
            </Nav>
          )}
          <Nav className="border-start ps-2">
            <Nav.Link disabled={loading} onClick={() => showLeaveGroupDialog(true)}>
              <FontAwesomeIcon size="lg" icon={faPersonWalkingArrowRight} fixedWidth />
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto ps-2">&nbsp;</Nav>
          <Nav className="ms-2 border-start ps-2">
            <Nav.Link disabled={loading} onClick={() => refetch()}>
              <FontAwesomeIcon size="lg" icon={faRotate} fixedWidth />
            </Nav.Link>
            <Nav.Link disabled={loading} onClick={() => permissionSidebarHandler.opened.set(!permissionSidebarHandler.opened.value)}>
              <FontAwesomeIcon size="lg" icon={faFingerprint} fixedWidth />
            </Nav.Link>
            <Nav.Link disabled={loading} onClick={() => infoSidebarHandler.opened.set(!infoSidebarHandler.opened.value)}>
              <FontAwesomeIcon size="lg" icon={faCircleInfo} fixedWidth />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default GroupToolbar;
