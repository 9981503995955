import React, { useEffect, useState } from 'react';

import { useApolloClient, useLazyQuery } from '@apollo/client';
import { faArrowTurnDown, faPencil, faPlus, faSort, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { Button, Form, InputGroup, Modal, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { GET_ATTRIBUTES, GET_TYPE_FOR_EDITING } from '../../components/collections/queries';
import { putData } from '../../utils/axiosHelper';
import AttributeModal from './ModalAttribte';
import TypeModalDelete from './ModalDeleteType';


const TypeModalEdit = ({ handler, original }) => {

  const [assignedAttributes, setAssignedAttributes] = useState([]);
  const [availableAttributes, setAvailableAttributes] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [preselect, setPreselect] = useState();
  const [selectedNewAttribute, setSelectedNewAttribute] = useState();
  const [type, setType] = useState();
  const client = useApolloClient();
  const { opened, ok, cancel, backdrop } = handler;
  const { t } = useTranslation();

  const [initialData, setInitialData] = useState({
    attributes: [],
    name: original.name,
    pk: original.pk,
    show_best_before: original.showBestBefore,
    show_consumption: original.showConsumption,
  });

  const [getAttributes, attributeQueryKing] = useLazyQuery(GET_ATTRIBUTES, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setAvailableAttributes(data.attributes);
      data.attributes.edges.forEach((attr) => {
        if (attr.node.pk === preselect) {
          setSelectedNewAttribute(attr);
        }
      });
    },
  });

  const [getTypeForEditing, { refetch }] = useLazyQuery(GET_TYPE_FOR_EDITING, {
    variables: { typeId: original.pk },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setInitialData({
        attributes: data.typeForEditing.definitions.map(definition => definition.attribute.pk),
        name: data.typeForEditing.name,
        pk: data.typeForEditing.pk,
        show_best_before: data.typeForEditing.showBestBefore,
        show_consumption: data.typeForEditing.showConsumption,
      });
      setAssignedAttributes(data.typeForEditing.definitions);
      setType(data.typeForEditing);
    },
  });

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleDelete = () => {
    deleteModalHandler.opened.set(true);
  };

  const removeDefinition = (attributePk) => {

    if (!attributePk) {
      return;
    }

    if (formik.values.attributes.indexOf(attributePk) === -1) {
      return;
    }

    let tmpAttributes = [...formik.values.attributes];
    let tmpAssignedAttributes = [...assignedAttributes];
    let index = tmpAttributes.indexOf(attributePk);
    let index2 = tmpAssignedAttributes.findIndex((element, index, array) => {
      return element.attribute.pk === attributePk
    });


    tmpAttributes.splice(index, 1);
    tmpAssignedAttributes.splice(index2, 1);
    formik.setFieldValue('attributes', tmpAttributes);
    setAssignedAttributes(tmpAssignedAttributes);
  };

  const addNewAttribute = () => {

    if (!selectedNewAttribute) {
      return
    }

    if (formik.values.attributes.indexOf(selectedNewAttribute.node.pk) > -1) {
      return
    }

    let tmpAttributes = [...formik.values.attributes];
    let tmpAssignedAttributes = [...assignedAttributes];

    tmpAttributes.push(selectedNewAttribute.node.pk);
    tmpAssignedAttributes.push({
      ordering: tmpAttributes.length,
      attribute: selectedNewAttribute.node,
    })

    formik.setFieldValue('attributes', tmpAttributes);
    setAssignedAttributes(tmpAssignedAttributes);
    setSelectedNewAttribute(null);
  };

  const handleEdit = async (values) => {

    var formData = {
      pk: values.pk,
      name: values.name,
      show_best_before: values.show_best_before,
      show_consumption: values.show_consumption,
    };

    formData['type_definitions'] = JSON.stringify(assignedAttributes.map((definition) => {
      return {
        attribute: definition.attribute.pk,
        ordering: definition.ordering,
        pk: definition.pk,
      };
    }));


    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.loading'));
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    await putData(`warehouse/types/${type.pk}/`, formData, { config }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.type-edit.updated-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      await client.clearStore();
      ok();
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });

  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    onSubmit: (values) => handleEdit(values),
  });

  const deleteModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      opened.set(false);
      ok('destroyed');
    },
  };

  const attributeModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: async (response) => {
      if (response) {
        if (response === 'destroyed') {
          // Deleted
          refetch();
        } else if (response.success === true) {
          // Update
          refetch();
        } else {
          // Create
          setPreselect(response?.pk);
          await attributeQueryKing.refetch();
        }
      }
    },
  };

  useEffect(() => {
    getTypeForEditing();
    getAttributes();
  }, [original.id, getTypeForEditing, getAttributes]);

  if (!type) return null;

  return (
    <>
      {deleteModalHandler.opened.value && <TypeModalDelete handler={deleteModalHandler} type={type} />}
      {attributeModalHandler.opened.value && <AttributeModal handler={attributeModalHandler} attributePk={attributeModalHandler.opened.value !== true && attributeModalHandler.opened.value} />}
      <Modal show={opened.value} onHide={handleClose} size="lg" backdrop={disabled || backdrop ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.type-edit.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={disabled}>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.type-edit.field-name')}</Form.Label>
              <Form.Control
                autoComplete="off"
                isInvalid={formik.errors?.fields?.name}
                name="name"
                onChange={formik.handleChange}
                type="text"
                value={formik.values.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.type-edit.field-options')}</Form.Label>
              <div>
                <Form.Check
                  inline
                  type="checkbox"
                  id="mode-cb-bb"
                  checked={formik.values.show_best_before === true}
                  onChange={(event) => formik.setFieldValue('show_best_before', event.target.checked)}
                  label={t('modals.type-edit.field-best-before')}
                />
                <Form.Check
                  inline
                  type="checkbox"
                  id="mode-cb-consumption"
                  checked={formik.values.show_consumption === true}
                  onChange={(event) => formik.setFieldValue('show_consumption', event.target.checked)}
                  label={t('modals.type-edit.field-consumption')}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.type-edit.field-new-attributes')}</Form.Label>
              <InputGroup>
                <Button variant="secondary" onClick={() => attributeModalHandler.opened.set(true)}>
                  <FontAwesomeIcon icon={faPlus} size="sm" fixedWidth />
                </Button>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  getOptionLabel={(e) => e.node.name}
                  getOptionValue={(e) => e.node.pk}
                  isClearable={true}
                  name="new_attribute"
                  onChange={(o) => setSelectedNewAttribute(o)}
                  options={availableAttributes?.edges?.filter(e => formik.values?.attributes?.indexOf(e.node.pk) === -1) || []}
                  placeholder={''}
                  value={selectedNewAttribute}
                  styles={{
                    container: (baseStyles, state) => ({
                      ...baseStyles,
                      flex: '1 1 auto',
                      borderTopRightRadius: '0',
                      borderBottomRightRadius: '0',
                    }),
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderTopRightRadius: '0',
                      borderBottomRightRadius: '0',
                    }),
                  }}
                />
                <Button variant="secondary" disabled={!selectedNewAttribute} onClick={() => addNewAttribute()}>
                  {t('forms.actions.add')}
                  <FontAwesomeIcon icon={faArrowTurnDown} size="sm" className="ms-2" />
                </Button>
              </InputGroup>
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.availableAttributes}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Label>{t('modals.type-edit.field-assigned-attributes')}</Form.Label>
            <div className="rounded border mb-3">
              <Table size="sm" className="mb-0" striped hover bordered>
                <thead>
                  <tr>
                    <th width="30" className="text-center">
                      <FontAwesomeIcon icon={faSort} size="sm" />
                    </th>
                    <th>{t('modals.type-edit.attr-table.name')}</th>
                    <th width="100">{t('modals.type-edit.attr-table.type')}</th>
                    <th width="60"></th>
                  </tr>
                </thead>
                <tbody>
                  {assignedAttributes && assignedAttributes.map((definition, index) => (
                    <tr key={index} className={`${!definition.pk ? 'table-warning' : ''}`}>
                      <td className="text-center">{definition.ordering}</td>
                      <td>{definition.attribute.name}</td>
                      <td>{definition.attribute.getTypeOf}</td>
                      <td className="text-left ps-2">
                        <FontAwesomeIcon icon={faTrashCan} size="sm" onClick={() => removeDefinition(definition.attribute.pk)} fixedWidth />
                        {definition.attribute.isPublic === false && (
                          <FontAwesomeIcon icon={faPencil} size="sm" className="ps-2" onClick={() => attributeModalHandler.opened.set(definition.attribute.pk)} fixedWidth />
                        )}
                      </td>
                    </tr>
                  ))}
                  {(!assignedAttributes || assignedAttributes.length === 0) && (
                    <tr>
                      <td colSpan="100" className="text-center py-4">
                        - {t('modals.type-edit.attr-table.no-attributes-assigned')} -
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </fieldset>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Button variant="danger" className="me-auto" onClick={handleDelete} disabled={disabled}>{t('forms.actions.delete')}</Button>
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.update')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TypeModalEdit;
