import React from 'react';

import { useTranslation } from 'react-i18next';
import ModalImage from 'react-modal-image';
import { Link } from 'react-router-dom';
import Formi from '../../components/basic/Formi';


const ElementSidebarInfo = ({ handler, element }) => {

  const { opened } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
  };

  if (!element) return null;

  return (
    <>
      <div className="panel bg-body-tertiary border-start flex-fill mw-330 overflow-hidden">
        <div className="app">
          <div className="module modal">
            <div className="modal-header">
              <div className="modal-title h5">{t('sidebars.element-info.title')}</div>
              <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
            </div>
            <div className="mainspace p-3">
              {element.item.imageThumbnail && (
                <ModalImage
                  alt={element.item.name}
                  className="img-fluid rounded border mb-3 bg-body"
                  large={`${process.env.REACT_APP_MEDIA_URL}${element.item.imageFull}`}
                  showRotate={true}
                  small={`${process.env.REACT_APP_MEDIA_URL}${element.item.imageThumbnail}`}
                />
              )}
              {!element.item.imageThumbnail && element.item?.typeOf?.itemImageThumbnail && (
                <img
                  alt={element.item.name}
                  className="img-fluid rounded border mb-3 bg-white"
                  src={`${process.env.REACT_APP_MEDIA_URL}${element.item?.typeOf?.itemImageThumbnail}`}
                />
              )}
              <dl>
                <dt>{t('sidebars.element-info.item-name')}</dt>
                <dd>
                  <Link to={`/private/items/${element.item?.pk}`}>{element.item.name}</Link>
                </dd>
                {element.item?.typeOf && (
                  <>
                    <dt>{t('sidebars.element-info.item-type')}</dt>
                    <dd>{element.item?.typeOf?.name}</dd>
                  </>
                )}
                <dt>{t('sidebars.element-info.created-at')}</dt>
                <dd>
                  <Formi dateTimeVal={element.createdAt} />
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ElementSidebarInfo;
