import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { Binoculars } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ISBNLookupResult from '../../components/items/ISBNLookupResult';
import { postData } from '../../utils/axiosHelper';


const ItemModalAddBook = ({ handler }) => {

  const [disabled, setDisabled] = useState(false);
  const [isbnLookup, setIsbnLookup] = useState();
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleSave = async (values) => {

    setDisabled(true);
    setIsbnLookup();
    formik.setErrors({});
    const brot = toast.loading(t('widgets.toast.loading'));

    await postData('warehouse/items/book/', { isbn_13: values?.isbn_13 }, { sleep: 100 }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.item-create-book.book-creation-success'), type: 'success', isLoading: false, autoClose: 2000 });
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
    }).finally(() => {
      toast.dismiss(brot);
      setDisabled(false);
    });

  };


  const lookupISBN = async (isbn) => {

    setDisabled(true);
    setIsbnLookup();
    formik.setErrors({});
    const brot = toast.loading(t('widgets.toast.loading'));

    await postData('warehouse/lookup/isbn13', { isbn_13: isbn }, { sleep: 1000 }).then(async (response) => {
      setIsbnLookup(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
    }).finally(() => {
      toast.dismiss(brot);
      setDisabled(false);
    });

  };


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      isbn_13: '',
    },
    onSubmit: (values) => handleSave(values),
  });

  return (
    <>
      <Modal show={opened.value} onHide={handleClose} backdrop={disabled ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.item-create-book.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={disabled}>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.item-create-book.field-isbn-13')}</Form.Label>
              <InputGroup>
                <Form.Control
                  isInvalid={formik.errors?.fields?.isbn_13}
                  name="isbn_13"
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.isbn_13}
                />
                <Button
                  variant="outline-primary"
                  disabled={!formik.values.isbn_13}
                  onClick={() => lookupISBN(formik.values.isbn_13)}
                >
                  <Binoculars />
                </Button>
              </InputGroup>
              <Form.Control
                isInvalid={formik.errors?.fields?.isbn_13}
                type="hidden"
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.isbn_13}
              </Form.Control.Feedback>
            </Form.Group>
            {isbnLookup && <ISBNLookupResult result={isbnLookup} />}
          </fieldset>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.create')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default ItemModalAddBook;
