import React from 'react';

import { useTranslation } from 'react-i18next';
import { currency, decimal } from '../../utils/formatter';


const SidebarInfoInventory = ({ handler, responseData }) => {

  const { opened } = handler;
  const { t, i18n } = useTranslation();

  const handleClose = () => {
    opened.set(false);
  };

  return (
    <>
      <div className="panel bg-body-tertiary border-start flex-fill mw-330 overflow-hidden">
        <div className="app">
          <div className="module modal">
            <div className="modal-header">
              <div className="modal-title h5">{t('sidebars.inventory-info.title')}</div>
              <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
            </div>
            <div className="mainspace p-3">
              <dl>
                <dt>{t('sidebars.inventory-info.element-count')}</dt>
                <dd>{responseData?.inventorySummary?.totalElements ? decimal(responseData.inventorySummary.totalElements, i18n.resolvedLanguage) : t('forms.values.unavailable')}</dd>
                {responseData?.inventorySummary?.totalElements !== responseData?.elements?.totalAmount && (
                  <>
                    <dt>{t('sidebars.inventory-info.total-amount')}</dt>
                    <dd>{responseData?.inventorySummary?.totalAmount ? decimal(responseData.inventorySummary.totalAmount, i18n.resolvedLanguage) : t('forms.values.unavailable')}</dd>
                  </>
                )}
                {responseData?.inventorySummary?.totalElements !== responseData?.elements?.totalCount && (
                  <>
                    <dt>{t('sidebars.inventory-info.element-count-filtered')}</dt>
                    <dd>{responseData?.elements?.totalCount ? decimal(responseData.elements.totalCount, i18n.resolvedLanguage) : t('forms.values.unavailable')}</dd>
                  </>
                )}
                <dt>{t('sidebars.inventory-info.total-value')}</dt>
                <dd>{responseData?.inventorySummary?.totalValue ? currency(responseData.inventorySummary.totalValue, i18n.resolvedLanguage) : t('forms.values.unavailable')}</dd>
                <dt>{t('sidebars.inventory-info.total-purchasing-price')}</dt>
                <dd>{responseData?.inventorySummary?.totalPurchasingPrice ? currency(responseData.inventorySummary.totalPurchasingPrice, i18n.resolvedLanguage) : t('forms.values.unavailable')}</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarInfoInventory;
