import React from 'react';

import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { relayStylePagination } from '@apollo/client/utilities';
import { Outlet } from 'react-router-dom';
import Main from '../components/basic/Main';
import Footer from '../components/public/Footer';
import Navbar from '../components/public/Navbar';
import i18n from '../i18n';


const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        collections: relayStylePagination(),
      },
    },
  },
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (networkError?.statusCode === 401) {
    window.location = '/';
  }
}
);


const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'Accept-Language': i18n.resolvedLanguage,
    },
  };
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GQL,
});


const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: cache,
});

const Collection = ({ children }) => (
  <>
    <Navbar variant='blue' />
    <Main className="main-landing">
      <ApolloProvider client={client}>
        <div style={{ marginTop: '40px', marginBottom: '40px' }}>
          {children}
          <Outlet />
        </div>
      </ApolloProvider>
    </Main>
    <Footer spacer={false} variant='blue' />
  </>
);

export default Collection;
