import React, { useState } from 'react';

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import teamwork from '../../../assets/img/illustrations/teamwork.png';
import useAuth from '../../../hooks/useAuth';
import { postData } from '../../../utils/axiosHelper';


const AccountDeletionConfirmation = () => {

  const [disabled, setDisabled] = useState();
  const navigate = useNavigate();
  const { signature } = useParams();
  const { signOut } = useAuth();
  const { t } = useTranslation();

  const deleteMe = async (values) => {

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.saving'));

    return await postData('auth/user/delete/confirm/', values, { sleep: 500 }).then(async (response) => {
      signOut();
      navigate('/');
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
      return false;
    }).finally(() => {
      setDisabled(false);
      toast.dismiss(brot);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      signature: signature,
    },
    onSubmit: async (values) => {
      deleteMe(values);
    },
  });

  return (
    <>
      <Helmet title={t('public.auth.account-deletion-confirmation-title')} />
      <Container>
        <Row style={{ margin: '100px 0 150px 0' }}>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 6, offset: 1 }} className="pb-5">
            <h1 className="text-center">
              {t('public.auth.account-deletion-confirmation-title')}
            </h1>
            <p className="text-center lead text-muted">{t('public.auth.account-deletion-confirmation-lead')}</p>
            <div className="text-center text-warning">
              <FontAwesomeIcon icon={faTriangleExclamation} size="2x" />
              <br />
              <br />
              <Button variant="danger" className="mb-5" disabled={disabled} onClick={formik.handleSubmit}>
                {t('public.auth.account-deletion-confirmation-btn')}
              </Button>
              <Form.Group className="mb-3">
                <Form.Control isInvalid={formik.errors?.fields?.signature} type="hidden" />
                <Form.Control.Feedback type="invalid">
                  <p className="lead">{formik.errors?.fields?.signature}</p>
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Col>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 3, offset: 1 }} className="pb-5">
            <img src={teamwork} alt="Teamwork" className="img-fluid" />
          </Col>
        </Row>
      </Container>

    </>
  );

}

export default AccountDeletionConfirmation;
