import React from 'react';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { NavLink } from 'react-router-dom';


const infoFromGroupPermission = (groupPermission) => {

  if (!groupPermission)
    return null;

  if (groupPermission?.group.icon)
    return {
      to: `/private/groups/${groupPermission.group.pk}`,
      img: `${groupPermission.group.icon}`,
      alt: `${groupPermission.group.name}`,
    };

};

const infoFromGroup = (group) => {

  if (!group)
    return null;

  if (group?.icon)
    return {
      to: `/private/group/${group.pk}`,
      img: `${group.icon}`,
      alt: `${group.name}`,
    };

};

const infoFromItem = (item) => {

  if (!item)
    return null;

  if (item?.icon)
    return {
      to: `/private/items/${item.pk}`,
      img: `${item.icon}`,
      alt: `${item.name}`,
    };

};

const infoFromElement = (element) => {

  if (!element)
    return null;

  if (element.item?.icon)
    return {
      to: `/private/collections/${element.collection.pk}/${element.pk}`,
      img: `${element.item.icon}`,
      alt: `${element.item.name}`,
    };

  if (!element.item?.icon && element.item.typeOf?.icon)
    return {
      to: `/private/collections/${element.collection.pk}/${element.pk}`,
      img: `${element.item.typeOf?.icon}`,
      alt: `${element.name}`,
    };

};

const infoFromCollection = (collection) => {

  if (!collection)
    return null;

  if (collection.icon)
    return {
      to: `/private/collections/${collection.pk}`,
      img: `${collection.icon}`,
      alt: `${collection.name}`,
    };

  if (!collection.icon && collection.defaultType?.icon)
    return {
      to: `/private/collections/${collection.pk}`,
      img: `${collection.defaultType.icon}`,
      alt: `${collection.name}`,
    };

  return null;
};

const renderParentBreadcrumps = ({ obj, element = null, collection = null, showImage = false, usePublicAddr = false, groupId = null }) => {

  var parent = collection || obj?.parent;
  var breadcrumbs = []

  if (element) {
    breadcrumbs.unshift({
      pk: element.pk,
      active: true,
      name: element.item.name,
      imgRepr: infoFromElement(element),
      url: `/private/collections/${element.collection.pk}/${element.pk}`,
    })
  }

  while (parent) {
    breadcrumbs.unshift({
      pk: parent.pk,
      name: parent.name,
      imgRepr: infoFromCollection(parent),
      url: usePublicAddr
        ? `/c/${parent.publicSlug || parent.publicPk}`
        : groupId === null
          ? `/private/collections/${parent.pk}`
          : `/private/groups/${groupId}/c/${parent.pk}`
    })

    parent = parent.parent;
  }

  if (breadcrumbs.length === 0) return null;

  return (
    <>
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <Breadcrumb.Item
            key={index}
            linkAs={NavLink}
            active={breadcrumb.active || (!element && index === breadcrumbs.length - 1)}
            linkProps={{ to: breadcrumb.url }}
          >
            {showImage && breadcrumb.imgRepr && (
              <img
                alt={breadcrumb.name}
                title={breadcrumb.name}
                className="img-fluid rounded-circle me-1 align-text-top"
                src={`${process.env.REACT_APP_MEDIA_URL}${breadcrumb.imgRepr.img}`}
                width="20"
                height="20"
                style={{ maxHeight: '20px', minHeight: '20px' }}
              />
            )}
            {breadcrumb.name}
          </Breadcrumb.Item>
        );
      })}
    </>
  );

};

export { infoFromGroup, infoFromGroupPermission, infoFromItem, renderParentBreadcrumps };
