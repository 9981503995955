import React, { useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { Binoculars } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { GET_COLLECTIONS } from '../../components/collections/queries';
import ISBNLookupResult from '../../components/items/ISBNLookupResult';
import { postData } from '../../utils/axiosHelper';


const ElementModalAddBook = ({ handler, collection }) => {

  const [defaultCollectionOptions, setDefaultCollectionOptions] = useState(collection ? [{ node: collection }] : null);
  const [disabled, setDisabled] = useState(false);
  const [isbnLookup, setIsbnLookup] = useState();
  const [selectedCollection, setSelectedCollection] = useState(collection ? { node: collection } : null);
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleSave = async (values) => {

    setDisabled(true);
    setIsbnLookup();
    formik.setErrors({});
    const brot = toast.loading(t('widgets.toast.loading'));
    let formdata = {
      isbn_13: values?.isbn_13,
      collection: selectedCollection?.node?.pk,
    };

    await postData('warehouse/elements/book/', formdata, { sleep: 100 }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('pages.items.book-creation-success'), type: 'success', isLoading: false, autoClose: 2000 });
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
    }).finally(() => {
      toast.dismiss(brot);
      setDisabled(false);
    });

  };


  const lookupISBN = async (isbn) => {

    setDisabled(true);
    setIsbnLookup();
    formik.setErrors({});
    const brot = toast.loading(t('widgets.toast.loading'));

    await postData('warehouse/lookup/isbn13', { isbn_13: isbn }, { sleep: 1000 }).then(async (response) => {
      setIsbnLookup(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
    }).finally(() => {
      toast.dismiss(brot);
      setDisabled(false);
    });

  };

  const [getMyCollections] = useLazyQuery(GET_COLLECTIONS, {
    variables: {
      name_Icontains: null,
      mine: true,
      orderBy: 'name',
    },
  });

  const asyncCollectionOptions = async (search) => {
    var response = await getMyCollections({ variables: { name_Icontains: search } });
    setDefaultCollectionOptions(response?.data?.collections?.edges || []);
    return response?.data?.collections?.edges || [];
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      isbn_13: '',
    },
    onSubmit: (values) => handleSave(values),
  });

  return (
    <>
      <Modal show={opened.value} onHide={handleClose} backdrop={disabled ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.element-create-book.title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <fieldset disabled={disabled}>

            <Form.Group className="mb-3">
              <Form.Label>{t('modals.element-create-book.field-collection')}</Form.Label>
              <Form.Control
                type="hidden"
                isInvalid={formik.errors?.fields?.collection}
              />
              <AsyncSelect
                cacheOptions
                defaultOptions={defaultCollectionOptions}
                getOptionLabel={(e) => e.node.name}
                getOptionValue={(e) => e.node.pk}
                loadOptions={asyncCollectionOptions}
                name="collection"
                onChange={(o) => { setSelectedCollection(o); formik.setFieldValue("collection", o?.node || null) }}
                placeholder={t('modals.element-create-book.field-collection-placeholder')}
                value={selectedCollection}
                styles={{
                  control: styles => ({
                    ...styles,
                    borderColor: formik.errors?.fields?.collection ? '#dc3545' : styles.borderColor,
                    '&:hover': {
                      borderColor: formik.errors?.fields?.collection ? '#dc3545' : styles['&:hover'].borderColor,
                    }
                  })
                }}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.collection}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{t('modals.element-create-book.field-isbn-13')}</Form.Label>
              <InputGroup>
                <Form.Control
                  isInvalid={formik.errors?.fields?.isbn_13}
                  name="isbn_13"
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.isbn_13}
                />
                <Button
                  style={{ zIndex: '0' }}
                  variant="outline-primary"
                  disabled={!formik.values.isbn_13}
                  onClick={() => lookupISBN(formik.values.isbn_13)}
                >
                  <Binoculars />
                </Button>
              </InputGroup>
              <Form.Control
                isInvalid={formik.errors?.fields?.isbn_13}
                type="hidden"
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.isbn_13}
              </Form.Control.Feedback>
            </Form.Group>
            {isbnLookup && <ISBNLookupResult result={isbnLookup} />}
          </fieldset>
        </Modal.Body>

        <Modal.Footer className="d-flex">
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.create')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default ElementModalAddBook;
