import { gql } from '@apollo/client';


const GET_ITEM_BY_ID = gql`
  query ItemById(
    $id: String!
    ) {
    itemById(
      id: $id
    ) {
      createdAt
      description
      icon
      imageFull
      imageThumbnail
      name
      pk
      updatedAt
      userCanEdit
      website
      elements {
        pk
        collection {
          pk
          name
        }
      }
      properties {
        value
        attribute {
          pk
          name
          typeOf
        }
      }
      permissions {
        getRoleDisplay
        gravatar
        pk
        repr
        role
        thumbnail
      }
      typeOf {
        itemImageThumbnail
        name
        pk
      }
    }
  }
`;

const GET_ITEM_COMMENTS = gql`
  query ItemById(
    $id: String!
    ) {
    itemById(
      id: $id
    ) {
      icon
      imageFull
      imageThumbnail
      name
      pk
      updatedAt
      typeOf {
        itemImageThumbnail
        name
        pk
      }
      comments {
        createdAt
        gravatar
        message
        repr
        shortId
        thumbnail
      }
    }
  }
`;


const GET_ITEM_FOR_EDITING = gql`
  query EditItem(
    $itemId: String!
  ) {
    itemForEditing(id: $itemId) {
      cardImage
      cardImageThumbnail
      description
      id
      name
      pk
      website
      typeOf {
        pk
        name
        cardImageThumbnail
      }
      properties {
        value
        attribute {
          name
          pk
          typeOf
        }
      }
    }
    types(orderBy: "name") {
      edges {
        node {
          cardImageThumbnail
          id
          isPublic
          itemImageThumbnail
          name
          pk
          definitions {
            ordering
            attribute {
              getTypeOf
              name
              pk
              typeOf
              choices {
                isDefault
                label
                value
              }
            }
          }
        }
      }
    }
  }
`;


const GET_ITEMS = gql`
  query Items(
    $name_Icontains: String
    $pk: ID
    $after: String
    $first: Int
  ) {
    items(
      orderBy: "name"
      name_Icontains: $name_Icontains
      after: $after
      id: $pk
      first: $first
    ) {
      totalCount
      edges {
        cursor
        node {
          cardImageSquare
          cardImageThumbnail
          imageFull
          name
          pk
          typeOf {
            cardImageSquare
            createdAt
            itemImageThumbnail
            name
            pk
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edgeCount
    }
  }
`;


export {
  GET_ITEM_BY_ID,
  GET_ITEM_COMMENTS,
  GET_ITEM_FOR_EDITING,
  GET_ITEMS,
};
