import React, { useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { Binoculars } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { confirm } from '../../components/basic/Confirmation';
import { GET_COLLECTIONS } from '../../components/collections/queries';
import FoodLookupResult from '../../components/items/FoodLookupResult';
import { getObjs, postData } from '../../utils/axiosHelper';


const ItemModalAddFood = ({ handler, collection }) => {

  const [defaultCollectionOptions, setDefaultCollectionOptions] = useState(collection ? [{ node: collection }] : null);
  const [disabled, setDisabled] = useState(false);
  const [foodLookup, setFoodLookup] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(collection ? { node: collection } : null);
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const onSaveClick = () => {

    if (foodLookup.length === 0) {
      return;
    }

    if (foodLookup.length > 1) {
      confirm({
        title: t('modals.element-create-food.choose-hint-title'),
        lead: t('modals.element-create-food.choose-hint-lead'),
        showCancel: false,
        danger: false,
      });

      return
    }

    create(foodLookup[0].pk);
  };

  const [getMyCollections] = useLazyQuery(GET_COLLECTIONS, {
    variables: {
      name_Icontains: null,
      mine: true,
      orderBy: 'name',
    },
  });

  const asyncCollectionOptions = async (search) => {
    var response = await getMyCollections({ variables: { name_Icontains: search } });
    setDefaultCollectionOptions(response?.data?.collections?.edges || []);
    return response?.data?.collections?.edges || [];
  };

  const create = async (pk) => {

    setDisabled(true);
    formik.setErrors({});
    const brot = toast.loading(t('widgets.toast.saveing'));
    let formData = {
      resource_pk: pk,
      collection: selectedCollection?.node?.pk,
    }

    await postData('warehouse/elements/food/', formData, { sleep: 100 }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.element-create-food.created-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
    }).finally(() => {
      toast.dismiss(brot);
      setDisabled(false);
    });

  };

  const lookupBarcode = async (barcode) => {

    setDisabled(true);
    setFoodLookup([]);
    formik.setErrors({});
    const brot = toast.loading(t('widgets.toast.loading'));

    await getObjs(`warehouse/lookup/ean?barcode=${barcode}`, { sleep: 1000 }).then(async (response) => {
      setFoodLookup(response.data);
      if (response.data.length === 0) {
        formik.setErrors({ fields: { barcode: t('modals.element-create-food.product-not-found') } });
      }
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
    }).finally(() => {
      toast.dismiss(brot);
      setDisabled(false);
    });

  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      barcode: '',
    },
  });

  return (
    <>
      <Modal show={opened.value} onHide={handleClose} backdrop={disabled ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.element-create-food.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={disabled}>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.element-create-book.field-collection')}</Form.Label>
              <Form.Control
                type="hidden"
                isInvalid={formik.errors?.fields?.collection}
              />
              <AsyncSelect
                cacheOptions
                defaultOptions={defaultCollectionOptions}
                getOptionLabel={(e) => e.node.name}
                getOptionValue={(e) => e.node.pk}
                loadOptions={asyncCollectionOptions}
                name="collection"
                onChange={(o) => { setSelectedCollection(o); formik.setFieldValue("collection", o?.node || null) }}
                placeholder={t('modals.element-create-book.field-collection-placeholder')}
                value={selectedCollection}
                styles={{
                  control: styles => ({
                    ...styles,
                    borderColor: formik.errors?.fields?.collection ? '#dc3545' : styles.borderColor,
                    '&:hover': {
                      borderColor: formik.errors?.fields?.collection ? '#dc3545' : styles['&:hover'].borderColor,
                    }
                  })
                }}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.collection}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.element-create-food.field-barcode')}</Form.Label>
              <InputGroup>
                <Form.Control
                  isInvalid={formik.errors?.fields?.barcode}
                  name="barcode"
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.barcode}
                />
                <Button variant="outline-primary" disabled={!formik.values.barcode} onClick={() => lookupBarcode(formik.values.barcode)}>
                  <Binoculars />
                </Button>
              </InputGroup>
              <Form.Control isInvalid={formik.errors?.fields?.barcode} type="hidden" />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.barcode}
              </Form.Control.Feedback>
            </Form.Group>
            {foodLookup.length > 0 && foodLookup.map((food, index) => (
              <FoodLookupResult key={index} result={food} resultSize={foodLookup.length} createCallback={create} />
            ))}
          </fieldset>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="primary" onClick={onSaveClick} disabled={disabled || foodLookup.length <= 0}>{t('forms.actions.create')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default ItemModalAddFood;
