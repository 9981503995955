import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';


function SignIn({ goto }) {

  const [disabled, setDisabled] = useState(false);
  const { signIn } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const next = location?.state?.next || goto || '/private';

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
      submit: false,
    },
    onSubmit: async (values) => {
      const brot = toast.loading(t('widgets.toast.processing'));
      try {
        await signIn(values.email, values.password);
        navigate(next);
      } catch (error) {
        formik.setErrors({ fields: error.response.data });
      }
      setDisabled(false);
      toast.dismiss(brot);
    },
  });

  return (
    <form>
      <Form.Group className="mb-3">
        <Form.Label>{t('public.auth.field-email')}</Form.Label>
        <Form.Control
          type="email"
          name="email"
          autoComplete="current-password"
          value={formik.values.email}
          isInvalid={formik.errors?.fields?.email || formik?.errors?.fields?.errors?.__all__}
          onChange={formik.handleChange}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors?.fields?.email || formik?.errors?.fields?.errors?.__all__}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('public.auth.field-password')}</Form.Label>
        <Form.Control
          autoComplete="current-password"
          isInvalid={formik.errors?.fields?.password || formik?.errors?.fields?.errors?.__all__}
          name="password"
          onChange={formik.handleChange}
          type="password"
          value={formik.values.password}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors?.fields?.password || formik?.errors?.fields?.errors?.__all__}
        </Form.Control.Feedback>
      </Form.Group>
      <div className="text-center mt-3">
        <div className="d-grid gap-2">
          <Button type="submit" onClick={formik.handleSubmit} variant="primary" disabled={disabled}>
            {t('public.auth.btn-login')}
          </Button>
        </div>
      </div>
      <br />
      <small>
        <Link to="/auth/password-reset" className="text-decoration-none">
          {t('public.auth.btn-forgot-passwd')}
        </Link>
        <br />
        <Link to="/auth/register" className="text-decoration-none">
          {t('public.auth.btn-register-instead')}
        </Link>
      </small>
    </form>
  );
}

export default SignIn;
