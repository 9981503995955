import React, { useCallback, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { GET_COLLECTIONS } from '../../components/collections/queries';
import { postData } from '../../utils/axiosHelper';
import PlanChooserModal from '../accounting/ModalPlanChooser';


const ModalExport = ({ handler, collection, source = 'items' }) => {

  const [disabled, setDisabled] = useState(false);
  const [selectableCollectionOptions, setSelectedableCollectionOptions] = useState(collection ? [{ node: collection }] : null);
  const [selectedCollection, setSelectedCollection] = useState(collection ? [{ node: collection }] : null);
  const { opened, cancel, backdrop } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleExport = async (values) => {

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.exporting'));

    let formData = { ...values };

    if (formData.collection && formData.source === 'collection') {
      formData.source = 'elements';
      formData.collection = formData.collection.pk;
    } else {
      delete formData.collection;
    }

    if (formData.source === 'collection' && !formData.collection) {
      formData.source = 'elements';
    }

    await postData('warehouse/export/', formData, { config: { responseType: 'blob' } }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.export.export-finished'), type: 'success', isLoading: false, autoClose: 2000 });
      startDownload(response);
    }).catch(async (error) => {
      let responseObj = JSON.parse(await error.response.data.text());
      formik.setErrors({ fields: responseObj });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });

  };

  const startDownload = (response) => {
    let contentDisposition = response.headers['content-disposition'];
    let filename = contentDisposition.split(';')[1].split('=')[1].replace('"', '').replace('"', '');

    const fileURL = window.URL.createObjectURL(response.data);
    let alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = filename;
    alink.click();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      source: source,
      format: 'csv',
      pdf_show_image_column: true,
      pdf_show_ean_column: true,
      collection: collection,
    },
    onSubmit: (values) => handleExport(values),
  });

  const [getMyCollections] = useLazyQuery(GET_COLLECTIONS, {
    variables: {
      name_Icontains: null,
    },
  });

  const planChooserModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      formik.handleSubmit();
    },
  };

  const asyncCollectionOptions = useCallback(async (search) => {
    var response = await getMyCollections({ variables: { name_Icontains: search } });
    setSelectedableCollectionOptions(response?.data?.collections?.edges || (collection ? [{ node: collection }] : null));
    return response?.data?.collections?.edges || [];
  }, [getMyCollections, collection, setSelectedableCollectionOptions]);

  return (
    <>
      {planChooserModalHandler.opened.value && <PlanChooserModal handler={planChooserModalHandler} />}
      <Modal show={opened.value} onHide={handleClose} backdrop={disabled || backdrop === true ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.export.title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="fw-bold mb-2">{t('modals.export.select-data-source')}</div>

          <div className="ps-3 mb-3">
            <Form.Group>
              <Form.Check
                checked={formik.values.source === 'items'}
                disabled={disabled}
                id="source-items"
                isInvalid={formik.errors?.fields?.source}
                label={t('modals.export.data-source-items')}
                onChange={(event) => formik.setFieldValue('source', 'items')}
                type="radio"
              />
            </Form.Group>

            <Form.Group>
              <Form.Check
                checked={formik.values.source === 'elements'}
                disabled={disabled}
                id="source-elements"
                isInvalid={formik.errors?.fields?.source}
                label={t('modals.export.data-source-elements')}
                onChange={(event) => formik.setFieldValue('source', 'elements')}
                type="radio"
              />
            </Form.Group>

            <Form.Group>
              <Form.Check
                checked={formik.values.source === 'collection'}
                disabled={disabled}
                id="source-collection"
                isInvalid={formik.errors?.fields?.source}
                label={t('modals.export.data-source-collection')}
                onChange={(event) => formik.setFieldValue('source', 'collection')}
                type="radio"
              />
            </Form.Group>

            {formik.values.source === 'collection' && (
              <div className="ps-4">
                <Form.Group className="mb-3">
                  <Form.Control
                    type="hidden"
                    isInvalid={formik.errors?.fields?.collection}
                  />
                  <AsyncSelect
                    cacheOptions
                    isClearable={true}
                    defaultOptions={selectableCollectionOptions}
                    getOptionLabel={(e) => e?.node?.name}
                    getOptionValue={(e) => e?.node?.pk}
                    loadOptions={asyncCollectionOptions}
                    name="collection"
                    onChange={(o) => { setSelectedCollection(o); formik.setFieldValue('collection', o?.node || null) }}
                    placeholder={t('modals.export.field-collection-placeholder')}
                    value={selectedCollection}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    styles={{
                      control: styles => ({
                        ...styles,
                        borderColor: formik.errors?.fields?.collection ? '#dc3545' : styles.borderColor,
                        '&:hover': {
                          borderColor: formik.errors?.fields?.collection ? '#dc3545' : styles['&:hover'].borderColor,
                        }
                      })
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.collection}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            )}


            <Form.Group>
              <Form.Control
                type="hidden"
                isInvalid={formik.errors?.fields?.source}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.source}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Control
                type="hidden"
                isValid={formik.errors?.fields?.upgrade_plan}
              />
              <Form.Control.Feedback type="valid">
                <Button variant="success" onClick={() => planChooserModalHandler.opened.set(true)}>{t('pages.accounting.switch-plan-btn')}</Button>
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div className="fw-bold mb-2">{t('modals.export.select-export-format')}</div>

          <div className="ps-3 mb-3">

            <Form.Group>
              <Form.Check
                checked={formik.values.format === 'csv'}
                disabled={disabled}
                id="format-csv"
                isInvalid={formik.errors?.fields?.format}
                label={t('modals.export.format-csv')}
                onChange={(event) => formik.setFieldValue('format', 'csv')}
                type="radio"
              />
            </Form.Group>

            <Form.Group>
              <Form.Check
                checked={formik.values.format === 'pdf'}
                disabled={disabled}
                id="source-pdf"
                isInvalid={formik.errors?.fields?.format}
                label={t('modals.export.format-pdf')}
                onChange={(event) => formik.setFieldValue('format', 'pdf')}
                type="radio"
              />
            </Form.Group>

            <Form.Group>
              <Form.Control
                type="hidden"
                isInvalid={formik.errors?.fields?.format}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.format}
              </Form.Control.Feedback>
            </Form.Group>

          </div>

          {formik.values.format === 'pdf' && (
            <>
              <div className="fw-bold mb-2">{t('modals.export.select-export-pdf-options')}</div>
              <div className="ps-3 mb-3">
                <Form.Check
                  checked={formik.values.pdf_show_image_column === true}
                  disabled={disabled}
                  id="source-pdf-column-image"
                  isInvalid={formik.errors?.fields?.pdf_column_image}
                  label={t('modals.export.format-pdf-show-image-column')}
                  onChange={(event) => formik.setFieldValue('pdf_show_image_column', event.target.checked)}
                  type="checkbox"
                />
                <Form.Check
                  checked={formik.values.pdf_show_ean_column === true}
                  disabled={disabled}
                  id="source-pdf-column-ean"
                  isInvalid={formik.errors?.fields?.pdf_show_ean_column}
                  label={t('modals.export.format-pdf-show-ean-column')}
                  onChange={(event) => formik.setFieldValue('pdf_show_ean_column', event.target.checked)}
                  type="checkbox"
                />
            </div>
        </>
          )}

        <div className="fw-bold">{t('modals.export.hint-headline')}</div>

        <div className="ps-3 mb-3">
          {t('modals.export.hints')}
        </div>

      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
        <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.export')}</Button>
      </Modal.Footer>
    </Modal >
    </>
  );
};

export default ModalExport;
