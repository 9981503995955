import React from 'react';

import { faCircleCheck, faCircleQuestion, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const currency = (price, resolvedLanguage) => {
  return new Intl.NumberFormat(resolvedLanguage, {
    style: "currency",
    currency: "EUR",
  }).format(price);
};

const decimal = (number, resolvedLanguage) => {
  return new Intl.NumberFormat(resolvedLanguage, {
    style: "decimal",
  }).format(number);
};

const dateFromTimestamp = (timestamp, resolvedLanguage) => {
  if (!timestamp) {
    return null;
  }
  const dateObj = new Date(timestamp * 1000);
  return new Intl.DateTimeFormat(resolvedLanguage, { dateStyle: 'long', timeStyle: 'long' }).format(dateObj);
};

const dateTimeFromDate = (date, resolvedLanguage) => {
  if (!date) {
    return null;
  }
  const dateObj = new Date(date);
  return new Intl.DateTimeFormat(resolvedLanguage, { dateStyle: 'long', timeStyle: 'long' }).format(dateObj);
};

const dateFromDate = (date, resolvedLanguage) => {
  if (!date) {
    return null;
  }
  const dateObj = new Date(date);
  return new Intl.DateTimeFormat(resolvedLanguage, { dateStyle: 'long' }).format(dateObj);
};

const shortDateFromDate = (date, resolvedLanguage) => {
  if (!date) {
    return null;
  }
  const dateObj = new Date(date);
  return new Intl.DateTimeFormat(resolvedLanguage, { dateStyle: 'short' }).format(dateObj);
};

const timeFromDate = (date, resolvedLanguage) => {
  if (!date) {
    return null;
  }
  const dateObj = new Date(date);
  return new Intl.DateTimeFormat(resolvedLanguage, { timeStyle: 'short' }).format(dateObj);
};

const basename = (path) => {
  return path.substring(path.lastIndexOf('/') + 1)
};

const propertyDisplay = (property, resolvedLanguage) => {

  if (property.attribute.typeOf === 'A_1') {
    return renderBoolean(property.value === 'true');
  }

  if (property.attribute.typeOf === 'A_9') {
    return dateFromDate(property.value, resolvedLanguage);
  }

  return property.value;
};

const formatBytes = (bytes, decimals = 2, resolvedLanguage) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const asFloat = parseFloat(bytes / Math.pow(k, i));
  const formattedFloat = decimal(asFloat, resolvedLanguage);
  return formattedFloat + ' ' + sizes[i];
};


const renderBoolean = (boolean) => {
  if (boolean === undefined) return <FontAwesomeIcon className="text-secondary" icon={faCircleQuestion} size="xs" fixedWidth />
  if (boolean) return <FontAwesomeIcon className="text-success" icon={faCircleCheck} size="xs" fixedWidth />

  return <FontAwesomeIcon className="text-danger" icon={faCircleXmark} size="xs" fixedWidth />
};

const renderVisibleCollectionOverallValue = (collection, resolvedLanguage) => {

  if (!collection?.elements) {
    return '?'
  }

  let overallValue = 0;
  collection?.elements.forEach((element) => {
    overallValue += element.price * element.amount;
  })

  return currency(overallValue, resolvedLanguage);
};


const renderVisibleCollectionUnitCount = (collection, resolvedLanguage) => {

  if (!collection?.elements) {
    return '?'
  }

  let overallValue = 0;
  collection?.elements.forEach((element) => {
    overallValue += element.amount;
  })

  return overallValue;
};

const renderVisibleCollectionUnitCountByEdges = (elements, resolvedLanguage) => {

  if (!elements?.edges) {
    return '?'
  }

  let overallValue = 0;
  elements.edges.forEach((elementEdge) => {
    overallValue += elementEdge.node.amount;
  })

  return overallValue;
};


const urlB64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

const browserName = (userAgent) => {

  if (userAgent.includes('Chrome')) {
    return 'Chrome';
  } else if (userAgent.includes('Firefox')) {
    return 'Firefox';
  } else if (userAgent.includes('Safari')) {
    return 'Safari';
  } else if (userAgent.includes('Edge')) {
    return 'Edge';
  } else if (userAgent.includes('Opera') || userAgent.includes('OPR/')) {
    return 'Opera';
  } else if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
    return 'Internet Explorer';
  }

  return 'Unbekannter Browser';
}

export {
  basename,
  browserName,
  currency,
  dateFromDate,
  dateFromTimestamp,
  dateTimeFromDate,
  decimal,
  formatBytes,
  propertyDisplay,
  renderBoolean,
  renderVisibleCollectionOverallValue,
  renderVisibleCollectionUnitCount,
  renderVisibleCollectionUnitCountByEdges,
  shortDateFromDate,
  timeFromDate,
  urlB64ToUint8Array
};
