import React, { useState } from 'react';

import { Button, Card, Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import UserModalEdit from '../../components/auth/ModalEditUser';
import PaymentInformationModalEdit from '../../components/settings/ModalEditPaymentInformation';


const CurrentPaymenInformation = ({ information, user, setCurrentPaymentInformation }) => {

  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const editUserModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => { },
  };

  const editPaymentInformationModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      setCurrentPaymentInformation(response);
    },
  };

  return (
    <>
      {editUserModalHandler.opened.value && <UserModalEdit handler={editUserModalHandler} user={user} />}
      {editPaymentInformationModalHandler.opened.value && <PaymentInformationModalEdit handler={editPaymentInformationModalHandler} user={user} />}

      {!information && (
        <Card className="mb-4" border="warning">
          <Card.Header className="bg-warning" as="h5">
            {t('pages.accounting.no-payment-information-set')}
          </Card.Header>
          <Card.Body>
            <Card.Title>{t('pages.accounting.no-payment-information-hint')}</Card.Title>
            <Card.Text>
              <Trans i18nKey="pages.accounting.no-payment-information-explanation">
                <span role="button" className="text-primary" onClick={() => editUserModalHandler.opened.set(true)}>profile settings</span>
              </Trans>
            </Card.Text>
            <div className="text-end">
              <Button variant="warning" onClick={() => editPaymentInformationModalHandler.opened.set(true)}>
                {t('pages.accounting.payment-information-setup')}
              </Button>
            </div>
          </Card.Body>
        </Card>
      )}

      {information && (
        <Card className="mb-3" border="success">
          <Card.Header className="bg-success text-light" as="h5">
            {t('pages.accounting.payment-information-set')}
          </Card.Header>
          <Card.Body>
            <Card.Title>{t('pages.accounting.payment-information-hint')}</Card.Title>
            <dl className="row mt-4">
              <dt className="col-sm-3">{t('modals.payment-information-edit.field-company')}</dt>
              <dd className="col-sm-9">{information.company || t('forms.values.unset')}</dd>
              <dt className="col-sm-3">{t('modals.payment-information-edit.field-address')}</dt>
              <dd className="col-sm-9 text-pre-wrap">{information.address || t('forms.values.unset')}</dd>
              <dt className="col-sm-3">{t('modals.payment-information-edit.field-payment-method')}</dt>
              <dd className="col-sm-9">
                {information.get_payment_method_display}
                {information.payment_method === 2 && (
                  <div className="mt-2 p-3 border border-2 fw-light rounded-3">
                    <Row>
                      <Col xl="6">
                        <b>{information.bank_name || t('modals.payment-information-edit.field-bank-details')}</b>
                        <br />
                        <span className="d-inline-block" style={{ minWidth: '100px' }}>{t('modals.payment-information-edit.field-iban')}</span> {information.iban}
                        <br />
                        <span className="d-inline-block" style={{ minWidth: '100px' }}>{t('modals.payment-information-edit.field-bic')}</span> {information.bic || 'BIC'}
                        <br />
                        <span className="d-inline-block" style={{ minWidth: '100px' }}>{t('modals.payment-information-edit.field-account-holder')}</span> {information.bank_account_holder}
                      </Col>
                      <Col xl="6">
                        <br />
                        {t('modals.payment-information-edit.field-mandate-reference-date')}
                        <br />
                        <Moment locale={i18n.resolvedLanguage} format="LL">{information.mandate_reference_date}</Moment>
                        <br />
                        {t('modals.payment-information-edit.field-mandate-reference-number')}
                        <br />
                        {information.mandate_reference_number}
                      </Col>
                    </Row>
                  </div>
                )}
              </dd>
            </dl>
            <div className="text-end">
              <Button variant="success" className="text-light" onClick={() => editPaymentInformationModalHandler.opened.set(true)}>
                {t('forms.actions.edit')}
              </Button>
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );

};

export default CurrentPaymenInformation;
