import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { faCheck, faReplyAll } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Barcode from 'react-barcode';
import { Breadcrumb, Button, Col, Form, Row, Table } from 'react-bootstrap';
import { Collection as CollectionIcon } from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import ModalImage from 'react-modal-image';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import pathfinder from '../../assets/img/illustrations/pathfinder.png';
import { confirm } from "../../components/basic/Confirmation";
import Formi from '../../components/basic/Formi';
import ElementAddIssueInputGroup from '../../components/elements/AddIssueInputGroup';
import ElementAmountInputGroup from '../../components/elements/AmountInputGroup';
import ElementBestBeforeInput from '../../components/elements/BestBeforeInput';
import ElementConsumptionInput from '../../components/elements/ConsumptionInput';
import ElementDeincreaseInputGroup from '../../components/elements/DeincreaseInputGroup';
import ElementImageBreadcrump from '../../components/elements/ImageBreadcrump';
import DispositionModalAdd from '../../components/elements/ModalAddDisposition';
import ElementModalDelete from '../../components/elements/ModalDelete';
import ElementModalEvents from '../../components/elements/ModalEvents';
import ElementModalMove from '../../components/elements/ModalMove';
import ElementModalPrint from '../../components/elements/ModalPrint';
import ElementPriceInputGroup from '../../components/elements/PriceInputGroup';
import ElementSidebarInfo from '../../components/elements/SidebarInfo';
import ElementToolbar from '../../components/elements/Toolbar';
import { GET_ELEMENT_BY_ID } from '../../components/elements/queries';
import ItemModalEdit from '../../components/items/ModalEdit';
import ItemSidebarComment from '../../components/items/SidebarComments';
import useLocalStorage from '../../hooks/useLocalStorage';
import { postData } from '../../utils/axiosHelper';
import { currency } from '../../utils/formatter';
import { renderParentBreadcrumps } from '../../utils/renderHelper';


const Element = () => {

  const [, setLastElementIdGathered] = useLocalStorage('last-element-id-gathered', false);
  const [disabled, setDisabled] = useState(false);
  const [element, setElement] = useState();
  const [showBarcodes, setShowBarcodes] = useState(false);
  const { elementId, collectionId } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [getElement, { loading, error, refetch }] = useLazyQuery(GET_ELEMENT_BY_ID, {
    variables: { elementId: elementId },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setLastElementIdGathered(elementId);
      setElement(data.elementById);
    },
  });

  const addDispositionModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      refetch();
    },
  };

  const editItemModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      if (response === 'destroyed') {
        handleItemDeletionResponse();
      } else {
        refetch();
      }
    },
  };

  const deleteModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      handleItemDeletionResponse();
    },
  };

  const printModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      alert('Done');
    },
  };

  const moveElementModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      if (!response.copy) {
        navigate(`/private/collections/${element?.collection.pk}`, { state: { doRefetch: true } });
      }
    },
  };

  const eventElementModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {},
  };

  const infoSidebarHandler = {
    opened: Object.assign({}, ...useLocalStorage('show-sidebar-info', false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
  };

  const commentSidebarHandler = {
    opened: Object.assign({}, ...useLocalStorage('show-sidebar-comments', false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
  };

  const handleItemDeletionResponse = () => {
    navigate(`/private/collections/${element?.collection.pk}`, { state: { doRefetch: true } });
  };

  const renderBreadcrump = ({showImage = true, className = 'mb-5', bsPrefix = 'breadcrumb'}) => {
    return (
      <Breadcrumb className={className} bsPrefix={bsPrefix}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/private/collections" }}>
          <CollectionIcon className="bi img-fluid rounded me-1 align-text-top" width={20} height={20} style={{maxHeight: '20px', minHeight: '20px'}} />
          {t('pages.collections.headline')}
        </Breadcrumb.Item>
        {element?.collection && renderParentBreadcrumps({ element: element, collection: element.collection, showImage: true})}
      </Breadcrumb>
    );
  };

  const renderPropertyValue = (property) => {

    switch(property.attribute.typeOf) {

      case 'A_14':
        return <a href={`https://www.imdb.com/title/${property.value}/`} target="_blank" rel="noreferrer">{property.value}</a>;

      case 'A_12':
        return <a href={`https://www.amazon.de/dp/${property.value}`} target="_blank" rel="noreferrer">{property.value}</a>;

      case 'A_15':
        return  (
          <>
            {property.value} (<Button variant="link" className="p-0 cursor-pointer" onClick={() => setShowBarcodes(prev => !prev)}>{t(showBarcodes ? 'forms.actions.toggle-hide' : 'forms.actions.toggle-show')}</Button>)
            <br />
            {showBarcodes && (<Barcode value={property.value} format="ean13" />)}
          </>
        )

      default:
        return <Formi propertyVal={property} />;
    }

  };

  const registerDisposition = (dispositionPk) => {

    confirm({
      title: t('pages.element.register-disposition-hint-title'),
      lead: t('pages.element.register-disposition-hint-lead'),
      showCancel: true,
      danger: false,
    }).then(async (response) => {

      if (response === false) return;

      setDisabled(true);
      const brot = toast.loading(t('widgets.toast.saving'));

      await postData(`warehouse/dispositions/${dispositionPk}/register/`, {}, {sleep: 500}).then(async (response) => {
        refetch();
        toast.update(brot, { render: t('pages.element.register-disposition-success'), type: 'success', isLoading: false, autoClose: 2000 });
      }).catch((error) => {
        toast.update(brot, {render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 2000 });
      }).finally(() => {
        setDisabled(false);
      });

    });
  };

  const returnIssue = (issuePk) => {

    confirm({
      title: t('pages.element.return-issue-hint-title'),
      lead: t('pages.element.return-issue-hint-lead'),
      showCancel: true,
      danger: false,
    }).then(async (response) => {

      if (response === false) return;

      setDisabled(true);
      const brot = toast.loading(t('widgets.toast.saving'));

      await postData(`warehouse/issues/${issuePk}/remit/`, {}, {sleep: 500}).then(async (response) => {
        refetch();
        toast.update(brot, { render: t('pages.element.return-issue-success'), type: 'success', isLoading: false, autoClose: 2000 });
      }).catch((error) => {
        toast.update(brot, {render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
      }).finally(() => {
        setDisabled(false);
      });

    });
  };

  useEffect(() => { getElement(); }, [elementId, getElement]);
  useEffect(() => { if (location.state?.doRefetch) refetch(); }, [location.state?.doRefetch, refetch]);

  if (error) return `${error}`;

  if (element === null) {
    return (
      <>
        <Helmet title={t('public.error.404')} />
        <ElementToolbar
          commentSidebarHandler={commentSidebarHandler}
          element={element}
          infoSidebarHandler={infoSidebarHandler}
          loading={loading}
          printModalHandler={printModalHandler}
          refetch={refetch}
          showDispositionDialog={addDispositionModalHandler.opened.set}
          showElementMoveDialog={moveElementModalHandler.opened.set}
          showElementRemoveDialog={deleteModalHandler.opened.set}
          showItemEditDialog={editItemModalHandler.opened.set}
        />
        <div className="mainspace d-flex">
          <div className="container align-self-center">
            <div className="row">
              <div className="col-md-4 order-md-2 col-xxl-4 h-100">
                <img src={pathfinder} alt="Pathfinder" className="img-fluid" />
              </div>
              <div className="col-md-8 order-md-1 align-middle col-xxl-8 d-flex">
                <div className="align-self-center">
                  <h1 className="display-5 font-weight-bold">{t('pages.element.404-headline')}</h1>
                  <p className="text-muted">{t('pages.element.404-message')}</p>
                  <Button variant="link" className="btn btn-link ps-0" onClick={() => refetch()}>{t('forms.actions.refresh')}</Button>
                  {collectionId && (
                    <Link to={`/private/collections/${collectionId}`} className="btn btn-link ps-0">
                      {t('pages.element.open-collection')}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {addDispositionModalHandler.opened.value && <DispositionModalAdd handler={addDispositionModalHandler} element={element} />}
      {deleteModalHandler.opened.value && <ElementModalDelete handler={deleteModalHandler} element={element} />}
      {editItemModalHandler.opened.value && <ItemModalEdit handler={editItemModalHandler} original={element?.item} />}
      {eventElementModalHandler.opened.value && <ElementModalEvents handler={eventElementModalHandler} elementId={eventElementModalHandler.opened.value} />}
      {moveElementModalHandler.opened.value && <ElementModalMove handler={moveElementModalHandler} elementId={moveElementModalHandler.opened.value} />}
      {printModalHandler.opened.value && <ElementModalPrint handler={printModalHandler} element={element} />}
      <Helmet title={element?.item?.name} />
      <ElementToolbar
        commentSidebarHandler={commentSidebarHandler}
        element={element}
        infoSidebarHandler={infoSidebarHandler}
        loading={loading}
        printModalHandler={printModalHandler}
        refetch={refetch}
        showDispositionDialog={addDispositionModalHandler.opened.set}
        showElementMoveDialog={moveElementModalHandler.opened.set}
        showElementRemoveDialog={deleteModalHandler.opened.set}
        showItemEditDialog={editItemModalHandler.opened.set}
      />
      <div className="mainspace">
        <div className="splitter d-flex">
          <div className={`panel overflow-auto flex-fill ${infoSidebarHandler.opened.value ? 'd-none d-sm-block' : ''}`}>
            <div className="position-relative h-100 w-100">
              <div className="module">
                <div className="mainspace">
                  <div className="p-4 pb-0 pt-3">
                    <div className="d-flex">
                      <div className="flex-grow-1 me-3">
                        <h2>{element?.item?.name || '...'}</h2>
                      </div>
                      <div className="flex-shrink-0 d-none d-sm-flex">
                        <ElementImageBreadcrump element={element} />
                      </div>
                    </div>
                    <hr className="mt-0 mb-3" />
                    {renderBreadcrump({ bsPrefix: 'breadcrumb flex-column flex-sm-row' })}
                    <Row>
                      <Col md={infoSidebarHandler.opened?.value ? 12 : 10} xxl={infoSidebarHandler.opened?.value ? 12 : 9}>
                        <h3>{t('pages.element.element-details')}</h3>
                        <dl className="row mb-5">
                          <dt className="col-sm-3 pt-1">{t('pages.element.amount')}</dt>
                          <dd className="col-sm-9">
                            {element?.amount !== undefined && element.amount > 3 && <ElementAmountInputGroup element={element} setDisabled={setDisabled} disabled={disabled} setElement={setElement} />}
                            {(element?.amount === undefined || element.amount <= 3) && <ElementDeincreaseInputGroup element={element} setDisabled={setDisabled} disabled={disabled} setElement={setElement} />}
                          </dd>
                          {element?.amount > 0 && (
                            <>
                              <dt className="col-sm-3 pt-1">{t('pages.element.add-issue')}</dt>
                              <dd className="col-sm-9">
                                {element && <ElementAddIssueInputGroup element={element} refetch={refetch} />}
                              </dd>
                            </>
                          )}
                          {element?.collection.enablePriceOptions && (
                            <>
                              <dt className="col-sm-3 pt-1">{t('pages.element.price')}</dt>
                              <dd className="col-sm-9">
                                <ElementPriceInputGroup element={element} refetch={refetch} />
                              </dd>
                              <dt className="col-sm-3 pt-1">{t('pages.element.purchasing_price')}</dt>
                              <dd className="col-sm-9">
                                <ElementPriceInputGroup element={element} refetch={refetch} sourceKey={'purchasingPrice'} formKey={'purchasing_price'} />
                              </dd>
                              {element?.amount > 1 && (
                                <>
                                  <dt className="col-sm-3 pt-1">{t('pages.element.total-price')}</dt>
                                  <dd className="col-sm-9">
                                    <Form.Control
                                      disabled
                                      size="sm"
                                      style={{ minWidth: '50px', width: '150px' }}
                                      type="text"
                                      value={currency(element?.price * element?.amount)}
                                    />
                                  </dd>
                                </>
                              )}
                            </>
                          )}
                          {(element?.item?.typeOf?.showBestBefore || element?.bestBefore) && (
                            <>
                              <dt className="col-sm-3">{t('pages.element.best-before')}</dt>
                              <dd className="col-sm-9">
                                <ElementBestBeforeInput element={element} refetch={refetch} />
                              </dd>
                            </>
                          )}
                          {element?.item?.typeOf?.showConsumption && (
                            <>
                              <dt className="col-sm-3">{t('pages.element.consumption-in-percent')}</dt>
                              <dd className="col-sm-9">
                                {element && <ElementConsumptionInput element={element} refetch={refetch} />}
                              </dd>
                            </>
                          )}
                        </dl>

                        <h3>{t('pages.element.item-details')}</h3>
                        {element?.item.properties.map((property, index) => (
                          <dl key={index} className="row mb-0">
                            <dt className="col-sm-3">{property.attribute.name}</dt>
                            <dd className="col-sm-9">
                              {renderPropertyValue(property)}
                            </dd>
                          </dl>
                        ))}

                        <dl className="row mb-5">
                          {element?.item.website && (
                            <>
                              <dt className="col-sm-3">{t('pages.element.website')}</dt>
                              <dd className="col-sm-9">
                                <a href={`${element?.item.website}`} target="_blank" rel="noreferrer">{element?.item.website}</a>
                              </dd>
                            </>
                          )}
                          {element?.item.typeOf && (
                            <>
                              <dt className="col-sm-3">{t('pages.element.type-of')}</dt>
                              <dd className="col-sm-9">{element?.item.typeOf.name}</dd>
                            </>
                          )}
                          {!element?.item.typeOf && (
                            <>
                              <dt className="col-sm-3">{t('pages.element.type-of')}</dt>
                              <dd className="col-sm-9">{t('pages.element.type-of-undefined')}</dd>
                            </>
                          )}
                          {element?.item.elements.filter((e) => e.collection.pk !== element.collection.pk).length > 0 && (
                            <>
                              <dt className="col-sm-3">{t('pages.element.also-available-in')}</dt>
                              <dd className="col-sm-9">
                                {element?.item.elements.filter((e) => e.collection.pk !== element.collection.pk).map((altElement, index) => (
                                  <span key={index}>
                                    {index > 0 && <span>, </span>}
                                    <Link className="comma-sep" to={`/private/collections/${altElement.collection.pk}`}>{altElement.collection.name}</Link>
                                  </span>
                                ))}
                              </dd>
                            </>
                          )}
                          <dt className="col-sm-3">{t('pages.element.created-at')}</dt>
                          <dd className="col-sm-9">
                            {element?.item.createdAt && <Formi dateTimeVal={element?.item.createdAt} />}
                            {!element?.item.createdAt && '...'}
                          </dd>
                          <dt className="col-sm-3">{t('pages.element.updated-at')}</dt>
                          <dd className="col-sm-9">
                            {element?.item.updatedAt && <Formi dateTimeVal={element?.item.updatedAt} />}
                            {!element?.item.updatedAt && '...'}
                          </dd>
                          <dt className="col-sm-3">{t('pages.element.internal-sku')}</dt>
                          <dd className="col-sm-9">
                            <Link to={`/private/items/${element?.item?.pk}`}>
                              {element?.item.pk.substring(0, 8) || '...'}
                            </Link>
                          </dd>
                        </dl>

                        {element?.item?.description && (
                          <>
                            <h3>{t('pages.element.element-description')}</h3>
                            <div className="mb-5">
                              <ReactMarkdown>{element?.item?.description}</ReactMarkdown>
                            </div>
                          </>
                        )}

                        {element?.dispositions?.filter(disposition => disposition.state === 'A_1').length > 0 && (
                          <>
                            <h3>{t('pages.element.pending-dispositions')}</h3>

                            <Table className="bg-white mb-5" size="sm" striped hover bordered responsive>
                              <thead>
                                <tr>
                                  <th width="80" className="text-center">{t('pages.element.disposition-table-amount')}</th>
                                  <th width="150">{t('pages.element.disposition-table-created-at')}</th>
                                  <th width="150">{t('pages.element.disposition-table-expected-at')}</th>
                                  <th width="200">{t('pages.element.disposition-table-supplier')}</th>
                                  <th>{t('pages.element.disposition-table-notes')}</th>
                                  <th width="40"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {element?.dispositions.filter(disposition => disposition.state === 'A_1').map((disposition, index) => (
                                  <tr key={index}>
                                    <td className="align-middle text-center">{disposition.amount}x</td>
                                    <td className="align-middle"><Formi dateVal={disposition.createdAt} /></td>
                                    <td className="align-middle"><Formi dateVal={disposition.deliveryDate} /></td>
                                    <td className="align-middle">{disposition.to}</td>
                                    <td className="align-middle">{disposition.notes}</td>
                                    <td>
                                      {disposition.state === 'A_1' && (
                                        <Button variant="secondary" size="sm" onClick={() => registerDisposition(disposition.pk)}>
                                          <FontAwesomeIcon icon={faCheck} alt={t('pages.element.register-disposition')} />
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        )}

                        {element?.issues.filter(issue => issue.state === 'A_1').length > 0 && (
                          <>
                            <h3>{t('pages.element.active-issue-overview')}</h3>

                            <Table className="bg-white" size="sm" striped hover bordered responsive>
                              <thead>
                                <tr>
                                  <th width="80" className="text-center">{t('pages.element.issue-table-amount')}</th>
                                  <th width="150">{t('pages.element.issue-table-created-at')}</th>
                                  <th width="200">{t('pages.element.issue-table-to')}</th>
                                  <th>{t('pages.element.issue-table-notes')}</th>
                                  <th width="40"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {element?.issues.filter(issue => issue.state === 'A_1').map((issue, index) => (
                                  <tr key={index}>
                                    <td className="align-middle text-center">{issue.amount}x</td>
                                    <td className="align-middle"><Formi dateVal={issue.createdAt} /></td>
                                    <td className="align-middle">{issue.to || <i>{t('forms.values.undetermined')}</i>}</td>
                                    <td className="align-middle">{issue.notes}</td>
                                    <td>
                                      {issue.state === 'A_1' && (
                                        <Button variant="secondary" size="sm" onClick={() => returnIssue(issue.pk)}>
                                          <FontAwesomeIcon icon={faReplyAll} alt={t('pages.element.return-issue')} />
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        )}

                      </Col>
                      {!infoSidebarHandler.opened?.value && element?.item.imageThumbnail && (
                        <Col md={2} xxl={3}>
                          <ModalImage
                            alt={element?.item.name}
                            className="img-fluid border rounded p-1 bg-body"
                            large={`${process.env.REACT_APP_MEDIA_URL}${element?.item.imageFull}`}
                            showRotate={true}
                            small={`${process.env.REACT_APP_MEDIA_URL}${element?.item.imageThumbnail}`}
                          />
                        </Col>
                      )}
                    </Row>
                    <div className="text-end my-4 border-top pt-4 d-grid d-md-flex mb-3">
                      <Button variant="secondary" className="mb-2" onClick={() => eventElementModalHandler.opened.set(element.pk)} id="button-logs">
                        {t('pages.element.event-log')}
                      </Button>
                      <Button variant="secondary" className="me-md-auto mb-2 ms-md-2" onClick={() => addDispositionModalHandler.opened.set(true)} id="button-disposition">
                        {t('pages.element.add-disposition')}
                      </Button>
                      <Button variant="warning" className="ms-md-2 mb-2" onClick={() => deleteModalHandler.opened.set(true)} id="button-remove">
                        {t('forms.actions.remove-from-collection')}
                      </Button>
                      <Button variant="primary" className="ms-md-2 mb-2" onClick={() => printModalHandler.opened.set(true)} id="button-print">
                        {t('forms.actions.print')}
                      </Button>
                      {element?.item?.userCanEdit && (
                        <Button variant="primary" className="ms-md-2 mb-2" onClick={() => editItemModalHandler.opened.set(true)} id="button-edit">
                          {t('forms.actions.edit')}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {commentSidebarHandler.opened.value && <ItemSidebarComment handler={commentSidebarHandler} item={element?.item} />}
          {infoSidebarHandler.opened.value && <ElementSidebarInfo handler={infoSidebarHandler} element={element} />}
        </div>
      </div>
    </>
  );
};

export default Element;
