import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Col, Row } from 'react-bootstrap';
import { People as GroupIcon } from 'react-bootstrap-icons';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import EmptyPanel from '../../components/basic/EmptyPanel';
import GroupCard from '../../components/groups/Card';
import GroupModalAdd from '../../components/groups/ModalAdd';
import GroupToolbarOverview from '../../components/groups/ToolbarOverview';
import { GET_GROUPS } from '../../components/groups/queries';


const Groups = () => {

  const [groups, setGroups] = useState([]);
  const location = useLocation();
  const { t } = useTranslation();

  const [getGroups, { refetch, loading }] = useLazyQuery(GET_GROUPS, {
    variables: {
      parent_Isnull: true,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setGroups(data.groups.edges);
    },
  });

  const createModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      refetch();
    },
  };

  const renderBreadcrump = (showImage = true, className = 'mb-5') => {
    return (
      <Breadcrumb className={className}>
        <Breadcrumb.Item linkAs={Link} active={true} linkProps={{ to: "/private/groups" }}>
          {showImage && <GroupIcon className="bi img-fluid rounded me-1 align-text-top" width={20} height={20} style={{maxHeight: '20px', minHeight: '20px'}} />}
          {t('pages.groups.headline')}
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  };

  useEffect(() => { getGroups(); }, [getGroups]);
  useEffect(() => { if (location?.state?.doRefetch) refetch(); }, [location.state?.doRefetch, refetch]);

  return (
    <>
      {createModalHandler.opened.value && <GroupModalAdd handler={createModalHandler} />}
      <Helmet title={t('pages.groups.headline')} />
      <GroupToolbarOverview
        refetch={refetch}
        loading={loading}
        showAddDialog={createModalHandler.opened.set}
      />
      <div className="mainspace p-4 pt-3">
        <div className="d-flex">
          <div className="flex-grow-1 me-3">
            <h2>
              {t('pages.groups.headline')}
            </h2>
          </div>
          <div className="flex-shrink-0 d-none d-sm-flex">
            &nbsp;
          </div>
        </div>
        <hr className="mt-0 mb-3" />
        {renderBreadcrump()}
        {groups && groups.filter((group) => group.node.myMembership.role === "A_1").length > 0 && (
          <>
            <h3>{t('pages.groups.administered-title')}</h3>
            <hr className="mt-3 mb-4" />
            <Row className="mb-5">
              {groups.filter((group) => group.node.myMembership.role === "A_1").map((group, index) => (
                <Col key={index} sm={12} md={12} lg={4} xl={3} xxl={2} className='d-flex'>
                  <GroupCard group={group.node} />
                </Col>
              ))}
            </Row>
          </>
        )}

        {groups && groups.filter((group) => group.node.myMembership.role !== "A_1").length > 0 && (
          <>
            <h3>{t('pages.groups.joined-title')}</h3>
            <hr className="mt-3 mb-4" />
            <Row className="mb-5">
              {groups.filter((group) => group.node.myMembership.role !== "A_1").map((group, index) => (
                <Col key={index} sm={12} md={12} lg={4} xl={3} xxl={2} className='d-flex'>
                  <GroupCard group={group.node} />
                </Col>
              ))}
            </Row>
          </>
        )}

        {groups?.length === 0 && !loading && (
          <EmptyPanel reloadTrigger={refetch} addEntry={createModalHandler.opened.set} />
        )}
      </div>
    </>
  );
};

export default Groups;
