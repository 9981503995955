import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';


function ResetForm() {

  const [disabled, setDisabled] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReset = async (values) => {

    if (!executeRecaptcha) {
      toast.error(t('pages.login.toast-recaptcha-error'), { autoClose: 2500 });
      return;
    }

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.processing'));
    var formData = { ...values };

    formData['recaptcha_response'] = await executeRecaptcha('dynamicAction');

    await postData('auth/password_reset/', formData, { sleep: 500 }).then(async (response) => {
      navigate('/auth/password-reset/confirm-otp', { state: { email: values.email, timestamp: response.data?.timestamp } })
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.error(`${t('widgets.toast.error')} ${error.response.status}`, { autoClose: 2000 });
    }).finally(() => {
      setDisabled(false);
      toast.dismiss(brot);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    onSubmit: (values) => handleReset(values),
  });

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{t('public.auth.field-email')}</Form.Label>
        <Form.Control
          autoComplete="off"
          isInvalid={formik.errors?.fields?.email}
          name="email"
          onChange={formik.handleChange}
          type="email"
          value={formik.values.email}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors?.fields?.email}
        </Form.Control.Feedback>
      </Form.Group>
      <div className="text-center mb-3">
        <div className="d-grid gap-2">
          <Button type="button" onClick={formik.handleSubmit} variant="primary" disabled={disabled}>
            {t('public.auth.btn-reset-password')}
          </Button>
        </div>
      </div>
      <small>
        <Link to="/auth/login" className="text-decoration-none">&lt; {t('public.auth.btn-just-remembered')}</Link>
      </small>
    </>
  );
}

export default ResetForm;
