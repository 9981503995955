import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import bgDesk from '../../assets/img/bg/712481386-huge.webp';
import screenshot1 from '../../assets/img/public/home_screenshot3.png';
import screenshot0 from '../../assets/img/screenshots/notebook_private_collections1.webp';


const Home = () => {

  const { t } = useTranslation();
  const location = useLocation();

  const linkTags = [
    { rel: 'canonical', href: `${process.env.REACT_APP_SELF}${location.pathname}` },
  ];

  return (
    <>
      <Helmet link={linkTags} />
      <div className="container mt-5 mb-5">
        <Row>
          <Col md={{ span: 4, offset: 1 }} className="pt-5">
            <h1 className="font-weight-bold pt-5">
              {t('public.home.slogan0-part1')}
              <br />
              {t('public.home.slogan0-part2')}
              <span className="ms-1 text-primary">{t('public.home.slogan0-part3')}</span>
            </h1>
            <p className="font-size-lg text-muted mt-4">{t('public.home.widget-keep-track.info')}</p>
            <figure>
              <blockquote className="blockquote mb-6">
                <p className="mb-0 text-muted">{t('public.home.widget-keep-track.slogan')}</p>
              </blockquote>
              <figcaption className="blockquote-footer">{t('public.home.widget-keep-track.slogan-author')} <cite title="Internet">{t('public.home.widget-keep-track.slogan-location')}</cite></figcaption>
            </figure>
          </Col>
          <Col md={{ span: 5, offset: 1 }}>
            <img className="img-fluid mt-5" src={screenshot1} alt={t('public.home.screenshot-alt')} />
          </Col>
        </Row>
        <Row className="mt-5 mb-5">
          <Col md={{ span: 5, offset: 1 }}>
            <img loading="lazy" className="img-fluid rounded" src={screenshot0} alt={t('public.home.screenshot-alt')} />
          </Col>
          <Col md={{ span: 5, offset: 0 }} className="pt-5 ps-5">
            <h1 className="font-weight-bold pt-5">
              {t('public.home.slogan1-part1')}<sup>1</sup> {t('public.home.slogan1-part2')}<sup>2</sup>
            </h1>
            <p className="font-size-lg text-muted mt-4">{t('public.home.widget-run-everywhere.info')}</p>
            <NavLink to="/auth/login" className="btn btn-lg btn-primary">
              {t('public.home.widget-run-everywhere.btn')}
            </NavLink>
            <div style={{ fontSize: '9px' }} className="text-small text-muted mt-4">
              <sup>1</sup><span className="me-1">{t('public.home.widget-run-everywhere.sup1')}</span>
              <sup>2</sup><span className="me-1">{t('public.home.widget-run-everywhere.sup2')}</span>
            </div>
          </Col>
        </Row>
      </div>
      <div className="position-relative mt-5">
        <div className="shape shape-top shape-fluid-x svg-shim text-body-bg" style={{ height: '72px' }}>
          <svg viewBox="0 0 2880 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2880 0H0v54.112h720s289.42 40.248 705 0c416.5-37.733 735 0 735 0h720V0z" fill="currentColor"></path>
          </svg>
        </div>
      </div>
      <section style={{ backgroundImage: `url(${bgDesk})` }} className="py-5 bg-cover">
        <div className="my-5 py-5"><div className="my-5 py-5">&nbsp;</div></div>
      </section>
    </>
  );
};

export default Home;
