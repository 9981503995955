import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap';
import { Collection as CollectionIcon } from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import ModalImage from 'react-modal-image';
import { Link, useLocation, useParams } from 'react-router-dom';
import Formi from '../../../components/basic/Formi';
import ElementImageBreadcrump from '../../../components/elements/ImageBreadcrump';
import { GET_PUBLIC_ELEMENT_BY_ID } from '../../../components/elements/queries';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { renderParentBreadcrumps } from '../../../utils/renderHelper';


const Element = () => {

  const [, setLastElementIdGathered] = useLocalStorage('last-element-id-gathered', false);
  const [element, setElement] = useState();
  const location = useLocation();
  const { collectionSlug } = useParams();
  const { elementId } = useParams();
  const { t } = useTranslation();

  const metaTags = [
    { name: 'description', content: t('public.element.meta-description') },
  ];

  const linkTags = [
    { rel: 'canonical', href: `${process.env.REACT_APP_SELF}${location.pathname}` },
  ];

  const [getElement, { error, refetch }] = useLazyQuery(GET_PUBLIC_ELEMENT_BY_ID, {
    variables: { elementId: elementId, slug: collectionSlug },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setLastElementIdGathered(elementId);
      setElement(data.elementById);
    },
  });

  const renderBreadcrump = ({ showImage = true, className = 'mb-5', bsPrefix = 'breadcrumb' }) => {
    return (
      <Breadcrumb className={className} bsPrefix={bsPrefix}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/collections" }}>
          <CollectionIcon className="bi img-fluid rounded me-1 align-text-top" width={20} height={20} style={{ maxHeight: '20px', minHeight: '20px' }} />
          {t('public.collections.home-breadcrump')}
        </Breadcrumb.Item>
        {element?.collection && renderParentBreadcrumps({ element: element, collection: element.collection, showImage: true, usePublicAddr: true })}
      </Breadcrumb>
    );
  };

  const renderPropertyValue = (property) => {

    switch (property.attribute.typeOf) {

      case 'A_14':
        return <a href={`https://www.imdb.com/title/${property.value}/`} target="_blank" rel="noreferrer">{property.value}</a>;

      case 'A_12':
        return <a href={`https://www.amazon.de/dp/${property.value}`} target="_blank" rel="noreferrer">{property.value}</a>;

      default:
        return <Formi propertyVal={property} />;
    }

  };

  useEffect(() => { getElement(); }, [elementId, getElement]);
  useEffect(() => { if (location.state?.doRefetch) refetch(); }, [location.state?.doRefetch, refetch]);

  if (error) return `${error}`;

  return (
    <>
      <Helmet title={element?.item?.name} meta={metaTags} link={linkTags} />
      <Container>
        <div className="d-flex">
          <div className="flex-grow-1 me-3">
            <h2>{element?.item?.name || '...'}</h2>
          </div>
          <div className="flex-shrink-0 d-none d-sm-flex">
            <ElementImageBreadcrump element={element} />
          </div>
        </div>
        <hr className="mt-0 mb-3" />
        {renderBreadcrump({ bsPrefix: 'breadcrumb flex-column flex-sm-row' })}
        <Row>
          <Col md={10} xxl={9}>
            {element?.item?.description && (
              <>
                <h3>{t('pages.element.element-description')}</h3>
                <div className="mb-4">
                  <ReactMarkdown>{element?.item?.description}</ReactMarkdown>
                </div>
              </>
            )}
            <h3>{t('pages.element.element-details')}</h3>
            <dl className="row">
              <dt className="col-sm-3">{t('pages.element.amount')}</dt>
              <dd className="col-sm-9">{element?.amount || '...'}</dd>
              <dt className="col-sm-3">{t('pages.element.collection')}</dt>
              <dd className="col-sm-9">
                <Link to={`/c/${element?.collection.publicSlug || element?.collection.publicPk}`} className="text-decoration-none">
                  {element?.collection.name || '...'}
                </Link>
              </dd>
              {element?.item?.typeOf?.showConsumption && (
                <>
                  <dt className="col-sm-3">{t('pages.element.consumption-in-percent')}</dt>
                  <dd className="col-sm-9">{element?.consumption}%</dd>
                </>
              )}
              {element?.bestBefore && (
                <>
                  <dt className="col-sm-3">{t('pages.element.best-before')}</dt>
                  <dd className="col-sm-9">
                    <Formi dateVal={element.bestBefore} />
                  </dd>
                </>
              )}
            </dl>
            <h3>{t('pages.element.item-details')}</h3>
            {element?.item.properties.map((property, index) => (
              <dl key={index} className="row mb-0">
                <dt className="col-sm-3">{property.attribute.name}</dt>
                <dd className="col-sm-9">
                  {renderPropertyValue(property)}
                </dd>
              </dl>
            ))}
            <dl className="row">
              {element?.item.website && (
                <>
                  <dt className="col-sm-3">{t('pages.element.website')}</dt>
                  <dd className="col-sm-9">
                    <a href={`${element?.item.website}`} target="_blank" rel="noreferrer">{element?.item.website}</a>
                  </dd>
                </>
              )}
              {element?.item.typeOf && (
                <>
                  <dt className="col-sm-3">{t('pages.element.type-of')}</dt>
                  <dd className="col-sm-9">{element?.item.typeOf.name}</dd>
                </>
              )}
              {!element?.item.typeOf && (
                <>
                  <dt className="col-sm-3">{t('pages.element.type-of')}</dt>
                  <dd className="col-sm-9">{t('pages.element.type-of-undefined')}</dd>
                </>
              )}
              <dt className="col-sm-3">{t('pages.element.created-at')}</dt>
              <dd className="col-sm-9">
                {element?.item.createdAt && <Formi dateTimeVal={element?.item.createdAt} />}
                {!element?.item.createdAt && '...'}
              </dd>
              <dt className="col-sm-3">{t('pages.element.updated-at')}</dt>
              <dd className="col-sm-9">
                {element?.item.updatedAt && <Formi dateTimeVal={element?.item.updatedAt} />}
                {!element?.item.updatedAt && '...'}
              </dd>
              <dt className="col-sm-3">{t('pages.element.internal-sku')}</dt>
              <dd className="col-sm-9">{element?.item.pk.substring(0, 8) || '...'}</dd>
            </dl>
          </Col>
          <Col md={2} xxl={3}>
            {element?.item.imageThumbnail && <ModalImage showRotate={true} className="img-fluid border rounded p-1 bg-body-tertiary" small={`${process.env.REACT_APP_MEDIA_URL}${element?.item.imageThumbnail}`} large={`${process.env.REACT_APP_MEDIA_URL}${element?.item.imageFull}`} alt={element?.item.name} />}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Element;
