import React from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import pathfinder from '../../../assets/img/illustrations/pathfinder.png';


const NewsletterPending = ({ msg }) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t('public.newsletter.pending.title')} />
      <div className="container pt-2">
        <Row className="mt-5">
          <Col md={8}>
            <div style={{ paddingTop: '80px' }}></div>
            <h1 className="font-weight-bold mb-4">
              {t('public.newsletter.pending.title')}<br />
            </h1>
            <p className="mb-5 text-muted">
              {t('public.newsletter.pending.details')}
            </p>
            <div>
              <Link to="/">
                <Button>{t('public.error.btn-homepage')}</Button>
              </Link>
            </div>
          </Col>
          <Col md={4}>
            <img src={pathfinder} alt="Teamwork" className="img-fluid" />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NewsletterPending;
