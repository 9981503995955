import React from 'react';

import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';


const PrivateMetaNavHeader = () => {

  const { t } = useTranslation();

  return (
    <Container>
      <h2 className="py-4 private-meta-nav">
        <NavLink to="/private/profile">{t('pages.profile.headline')}</NavLink> <span className="mx-2">|</span> <NavLink to="/private/settings">{t('pages.settings.headline')}</NavLink> <span className="mx-2">|</span> <NavLink to="/private/seats">{t('pages.seats.headline')}</NavLink> <span className="mx-2">|</span> <NavLink to="/private/accounting">{t('pages.accounting.headline')}</NavLink>
      </h2>
    </Container>
  );
};

export default PrivateMetaNavHeader;
