import { gql } from '@apollo/client';

const GET_ISSUES = gql`
  query Issues(
      $state: WarehouseIssueStateChoices
    ) {
    issues(
      state: $state
      orderBy: "updatedAt"
    ) {
      totalCount
      edges {
        node {
          amount
          getStateDisplay
          pk
          returnedAt
          state
          to
          element {
            pk
            collection {
              pk
              name
            }
            item {
              cardImageSquare
              imageFull
              name
              pk
              typeOf {
                name
                cardImageSquare
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edgeCount
    }
  }
`;

export {
  GET_ISSUES,
};
