import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Col, Dropdown, Row, Table } from 'react-bootstrap';
import { Box as ItemIcon, ThreeDotsVertical } from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';
import { Lightbox } from 'react-modal-image';
import Moment from 'react-moment';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Lightbox as YALightbox } from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import { DEFAULT_LIST_VIEW_MODE, LIST_VIEW_MODES } from '../../components/basic/Classes';
import EmptyPanel from '../../components/basic/EmptyPanel';
import EndOfSteamHandle from '../../components/basic/EndOfSteamHandle';
import ModalExport from '../../components/basic/ModalExport';
import StreamFooter from '../../components/basic/StreamFooter';
import ItemCard from '../../components/items/Card';
import ItemModalAdd from '../../components/items/ModalAdd';
import ItemModalAddBook from '../../components/items/ModalAddBook';
import ItemModalAddFood from '../../components/items/ModalAddFood';
import ItemModalAddImage from '../../components/items/ModalAddImage';
import ItemsToolbarOverview from '../../components/items/ToolbarOverview';
import { GET_ITEMS } from '../../components/items/queries';
import useLocalStorage from '../../hooks/useLocalStorage';


const Items = () => {

  const [currentListMode, setCurrentListMode] = useLocalStorage('list-view-mode', DEFAULT_LIST_VIEW_MODE);
  const [disableHistoryScroll, ] = useLocalStorage('disable-history-scroll', false);
  const [firstFetchHandled, setFirstFetchHandled] = useState(false);
  const [items, setItems] = useState([]);
  const [lastItemIdGathered, ] = useLocalStorage('last-item-id-gathered', false);
  const [lightbox, setLightbox] = useState();
  const [responseData, setResponseData] = useState();
  const [yaLightboxIndex, setYaLightboxIndex] = useState(-1);
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [getItems, { refetch, loading, fetchMore }] = useLazyQuery(GET_ITEMS, {
    variables: {
      first: 60,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setItems(data.items.edges);
      setResponseData(data);
      if (firstFetchHandled === false) {
        setFirstFetchHandled(true);
      }
    },
  });

  const createModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      refetch();
    },
  };

  const createImageModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      refetch();
    },
  };

  const createBookModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      refetch();
      if (response?.pk) {
        navigate(`/private/items/${response.pk}`);
      }

    },
  };

  const createFoodModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      refetch();
      if (response?.pk) {
        navigate(`/private/items/${response.pk}`);
      }

    },
  };

  const exportModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
    },
  };

  const openItem = (itemId) => {
    navigate(`/private/items/${itemId}`);
  };

  const listenScrollEvent = async (event) => {
    if (responseData.items?.edges?.length === 0) return;
    if (event.target.scrollHeight - event.target.scrollTop > event.target.clientHeight * 2.5) return;
    if (!responseData.items?.pageInfo.hasNextPage || loading) return;

    fetchMore({
      variables: {
        after: responseData.items?.pageInfo.endCursor,
        first: 12,
      },
    });
  };

  const renderBreadcrump = ({ showImage = true, className = 'mb-4', bsPrefix = 'breadcrumb' }) => {
    return (
      <Breadcrumb className={className} bsPrefix={bsPrefix}>
        <Breadcrumb.Item active linkAs={Link} linkProps={{ to: "/private/items" }}>
          {showImage && <ItemIcon className="bi img-fluid rounded me-1 align-text-top" width={20} height={20} style={{maxHeight: '20px', minHeight: '20px'}} />}
          {t('pages.items.title')}
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  };

  const renderDropDown = (item, index = -1) => {

    if (!item) return null;

    return (
      <Dropdown onClick={(e) => e.stopPropagation()}>
        <Dropdown.Toggle size="sm" className="dropdown-no-toggle ps-1 border-secondary" id={`dropdown-basic-${item?.pk}`}>
          <ThreeDotsVertical className="ms-1 dropdown-no-toggle" size={14} alt="Select actions" />
        </Dropdown.Toggle>

        <Dropdown.Menu align="end">
          <Dropdown.Item onClick={() => setYaLightboxIndex(index)}>
            {t('pages.items.card-burger-gallery')}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => openItem(item.pk)}>
            {t('pages.items.card-burger-open')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const openLightbox = (params) => {
    if (params?.large) setLightbox(params);
  }

  const closeLightbox = (params) => {
    setLightbox();
  }

  useEffect(() => {
    if (firstFetchHandled === true && disableHistoryScroll !== true) {
      let lastItemMatch = document.querySelector('[data-last-item="true"]')
      lastItemMatch && lastItemMatch.scrollIntoView({block: "center", behavior: 'smooth'});
    }
  }, [currentListMode, firstFetchHandled, disableHistoryScroll]);

  useEffect(() => {
    if (firstFetchHandled === true && disableHistoryScroll !== true) {
      let lastItemMatch = document.querySelector('[data-last-item="true"]')
      lastItemMatch && lastItemMatch.scrollIntoView({block: "center", behavior: 'smooth'});
    }
  }, [firstFetchHandled, disableHistoryScroll]);

  useEffect(() => { getItems(); }, [getItems]);
  useEffect(() => {
    if (location?.state?.doRefetch) refetch();
  }, [location.state?.doRefetch, refetch]);

  return (
    <>
      {createBookModalHandler.opened.value && <ItemModalAddBook handler={createBookModalHandler} />}
      {createFoodModalHandler.opened.value && <ItemModalAddFood handler={createFoodModalHandler} />}
      {createImageModalHandler.opened.value && <ItemModalAddImage handler={createImageModalHandler} />}
      {createModalHandler.opened.value && <ItemModalAdd handler={createModalHandler} />}
      {exportModalHandler.opened.value && <ModalExport handler={exportModalHandler} />}
      <Helmet title={t('pages.items.headline')} />
      <ItemsToolbarOverview
        currentListMode={currentListMode}
        loading={loading}
        refetch={refetch}
        setCurrentListMode={setCurrentListMode}
        showAddBookDialog={createBookModalHandler.opened.set}
        showAddDialog={createModalHandler.opened.set}
        showAddFoodDialog={createFoodModalHandler.opened.set}
        showAddImageDialog={createImageModalHandler.opened.set}
        showExportDialog={exportModalHandler.opened.set}
      />
      <div className="mainspace">
        <div className="splitter d-flex">
          <div className="panel p-4 pt-3 flex-grow-1" onScroll={listenScrollEvent.bind(this)}>

            {items?.length > 0 && (
              <>
                <h2>{t('pages.items.title')}</h2>
                <hr className="mt-0 mb-3" />

                {renderBreadcrump({ bsPrefix: 'breadcrumb flex-column flex-sm-row' })}

                {currentListMode === LIST_VIEW_MODES.Vertical && (
                  <Masonry breakpointCols={{default: 5, 576: 1, 768: 2, 992: 3, 1200: 4}} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                    {items && items.map((item, index) => (
                      <ItemCard item={item.node} key={index} selected={!disableHistoryScroll && lastItemIdGathered === item.node.pk} />
                    ))}
                  </Masonry>
                )}

                {[LIST_VIEW_MODES.Horizontal, LIST_VIEW_MODES.Square, LIST_VIEW_MODES.Compress].indexOf(currentListMode) > -1 && (
                  <Row>
                    {items.map((item, index) => (
                      <Col xs="12" sm="6" lg="4" xxl="2" className="pb-4" key={index}>
                        <ItemCard item={item.node} listViewMode={currentListMode} selected={!disableHistoryScroll && lastItemIdGathered === item.node.pk} />
                      </Col>
                    ))}
                  </Row>
                )}

                {[LIST_VIEW_MODES.ListWithImage, LIST_VIEW_MODES.PlainList].indexOf(currentListMode) > -1 && (
                  <Table className="bg-light" hover bordered striped responsive>
                    <thead>
                      <tr>
                        {currentListMode === LIST_VIEW_MODES.ListWithImage && (
                          <th className="text-center" width="60">
                            <FontAwesomeIcon icon={faImage} />
                          </th>
                        )}
                        <th>{t('pages.items.item-table-name')}</th>
                        <th width="150">{t('pages.items.item-table-type_of')}</th>
                        <th width="145">{t('pages.items.item-table-updated-at')}</th>
                        <th width="50"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item, index) => (
                        <tr
                          className={`${!disableHistoryScroll && lastItemIdGathered === item.node.pk ? 'table-primary' : ''}`}
                          data-last-item={lastItemIdGathered === item.node.pk}
                          key={index}
                        >
                          {currentListMode === LIST_VIEW_MODES.ListWithImage && (
                            <td className="text-center">
                              {(item.node.cardImageSquare || item.node.typeOf?.cardImageSquare) && (
                                <img
                                  className="rounded border"
                                  width="46"
                                  height="46"
                                  src={`${process.env.REACT_APP_MEDIA_URL}${item.node.cardImageSquare || item.node.typeOf?.cardImageSquare}`}
                                  alt={item?.node.name}
                                  loading="lazy"
                                  role="button"
                                  onClick={() => openLightbox({
                                    large: `${process.env.REACT_APP_MEDIA_URL}${item.node.imageFull}`,
                                    alt: item?.node.name,
                                  })}
                                />
                              )}
                              {!(item.node.cardImageSquare || item.node.typeOf?.cardImageSquare) && (
                                <FontAwesomeIcon icon={faImage} className="text-secondary" style={{ fontSize: '45px' }} />
                              )}
                            </td>
                          )}
                          <td role="button" onClick={() => openItem(item.node.pk)}>{item.node.name}</td>
                          <td role="button" onClick={() => openItem(item.node.pk)}>{item.node.typeOf?.name}</td>
                          <td>
                            <Moment locale={i18n.resolvedLanguage} date={item.node.updatedAt} format="L" />
                          </td>
                          <td className="text-center">
                            {renderDropDown(item.node, index)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                <EndOfSteamHandle loading={loading} hasNext={responseData.items?.pageInfo.hasNextPage} />
              </>
            )}
            {items?.length === 0 && !loading && (
              <EmptyPanel
                reloadTrigger={refetch}
                addBook={createBookModalHandler.opened.set}
                addFood={createFoodModalHandler.opened.set}
                addImage={createImageModalHandler.opened.set}
                addEntry={createModalHandler.opened.set}
              />
            )}
            {lightbox && <Lightbox large={lightbox.large} alt={lightbox.alt} onClose={closeLightbox} showRotate={true} />}

            <YALightbox
              open={yaLightboxIndex >= 0}
              index={yaLightboxIndex}
              close={() => setYaLightboxIndex(-1)}
              slides={items?.map((item, index) => ({
                src: `${process.env.REACT_APP_MEDIA_URL}${item.node.cardImageSquare || item.node.typeOf?.cardImageSquare}`,
                title: item.node.name,
              })) || []}
              plugins={[Captions]}
            />
          </div>
        </div>
      </div>
      <StreamFooter
        itemsLoaded={items?.length}
        itemCount={responseData?.items?.totalCount}
        loading={loading}
      />
    </>
  );
};

export default Items;
