import React, { useState } from 'react';

import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Formi from '../../components/basic/Formi';
import { postData } from '../../utils/axiosHelper';


const ModalConfirmPayment = ({ handler, newPlan, lastSubscriptionAutoRenewState }) => {

  const [autoRenew, setAutoRenew] = useState(lastSubscriptionAutoRenewState === true);
  const [disabled, setDisabled] = useState(false);
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleOk = async () => {

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.processing'));
    const data = {
      plan: newPlan.id,
      extend: autoRenew,
    };

    await postData('accounting/subscriptions/set/', data).then(async (response) => {
      toast.dismiss(brot);
      opened.set(false);
      ok(response.data);
    }).catch((error) => {
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    });

  };

  return (
    <Modal show={opened.value} onHide={handleClose}>
      <Modal.Header closeButton={!disabled}>
        <Modal.Title>{t('modals.confirm-plan.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <dl>
          <dt>{t('modals.confirm-plan.cost-overview')}</dt>
          <dd><Formi currencyVal={newPlan.price} /> {t('modals.confirm-plan.plan-costs-per-days', { days: newPlan.runtime_in_days })}</dd>
          <dt>{t('modals.confirm-plan.plan-description')}</dt>
          <dd>{newPlan.name}</dd>
          <dt>{t('modals.confirm-plan.plan-subscription')}</dt>
          <dd>
            {t('modals.confirm-plan.plan-subscription-lead')}
            <Form.Group className="mt-2 mb-3" controlId="autoRenew">
              <Form.Check
                type="checkbox"
                label={t('modals.confirm-plan.auto-renew')}
                checked={autoRenew}
                onChange={(e) => setAutoRenew(e.target.checked)}
              />
            </Form.Group>
          </dd>
        </dl>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
        <Button variant="primary" onClick={handleOk} disabled={disabled}>{t('modals.confirm-plan.order-at-costs')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirmPayment;
