import React from 'react';

import useLocalStorage from '../hooks/useLocalStorage';
import useViewport from '../hooks/useViewport';


const initialState = {
  collapsed: false,
  autoCollapsed: true,
  breakpoint: 1100,
};

const SidebarContext = React.createContext(initialState);

function SidebarProvider({ children }) {
  const [collapsed, setCollapsed] = useLocalStorage('sidebar-collapsed', false);
  const [autoCollapsed, setAutoCollapsed] = useLocalStorage('sidebar-auto-collapsed', true);
  const [breakpoint, setBreakpoint] = useLocalStorage('sidebar-breakpoint', 1100);
  const { width } = useViewport();

  if (autoCollapsed && width < breakpoint && !collapsed) {
    setCollapsed(true);
  } else if (autoCollapsed && collapsed && width > breakpoint) {
    setCollapsed(false);
  }

  return (
    <SidebarContext.Provider
      value={{
        autoCollapsed,
        breakpoint,
        collapsed,
        setAutoCollapsed,
        setBreakpoint,
        setCollapsed,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}

export { SidebarProvider, SidebarContext };
