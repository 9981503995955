import React from 'react';

import { faCircleInfo, faFingerprint, faPlus, faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Nav, Navbar, Spinner } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useLocalStorage from '../../hooks/useLocalStorage';
import { DEFAULT_LIST_VIEW_MODE } from '../basic/Classes';
import ListModeNavToolbar from '../basic/ToolbarListModeNav';


const CollectionToolbarBrowser = ({ refetch, showAddDialog, loading, showBreadcrumps = false }) => {

  const [currentListMode, setCurrentListMode] = useLocalStorage('list-view-mode', DEFAULT_LIST_VIEW_MODE)
  const { t } = useTranslation();

  return (
    <Navbar className="bg-body-tertiary border-bottom" expand={true}>
      <Container fluid={true}>
        <Navbar.Toggle aria-controls="collections-navbar-nav" />
        <Navbar.Collapse id="collections-navbar-nav">
          {showBreadcrumps && (
            <Nav className="d-none d-sm-flex">
              <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/private/collections" }} active>{t('pages.collections.headline')}</Breadcrumb.Item>
                {loading && <Breadcrumb.Item active><Spinner animation="border" size="sm"></Spinner></Breadcrumb.Item>}
              </Breadcrumb>
            </Nav>
          )}
          <Nav className="ms-0">
            <Nav.Link id="navbar-collections-add" onClick={() => showAddDialog(true)}>
              <FontAwesomeIcon size="lg" icon={faPlus} fixedWidth />
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto">&nbsp;</Nav>
          <ListModeNavToolbar loading={loading} disabled={true} current={currentListMode} setCurrent={setCurrentListMode} className="" />
          <Nav className="ms-2 border-start ps-2">
            <Nav.Link disabled={loading} onClick={() => refetch()}>
              <FontAwesomeIcon size="lg" icon={faRotate} fixedWidth />
            </Nav.Link>
            <Nav.Link disabled={true}>
              <FontAwesomeIcon size="lg" icon={faFingerprint} fixedWidth />
            </Nav.Link>
            <Nav.Link disabled={true}>
              <FontAwesomeIcon size="lg" icon={faCircleInfo} fixedWidth />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CollectionToolbarBrowser;
