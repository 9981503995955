import React, { useCallback, useEffect, useState } from 'react';

import { faAsterisk, faBars, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { Button, Form, Modal, Spinner, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Formi from '../../components/basic/Formi';
import { getObjs, postData } from '../../utils/axiosHelper';
import ConfirmPaymentModal from './ModalConfirmPayment';
import FreeFallbackModal from './ModalFreeFallbackInfo';


const PlanChooserModal = ({ handler }) => {

  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newPlan, setNewPlan] = useState(undefined);
  const [plans, setPlans] = useState([]);
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleSave = async (values) => {

    var formData = { ...values };
    formData.default_type = formData.defaultType?.pk || null;

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.saving'));

    await postData('warehouse/types/', formData, { sleep: 500 }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.plan-chooser.created-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const initialize = useCallback(async () => {

    setLoading(true);

    await getObjs('accounting/plans/', { sleep: 1200 }).then(async (response) => {
      setPlans(response.data);
    }).catch((error) => {
      toast.error(`${t('widgets.toast.error')} ${error.response.status}`);
    });

    await getObjs('accounting/subscription/').then(async (response) => {
      setCurrentSubscription(response.data);
    }).catch((error) => {
      if (error.response.status !== 404) {
        toast.error(`${t('widgets.toast.error')} ${error.response.status}`);
      }
    });

    setLoading(false);
  }, [t]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
    },
    onSubmit: (values) => handleSave(values),
  });

  const selectPlan = (plan) => {
    if (plan === null && currentSubscription === null) {
      setNewPlan(undefined);
      return;
    }
    if (plan && currentSubscription && plan.id === currentSubscription.plan.id) {
      setNewPlan(undefined);
      return;
    }

    setNewPlan(plan);
  };

  const activateSelectedPlan = () => {
    if (newPlan === undefined) {
      opened.set(false);
      ok('unchanged');
    }

    if (newPlan === null && currentSubscription !== null) {
      freeFallbackModalHandler.opened.set(true);
      return;
    }

    confirmPaymentModalHandler.opened.set(true);
  };

  const freeFallbackModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      setNewPlan(undefined);
    },
  };

  const confirmPaymentModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      opened.set(false);
      if (ok) ok(response);
    },
  };

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <>
      {freeFallbackModalHandler.opened.value && <FreeFallbackModal handler={freeFallbackModalHandler} currentSubscription={currentSubscription} />}
      {confirmPaymentModalHandler.opened.value && <ConfirmPaymentModal handler={confirmPaymentModalHandler} newPlan={newPlan} lastSubscriptionAutoRenewState={currentSubscription?.extend} />}
      <Modal show={opened.value} onHide={handleClose} size="lg" backdrop={'static'}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.plan-chooser.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={disabled}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th width="50" className="text-center">
                    <FontAwesomeIcon icon={faBars} />
                  </th>
                  <th>{t('modals.plan-chooser.name')}</th>
                  <th width="100" className="text-center">{t('modals.plan-chooser.seats')}</th>
                  <th width="100">{t('modals.plan-chooser.runtime')}</th>
                  <th width="100">{t('modals.plan-chooser.costs')}</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan="100" className="text-center p-5">
                      <div className="my-5">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <div>
                          {t('modals.plan-chooser.loading')}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {!loading && (
                  <tr role="button" className={`${currentSubscription === null ? 'table-success' : ''}`} onClick={() => selectPlan(null)}>
                    <td className="text-center">
                      {currentSubscription === null && <FontAwesomeIcon icon={faCheck} className="text-success" />}
                      {newPlan === null && <FontAwesomeIcon icon={faAsterisk} className="text-warning" />}
                    </td>
                    <td>{t('modals.plan-chooser.private-plan')}</td>
                    <td className="text-center">1</td>
                    <td className="text-center">∞</td>
                    <td className="text-end">
                      <Formi currencyVal={0} />
                    </td>
                  </tr>
                )}
                {!loading && plans.map((plan, index) => (
                  <tr key={index} role="button" className={`${(currentSubscription && currentSubscription.plan.id === plan.id) ? 'table-success' : ''}`} onClick={() => selectPlan(plan)}>
                    <td className="text-center">
                      {currentSubscription && currentSubscription.plan.id === plan.id && <FontAwesomeIcon icon={faCheck} className="text-success" />}
                      {newPlan && newPlan.id === plan.id && <FontAwesomeIcon icon={faAsterisk} className="text-warning" />}
                    </td>
                    <td>{plan.name}</td>
                    <td className="text-center">{plan.seats}</td>
                    <td className="text-center">{plan.runtime_in_days} {t('modals.plan-chooser.days')}</td>
                    <td className="text-end">
                      <Formi currencyVal={plan.price} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {newPlan && newPlan.description && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="fw-bold mb-0">{t('modals.plan-chooser.new-plan-description')}</Form.Label>
                <p>{newPlan.description}</p>
              </Form.Group>
            )}
          </fieldset>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          {newPlan !== undefined && <Button variant="primary" onClick={() => activateSelectedPlan()} disabled={disabled}>{t('forms.actions.activate')}</Button>}
          {newPlan === undefined && <Button variant="primary" onClick={handleClose} disabled={disabled}>{t('modals.plan-chooser.keep')}</Button>}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PlanChooserModal;
