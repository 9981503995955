import React from 'react';

import { faCheck, faDownload, faFilePdf, faPaperPlane, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { downloadBinary } from '../../utils/binaryDownload';
import { renderBoolean } from '../../utils/formatter';
import Formi from '../basic/Formi';


const InvoiceTable = ({ invoices }) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const downloadInvoice = async (invoice) => {
    downloadBinary(`accounting/invoices/${invoice.id}/pdf/`);
  };

  const navigateToInvoice = (invoice) => {
    navigate(`/private/accounting/${invoice.id}`);
  };

  return (
    <>
      <Table className="bg-light mb-0" striped bordered hover responsive>
        <thead>
          <tr>
            <th width="80" className="text-center">{t('pages.accounting.invoice-table.paid')}</th>
            <th width="140">{t('pages.accounting.invoice-table.number')}</th>
            <th className="border-end-0">{t('pages.accounting.invoice-table.created-at')}</th>
            <th width="138" className="border-start-0"></th>
            <th width="120">{t('pages.accounting.invoice-table.total')}</th>
            <th width="60" className="text-center">
              <FontAwesomeIcon icon={faPaperPlane} />
            </th>
            <th width="60" className="text-center">
              <FontAwesomeIcon icon={faDownload} />
            </th>
          </tr>
        </thead>
        <tbody>
          {invoices.sort((a, b) => a.created_at > b.created_at ? -1 : 1).map((invoice, index) => (
            <tr key={index} role="button">
              <td className="text-center" onClick={() => navigateToInvoice(invoice)}>{renderBoolean(invoice.paid)}</td>
              <td width="140" onClick={() => navigateToInvoice(invoice)}>{invoice.number}</td>
              <td className="border-end-0" onClick={() => navigateToInvoice(invoice)}><Formi dateTimeVal={invoice.created_at} /></td>
              <td className="text-end border-start-0 text-center">
                {!invoice.paid && <Link to={`/private/accounting/${invoice.id}`}>{t('pages.accounting.invoice-table.pay-now')}</Link>}
              </td>
              <td className="text-end" onClick={() => navigateToInvoice(invoice)}><Formi currencyVal={invoice.total} /></td>
              <td className="text-center" onClick={() => navigateToInvoice(invoice)}>
                {invoice.email_sent_at && <FontAwesomeIcon icon={faCheck} className="text-success" title={`${t('pages.accounting.invoice-table.send_at')}: ${invoice.email_sent_at}`} />}
                {!invoice.email_sent_at && <FontAwesomeIcon icon={faXmark} className="text-danger" title={t('pages.accounting.invoice-table.not_send_yet')} />}
              </td>
              <td className="text-center" onClick={() => downloadInvoice(invoice)}>
                <FontAwesomeIcon icon={faFilePdf} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default InvoiceTable;
