import React, { useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { patchData } from '../../utils/axiosHelper';


const ElementPriceInputGroup = ({ element, refetch, sourceKey = 'price', formKey = 'price' }) => {

  const [disabled, setDisabled] = useState(false);
  const [price, setPrice] = useState(element?.[sourceKey]);
  const client = useApolloClient();
  const { t } = useTranslation();

  const save = async () => {

    const brot = toast.loading(t('widgets.toast.loading'));

    let formData = { element: element.pk }
    formData[formKey] = price;

    setDisabled(true);

    await patchData(`warehouse/elements/${element.pk}/`, formData, { sleep: 500 }).then(async (response) => {
      toast.dismiss(brot);
      await client.clearStore();
      await refetch();
    }).catch((error) => {
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });

  };

  return (
    <div className="d-inline-block">
      <InputGroup size="sm">
        <Form.Control
          type="number"
          min="0"
          step="0.01"
          id="inputGroup-pricing-sm"
          value={price}
          disabled={disabled}
          onChange={(event) => setPrice(event.target.value)}
          style={{ minWidth: '50px', width: '150px' }}
        />
        {(`${price}` !== `${element?.[sourceKey]}`) && (
          <Button variant="primary" onClick={save} id="button-save" disabled={disabled}>
            <FontAwesomeIcon icon={faFloppyDisk} fixedWidth />
          </Button>
        )}
      </InputGroup>
    </div>
  );

};

export default ElementPriceInputGroup;
