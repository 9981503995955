import React, { useState } from 'react';

import { Button, Card, Col, Row } from 'react-bootstrap';
import { CheckLg, ExclamationLg } from 'react-bootstrap-icons';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import PlanChooserModal from '../../components/accounting/ModalPlanChooser';
import Formi from '../../components/basic/Formi';
import { postData } from '../../utils/axiosHelper';
import { renderBoolean } from '../../utils/formatter';


const SubscriptionOverview = ({ subscription, setSubscription, reloadSubscription }) => {

  const { t } = useTranslation();

  const activateSubscription = async () => {
    const brot = toast.loading(t('widgets.toast.processing'));

    await postData('accounting/subscriptions/activate/').then(async (response) => {
      setSubscription(response.data);
      toast.dismiss(brot);
    }).catch((error) => {
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 2000 });
    });

  };

  const deactivateSubscription = async () => {
    const brot = toast.loading(t('widgets.toast.processing'));

    await postData('accounting/subscriptions/deactivate/').then(async (response) => {
      setSubscription(response.data);
      toast.dismiss(brot);
    }).catch((error) => {
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    });

  };

  const planChooserModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      if (reloadSubscription) {
        reloadSubscription();
      }
    },
  };

  if (subscription === null)
    return (
      <>
        {planChooserModalHandler.opened.value && <PlanChooserModal handler={planChooserModalHandler} />}
        <Card className="mb-5" border="success">
          <Card.Header className="bg-success text-light" as="h5">
            {t('pages.accounting.no-plan-title')}
          </Card.Header>
          <Card.Body>
            <div className="d-flex">
              <div className="flex-shrink-0 d-none d-sm-flex">
                <CheckLg className="text-success bg-light border rounded p-2" size={64} />
              </div>
              <div className="flex-grow-1 ms-3">
                <p className="lead">
                  <Trans i18nKey="pages.accounting.no-subscription-hint-lead">
                    <NavLink to="/meta/fair-use">fair-Use</NavLink>
                    <NavLink to="/meta/community-guidelines">community guidelines</NavLink>
                  </Trans>
                </p>
                <p className="lead">
                  {t('pages.accounting.no-subscription-hint-pro')}
                </p>
                <p className="lead">
                  <Trans i18nKey="pages.accounting.no-subscription-hint-donation">
                    <NavLink to="/meta/donate">donation page</NavLink>
                  </Trans>
                </p>
              </div>
            </div>
            <div className="text-end">
              <Button variant="success" onClick={() => planChooserModalHandler.opened.set(true)}>{t('pages.accounting.switch-plan-btn')}</Button>
            </div>
          </Card.Body>
        </Card>
      </>
    );

  return (
    <>
      {planChooserModalHandler.opened.value && <PlanChooserModal handler={planChooserModalHandler} />}
      <Card className="mb-5" border="success">
        <Card.Header className="bg-success text-light" as="h5">
          {subscription.plan.name}-{t('pages.accounting.tariff')}
        </Card.Header>
        <Card.Body>
          <div className="d-flex">
            <div className="flex-shrink-0 d-none d-sm-flex">
              {subscription.extend && <CheckLg className="text-success bg-light border rounded p-2" size={64} />}
              {!subscription.extend && <ExclamationLg className="text-danger bg-light border rounded p-2" size={64} />}
            </div>
            <div className="flex-grow-1 ms-3">
              <Row>
                <Col md={8}>
                  <dl className="row">
                    <dt className="col-md-5">{t('pages.accounting.field-start')}</dt>
                    <dd className="col-md-7 mb-1"><Formi dateVal={subscription.start} /></dd>
                    <dt className="col-md-5">{t('pages.accounting.field-end')}</dt>
                    <dd className="col-md-7 mb-1"><Formi dateVal={subscription.end} /></dd>
                    <dt className="col-md-5">{t('pages.accounting.field-runtime_in_days')}</dt>
                    <dd className="col-md-7 mb-1">{subscription.plan.runtime_in_days} {t('pages.accounting.field-days')}</dd>
                    <dt className="col-md-5">{t('pages.accounting.field-price')}</dt>
                    <dd className="col-md-7 mb-1"><Formi currencyVal={subscription.plan.price} /></dd>
                    <dt className="col-md-5">{t('pages.accounting.field-seats')}</dt>
                    <dd className="col-md-7 mb-1">{subscription.plan.seats}</dd>
                    <dt className="col-md-5">{t('pages.accounting.field-extendible')}</dt>
                    <dd className="col-md-7 mb-1">{renderBoolean(subscription.plan.extendible)}</dd>
                    <dt className="col-md-5">{t('pages.accounting.field-extend')}</dt>
                    <dd className="col-md-7 mb-1">{renderBoolean(subscription.extend)}</dd>
                  </dl>
                </Col>
                <Col md={4}>
                  {subscription.plan.description || '👀'}
                </Col>
              </Row>
            </div>
          </div>
          <div className="text-end">
            {!subscription.extend && (
              <Button className="ms-1" variant="success" onClick={() => activateSubscription()}>
                {t('pages.accounting.activate-subscription-btn')}
              </Button>
            )}
            {subscription.extend && (
              <Button className="ms-1" variant="secondary" onClick={() => deactivateSubscription()}>
                {t('pages.accounting.deactivate-subscription-btn')}
              </Button>
            )}
            <Button className="ms-1" variant="success" onClick={() => planChooserModalHandler.opened.set(true)}>
              {t('pages.accounting.switch-plan-btn')}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );

};

export default SubscriptionOverview;
