import React from 'react';

import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import meditation from '../../../assets/img/illustrations/meditation.png';


const Contact = () => {

  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t('pages.meta.contact.headline')} />
      <Container className="py-5">
        <div className="d-flex align-items-center h-100">
          <div className="row m-0 align-items-center justify-content-center no-gutters">
            <div className="col-md-5 order-md-2">
              <img src={meditation} alt="Meditation" className="img-fluid" />
            </div>
            <div className="col-md-5 order-md-1">
              <h1 className="display-5 font-weight-bold text-center">{t('pages.meta.contact.send-successfully')}</h1>
              <p className="mb-5 text-center text-muted">{t('pages.meta.contact.send-success-message')}</p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Contact;
