import React, { useEffect, useState } from 'react';

import { faBook, faCircleInfo, faDownload, faFile, faFileImage, faFileLines, faFilter, faFingerprint, faMagnifyingGlass, faPlus, faRotate, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Form, InputGroup, Nav, Navbar, Spinner } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useLocalStorage from '../../hooks/useLocalStorage';
import ListModeNavToolbar from '../basic/ToolbarListModeNav';


const ElementsToolbarOverview = ({
  currentListMode,
  fetchMore,
  filterSidebarHandler,
  getElements,
  infoSidebarHandler,
  loading,
  q,
  refetch,
  setCurrentListMode,
  setQ,
  showAddBookElementDialog,
  showAddDialog,
  showAddFoodElementDialog,
  showAddImageDialog,
  showAddISBNsDialog,
  showBreadcrumps = false,
  showExportDialog,
  variables,
}) => {

  const [, setPerformedSearch] = useLocalStorage('q-elements', '');
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  const { t } = useTranslation();

  const searchList = async () => {
    setPerformedSearch(q);
    variables = { ...variables, item_Name_Icontains: q };
    await getElements({
      variables: variables,
    });
    refetch();
  };

  useEffect(() => {
    let attributeCount = JSON.parse(variables?.attributes || "[]").length;
    attributeCount += (variables?.item_TypeOf_In?.length || 0);
    attributeCount += (variables?.collection_In?.length || 0);

    setActiveFilterCount(attributeCount);
  }, [variables]);

  return (
    <Navbar className="bg-body-tertiary border-bottom" expand={true}>
      <Container fluid={true}>
        <Navbar.Toggle aria-controls="items-navbar-nav" />
        <Navbar.Collapse id="items-navbar-nav">
          {showBreadcrumps && (
            <Nav className="d-none d-sm-flex">
              <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/private/items" }} active>{t('pages.elements.headline')}</Breadcrumb.Item>
                {loading && <Breadcrumb.Item active><Spinner animation="border" size="sm"></Spinner></Breadcrumb.Item>}
              </Breadcrumb>
            </Nav>
          )}
          <Nav>
            <NavDropdown className="navdropdown-no-toggle" id="nav-add-dropdown" title=<FontAwesomeIcon size="lg" icon={faPlus} fixedWidth />>
              <NavDropdown.Item onClick={() => showAddDialog(true)}>
                <FontAwesomeIcon size="lg" icon={faFile} className="me-2" fixedWidth />
                {t('pages.elements.add')}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => showAddImageDialog(true)}>
                <FontAwesomeIcon size="lg" icon={faFileImage} className="me-2" fixedWidth />
                {t('pages.elements.add-image')}
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => showAddBookElementDialog(true)}>
                <FontAwesomeIcon size="lg" icon={faFileLines} className="me-2" fixedWidth />
                {t('pages.elements.add-book')}
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => showAddISBNsDialog(true)}>
                <span className="fa-layers me-3">
                  <FontAwesomeIcon size="lg" icon={faFile} fixedWidth />
                  <FontAwesomeIcon size="lg" icon={faBook} transform="shrink-9 down-2 left-1" inverse fixedWidth />
                </span>
                {t('pages.elements.add-books')}
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => showAddFoodElementDialog(true)}>
                <span className="fa-layers me-3">
                  <FontAwesomeIcon size="lg" icon={faFile} fixedWidth />
                  <FontAwesomeIcon size="lg" icon={faUtensils} transform="shrink-9 down-2 left-1" inverse fixedWidth />
                </span>
                {t('pages.elements.add-food')}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className="d-none">
            <Nav.Link disabled={loading} onClick={() => showAddDialog(true)}>
              <span className="fa-layers">
                <FontAwesomeIcon size="lg" icon={faFile} />
                <FontAwesomeIcon size="lg" icon={faPlus} inverse transform="shrink-9 down-2 left-0.5" />
              </span>
            </Nav.Link>
            <Nav.Link disabled={loading} onClick={() => showAddImageDialog(true)}>
              <FontAwesomeIcon size="lg" icon={faFileImage} />
            </Nav.Link>
            <Nav.Link disabled={loading} onClick={() => showAddBookElementDialog(true)}>
              <FontAwesomeIcon size="lg" icon={faFileLines} />
            </Nav.Link>
            <Nav.Link disabled={loading} onClick={() => showAddFoodElementDialog(true)}>
              <span className="fa-layers">
                <FontAwesomeIcon size="lg" icon={faFile} />
                <FontAwesomeIcon size="lg" icon={faUtensils} inverse transform="shrink-9 down-2 left-1" />
              </span>
            </Nav.Link>
            <Nav.Link disabled={loading} onClick={() => showAddISBNsDialog(true)}>
              <span className="fa-layers">
                <FontAwesomeIcon size="lg" icon={faFile} />
                <FontAwesomeIcon size="lg" icon={faBook} inverse transform="shrink-9 down-2 left-1" />
              </span>
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link disabled={loading} onClick={() => showExportDialog(true)}>
              <FontAwesomeIcon size="lg" icon={faDownload} fixedWidth />
            </Nav.Link>
            <Nav.Link disabled={loading} active={filterSidebarHandler.opened.value} onClick={() => filterSidebarHandler.opened.set(!filterSidebarHandler.opened.value)}>
              <FontAwesomeIcon size="lg" icon={faFilter} />
              {activeFilterCount > 0 && (
                <span className="top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  <span>{activeFilterCount}</span>
                  <span className="visually-hidden">active filter</span>
                </span>
              )}
            </Nav.Link>
          </Nav>
          <Nav className="ms-2 d-none d-sm-flex border-start ps-3">
            <InputGroup size="sm">
              <Form.Control type="search" value={q} placeholder={t('forms.actions.search')} onChange={(e) => setQ(e.target.value)} />
              <Button variant="secondary" id="button-addon2" onClick={() => searchList()}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </Button>
            </InputGroup>
          </Nav>
          <Nav className="ms-auto ps-2">&nbsp;</Nav>
          <ListModeNavToolbar loading={loading} current={currentListMode} setCurrent={setCurrentListMode} className="" />
          <Nav className="ms-2 border-start ps-2">
            <Nav.Link disabled={loading} onClick={() => refetch()}>
              <FontAwesomeIcon size="lg" icon={faRotate} fixedWidth />
            </Nav.Link>
            <Nav.Link disabled={true}>
              <FontAwesomeIcon size="lg" icon={faFingerprint} fixedWidth />
            </Nav.Link>
            <Nav.Link disabled={loading} onClick={() => infoSidebarHandler.opened.set(!infoSidebarHandler.opened.value)}>
              <FontAwesomeIcon size="lg" icon={faCircleInfo} fixedWidth />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default ElementsToolbarOverview;
