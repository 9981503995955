import React from 'react';

import { Link } from 'react-router-dom';


const infoFromElement = (element) => {

  if (!element)
    return null;

  if (element.item.imageThumbnail)
    return {
      to: `/private/collections/${element.collection.pk}/${element.pk}`,
      img: `${element.item.imageThumbnail}`,
      alt: `${element.item.name}`,
    };

  if (!element.imageThumbnail && element.typeOf?.itemImageThumbnail)
    return {
      to: `/private/collections/${element.collection.pk}/${element.pk}`,
      img: `${element.typeOf.itemImageThumbnail}`,
      alt: `${element.name}`,
    };

};

const ElementImageBreadcrump = ({ element }) => {

  var crumps = [];

  crumps.push(infoFromElement(element));

  return (
    <>
      {crumps && crumps.filter((e) => e).map((crump, index) => (
        <Link to={crump.to} key={index}>
          <img
            alt={crump.alt}
            title={crump.alt}
            className="img-fluid rounded border bg-white ms-1"
            src={`${process.env.REACT_APP_MEDIA_URL}${crump.img}`}
            style={{ maxHeight: '40px', minHeight: '40px' }}
          />
        </Link>
      ))}
    </>
  );
};

export default ElementImageBreadcrump;
