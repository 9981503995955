import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';


function RegisterConfirmForm() {

  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();

  if (!token) {
    navigate('/auth/password-reset');
  }

  const activate = async (values) => {

    var formData = { ...values };

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.saving'));

    return await postData('auth/register/activate/', formData, { sleep: 500 }).then(async (response) => {
      navigate('/auth/login', { state: { justActivated: true } })
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
      return false;
    }).finally(() => {
      setDisabled(false);
      toast.dismiss(brot);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      activation_key: token,
    },
    onSubmit: async (values) => {
      activate(values);
    },
  });

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{t('public.auth.field-activation-key')}</Form.Label>
        <Form.Control
          autoComplete="off"
          isInvalid={formik.errors?.fields?.activation_key}
          name="activation_key"
          onChange={formik.handleChange}
          value={formik.values.activation_key}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors?.fields?.activation_key}
        </Form.Control.Feedback>
      </Form.Group>
      <div className="text-center mt-3">
        <div className="d-grid gap-2">
          <Button type="button" onClick={formik.handleSubmit} variant="primary" disabled={disabled}>
            {t('public.auth.btn-activate-account')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default RegisterConfirmForm;
