import React from 'react';

import { faCircleInfo, faDownload, faFile, faFileImage, faFileLines, faFingerprint, faPlus, faRotate, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Nav, Navbar, Spinner } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ListModeNavToolbar from '../basic/ToolbarListModeNav';


const ItemsToolbarOverview = ({
  currentListMode,
  loading,
  refetch,
  setCurrentListMode,
  showAddBookDialog,
  showAddDialog,
  showAddFoodDialog,
  showAddImageDialog,
  showBreadcrumps = false,
  showExportDialog,
}) => {

  const { t } = useTranslation();

  return (
    <Navbar className="bg-body-tertiary border-bottom" expand={true}>
      <Container fluid={true}>
        <Navbar.Toggle aria-controls="items-navbar-nav" />
        <Navbar.Collapse id="items-navbar-nav">
          {showBreadcrumps && (
            <Nav className="d-none d-sm-flex">
              <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/private/items" }} active>{t('pages.items.headline')}</Breadcrumb.Item>
                {loading && <Breadcrumb.Item active><Spinner animation="border" size="sm"></Spinner></Breadcrumb.Item>}
              </Breadcrumb>
            </Nav>
          )}
          <Nav>
            <NavDropdown
              title=<FontAwesomeIcon size="lg" icon={faPlus} fixedWidth />
              className="navdropdown-no-toggle"
              id="nav-add-dropdown"
            >
              <NavDropdown.Item onClick={() => showAddDialog(true)}>
                <FontAwesomeIcon size="lg" icon={faFile} className="me-2" fixedWidth />
                {t('pages.items.add-element')}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => showAddImageDialog(true)}>
                <FontAwesomeIcon size="lg" icon={faFileImage} className="me-2" fixedWidth />
                {t('pages.items.add-image')}
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => showAddBookDialog(true)}>
                <FontAwesomeIcon size="lg" icon={faFileLines} className="me-2" fixedWidth />
                {t('pages.items.add-book')}
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => showAddFoodDialog(true)}>
                <span className="fa-layers me-3">
                  <FontAwesomeIcon size="lg" icon={faFile} fixedWidth />
                  <FontAwesomeIcon size="lg" icon={faUtensils} transform="shrink-9 down-2 left-1" inverse fixedWidth />
                </span>
                {t('pages.items.add-food')}
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link disabled={loading} onClick={() => showExportDialog(true)}>
              <FontAwesomeIcon size="lg" icon={faDownload} fixedWidth />
            </Nav.Link>
          </Nav>
          <Nav className="d-none">
            <Nav.Link disabled={loading} onClick={() => showAddDialog(true)}>
              <span className="fa-layers">
                <FontAwesomeIcon size="lg" icon={faFile} />
                <FontAwesomeIcon size="lg" icon={faPlus} inverse transform="shrink-9 down-2 left-0.5" />
              </span>
            </Nav.Link>
            <Nav.Link disabled={loading} onClick={() => showAddImageDialog(true)}>
              <FontAwesomeIcon size="lg" icon={faFileImage} />
            </Nav.Link>
            <Nav.Link disabled={loading} onClick={() => showAddBookDialog(true)}>
              <FontAwesomeIcon size="lg" icon={faFileLines} />
            </Nav.Link>
            <Nav.Link disabled={loading} onClick={() => showAddFoodDialog(true)}>
              <span className="fa-layers">
                <FontAwesomeIcon size="lg" icon={faFile} />
                <FontAwesomeIcon size="lg" icon={faUtensils} inverse transform="shrink-9 down-2 left-1" />
              </span>
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto ps-2">&nbsp;</Nav>
          <ListModeNavToolbar loading={loading} current={currentListMode} setCurrent={setCurrentListMode} className="" />
          <Nav className="ms-2 border-start ps-2">
            <Nav.Link disabled={loading} onClick={() => refetch()}>
              <FontAwesomeIcon size="lg" icon={faRotate} fixedWidth />
            </Nav.Link>
            <Nav.Link disabled={true}>
              <FontAwesomeIcon size="lg" icon={faFingerprint} fixedWidth />
            </Nav.Link>
            <Nav.Link disabled={true}>
              <FontAwesomeIcon size="lg" icon={faCircleInfo} fixedWidth />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default ItemsToolbarOverview;
