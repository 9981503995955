import React, { useEffect, useState } from 'react';

import { useApolloClient, useLazyQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GET_CHOICE_FOR_EDITING } from '../../components/collections/queries';
import { postData, putData } from '../../utils/axiosHelper';


const ChoiceModal = ({ handler, choicePk, attributePk }) => {

  const [disabled, setDisabled] = useState(false);
  const client = useApolloClient();
  const { opened, ok, cancel, backdrop } = handler;
  const { t } = useTranslation();

  const [initialData, setInitialData] = useState({
    label: '',
    pk: choicePk !== false && choicePk,
    attribute: attributePk,
    value: '',
  });

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleEdit = async (values) => {

    var formData = { ...values };

    setDisabled(true);

    const brot = toast.loading(t('widgets.toast.saveing'));

    let method = choicePk ? putData : postData;

    await method(`warehouse/choices/${choicePk ? `${choicePk}/` : ''}`, formData).then(async (response) => {
      opened.set(false);
      if (choicePk) {
        toast.update(brot, { render: t('modals.choice.updated-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      } else {
        toast.update(brot, { render: t('modals.choice.saved-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      }
      await client.clearStore();
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const [getChoiceForEditing] = useLazyQuery(GET_CHOICE_FOR_EDITING, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setInitialData({
        label: data.choiceForEditing.label,
        pk: data.choiceForEditing.pk,
        value: data.choiceForEditing.value,
        attribute: data.choiceForEditing.attribute.pk,
      });
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    onSubmit: (values) => handleEdit(values),
  });

  useEffect(() => {
    if (choicePk !== false) {
      getChoiceForEditing({
        variables: { choiceId: choicePk }
      });
    }
  }, [getChoiceForEditing, choicePk]);

  return (
    <>
      <Modal show={opened.value} onHide={handleClose} backdrop={disabled || backdrop ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>
            {formik.values.pk && t('modals.choice.title-edit')}
            {!formik.values.pk && t('modals.choice.title-create')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={disabled}>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.choice.field-label')}</Form.Label>
              <Form.Control
                autoComplete="off"
                isInvalid={formik.errors?.fields?.label}
                name="label"
                onChange={formik.handleChange}
                type="text"
                value={formik.values.label}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.label}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{t('modals.choice.field-value')}</Form.Label>
              <Form.Control type="hidden" isInvalid={formik.errors?.fields?.value} />
              <Form.Control
                autoComplete="off"
                isInvalid={formik.errors?.fields?.value}
                name="value"
                onChange={formik.handleChange}
                type="text"
                value={formik.values.value}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.value}
              </Form.Control.Feedback>
            </Form.Group>

          </fieldset>
        </Modal.Body>

        <Modal.Footer className="d-flex">
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>
            {formik.values.pk && t('forms.actions.save')}
            {!formik.values.pk && t('forms.actions.create')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChoiceModal;
