import React, { useEffect, useState, useCallback } from 'react';

import { faCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import pathfinder from '../../../assets/img/illustrations/pathfinder.png';
import { getObj } from '../../../utils/axiosHelper';


const NewsletterConfirm = () => {
  const { t } = useTranslation();
  const [confirmationState, setConfirmationState] = useState(null);
  const { confirmationId } = useParams();

  const checkConfirmation = useCallback(() => {
    setConfirmationState('loading');
    getObj(`newsletter/subscriptions/${confirmationId}/`, { sleep: 1000 }).then(async (response) => {
      const status = response?.data?.status || 'unknown';
      setConfirmationState(status);
    }).catch((error) => {
      setConfirmationState(`${t('widgets.toast.error')} ${error?.response?.status}`);
    });
  }, [confirmationId, t]);

  useEffect(() => {
    if (!confirmationId) return;
    checkConfirmation();
  }, [confirmationId, checkConfirmation]);

  return (
    <>
      <Helmet title={t('public.newsletter.confirm.title')} />
      <div className="container pt-2">
        <Row className="mt-5">
          <Col md={8}>
            <div style={{ paddingTop: '80px' }}></div>
            <h1 className="font-weight-bold mb-4">
              {t('public.newsletter.confirm.title')}<br />
            </h1>
            <p className="mb-5 text-muted">
              {confirmationState === 'loading' ? <FontAwesomeIcon icon={faSpinner} className="fa-spin me-1" size="xs" fixedWidth /> : null}
              {confirmationState !== 'loading' ? <FontAwesomeIcon icon={faCircle} className={`me-1 ${confirmationState === 'active' ? 'text-success' : ''}`} size="xs" fixedWidth /> : null}
              {t('public.newsletter.confirm.state')}:
              <span className="ms-1">{confirmationState}</span>
            </p>
            <div>
              <Link to="/">
                <Button>{t('public.error.btn-homepage')}</Button>
              </Link>
            </div>
          </Col>
          <Col md={4}>
            <img src={pathfinder} alt="Teamwork" className="img-fluid" />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NewsletterConfirm;
