import React, { useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';


const ItemModalRemoveUserPermission = ({ handler, item, permission }) => {

  const [disabled, setDisabled] = useState(false);
  const client = useApolloClient();
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleDelete = async (values) => {

    var formData = { ...values };

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.deleting'));

    await postData(`warehouse/items/${item.pk}/remove_user_permission/`, formData, { sleep: 500 }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.item-remove-user.removed-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      await client.clearStore();
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      item: item?.pk,
      permission: permission?.pk,
    },
    onSubmit: (values) => handleDelete(values),
  });

  return (
    <Modal show={opened.value} onHide={handleClose} backdrop={disabled ? 'static' : true}>
      <Modal.Header closeButton={!disabled}>
        <Modal.Title>{t('modals.item-remove-user.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Trans i18nKey="modals.item-remove-user.message" values={{ userName: permission.repr, itemName: item.name }}>
            <b>user repr</b>
            <b>item name</b>
          </Trans>
        </p>
        <Form.Group className="mb-3">
          <Form.Control type="hidden" isInvalid={formik.errors?.fields?.permission || formik.errors?.fields?.item} />
          <Form.Control.Feedback type="invalid">
            {formik.errors?.fields?.permission || formik.errors?.fields?.item}
          </Form.Control.Feedback>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
        <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.remove')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ItemModalRemoveUserPermission;
