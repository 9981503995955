import React, { useEffect, useRef, useState } from 'react';

import { useApolloClient, useLazyQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { Folder2Open, Trash, X } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { confirm } from '../../components/basic/Confirmation';
import { GET_ITEM_FOR_EDITING } from '../../components/items/queries';
import { putData } from '../../utils/axiosHelper';
import { basename } from '../../utils/formatter';
import AttributeInput from './AttributeInput';
import ItemModalDelete from './ModalDelete';


const ItemModalEdit = ({ handler, original }) => {

  const [disabled, setDisabled] = useState(false);
  const [item, setItem] = useState();
  const [originalProperties, setOriginalProperties] = useState([]);
  const [types, setTypes] = useState([]);
  const cardImageRef = useRef();
  const client = useApolloClient();
  const { opened, ok, cancel, backdrop } = handler;
  const { t } = useTranslation();

  const [initialData, setInitialData] = useState({
    cardImageName: basename(original.cardImage || t('forms.values.undefined')),
    delete_card_image: false,
    description: original.description,
    name: original.name,
    new_card_image: null,
    pk: original.pk,
    properties: [],
    typeOf: '',
    website: '',
  });

  const [getItemForEditing] = useLazyQuery(GET_ITEM_FOR_EDITING, {
    variables: { itemId: original.pk },
    notifyOnNetworkStatusChange: true,
    onCompleted: async (data) => {
      setTypes(data.types);
      setItem(data.itemForEditing);
      setOriginalProperties(data.itemForEditing?.properties || []);
      setInitialData({
        ...data.itemForEditing,
        cardImageName: basename(data.itemForEditing?.cardImage || t('forms.values.undefined')),
      });

      if (data.itemForEditing === null) {

        await confirm({
          title: t('modals.confirmation.permission-error-title'),
          lead: t('modals.confirmation.permission-error-msg'),
          showCancel: false,
          danger: false,
        })

        opened.set(false);
      }
    },
  });

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleDelete = () => {
    deleteModalHandler.opened.set(true);
  };

  const handleEdit = async (values) => {

    var formData = { ...values };
    formData.type_of = formData.typeOf?.pk || null;
    formData['properties'] = JSON.stringify(formData.properties.map((p) => { return { attribute: p.attribute.pk, value: p.value }; }));
    delete formData['typeOf'];

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.loading'));
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    await putData(`warehouse/items/${item.pk}/`, formData, { config }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.item-edit.updated-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      await client.clearStore();
      ok();
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const updateMergedProperties = (propertiesFromOriginal, propertiesFromTypeSelection) => {

    const existingAttributeIds = (propertiesFromOriginal || []).map(e => e.attribute?.pk);
    const filteredPropertiesFromTypeSelection = propertiesFromTypeSelection.filter(p => existingAttributeIds.indexOf(p.definition.attribute.pk) === -1);
    var mergedProperties = [...propertiesFromOriginal, ...filteredPropertiesFromTypeSelection];

    // Lookup definition for existing properties to allow choices and other stuff
    for (var k = 0; k < mergedProperties.length; k++) {
      var prop = mergedProperties[k];

      if (!prop.definition) {
        for (var i = 0; i < propertiesFromTypeSelection.length; i++) {
          if (propertiesFromTypeSelection[i].attribute.pk === prop.attribute.pk && propertiesFromTypeSelection[i].definition) {
            mergedProperties[k] = { ...prop, definition: propertiesFromTypeSelection[i].definition }
          }
        }
      }
    }

    return mergedProperties;
  };

  const toggleCardImageDeletion = () => {
    formik.setFieldValue('delete_card_image', !formik.values.delete_card_image);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    onSubmit: (values) => handleEdit(values),
  });

  const deleteModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: (response) => {
      opened.set(false);
      ok('destroyed');
    },
  };

  const valueFromId = (opts, id) => opts.find((o) => o.node.pk === id);
  const setFieldValue = formik.setFieldValue;

  useEffect(() => {
    if (formik.values.typeOf) {
      const newProperties = [];

      // If type has no definition, lookup global type definitions
      if (!formik.values.typeOf?.definitions) {

        const matchFound = types?.edges?.find(element => element.node.pk === formik.values.typeOf.pk);

        if (matchFound) {
          matchFound.node?.definitions?.forEach((definition) => {
            newProperties.push({ definition, attribute: definition.attribute, value: '' });
          })
        }

      } else {

        formik.values.typeOf?.definitions?.forEach((definition) => {
          newProperties.push({ definition, attribute: definition.attribute, value: '' });
        })

      }

      setFieldValue('properties', updateMergedProperties(originalProperties, newProperties))
    }
  }, [formik.values.typeOf, setFieldValue, types, originalProperties]);


  useEffect(() => {
    getItemForEditing();
  }, [original.id, getItemForEditing]);

  if (!item) return null;

  return (
    <>
      {deleteModalHandler.opened.value && <ItemModalDelete handler={deleteModalHandler} item={item} />}
      <Modal show={opened.value} onHide={handleClose} size="lg" backdrop={disabled || backdrop === true ? 'static' : true} scrollable>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.item-edit.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={disabled}>
            <Row>
              <Col md="8">
                <Row>
                  <Col md="7">
                    <Form.Group className="mb-3">
                      <Form.Label>{t('modals.item-edit.field-name')}</Form.Label>
                      <Form.Control
                        autoComplete="off"
                        isInvalid={formik.errors?.fields?.name}
                        name="name"
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.name}
                      />
                      <Form.Control.Feedback type="invalid">{formik.errors?.fields?.name}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="5">
                    <Form.Group className="mb-3">
                      <Form.Label>{t('modals.item-edit.field-type')}</Form.Label>
                      <Select
                        getOptionLabel={(e) => e.node.name}
                        getOptionValue={(e) => e.node.pk}
                        placeholder={t('modals.item-edit.field-type-placeholder')}
                        isClearable={true}
                        name="typeOf"
                        onChange={(o) => formik.setFieldValue("typeOf", o?.node || null)}
                        options={types.edges || []}
                        value={valueFromId(types.edges || [], formik.values.typeOf?.pk)}
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                      <Form.Control.Feedback type="invalid">{formik.errors?.fields?.default_type}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md="4">
                <Form.Group className="mb-3">
                  <Form.Label>{t('modals.item-edit.field-list-image')}</Form.Label>
                  <InputGroup>
                    {item.cardImage && !formik.values.new_card_image && (
                      <Button variant="outline-secondary" className="px-1" onClick={toggleCardImageDeletion}>
                        <Trash />
                      </Button>
                    )}
                    <Form.Control
                      className={formik.values.delete_card_image ? "text-decoration-line-through" : ""}
                      value={formik.values.new_card_image?.name || formik.values.cardImageName}
                      isInvalid={formik.errors?.fields?.card_image}
                      disabled
                    />
                    {formik.values.new_card_image && (
                      <Button
                        variant="outline-secondary"
                        className="px-1"
                        onClick={() => { cardImageRef.current.value = null; formik.setFieldValue('new_card_image', null) }}
                      >
                        <X />
                      </Button>
                    )}
                    <label className="file-btn btn btn-primary px-2">
                      <input
                        accept="image/*"
                        id="field-new_card_image"
                        ref={cardImageRef}
                        name="new_card_image"
                        onChange={(event) => formik.setFieldValue('new_card_image', event.currentTarget.files[0])}
                        className="d-none"
                        type="file"
                      />
                      <Folder2Open />
                    </label>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors?.fields?.card_image}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={((item.cardImageThumbnail && !formik.values.delete_card_image && !formik.values.new_card_image) || formik.values.new_card_image || (!formik.values.new_card_image && (!item.cardImageThumbnail || formik.values.delete_card_image) && formik.values.typeOf?.cardImageThumbnail)) ? 8 : 12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('modals.item-edit.field-website')}</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    isInvalid={formik.errors?.fields?.website}
                    name="website"
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.website}
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors?.fields?.website}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t('modals.item-edit.field-description')}</Form.Label>
                  <Form.Control
                    name="description"
                    as="textarea"
                    rows={4}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.description}
                    isInvalid={formik.errors?.fields?.description}
                  />
                  <div className="form-text">{t('modals.item-edit.field-description-info')}</div>
                  <Form.Control.Feedback type="invalid">{formik.errors?.fields?.description}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="4">
                {item.cardImageThumbnail && !formik.values.delete_card_image && !formik.values.new_card_image && (
                  <Form.Group className="mb-3">
                    <Form.Label>{t('modals.item-edit.field-list-image-preview')}</Form.Label>
                    <img
                      alt={t('modals.item-edit.field-list-image-preview')}
                      className="img-fluid rounded border"
                      src={`${process.env.REACT_APP_MEDIA_URL}${item.cardImageThumbnail}`}
                    />
                  </Form.Group>
                )}
                {formik.values.new_card_image && (
                  <Form.Group className="mb-3">
                    <Form.Label>{t('modals.item-edit.field-new-list-image-preview')}</Form.Label>
                    <img
                      alt={t('modals.item-edit.field-new-list-image-preview')}
                      className="img-fluid rounded border"
                      src={URL.createObjectURL(formik.values.new_card_image)}
                    />
                  </Form.Group>
                )}
                {!formik.values.new_card_image && (!item.cardImageThumbnail || formik.values.delete_card_image) && formik.values.typeOf?.cardImageThumbnail && (
                  <Form.Group className="mb-3">
                    <Form.Label>{t('modals.item-edit.field-type-fallback-image-preview')}</Form.Label>
                    <img
                      alt={t('modals.item-edit.field-type-fallback-image-preview')}
                      className="img-fluid rounded border"
                      src={`${process.env.REACT_APP_MEDIA_URL}${formik.values.typeOf?.cardImageThumbnail}`}
                    />
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              {formik.values?.properties.map((property, index) => (
                <Col md={4} key={index}>
                  <AttributeInput formik={formik} index={index} mode='edit' property={property} />
                </Col>
              ))}
            </Row>
          </fieldset>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Button variant="danger" className="me-auto" onClick={handleDelete} disabled={disabled}>{t('forms.actions.delete')}</Button>
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.update')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ItemModalEdit;
