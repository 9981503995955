import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import Barcode from 'react-barcode';
import { Button, Col, Row } from 'react-bootstrap';
import { Box as ItemIcon } from 'react-bootstrap-icons';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import ModalImage from 'react-modal-image';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Formi from '../../components/basic/Formi';
import ItemImageBreadcrump from '../../components/items/ImageBreadcrump';
import ItemModalDelete from '../../components/items/ModalDelete';
import ItemModalEdit from '../../components/items/ModalEdit';
import ItemSidebarComment from '../../components/items/SidebarComments';
import ItemSidebarPermission from '../../components/items/SidebarPermission';
import ItemToolbar from '../../components/items/Toolbar';
import { GET_ITEM_BY_ID } from '../../components/items/queries';
import useLocalStorage from '../../hooks/useLocalStorage';
import { infoFromItem } from '../../utils/renderHelper';


const Item = () => {

  const [, setLastItemIdGathered] = useLocalStorage('last-item-id-gathered', false);
  const [item, setItem] = useState();
  const [showBarcodes, setShowBarcodes] = useState(false);
  const { itemId } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [getItem, { loading, error, refetch }] = useLazyQuery(GET_ITEM_BY_ID, {
    variables: { id: itemId },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setLastItemIdGathered(itemId);
      setItem(data.itemById);
    },
  });

  const editModalHandler = {
    backdrop: true,
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      if (response === 'destroyed') {
        handleDeletionResponse();
      } else {
        refetch();
      }
    },
  };

  const deleteModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
    ok: (response) => {
      handleDeletionResponse(response);
    },
  };

  const commentSidebarHandler = {
    opened: Object.assign({}, ...useLocalStorage('show-sidebar-comments', false).map((e, i) => { return {[i === 0 ? 'value' : 'set']: e}})),
  };

  const permissionSidebarHandler = {
    opened: Object.assign({}, ...useLocalStorage('show-sidebar-permissions', false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
  };

  const handleDeletionResponse = () => {
    navigate('/private/items', { state: { doRefetch: true } });
  };

  const renderBreadcrump = ({ showImage = true, className = 'mb-5', bsPrefix = 'breadcrumb' }) => {
    let imgInfo = infoFromItem(item);

    return (
      <Breadcrumb className={className} bsPrefix={bsPrefix}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/private/items" }}>
          {showImage && <ItemIcon className="bi img-fluid rounded me-1 align-text-top" width={20} height={20} style={{maxHeight: '20px', minHeight: '20px'}} />}
          {t('pages.items.title')}
        </Breadcrumb.Item>
        <Breadcrumb.Item active linkAs={Link} linkProps={{ to: `/private/items/${item.pk}` }}>
          {showImage && imgInfo && (
            <img
              alt={item.name}
              title={item.name}
              className="img-fluid rounded-circle me-1 align-text-top"
              src={`${process.env.REACT_APP_MEDIA_URL}${imgInfo.img}`}
              width="20"
              height="20"
              style={{maxHeight: '20px', minHeight: '20px'}}
            />
          )}
          {item.name}
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  };

  const renderPropertyValue = (property) => {

    switch(property.attribute.typeOf) {

      case 'A_14':
        return <a href={`https://www.imdb.com/title/${property.value}/`} target="_blank" rel="noreferrer">{property.value}</a>;

      case 'A_12':
        return <a href={`https://www.amazon.de/dp/${property.value}`} target="_blank" rel="noreferrer">{property.value}</a>;

      case 'A_15':
        return  (
          <>
            {property.value} (<Button variant="link" className="p-0 cursor-pointer" onClick={() => setShowBarcodes(prev => !prev)}>{t(showBarcodes ? 'forms.actions.toggle-hide' : 'forms.actions.toggle-show')}</Button>)
            <br />
            {showBarcodes && (<Barcode value={property.value} format="ean13" />)}
          </>
        )

      default:
        return <Formi propertyVal={property} />;
    }

  };

  useEffect(() => { getItem(); }, [itemId, getItem]);
  useEffect(() => { if (location.state?.doRefetch) refetch(); }, [location.state?.doRefetch, refetch]);

  if (error) return `${error}`;
  if (!item) return null;

  return (
    <>
      {editModalHandler.opened.value && <ItemModalEdit handler={editModalHandler} original={item} />}
      {deleteModalHandler.opened.value && <ItemModalDelete handler={deleteModalHandler} item={item} />}
      <Helmet title={item.name} />
      <ItemToolbar
        commentSidebarHandler={commentSidebarHandler}
        item={item}
        loading={loading}
        permissionSidebarHandler={permissionSidebarHandler}
        refetch={refetch}
        showDeleteDialog={deleteModalHandler.opened.set}
        showEditDialog={editModalHandler.opened.set}
      />
      <div className="mainspace">
        <div className="splitter d-flex">
          <div className={`panel overflow-auto flex-fill ${(permissionSidebarHandler.opened.value || commentSidebarHandler.opened.value) ? 'd-none d-sm-block' : ''}`}>
            <div className="position-relative h-100 w-100">
              <div className="module">
                <div className="mainspace">
                  <div className="p-4 pb-0 pt-3">
                    <div className="d-flex">
                      <div className="flex-grow-1 me-3">
                        <h2>{item.name}</h2>
                      </div>
                      <div className="flex-shrink-0 d-none d-sm-flex">
                        <ItemImageBreadcrump item={item} />
                      </div>
                    </div>
                    <hr className="mt-0 mb-3" />
                    {renderBreadcrump({ bsPrefix: 'breadcrumb flex-column flex-sm-row' })}
                    <Row>
                      <Col md={10} xxl={9}>
                        {item?.description && (
                          <>
                            <h3>{t('pages.items.item-description')}</h3>
                            <div className="mb-4">
                              <ReactMarkdown>{item.description}</ReactMarkdown>
                            </div>
                          </>
                        )}

                        <h3>{t('pages.items.item-details')}</h3>
                        {item.properties.map((property, index) => (
                          <dl key={index} className="row mb-0">
                            <dt className="col-sm-3">{property.attribute.name}</dt>
                            <dd className="col-sm-9">
                              {renderPropertyValue(property)}
                            </dd>
                          </dl>
                        ))}

                        <dl className="row">
                          {item.website && (
                            <>
                              <dt className="col-sm-3">{t('pages.items.website')}</dt>
                              <dd className="col-sm-9">
                                <a href={`${item.website}`} target="_blank" rel="noreferrer">{item.website}</a>
                              </dd>
                            </>
                          )}
                          {item.typeOf && (
                            <>
                              <dt className="col-sm-3">{t('pages.items.type-of')}</dt>
                              <dd className="col-sm-9">{item.typeOf.name}</dd>
                            </>
                          )}
                          {item.elements.length > 0 && (
                            <>
                              <dt className="col-sm-3">{t('pages.items.available-in')}</dt>
                              <dd className="col-sm-9">
                                {item.elements.map((element, index) => (
                                  <span key={index}>
                                    {index > 0 && <span>, </span>}
                                    <Link to={`/private/collections/${element.collection.pk}`}>
                                      {element.collection.name}
                                    </Link>
                                    <span className="ms-1">(</span>
                                    <Link to={`/private/collections/${element.collection.pk}/${element.pk}`}>
                                      {element.pk.substring(0, 8)}
                                    </Link>
                                    <span>)</span>
                                  </span>
                                ))}
                              </dd>
                            </>
                          )}
                          <dt className="col-sm-3">{t('pages.items.created-at')}</dt>
                          <dd className="col-sm-9">
                            <Formi dateTimeVal={item.createdAt} />
                          </dd>
                          <dt className="col-sm-3">{t('pages.items.updated-at')}</dt>
                          <dd className="col-sm-9">
                            <Formi dateTimeVal={item.updatedAt} />
                          </dd>
                          <dt className="col-sm-3">{t('pages.items.internal-sku')}</dt>
                          <dd className="col-sm-9">{item.pk.substring(0, 8)}</dd>
                        </dl>
                      </Col>
                      <Col md={2} xxl={3}>
                        {item.imageThumbnail && <ModalImage
                          className="img-fluid border rounded p-1 bg-body"
                          small={`${process.env.REACT_APP_MEDIA_URL}${item.imageThumbnail}`}
                          large={`${process.env.REACT_APP_MEDIA_URL}${item.imageFull}`}
                          showRotate={true}
                          alt={item.name}
                        />}
                      </Col>
                    </Row>

                    {item.userCanEdit && (
                      <div className="text-end mt-4 border-top pt-4 mb-3">
                        <Button variant="danger" onClick={() => deleteModalHandler.opened.set(true)} id="button-remove" className="ms-1">
                          {t('forms.actions.delete')}
                        </Button>
                        <Button variant="primary" onClick={() => editModalHandler.opened.set(true)} id="button-edit" className="ms-1">
                          {t('forms.actions.edit')}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {commentSidebarHandler.opened.value && <ItemSidebarComment handler={commentSidebarHandler} item={item} />}
          {permissionSidebarHandler.opened.value && <ItemSidebarPermission handler={permissionSidebarHandler} item={item} refetch={refetch} />}
        </div>
      </div>
    </>
  );
};

export default Item;
