import React, { useCallback, useEffect, useState } from 'react';

import { useApolloClient, useLazyQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { Button, Form, Modal, SplitButton } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import InputGroup from 'react-bootstrap/InputGroup';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { GET_COLLECTIONS } from '../../components/collections/queries';
import { GET_ITEMS } from '../../components/items/queries';
import { postData } from '../../utils/axiosHelper';
import ItemModalAdd from '../items/ModalAdd';
import ItemModalAddBook from '../items/ModalAddBook';
import ItemModalAddImage from '../items/ModalAddImage';


const ElementModalAdd = ({ handler, collection }) => {

  const [defaultCollectionOptions, setDefaultCollectionOptions] = useState(collection ? [{ node: collection }] : null);
  const [defaultItemOptions, setDefaultItemOptions] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(collection ? { node: collection } : null);
  const [selectedItem, setSelectedItem] = useState(null);
  const client = useApolloClient();
  const { opened, ok, cancel, backdrop } = handler;
  const { t } = useTranslation();

  const [getMyCollections] = useLazyQuery(GET_COLLECTIONS, {
    variables: {
      name_Icontains: null,
      mine: true,
      orderBy: 'name',
    },
  });

  const [getItems] = useLazyQuery(GET_ITEMS, {
    variables: {
      pk: null,
      name_Icontains: null,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const asyncCollectionOptions = async (search) => {
    var response = await getMyCollections({ variables: { name_Icontains: search } });
    setDefaultCollectionOptions(response?.data?.collections?.edges || [{ node: collection }]);
    return response?.data?.collections?.edges || [];
  };

  const asyncItemOptions = useCallback(async (search, store) => {
    var response = await getItems({ variables: { name_Icontains: search, first: 10 } });
    if (store === true) {
      setDefaultItemOptions(response?.data?.items?.edges || [{ node: collection }]);
    }
    return response?.data?.items?.edges || [];
  }, [collection, getItems]);

  const asyncItemOption = async (pk, store) => {
    var response = await getItems({ variables: { pk: pk } });
    if (store) {
      setDefaultItemOptions(response?.data?.items?.edges || [{ node: collection }]);
    }
    return response?.data?.items?.edges || [];
  };

  const handleSave = async (values) => {

    var formData = { ...values };
    formData.collection = formData.collection?.pk;
    formData.item = formData.item?.pk;

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.saving'));

    await postData('warehouse/elements/', formData, { sleep: 500 }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.element-create.created-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      await client.clearStore();
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const selectOptionByPk = async (pk) => {
    const options = await asyncItemOption(pk);

    if (options.length === 1) {
      setSelectedItem(options[0]);
      formik.setFieldValue("item", options[0].node || null);
    }
  };

  const createItemModalHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: async (response) => {
      selectOptionByPk(response.pk)
    },
  };

  const createItemModalBookHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: async (response) => {
      selectOptionByPk(response.pk)
    },
  };

  const createItemModalImageHandler = {
    opened: Object.assign({}, ...useState(false).map((e, i) => { return { [i === 0 ? 'value' : 'set']: e } })),
    ok: async (response) => {
      selectOptionByPk(response.pk)
    },
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      item: null,
      amount: 1,
      collection: collection,
    },
    onSubmit: (values) => handleSave(values),
  });

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      asyncItemOptions('', true);
    }
  }, [initialized, setInitialized, asyncItemOptions]);


  return (
    <>
      {createItemModalHandler.opened.value && <ItemModalAdd handler={createItemModalHandler} />}
      {createItemModalBookHandler.opened.value && <ItemModalAddBook handler={createItemModalBookHandler} />}
      {createItemModalImageHandler.opened.value && <ItemModalAddImage handler={createItemModalImageHandler} />}
      <Modal show={opened.value} onHide={handleClose} backdrop={disabled || backdrop ? 'static' : true}>
        <Modal.Header closeButton={!disabled}>
          <Modal.Title>{t('modals.element-create.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset disabled={disabled}>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.element-create.field-item')}</Form.Label>
              <Form.Control type="hidden" isInvalid={formik.errors?.fields?.item} />
              <InputGroup className="mb-3">
                <SplitButton
                  variant="outline-primary"
                  title=<FontAwesomeIcon icon={faPlus} />
                  id="segmented-button-dropdown-1"
                  onClick={() => createItemModalHandler.opened.set(true)}
                >
                  <Dropdown.Item onClick={() => createItemModalHandler.opened.set(true)}>{t('modals.element-create.new-item')}</Dropdown.Item>
                  <Dropdown.Item onClick={() => createItemModalBookHandler.opened.set(true)}>{t('modals.element-create.new-book')}</Dropdown.Item>
                  <Dropdown.Item onClick={() => createItemModalImageHandler.opened.set(true)}>{t('modals.element-create.new-image')}</Dropdown.Item>
                </SplitButton>
                <div className="react-select rounded-start-0 form-control p-0 border-0">
                  <AsyncSelect
                    cacheOptions
                    isClearable={true}
                    defaultOptions={defaultItemOptions}
                    getOptionLabel={(e) => e.node.name}
                    getOptionValue={(e) => e.node.pk}
                    loadOptions={asyncItemOptions}
                    className="border-0 react-select-container"
                    classNamePrefix="react-select"
                    name="item"
                    onChange={(o) => { setSelectedItem(o); formik.setFieldValue('item', o?.node || null) }}
                    placeholder={t('modals.element-create.field-item-placeholder')}
                    value={selectedItem}
                  />
                </div>
              </InputGroup>
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.item}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.element-create.field-collection')}</Form.Label>
              <Form.Control type="hidden" isInvalid={formik.errors?.fields?.collection} />
              <AsyncSelect
                cacheOptions
                defaultOptions={defaultCollectionOptions}
                getOptionLabel={(e) => e.node.name}
                getOptionValue={(e) => e.node.pk}
                className="react-select-container"
                classNamePrefix="react-select"
                loadOptions={asyncCollectionOptions}
                name="collection"
                onChange={(o) => { setSelectedCollection(o); formik.setFieldValue('collection', o?.node || null) }}
                placeholder={t('modals.element-create.field-collection-placeholder')}
                value={selectedCollection}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors?.fields?.collection}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('modals.element-create.field-amount')}</Form.Label>
              <Form.Control
                name="amount"
                onChange={formik.handleChange}
                autoComplete="off"
                type="number"
                value={formik.values.amount}
                isInvalid={formik.errors?.fields?.amount}
              />
              <Form.Control.Feedback type="invalid">{formik.errors?.fields?.amount}</Form.Control.Feedback>
            </Form.Group>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
          <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.create')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ElementModalAdd;
