import axios from 'axios';
import jwt from 'jwt-decode';
import { createContext, useCallback, useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';
import i18n from '../i18n';
import { toastDjangoError } from '../utils/axiosHelper';
import { isValidToken, setSession } from '../utils/jwt';


const INITIALIZE = 'INITIALIZE';
const SIGN_IN = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';
const SIGN_UP = 'SIGN_UP';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  payload: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        payload: action.payload,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        payload: action.payload,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        payload: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        payload: action.payload,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {

  const [state, dispatch] = useReducer(JWTReducer, initialState);

  const refreshToken = useCallback(async () => {

    const accessToken = window.localStorage.getItem('accessToken');

    const response = await axios.post(process.env.REACT_APP_JWT_REFRESH, {
      token: accessToken
    }).catch((axiosError) => {
      dispatch({ type: SIGN_OUT });
      window.localStorage.removeItem('accessToken')
      if (axiosError.response.data?.errors && typeof axiosError.response.data.errors === "string") {
        toast.warning(axiosError.response.data.errors, { autoClose: 4000 });
      } else {
        toast.warning(<div dangerouslySetInnerHTML={toastDjangoError(axiosError.response.status, axiosError.response.data)}></div>, { autoClose: 4000 });
      }
    });

    if (!response) {
      return;
    }

    const { token } = response.data;
    const payload = jwt(token);

    setSession(token);

    let expiredTimer;

    const currentTime = Date.now();
    const timeLeft = payload.exp * 1000 - currentTime;

    window.clearTimeout(expiredTimer);
    expiredTimer = window.setTimeout(() => {
      refreshToken();
    }, timeLeft);

    dispatch({
      type: SIGN_IN,
      payload: {
        isAuthenticated: true,
        user: payload.user,
        payload: payload,
      }
    });
  }, []);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          const response = await axios.post(
            process.env.REACT_APP_JWT_REFRESH,
            {
              token: accessToken,
            }
          );

          const { token } = response.data;
          const payload = jwt(token);

          setSession(token);

          let expiredTimer;

          const currentTime = Date.now();
          const timeLeft = payload.exp * 1000 - currentTime;

          window.clearTimeout(expiredTimer);
          expiredTimer = window.setTimeout(() => {
            refreshToken();
          }, timeLeft);

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user: payload.user,
              payload: payload,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
              payload: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
            payload: null,
          },
        });
      }
    };

    initialize();
  }, [refreshToken]);

  const signIn = async (email, password) => {

    axios.defaults.headers.common["Accept-Language"] = i18n.resolvedLanguage;

    const response = await axios.post(process.env.REACT_APP_JWT_LOGIN, {
      email,
      password,
    });
    const { token } = response.data;
    const payload = jwt(token);

    setSession(token);

    let expiredTimer;

    const currentTime = Date.now();
    const timeLeft = payload.exp * 1000 - currentTime;

    window.clearTimeout(expiredTimer);
    expiredTimer = window.setTimeout(() => {
      refreshToken();
    }, timeLeft);

    dispatch({
      type: SIGN_IN,
      payload: {
        isAuthenticated: true,
        user: payload.user,
        payload: payload,
      }
    });
  };

  const signOut = async (apolloClient) => {
    if (apolloClient) await apolloClient.clearStore();
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        authPayload: state?.payload,
        user: {
          email: state?.user?.email,
          first_name: state?.user?.first_name,
          gravatar: state?.user?.gravatar,
          last_name: state?.user?.last_name,
          pk: state?.user?.pk,
          repr: state?.user?.repr,
          thumbnail: state?.user?.thumbnail,
        },
        signIn,
        signOut,
        refreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
