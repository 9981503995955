import React from 'react';

import { useTranslation } from 'react-i18next';
import guestAvatar from '../../assets/img/artwork/guest_avatar.png';


const UserAvatar = ({ user, height = 32, width = 32, className = 'rounded-circle', border = 'border border-1 border-dark-subtle', alt = null }) => {

  const { t } = useTranslation();

  return (
    <>
      {user.thumbnail && (
        <img
          alt={alt || t('forms.alt-tags.avatar')}
          className={`${className} ${border}`}
          height={height}
          src={`${process.env.REACT_APP_MEDIA_URL}${user.thumbnail}`}
          width={width}
        />
      )}

      {!user.thumbnail && user.gravatar && (
        <img
          alt={alt || t('forms.alt-tags.avatar')}
          className={`${className} ${border}`}
          height={height}
          src={user.gravatar}
          width={width}
        />
      )}

      {!user.thumbnail && !user.gravatar && (
        <img
          alt={alt || t('forms.alt-tags.avatar')}
          className={`${className} ${border}`}
          height={height}
          src={guestAvatar}
          width={width}
        />
      )}
    </>
  );
};

export default UserAvatar;
