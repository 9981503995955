import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';


const ImportFromInstagram = ({ handler }) => {

  const [disabled, setDisabled] = useState(false);
  const authUrl = 'https://api.instagram.com/oauth/authorize?client_id=970287347679344&scope=user_profile,user_media&response_type=code&redirect_uri=https://lagerraum.pro/private/settings';
  const { opened, ok, cancel } = handler;
  const { t } = useTranslation();

  const handleClose = () => {
    opened.set(false);
    if (cancel) cancel();
  };

  const handleSave = async (values) => {
    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.importing'));

    await postData('importer/lagerraumv1/', values, { sleep: 300 }).then(async (response) => {
      opened.set(false);
      toast.update(brot, { render: t('modals.settings-import-v1.imported-successfully'), type: 'success', isLoading: false, autoClose: 2000 });
      ok(response.data);
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.update(brot, { render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    }).finally(() => {
      setDisabled(false);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: (values) => handleSave(values),
  });

  return (
    <Modal show={opened.value} onHide={handleClose} backdrop={disabled ? 'static' : true}>
      <Modal.Header closeButton={!disabled}>
        <Modal.Title>{t('modals.settings-import-v1.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <fieldset disabled={disabled}>
          <iframe
            className="w-100"
            style={{ height: '450px' }}
            src={authUrl}
            title="Instagram auth"
          >
          </iframe>
        </fieldset>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={disabled}>{t('forms.actions.cancel')}</Button>
        <Button variant="primary" onClick={formik.handleSubmit} disabled={disabled}>{t('forms.actions.import')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportFromInstagram;
