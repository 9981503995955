import React, { useState } from 'react';

import { useFormik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postData } from '../../utils/axiosHelper';


function RegisterConfirmOTPForm() {

  const location = useLocation();

  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const email = location?.state?.email;
  const navigate = useNavigate();
  const timestamp = location?.state?.timestamp;

  if (!timestamp || !email) {
    navigate('/auth/password-reset');
  }

  const activate = async (values) => {

    var formData = { ...values };

    setDisabled(true);
    const brot = toast.loading(t('widgets.toast.saving'));

    await postData('auth/register/otp-activate/', formData, { sleep: 500 }).then(async (response) => {
      navigate('/auth/login', { state: { justActivated: true } })
    }).catch((error) => {
      formik.setErrors({ fields: error.response.data });
      toast.error(`${t('widgets.toast.error')} ${error.response.status}`, { autoClose: 2000 });
    }).finally(() => {
      setDisabled(false);
      toast.dismiss(brot);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: '',
      email: email,
      timestamp: timestamp,
    },
    onSubmit: async (values) => {
      activate(values);
    },
  });

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{t('public.auth.field-otp')}</Form.Label>
        <Form.Control
          autoComplete="off"
          isInvalid={formik.errors?.fields?.code}
          name="code"
          onChange={formik.handleChange}
          value={formik.values.code}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors?.fields?.code}
        </Form.Control.Feedback>
      </Form.Group>
      <div className="text-center my-3">
        <div className="d-grid gap-2">
          <Button type="button" onClick={formik.handleSubmit} variant="primary" disabled={disabled}>
            {t('public.auth.btn-activate-account')}
          </Button>
        </div>
      </div>
      <small>
        <Link to="/auth/login" className="text-decoration-none">{t('forms.actions.cancel')}</Link>
      </small>
    </>
  );
}

export default RegisterConfirmOTPForm;
