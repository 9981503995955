import { useCallback, useEffect, useState } from 'react';

import { Container } from 'react-bootstrap';
import { Bookmarks as BookmarkIcon } from 'react-bootstrap-icons';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { Link, useLocation, useParams } from 'react-router-dom';
import remarkGfm from 'remark-gfm';
import { getObjs } from '../../utils/axiosHelper';

function ImageRenderer(props) {
  return <img data-loading="lazy" className={props.title} src={props.src} alt={props.alt} />;
}

const Wiki = () => {

  const [, setLoading] = useState(false);
  const [article, setArticle] = useState();
  const location = useLocation();
  const { t } = useTranslation();
  const { articleSlug } = useParams();

  const metaTags = [
    { name: 'description', content: t('pages.blog-article.meta-description') },
  ];

  const linkTags = [
    { rel: 'canonical', href: `${process.env.REACT_APP_SELF}${location.pathname}` },
  ];

  const renderBreadcrump = ({ className = 'mb-4', bsPrefix = 'breadcrumb' }) => {
    return (
      <Breadcrumb className={className} bsPrefix={bsPrefix}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/blog" }}>
          <BookmarkIcon className="bi img-fluid rounded me-1 align-text-top" width={20} height={20} style={{ maxHeight: '20px', minHeight: '20px' }} />
          {t('public.blog.home-breadcrump')}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {article?.title}
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  };

  const getArticle = useCallback(async () => {
    setLoading(true);
    await getObjs(`blog/${articleSlug}/`).then(async (response) => {
      setArticle(response.data);
    }).catch((error) => {
    }).finally(() => {
      setLoading(false);
    });
  }, [articleSlug]);


  useEffect(() => { getArticle() }, [getArticle]);

  return (
    <>
      <Helmet title={article?.title || '...'} meta={article ? [{ name: 'description', content: article.summary }] : metaTags} link={linkTags} />
      <Container>
        <div className="d-flex">
          <div className="flex-grow-1 me-3">
            <h2>{article?.title || '...'}</h2>
          </div>
          <div className="flex-shrink-0 d-none d-sm-flex">
          </div>
        </div>
        <hr className="mt-0 mb-3" />
        {renderBreadcrump({ bsPrefix: 'breadcrumb flex-column flex-sm-row' })}
        <div>
          <Markdown
            transformImageUri={uri => uri.startsWith("http") ? uri : `${process.env.REACT_APP_MEDIA_URL}${uri}`}
            components={{ "img": ImageRenderer }}
            remarkPlugins={[remarkGfm]
            }>
            {article?.body}
          </Markdown>
        </div>
      </Container>
    </>
  );
}

export default Wiki;
