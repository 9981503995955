import React from 'react';

import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const FoodLookupResult = ({ result, resultSize, createCallback }) => {

  const { t } = useTranslation();

  const performCreate = (pk) => {
    createCallback(pk);
  };

  return (
    <div className="d-flex mt-4">
      <div className="flex-shrink-0">
        {result.img_repr_thumbnail && <img src={`${process.env.REACT_APP_MEDIA_URL}${result.img_repr_thumbnail}`} className="rounded" alt={result.ident} style={{ maxWidth: '64px', width: '64px' }} />}
      </div>
      <div className={`flex-grow-1 ${result.img_repr_thumbnail ? 'ms-3' : ''}`}>
        <div className="fs-5">{result.title}</div>

        {result.description && <p>{result.description.substring(0, 100)}...</p>}

        <dl className="row mt-2">
          <dt className="col-4">{t('modals.item-create-food.name')}</dt>
          <dd className="col-8 mb-0">{result.name}</dd>
          <dt className="col-4">{t('modals.item-create-food.brands')}</dt>
          <dd className="col-8 mb-0">{result.brands}</dd>
          <dt className="col-4 mt-3">{t('modals.item-create-food.source')}</dt>
          <dd className="col-8 mt-3 mb-0">{result.get_source_display}</dd>
          <dt className="col-4">{t('modals.item-create-food.identifier')}</dt>
          <dd className="col-8 mb-0">{result.ident}</dd>
        </dl>

        {resultSize > 1 && (
          <Button onClick={() => performCreate(result.pk)}>{t('modals.item-create-food.use-this-result')}</Button>
        )}

        {/*<div><pre>{JSON.stringify(result, null, 4)}</pre></div>*/}
      </div>
    </div>
  );
};

export default FoodLookupResult;
