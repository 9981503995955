import React from 'react';

import { CircleHalf, MoonStars, Sun } from 'react-bootstrap-icons';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as Brand } from '../../assets/img/ci/brand.svg';
import { ReactComponent as Icon } from '../../assets/img/ci/icon.svg';
import lng_de from '../../assets/img/lng/de_circle.webp';
import lng_en from '../../assets/img/lng/en_circle.webp';
import lng_zhTW from '../../assets/img/lng/zh-TW_circle.webp';
import UserAvatar from '../../components/auth/UserAvatar';
import { THEME } from '../../constants';
import useAuth from '../../hooks/useAuth';
import useTheme from '../../hooks/useTheme';

const FLAGS = {
  'de': lng_de,
  'en': lng_en,
  'zh-TW': lng_zhTW,
};

const NavBar = ({ variant = 'default' }) => {

  const { t, i18n } = useTranslation();
  const { theme, setTheme, themeIsDark } = useTheme();

  const activeLngFlag = FLAGS[i18n.resolvedLanguage];
  const { signOut, user } = useAuth();
  const ActiveThemeIcon = theme === THEME.LIGHT ? Sun : theme === THEME.DARK ? MoonStars : CircleHalf;

  return (
    <Navbar expand="lg" className={`py-0 ${themeIsDark ? 'bg-dark' : 'bg-light'} border-bottom ${variant === 'blue' ? 'border-dark' : ''}`}>
      <Container className="px-md-0">
        <Link to="/" className="navbar-brand" alt={t('public.navbar.home')}>
          <Icon alt="Lagerraum" width="40" height="40" />
          <Brand className="ms-2 mt-1" width="90" />
        </Link>
        <Navbar className="justify-content-end">
          <NavDropdown
            className="nav-item-shrink pe-0"
            title=<ActiveThemeIcon className="rounded-circle border border-dark-subtle text-opacity-50 p-1" height={32} width={32} />
            id="theme-nav-dropdown"
            align="end"
          >
            <NavDropdown.Item onClick={() => setTheme(THEME.LIGHT)} active={theme === THEME.LIGHT}>
              <Sun className="align-text-bottom me-2" />
              <span>{t('meta.constants.theme-light')}</span>
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => setTheme(THEME.DARK)} active={theme === THEME.DARK}>
              <MoonStars className="align-text-bottom me-2" />
              {t('meta.constants.theme-dark')}
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => setTheme(THEME.AUTO)} active={theme === THEME.AUTO}>
              <CircleHalf className="align-text-bottom me-2" />
              {t('meta.constants.theme-auto')}
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            className="nav-item-shrink pe-0"
            title=<img src={activeLngFlag} className="rounded-circle border border-dark-subtle p-1" alt="current language" width="32" height="32" />
            id="lng-nav-dropdown"
            align="end"
          >
            <NavDropdown.Item onClick={() => { i18n.changeLanguage('de') }}>deutsch</NavDropdown.Item>
            <NavDropdown.Item onClick={() => { i18n.changeLanguage('en') }}>english</NavDropdown.Item>
            <NavDropdown.Item onClick={() => { i18n.changeLanguage('zh-TW') }}>繁體中文</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            className="nav-item-shrink pe-0"
            title=<UserAvatar user={user} className="rounded-circle border p-1" width="32" height="32" />
            id="basic-nav-dropdown"
            align="end"
          >
            {user?.pk && (
              <>
                <NavDropdown.Item as={Link} to="/private" className="pe-4">{t('public.navbar.usr.dashboard')}</NavDropdown.Item>
                <Dropdown.Divider />
                <NavDropdown.Item as={Link} to="/private/collections" className="pe-4">{t('public.navbar.usr.collections')}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/private/inventory" className="pe-4">{t('public.navbar.usr.inventory')}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/private/items" className="pe-4">{t('public.navbar.usr.items')}</NavDropdown.Item>
                <Dropdown.Divider />
                <NavDropdown.Item as={Link} to="/private/profile" className="pe-4">{t('public.navbar.usr.profile')}</NavDropdown.Item>
                <Dropdown.Divider />
                <NavDropdown.Item onClick={() => signOut()} className="pe-4">{t('public.navbar.logout')}</NavDropdown.Item>
              </>
            )}
            {!user?.pk && (
              <>
                <NavDropdown.Item as={Link} to="/auth/login" className="pe-4">{t('public.navbar.login')}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/auth/register" className="pe-4">{t('public.navbar.register')}</NavDropdown.Item>
              </>
            )}
          </NavDropdown>
        </Navbar>
      </Container>
    </Navbar>
  )
};

export default NavBar;
