import React, { useCallback, useEffect, useState } from 'react';

import { faChevronLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { CheckLg, QuestionLg } from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Formi from '../../components/basic/Formi';
import PrivateMetaNavHeader from '../../components/basic/PrivateMetaNavHeader';
import useAuth from '../../hooks/useAuth';
import { getObj, postData } from '../../utils/axiosHelper';
import { downloadBinary } from '../../utils/binaryDownload';
import { dateFromDate, dateTimeFromDate } from '../../utils/formatter';


const Invoice = () => {

  const [invoice, setInvoice] = useState();
  const [justPayed, setJustPayed] = useState(false);
  const { invoiceId } = useParams();
  const { t, i18n } = useTranslation();
  const { user } = useAuth();

  const getInvoice = useCallback(async () => {
    const brot = toast.loading(t('widgets.toast.loading'));

    await getObj(`accounting/invoices/${invoiceId}/`).then(async (response) => {
      setInvoice(response.data);
      toast.dismiss(brot);
    }).catch((error) => {
      toast.update(brot, {render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 2000 });
    });
  }, [invoiceId, t]);

  const initialOptions = {
    'client-id': process.env.REACT_APP_PP_CLIENT_ID,
    'currency': 'EUR',
  };

  const markInvoiceAsProcessed = async () => {
    const brot = toast.loading(t('widgets.toast.processing'));
    const formData = {
      id: invoiceId,
    };

    await postData(`accounting/invoices/${invoiceId}/processed/`, formData).then(async (response) => {
      setInvoice(response.data);
      toast.dismiss(brot);
    }).catch((error) => {
      toast.update(brot, {render: `${t('widgets.toast.error')} ${error.response.status}`, type: 'error', isLoading: false, autoClose: 3000 });
    });
  };

  const downloadInvoice = async (invoice) => {
    downloadBinary(`accounting/invoices/${invoice.id}/pdf/`);
  };

  useEffect(() => {
    if (user?.pk && invoiceId) {
      getInvoice();
    }
  }, [user?.pk, invoiceId, getInvoice]);


  if (!invoice) {
    return null;
  }

  return (
    <>
      <Helmet title={`${t('pages.invoice.headline-prefix')} ${invoice.number}`} />
      <PrivateMetaNavHeader />
      <div className="mainspace p-4">
        <div className="container">
          <h3 className="mb-3">
            <Link to="/private/accounting/" className="text-secondary text-decoration-none">
              <FontAwesomeIcon icon={faChevronLeft} size="sm" className="me-2" />
              {t('pages.invoice.back-to-overview')}
            </Link>
          </h3>
          <Row>
            <Col md={8}>
              <Card className="mb-5">
                <Card.Header className="bg-success text-light" as="h5">
                  {invoice.number}
                </Card.Header>
                <Card.Body className="">
                  <h4>{t('pages.invoice.details')}</h4>
                  <dl className="row mb-5">
                    <dt className="col-sm-4">{t('pages.invoice.number')}</dt>
                    <dd className="col-sm-8">{invoice.number}</dd>
                    <dt className="col-sm-4">{t('pages.invoice.created-at')}</dt>
                    <dd className="col-sm-8">{dateTimeFromDate(invoice.created_at, i18n.resolvedLanguage)}</dd>
                    <dt className="col-sm-4">{t('pages.invoice.payed-at')}</dt>
                    <dd className="col-sm-8">
                      {invoice.payed_at && dateTimeFromDate(invoice.payed_at, i18n.resolvedLanguage)}
                      {!invoice.payed_at && t('pages.invoice.not-yet-payed')}
                    </dd>
                    <dt className="col-sm-4">{t('pages.invoice.email-notification')}</dt>
                    <dd className="col-sm-8">
                      {invoice.email_sent_at && `${t('pages.invoice.email-notification-sent-at')} ${dateTimeFromDate(invoice.email_sent_at, i18n.resolvedLanguage)}`}
                      {!invoice.email_sent_at && t('pages.invoice.email-notification-not-send-Yet')}
                    </dd>
                  </dl>
                  <h4>{t('pages.invoice.items')}</h4>
                  <Table size="sm" className="mb-5" bordered striped responsive>
                    <thead>
                      <tr>
                        <th>{t('pages.invoice.item-description')}</th>
                        <th className="text-center">{t('pages.invoice.item-quantity')}</th>
                        <th className="text-end">{t('pages.invoice.item-costs')}</th>
                        <th className="text-end">{t('pages.invoice.item-tax-rate')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoice.items.map( (item, index) => (
                        <tr key={index}>
                          <td>{item.description}</td>
                          <td className="text-center">{item.amount}</td>
                          <td className="text-end">
                            <Formi currencyVal={item.costs} />
                          </td>
                          <td className="text-end">
                            <Formi decimalVal={item.included_vat * 100} />%
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="text-end">
                    <Button variant="primary" onClick={() => downloadInvoice(invoice)}>
                      <FontAwesomeIcon icon={faDownload} size="sm" className="me-2" />
                      {t('pages.invoice.pdf-version')}
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mb-5">
                <Card.Header className="bg-success text-light" as="h5">
                  {t('pages.invoice.payment-options')}
                </Card.Header>
                <Card.Body className="">
                  {invoice.paid && !justPayed && (
                    <div className="d-flex">
                      <div className="flex-shrink-0 d-none d-sm-flex">
                        <CheckLg className="text-success bg-light border rounded p-2" size={64} />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <p className="lead">
                          <Trans i18nKey="pages.invoice.invoice-payed-at" values={{ date: dateFromDate(invoice.payed_at, i18n.resolvedLanguage) }}><b>date</b></Trans>
                        </p>
                      </div>
                    </div>
                  )}
                  {!invoice.paid && !invoice.processed && !justPayed && (
                    <PayPalScriptProvider options={initialOptions}>
                      <PayPalButtons
                        createOrder={(data, actions) => {
                          return actions.order.create({
                            purchase_units: [
                              {
                                'reference_id': invoice.number,
                                'amount': {
                                  'currency_code': 'EUR',
                                  'value': invoice.total,
                                },
                              },
                            ],
                          });
                        }}
                        onApprove={(data, actions) => {
                          return actions.order.capture().then((details) => {
                            if (details.status === 'COMPLETED') {
                              setJustPayed(details.payer.name);
                              markInvoiceAsProcessed();
                            }
                          });
                        }}
                      />
                    </PayPalScriptProvider>
                  )}
                  {justPayed && (
                    <div className="d-flex">
                      <div className="flex-shrink-0 d-none d-sm-flex">
                        <CheckLg className="text-success bg-light border rounded p-2" size={64} />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <p className="lead">
                          <Trans i18nKey="pages.invoice.invoice-just-payed" values={{ full_name: `${justPayed.given_name} ${justPayed.surname}` }}><b>full name</b></Trans>
                        </p>
                      </div>
                    </div>
                  )}
                  {!invoice.paid && !justPayed && invoice.processed && (
                    <div className="d-flex">
                      <div className="flex-shrink-0 d-none d-sm-flex">
                        <QuestionLg className="text-warning bg-light border rounded p-2" size={64} />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <p className="lead">
                          <Trans i18nKey="pages.invoice.invoice-waiting-for-approval"></Trans>
                        </p>
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Invoice;
